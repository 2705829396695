<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_ORDER_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>
    </div>

    <DataTableComponent v-if="url" :columns="columns" :size="10" :url="url" @viewItem="viewOrder"></DataTableComponent>
     
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref, onMounted } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
export default defineComponent({
  name: "OrderListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
     const $t = inject("$translation");
     const columns = [
      {
        title: $t.getTranslation("LBL_ORDER_NUMBER"),
        field: 'OrderNumber'
      },
      {
        title: $t.getTranslation("LBL_USER_NAME"),
        field: "UserName"
      },
      {
        title: $t.getTranslation("LBL_FIRST_NAME"),
        field: "FirstName"
      },
      {
        title: $t.getTranslation("LBL_LAST_NAME"),
        field: "LastName"
      },
      {
        title: $t.getTranslation("LBL_COUPON_AMOUNT"),
        field: "UserVoucherAmount"
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.OrderStatusCode === "PAID" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.OrderStatusName || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_REDEEMED_DATE"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().RedeemedDate)}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_ORDER_CREATED"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        },
      },
    ];

    const viewOrder = (data) => {
      if (data?.value?.OrderKey) {
        props.f7router.navigate({ name: "orderView", params: { id: data.value.OrderKey } });
      }
    };

    let url = ref("");

    onMounted(async() => {
      if (props?.f7route?.query && props?.f7route?.query?.VoucherId) {
        url.value = `/admin/order/list?VoucherId=${props.f7route.query.VoucherId}`;
      }
    });

    return {
      columns,
      url,
      viewOrder
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
