<template>
  <link :href="require('@/assets/css/materialicon.css')" rel="stylesheet" />

  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_MERCHANT_SAVE') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation('LBL_MERCHANT_ACCOUNT_PROFILE') }}</f7-list-item>

          <f7-list-input
            v-model:value="formData.UserName"
            name="UserName"
            :required="validationRules?.UserName?.required"
            :minlength="validationRules?.UserName?.minimumLength"
            :maxlength="validationRules?.UserName?.maximumLength"
            :label="$t.getTranslation('LBL_USERNAME')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            :disabled="formData.MerchantId"
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Email"
            name="Email"
            :required="validationRules?.Email?.required"
            :minlength="validationRules?.Email?.minimumLength"
            :maxlength="validationRules?.Email?.maximumLength"
            :label="$t.getTranslation('LBL_EMAIL')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            :disabled="formData.MerchantId"
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.MobileNumber"
            name="MobileNumber"
            :required="validationRules?.MobileNumber?.required"
            :minlength="validationRules?.MobileNumber?.minimumLength"
            :maxlength="validationRules?.MobileNumber?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_NUMBER')"
            :placeholder="$t.getTranslation('LBL_MOBILE_NUMBER_PLACEHOLDER')"
            :info="$t.getTranslation('LBL_MOBILE_NUMBER_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="tel"
            validate
            :disabled="formData.MerchantId"
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Password"
            name="Password"
            :required="validationRules?.Password?.required"
            :minlength="validationRules?.Password?.minimumLength"
            :maxlength="validationRules?.Password?.maximumLength"
            :label="$t.getTranslation('LBL_PASSWORD')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="password"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.ConfirmPassword"
            name="ConfirmPassword"
            :required="validationRules?.ConfirmPassword?.required"
            :minlength="validationRules?.ConfirmPassword?.minimumLength"
            :maxlength="validationRules?.ConfirmPassword?.maximumLength"
            :label="$t.getTranslation('LBL_CONFIRM_PASSWORD')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="password"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.UserStatusCode"
            name="UserStatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_USER_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
            :disabled="formData.MerchantId"
          >
            <option v-for="userstatus in userStatusList" :key="'st_' + userstatus.Code" :value="userstatus.Code">{{ userstatus.Name }}</option>
          </f7-list-input>

          <f7-list-item divider>{{ $t.getTranslation('LBL_MERCHANT_DETAILS') }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.MerchantCode"
            name="MerchantCode"
            :required="validationRules?.MerchantCode?.required"
            :minlength="validationRules?.MerchantCode?.minimumLength"
            :maxlength="validationRules?.MerchantCode?.maximumLength"
            :label="$t.getTranslation('LBL_MERCHANT_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            :disabled="formData.MerchantId"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.SupplierCode"
            name="SupplierCode"
            :required="validationRules?.SupplierCode?.required"
            :minlength="validationRules?.SupplierCode?.minimumLength"
            :maxlength="validationRules?.SupplierCode?.maximumLength"
            :label="$t.getTranslation('LBL_SUPPLIER_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            :disabled="formData.MerchantId"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.CompanyName"
            name="CompanyName"
            :required="validationRules?.CompanyName?.required"
            :minlength="validationRules?.CompanyName?.minimumLength"
            :maxlength="validationRules?.CompanyName?.maximumLength"
            :label="$t.getTranslation('LBL_COMPANY_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.UrlShortCode"
            name="UrlShortCode"
            :required="validationRules?.UrlShortCode?.required"
            :minlength="validationRules?.UrlShortCode?.minimumLength"
            :maxlength="validationRules?.UrlShortCode?.maximumLength"
            :label="$t.getTranslation('LBL_URL_SHORT_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
            @keydown.space.prevent
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.ShippingFee"
            name="ShippingFee"
            :required="validationRules?.ShippingFee?.required"
            :minlength="validationRules?.ShippingFee?.minimumLength"
            :maxlength="validationRules?.ShippingFee?.maximumLength"
            :label="$t.getTranslation('LBL_SHIPPING_FEE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            step="any"
            validate
            clear-button
            @keydown.space.prevent
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.ShippingPerBottle"
            name="Shipping1stBottle"
            :required="validationRules?.ShippingPerBottle?.required"
            :minlength="validationRules?.ShippingPerBottle?.minimumLength"
            :maxlength="validationRules?.ShippingPerBottle?.maximumLength"
            :label="$t.getTranslation('FRM_SHIPPING_PER_ITEM')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            step="any"
            validate
            clear-button
            @keydown.space.prevent
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.ShippingStatusCode"
            name="ShippingStatusCode"
            :required="validationRules?.ShippingStatusCode?.required"
            :minlength="validationRules?.ShippingStatusCode?.minimumLength"
            :maxlength="validationRules?.ShippingStatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_SHIPPING_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
            v-if="formData.MerchantId"
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.StatusCode"
            name="ShippingStatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.ShippingStatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_MERCHANT_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
            v-if="formData.MerchantId"
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-list-item divider>{{ $t.getTranslation('LBL_MERCHANT_NAME_CULTURES') }}</f7-list-item>

          <div class="form-create">
            <f7-list form no-hairlines>
              <f7-list-input
                v-model:value="formData.MerchantName.EN"
                name="MerchantName"
                :required="validationRules?.MerchantName?.required"
                :minlength="validationRules?.MerchantName?.minimumLength"
                :maxlength="validationRules?.MerchantName?.maximumLength"
                :label="$t.getTranslation('LBL_MERCHANT_NAME_EN')"
                :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
                :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                floating-label
                outline
                type="text"
                validate
                clear-button
              >
              </f7-list-input>
              <f7-text-editor
                v-model:value="formData.MerchantDescription.EN"
                name="Description"
                :dividers="false"
                :required="validationRules?.Description?.required"
                :minlength="validationRules?.Description?.minimumLength"
                :maxlength="validationRules?.Description?.maximumLength"
                :label="$t.getTranslation('LBL_MERCHANT_DESCRIPTION_EN')"
                :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
                :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                outline
                floating-label
                validate
                clear-button
                @texteditor:change="(v) => (formData.MerchantDescription.EN = v)"
              >
              </f7-text-editor>
            </f7-list>
          </div>

          <div class="form-create">
            <f7-list form no-hairlines>
              <f7-list-input
                v-model:value="formData.MerchantName.CHS"
                name="MerchantName"
                :required="validationRules?.MerchantName?.required"
                :minlength="validationRules?.MerchantName?.minimumLength"
                :maxlength="validationRules?.MerchantName?.maximumLength"
                :label="$t.getTranslation('LBL_MERCHANT_NAME_CHS')"
                :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
                :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                floating-label
                outline
                type="text"
                validate
                clear-button
              >
              </f7-list-input>
              <f7-text-editor
                v-model:value="formData.MerchantDescription.CHS"
                name="Description"
                :dividers="false"
                :required="validationRules?.Description?.required"
                :minlength="validationRules?.Description?.minimumLength"
                :maxlength="validationRules?.Description?.maximumLength"
                :label="$t.getTranslation('LBL_MERCHANT_DESCRIPTION_CHS')"
                :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
                :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                outline
                floating-label
                validate
                clear-button
                @texteditor:change="(v) => (formData.MerchantDescription.CHS = v)"
              >
              </f7-text-editor>
            </f7-list>
          </div>

          <div class="form-create">
            <f7-list form no-hairlines>
              <f7-list-input
                v-model:value="formData.MerchantName.CHT"
                name="MerchantName"
                :required="validationRules?.MerchantName?.required"
                :minlength="validationRules?.MerchantName?.minimumLength"
                :maxlength="validationRules?.MerchantName?.maximumLength"
                :label="$t.getTranslation('LBL_MERCHANT_NAME_CHT')"
                :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
                :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                floating-label
                outline
                type="text"
                validate
                clear-button
              >
              </f7-list-input>
              <f7-text-editor
                v-model:value="formData.MerchantDescription.CHT"
                name="Description"
                :dividers="false"
                :required="validationRules?.Description?.required"
                :minlength="validationRules?.Description?.minimumLength"
                :maxlength="validationRules?.Description?.maximumLength"
                :label="$t.getTranslation('LBL_MERCHANT_DESCRIPTION_CHT')"
                :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
                :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                outline
                floating-label
                validate
                clear-button
                @texteditor:change="(v) => (formData.MerchantDescription.CHT = v)"
              >
              </f7-text-editor>
            </f7-list>
          </div>

          <!-- <f7-list-input
            v-model:value="formData.MerchantName.EN"
            name="MerchantName"
            :required="validationRules?.MerchantName?.required"
            :minlength="validationRules?.MerchantName?.minimumLength"
            :maxlength="validationRules?.MerchantName?.maximumLength"
            :label="$t.getTranslation('LBL_MERCHANT_NAME_EN')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.MerchantDescription.EN"
            name="Description"
            :required="validationRules?.Description?.required"
            :minlength="validationRules?.Description?.minimumLength"
            :maxlength="validationRules?.Description?.maximumLength"
            :label="$t.getTranslation('LBL_MERCHANT_DESCRIPTION_EN')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.MerchantName.CHS"
            name="MerchantName"
            :required="validationRules?.MerchantName?.required"
            :minlength="validationRules?.MerchantName?.minimumLength"
            :maxlength="validationRules?.MerchantName?.maximumLength"
            :label="$t.getTranslation('LBL_MERCHANT_NAME_CHS')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.MerchantDescription.CHS"
            name="Description"
            :required="validationRules?.Description?.required"
            :minlength="validationRules?.Description?.minimumLength"
            :maxlength="validationRules?.Description?.maximumLength"
            :label="$t.getTranslation('LBL_MERCHANT_DESCRIPTION_CHS')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.MerchantName.CHT"
            name="MerchantName"
            :required="validationRules?.MerchantName?.required"
            :minlength="validationRules?.MerchantName?.minimumLength"
            :maxlength="validationRules?.MerchantName?.maximumLength"
            :label="$t.getTranslation('LBL_MERCHANT_NAME_CHT')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.MerchantDescription.CHT"
            name="Description"
            :required="validationRules?.Description?.required"
            :minlength="validationRules?.Description?.minimumLength"
            :maxlength="validationRules?.Description?.maximumLength"
            :label="$t.getTranslation('LBL_MERCHANT_DESCRIPTION_CHT')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input> -->

          <div v-if="formData.MerchantId">
            <f7-list-item>
              <ViewImageListComponent :title="$t.getTranslation('LBL_UPLOADED_IMAGES')" :data="uploadedImages" />
            </f7-list-item>

            <f7-segmented raised tag="p">
              <f7-button @click="upload('HEADER_LOGO')">{{ $t.getTranslation('LBL_UPLOAD_HEADER_LOGO_IMAGE') }}</f7-button>
              <f7-button @click="upload('PROFILE_BANNER_IMAGE')">{{ $t.getTranslation('LBL_UPLOAD_PROFILE_BANNER_IMAGE') }}</f7-button>
            </f7-segmented>

            <!-- <f7-segmented raised tag="p">
              <f7-button @click="upload('SMALL_LOGO_IMAGE')">{{  $t.getTranslation('LBL_UPLOAD_SMALL_LOGO_IMAGE') }}</f7-button>
              <f7-button>{{  $t.getTranslation('LBL_UPLOAD_LARGE_LOGO_IMAGE') }}</f7-button>
            </f7-segmented> -->
          </div>

          <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
            {{ $t.getTranslation('LBL_SAVE') }}
          </f7-button>
        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from 'vue'

import { useStore } from '@/store'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'

import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'

import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import ViewImageListComponent from '@/components/ViewImageListComponent.vue'

export default defineComponent({
  name: 'CustomerSavePage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    ViewImageListComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const isUpdate = ref(false)
    const isButtonProcessing = ref(false)
    const userStatusList = computed(() => store.getters['config/getData']?.userStatusList)
    const statusList = computed(() => store.getters['config/getData']?.statusList)
    const shipingStatusList = computed(() => store.getters['config/getData']?.shipingStatusList)
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const mobileCodeList = computed(() => store.getters['config/getData']?.mobileCodeList)
    const uploadedImages = ref([])
    const merchantId = ref(null)
    const formData = reactive({
      MerchantName: {
        EN: '',
        CHS: '',
        CHT: ''
      },
      MerchantDescription: {
        EN: '',
        CHS: '',
        CHT: ''
      }
    })

    // uploadedImages.value.push({fields: "Identification", name: "Identification", link: helpers.getImage('http://localhost:3003/img/avatar_male.1e0b4bfd.svg', null, null, true)});

    let validationRules = {}
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys]
      }
    }

    onMounted(async () => {
      console.log(props?.f7route?.query?.MerchantId)
      if (props?.f7route?.query && props?.f7route?.query?.MerchantId) {
        isUpdate.value = true
        merchantId.value = props?.f7route?.query?.MerchantId
        await getData()
      }
    })

    const getData = async () => {
      try {
        helpers.showLoader()
        let res = await $HTTP.get(`/admin/merchant/view?MerchantId=${merchantId.value}`)
        console.log(res, '=fff')
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()
          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key]
          }

          for (const img of res.data.data.MerchantImages) {
            let name
            if (img.FieldName == 'HeaderLogo') {
              name = $t.getTranslation('LBL_HEADER_LOGO_IMAGE')
            }
            if (img.FieldName == 'ProfileBannerImage') {
              name = $t.getTranslation('LBL_PROFILE_BANNER_IMAGE')
            }

            uploadedImages.value.push({ fields: img.FieldName, name: name, link: helpers.getImage(img.FileName, 'MERCHANT') })
          }

          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false)

      return !isValid
    })

    const upload = (type) => {
      props.f7router.navigate({ name: 'merchantUpload', params: { id: formData.MerchantId }, query: { type: type } })
    }

    const save = async () => {
      try {
        let isValid = validate(true)
        if (formData.Password && !(formData.ConfirmPassword == formData.Password)) {
          throw new Error($t.getTranslation('LBL_ERROR_PASSWORD_CONFIRM_PASSWORD_MATCH'))
        }

        if (isValid) {
          isButtonProcessing.value = true
          helpers.showLoader()
          formData.LanguageCode = currentLanguageCode.value
          //send update to server
          let res = await $HTTP.post('/admin/merchant/save', formData)
          isButtonProcessing.value = false
          helpers.hideLoader()
          formData.MerchantId = res.data.data.MerchantId
          merchantId.value = formData.MerchantId
          //recall the api
          //props.f7router.back({ force: true, ignoreCache: true });
          helpers.createNotification({ type: 'success', title: $t.getTranslation('LBL_SUCCESS'), message: $t.getTranslation('LBL_SUCCESS_SAVE_MERCHANT') })
          return

          throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
        }
      } catch (err) {
        isButtonProcessing.value = false
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    return { isUpdate, formData, validationRules, isButtonProcessing, isDisableSaveButton, save, userStatusList, uploadedImages, upload, mobileCodeList, statusList, shipingStatusList }
  }
})
</script>
