<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_PRODUCT_SALE_SAVE') }}</h1>
        </div>
      </div>
      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation('LBL_PRODUCT_SALE_DETAILS') }}</f7-list-item>
          <f7-list-item>
            <template v-if="formData.ProductId">
              <ProductListCardComponent :directSend="true" :sendProduct="removeProduct" :data="formData.Product" :category="true" style="width: calc(100% - 23px)" />
            </template>
          </f7-list-item>

          <f7-list-item>
            <f7-button class="select-user" large fill raised @click="selectSku()">
              {{ $t.getTranslation('BTN_ADD_SKU') }}
            </f7-button>
          </f7-list-item>

          <f7-list-input
            v-model:value="formData.QtyPerUser"
            name="QtyPerUser"
            :required="validationRules?.QtyPerUser?.required"
            :minlength="validationRules?.QtyPerUser?.minimumLength"
            :maxlength="validationRules?.QtyPerUser?.maximumLength"
            :label="$t.getTranslation('LBL_AVAILABLE_QTY_PER_USER')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.QtyPerOrder"
            name="QtyPerOrder"
            :required="validationRules?.QtyPerOrder?.required"
            :minlength="validationRules?.QtyPerOrder?.minimumLength"
            :maxlength="validationRules?.QtyPerOrder?.maximumLength"
            :label="$t.getTranslation('LBL_QTY_ON_ALL_ORDER')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.QtyMaxPerUser"
            name="QtyMaxPerUser"
            :required="validationRules?.QtyMaxPerUser?.required"
            :minlength="validationRules?.QtyMaxPerUser?.minimumLength"
            :maxlength="validationRules?.QtyMaxPerUser?.maximumLength"
            :label="$t.getTranslation('LBL_QTY_MAX_PER_USER')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            placeholder=""
            validate
            v-if="formData.Product?.Moq > 1"
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Amount"
            name="Amount"
            :required="validationRules?.Amount?.required"
            :minlength="validationRules?.Amount?.minimumLength"
            :maxlength="validationRules?.Amount?.maximumLength"
            :label="$t.getTranslation('LBL_PRODUCT_SALE_AMOUNT')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.SaleFrom"
            name="StartDate"
            :required="validationRules?.StartDate?.required"
            :minlength="validationRules?.StartDate?.minimumLength"
            :maxlength="validationRules?.StartDate?.maximumLength"
            :label="$t.getTranslation('LBL_VALID_FROM')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="datetime-local"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.SaleTo"
            name="StartDate"
            :required="validationRules?.EndDate?.required"
            :minlength="validationRules?.EndDate?.minimumLength"
            :maxlength="validationRules?.EndDate?.maximumLength"
            :label="$t.getTranslation('LBL_VALID_TO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="datetime-local"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.StatusCode"
            name="StatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.WareHouseRegionCode"
            name="WareHouseRegionCode"
            :required="validationRules?.WareHouseRegionCode?.required"
            :minlength="validationRules?.WareHouseRegionCode?.minimumLength"
            :maxlength="validationRules?.WareHouseRegionCode?.maximumLength"
            :label="$t.getTranslation('LBL_WAREHOUSE_REGION')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
            v-if="wareHouseRegions.length > 0"
          >
            <option v-for="region in wareHouseRegions" :key="'wh_' + region.Code" :value="region.Code">{{ region.Name }}</option>
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.IsCoupon"
            name="IsCoupon"
            :required="validationRules?.IsCoupon?.required"
            :minlength="validationRules?.IsCoupon?.minimumLength"
            :maxlength="validationRules?.IsCoupon?.maximumLength"
            :label="$t.getTranslation('LBL_IS_COUPON')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="status in statusCouponList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-button large fill raised preloader @click="saveData">
            {{ $t.getTranslation('LBL_SAVE') }}
          </f7-button>

          <f7-button large fill raised preloader @click="back">
            {{ $t.getTranslation('LBL_BACK') }}
          </f7-button>
        </f7-list>
      </div>
    </section>

    <SkuSelectionPopUpComponent :open="openSkuPopup" @closed="onCloseSkuPopup" @selected="onSkuSelected" :isSale="true" />
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from 'vue'

import { useStore } from '@/store'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'

import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'

import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import SkuSelectionPopUpComponent from '@/components/SkuSelectionPopUpComponent.vue'
import ProductListCardComponent from '@/components/cards/ProductListCardComponent.vue'

export default defineComponent({
  name: 'BannerSavePage',
  components: {
    ProductListCardComponent,
    SkuSelectionPopUpComponent,
    MainHeaderComponent,
    MainFooterComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const statusList = computed(() => store.getters['config/getData']?.statusList)

    const statusCouponList = ref([
      {
        Code: 1,
        Name: $t.getTranslation('LBL_YES')
      },
      {
        Code: 0,
        Name: $t.getTranslation('LBL_NO')
      }
    ])

    const wareHouseRegions = ref([])

    const formData = reactive({
      StatusCode: '',
      SpecificSku: null
    })

    let validationRules = {}
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys]
      }
    }

    onMounted(async () => {
      if (props?.f7route?.query && props?.f7route?.query?.ProductSaleId) {
        formData.ProductSaleId = props?.f7route?.query?.ProductSaleId
        await getData()
      }
    })

    const getData = async () => {
      try {
        helpers.showLoader()
        let res = await $HTTP.get(`/admin/product/view/sale?ProductSaleId=${formData.ProductSaleId}`)
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()

          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key]
          }

          let region = res?.data?.data?.WareHouseRegions || []

          if (region && region.length > 0) {
            region = region.split(',')
            region.forEach((element) => {
              wareHouseRegions.value.push({
                Code: element,
                Name: element
              })
            })
          }

          formData.SaleFrom = helpers.formatDatimeTimePicker(formData.SaleFrom)
          formData.SaleTo = helpers.formatDatimeTimePicker(formData.SaleTo)
          helpers.hideLoader()
          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const saveData = async () => {
      try {
        helpers.showLoader()

        formData.SaleFrom = helpers.formatSQLDateTime(formData.SaleFrom)
        formData.SaleTo = helpers.formatSQLDateTime(formData.SaleTo)

        let StartDate = new Date(formData.SaleFrom).getTime()
        let EndDate = new Date(formData.SaleTo).getTime()

        // if (!(helpers.isDecimal(formData.Amount))) {
        //   throw new Error($t.getTranslation("ERR_INVALID_AMOUNT"));
        // }

        if (EndDate < StartDate) {
          throw new Error($t.getTranslation('ERR_INVALID_DATE'))
        }

        if (helpers.isBlank(formData.ProductId)) {
          throw new Error($t.getTranslation('ERR_PLEASE_SELECT_PRODUCT'))
        }
        let res = await $HTTP.post(`/admin/product/sale/save`, formData)

        if (res?.status === 200 && res?.data?.data) {
          formData.ProductSaleId = res?.data?.data.ProductSaleId
          helpers.createNotification({ type: 'success', title: $t.getTranslation('LBL_SUCCESS'), message: $t.getTranslation('LBL_INFO_SUCCESSFULLY_SAVE_PRODUCT_SALE') })
          helpers.hideLoader()
          back()
          return
        }

        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const back = () => {
      props.f7router.navigate({ name: 'productSaleList', params: {}, query: {} })
    }

    const openSkuPopup = ref(false)
    const onCloseSkuPopup = () => {
      openSkuPopup.value = false
    }
    const selectSku = () => {
      openSkuPopup.value = true
    }
    const onSkuSelected = (data) => {
      if (data?.WareHouseRegions) {
        data.WareHouseRegions.forEach((element) => {
          wareHouseRegions.value.push({
            Code: element,
            Name: element
          })
        })
      }

      formData.Product = data
      formData.ProductId = data.ProductId
    }
    const removeProduct = (data) => {
      formData.Product = null
    }
    return {
      openSkuPopup,
      onCloseSkuPopup,
      selectSku,
      onSkuSelected,
      removeProduct,
      formData,
      validationRules,
      back,
      statusList,
      saveData,
      statusCouponList,
      wareHouseRegions
    }
  }
})
</script>
