<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'shopping-cart']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_ORDER_LIST") }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button v-if="showBatchReleased" large fill raised @click="onBatchReleased">{{ $t.getTranslation("LBL_BATCH_RELEASE") }}</f7-button>
        <f7-button large fill raised @click="onGenerateReport">{{ $t.getTranslation("LBL_GENERATE_REPORT") }}</f7-button>
      </div>
    </div>

    <div v-if="orderCommissionInfo" class="board-container">
      <div class="board">
        <h1>{{ "$" + $h.formatPrice(orderCommissionInfo.TotalPendingCommission) }}</h1>
        <h3>{{ $t.getTranslation("LBL_PENDING_GROUP_COMMISSION") }}</h3>
      </div>
      <div class="board">
        <h1>{{ "$" + $h.formatPrice(orderCommissionInfo.TotalAvailableCommission) }}</h1>
        <h3>{{ $t.getTranslation("LBL_AVAILABLE_GROUP_COMMISSION") }}</h3>
      </div>
      <div class="board">
        <h1>{{ "$" + $h.formatPrice(orderCommissionInfo.TotalRedeemedCommission) }}</h1>
        <h3>{{ $t.getTranslation("LBL_REDEEMED_GROUP_COMMISSION") }}</h3>
      </div>
    </div>

    <DataTableComponent2
      ref="dataTableComponent"
      :class="{ 'has-board': orderCommissionInfo }"
      :columns="columns"
      :configs="tableConfig"
      :actions="actions"
      :url="url"
      :size="15"
      :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')"
      @performAction="performAction"
    ></DataTableComponent2>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref, onMounted } from "vue";
import { Dom7 } from "framework7";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { configs } from "@/utils/configs";

export default defineComponent({
  name: "OrderListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const orderCommissionInfo = ref(null);
    const showBatchReleased = ref(false);

    const actions = [
      {
        groupText: "Perform Actions",
        list: [{ text: "View", type: "VIEW", bold: true }],
      },
    ];

    const tableConfig = {
      groupBy: "ParentOrderNumber",
      groupToggleElement: "header",
    };

    const columns = [
      {
        frozen: true,
        title: `
          <label class="checkbox no-ripple">
            <input class="main-checkbox" type="checkbox" />
            <i class="icon-checkbox"></i>
          </label>
        `,
        minWidth: 35,
        maxWidth: 35,
        formatter(cell) {
          return `
            <div>
              <label class="checkbox">
                <input class="check-item" type="checkbox" value="${cell.getData()?.OrderId}" />
                <i class="icon-checkbox"></i>
              </label>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_ID"),
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().OrderId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_ORDER_INFO"),
        frozen: true,
        minWidth: 80,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().OrderNumber || "--"}</strong></div>
              <div><span>${$t.getTranslation("LBL_COURIER")}:</span> ${cell.getData().CourierTrackingNo || ""}</div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.OrderStatusCode)} ${cell.getData()?.OrderStatusCode}">
                <div class="chip-label">${cell.getData()?.OrderStatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      // {
      //   title: $t.getTranslation("LBL_PARENT_ORDER_INFO"),
      //   minWidth: 80,
      //   maxWidth: 220,
      //   formatter(cell) {
      //     if (cell.getData()?.IsParent) {
      //       return `
      //         <div>
      //           <div class="chip color-teal">
      //             <div class="chip-label">${$t.getTranslation("LBL_PARENT_ORDER")}</div>
      //           </div>
      //         </div>
      //       `;
      //     } else {
      //       return `
      //         <div>
      //           ${cell.getData()?.ParentOrderNumber || ""}
      //         </div>
      //       `;
      //     }
      //   },
      // },
      {
        title: $t.getTranslation("LBL_ORDER_ITEM_INFO"),
        minWidth: 220,
        maxWidth: 340,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().SKU || ""}</strong> - ${cell.getData().MerchantName || ""}</div>
              <div>${cell.getData().ProductName || ""}</div>
              <div><strong>${$t.getTranslation("LBL_QTY")}:</strong> ${cell.getData().Quantity || 0}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_INFO"),
        minWidth: 220,
        maxWidth: 340,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().IdNumber || ""}</strong></div>
              <div>${cell.getData().BuyerName || ""}</div>
              <div>${cell.getData().UserName || ""} - ${cell.getData().Email || "--"}</div>
              <div>${cell.getData().MobileCode || ""}${cell.getData().MobileNumber || ""}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DISCOUNT_INFO"),
        minWidth: 220,
        maxWidth: 340,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_COUPON_AMOUNT")}:</strong> ${helpers.formatNumber(cell.getData().UserVoucherAmount || 0)}</div>
              <div><strong>${$t.getTranslation("LBL_ORDER_DISCOUNT")}:</strong> ${helpers.formatNumber(cell.getData().OrderDiscount || 0)}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_SHIPPING_INFO"),
        minWidth: 220,
        maxWidth: 340,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_SHIPPING_FEE")}:</strong> ${helpers.formatNumber(cell.getData().ShippingFee || 0)}</div>
              <div><strong>${$t.getTranslation("LBL_SHIPPING_TOTAL")}:</strong> ${helpers.formatNumber(cell.getData().ShippingTotal || 0)}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PAYMENT_INFO"),
        minWidth: 220,
        maxWidth: 340,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_SUB_TOTAL")}:</strong> ${helpers.formatNumber(cell.getData().SubTotal || 0)}</div>
              <div><strong>${$t.getTranslation("LBL_TAX")}:</strong> ${helpers.formatNumber(cell.getData().TotalOrderTax || 0)}</div>
              <div><strong>${$t.getTranslation("LBL_GRAND_TOTAL")}:</strong> ${helpers.formatNumber(cell.getData().GrandTotal || 0)}</div>
              ${
                cell.getData()?.PaymentMethod
                  ? `<div class="chip color-primary">
                    <div class="chip-label">${cell.getData()?.PaymentMethod || "--"}</div>
                  </div>`
                  : ""
              }
              <div><strong>${$t.getTranslation("LBL_TERMINAL")}:</strong> ${cell.getData().PaymentTerminal || ""}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_GROUP_COMMISSION_INFO"),
        minWidth: 220,
        maxWidth: 340,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_COMMISSION")}:</strong> ${cell.getData().GroupCommission || 0}</div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.GroupCommissionStatus)} ${cell.getData()?.GroupCommissionStatus}">
                <div class="chip-label">${cell.getData()?.GroupCommissionStatus || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_CUSTOMS_INFO"),
        minWidth: 150,
        maxWidth: 340,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.CustomsStatus === "YES" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.CustomsStatus || "--"}</div>
              </div>
              <div title="Date Pushed">${helpers.formatDateTime(cell.getData().LastCustomsPush)}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="${$t.getTranslation("LBL_DATE_CREATED")}"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="${$t.getTranslation("LBL_DATE_UPDATED")}"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const url = ref("/admin/order/datalist");

    if (props?.f7route?.query?.ParentOrderId) {
      url.value += `?ParentOrderId=${props?.f7route?.query?.ParentOrderId}`;
    } else if (props?.f7route?.query?.referrer && props?.f7route?.query?.referrer != "") {
      url.value += `?ReferrerKey=${props?.f7route?.query?.referrer}`;

      (async () => {
        let ret = await get("/admin/order/commission/info", {
          ReferrerKey: props?.f7route?.query?.referrer,
        });

        orderCommissionInfo.value = ret;
      })();
    } else if (props?.f7route?.query?.referrergroup && props?.f7route?.query?.referrergroup != "") {
      url.value += `?GroupLinkCode=${props?.f7route?.query?.referrergroup}`;
    } else if (props?.f7route?.query?.groupbuy && props?.f7route?.query?.groupbuy != "") {
      url.value += `?GroupBuyId=${props?.f7route?.query?.groupbuy}`;

      columns.splice(2, 0, {
        title: $t.getTranslation("LBL_GROUP_BUY_ORDER_NUMBER"),
        field: "GroupBuyOrderNumber",
        frozen: true,
      });
    } else if (props?.f7route?.query?.type && props?.f7route?.query?.type === "markedorder" && props?.f7route?.query?.UserId) {
      url.value += `?Type=MARKEDORDER&UserId=${props?.f7route?.query?.UserId}`;
    }

    if (props?.f7route?.query?.commission_status) {
      url.value += `${url.value.indexOf("?") > -1 ? "&" : "?"}GroupCommissionStatus=${props?.f7route?.query?.commission_status}`;
      showBatchReleased.value = props?.f7route?.query?.commission_status === "available" ? true : false;
    }

    const getCheckList = () => {
      let checkList = [];

      Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
        let isChecked = Dom7(item).prop("checked");
        if (isChecked) {
          checkList.push(parseInt(Dom7(item).value()));
        }
      });

      return checkList;
    };

    const onBatchReleased = async () => {
      let checkList = getCheckList();

      if (checkList.length > 0) {
        helpers.createRemarks({
          message: $t.getTranslation("LBL_CONFIRM_RELEASE_GROUP_COMMISSION"),
          confirm: async (Remarks) => {
            if (Remarks) {
              let doneCounter = 0;
              for (let OrderId of checkList) {
                await post("/admin/user/groupcommission/release", { OrderId: OrderId, Remarks });
                doneCounter++;
              }

              if (doneCounter == checkList.length) {
                helpers.createNotification({
                  type: "info",
                  title: "INFO",
                  message: "Successfully release user group commission.",
                });

                window.location.reload();
              }
            }
          },
        });
      }
    };

    const onGenerateReport = async () => {
      let checkList = getCheckList();

      if (checkList.length > 0) {
        helpers.showLoaderText("Generating Report");

        let returnData = await post(`/report/generate/table`, {
          ReportType: "order",
          List: checkList,
        });

        if (returnData?.FileName) {
          let reportFileLink = `${configs.baseURL}/report/download?FileName=${returnData?.FileName}&FileType=${returnData?.FileType}`;
          window.open(reportFileLink);
        }

        helpers.hideLoaderText();
      }
    };

    onMounted(() => {
      Dom7("body").on("change", ".main-checkbox", (e) => {
        let isChecked = Dom7(".main-checkbox").prop("checked");
        if (isChecked) {
          Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
            Dom7(item).prop("checked", true);
          });
        } else {
          Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
            Dom7(item).prop("checked", false);
          });
        }
      });

      Dom7("body").on("click", ".tabulator-page", (e) => {
        Dom7(".main-checkbox").prop("checked", false);
      });
    });

    return {
      url,
      columns,
      actions,
      tableConfig,
      orderCommissionInfo,
      showBatchReleased,
      onBatchReleased,
      onGenerateReport,
    };
  },
  methods: {
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.value?.OrderKey) {
            self.f7router.navigate({ name: "orderView", params: { id: data.value.OrderKey } });
          }
          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
