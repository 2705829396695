<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_REVIEW_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @viewItem="editItem" @editItem="editItem"></DataTableComponent>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, reactive } from "vue";

import _ from "lodash";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "ProductDetailsPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
     const $t = inject("$translation");
     const store = useStore();
     const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
     const categoyList = ref([]);
     const url = `/admin/review/list?&LanguageCode=${currentLanguageCode.value || 'EN'}`;

     const columns = [
      {
        title: $t.getTranslation("LBL_PRODUCT_NAME"),
        field: 'Name'
      },
      {
        title: $t.getTranslation("LBL_ORDER_NUMBER"),
        field: "OrderNumber"
      },
      {
        title: $t.getTranslation("LBL_RATING"),
        field: 'Rating'
      },
      {
        title: $t.getTranslation("LBL_FIRST_NAME"),
        field: 'FirstName'
      },
      {
        title: $t.getTranslation("LBL_LAST_NAME"),
        field: 'LastName'
      },
      {
        title: $t.getTranslation("LBL_EMAIL"),
        field: "Email"
      },
      
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_LAST_CREATED"),
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        },
      }
    ];

    onMounted(async () => {
  
    });

    const editItem = (data) => {
        if (data?.value?.BannerId) {
          props.f7router.navigate({ name: "bannerSave", query: { BannerId: data.value.BannerId } });
        }
    };

    return {
        categoyList,
        columns,
        url,
        editItem
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img{
  width: 100px;
  height: 100px;
}
.tab-container{
  margin-top: 30px;
}
</style>
