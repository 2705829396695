<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_MESSAGES_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @viewItem="viewItem"></DataTableComponent>
     
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, computed } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { useStore } from "@/store";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
export default defineComponent({
  name: "BlankPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object },
  setup(props) {
     const $t = inject("$translation");
     const store = useStore();

     const columns = [
      {
        title: $t.getTranslation("LBL_USER_NAME"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.renderUserName(cell.getData())}
            </div>
          `;
        }
      }, 
      {
        title: $t.getTranslation("LBL_SOURCE"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${cell.getData()?.Source?.toUpperCase() || "N/A"}
            </div>
          `;
        }
      }, 
      {
        title: $t.getTranslation("LBL_IS_ANSWERED"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${(cell.getData().IsAnswered == 'YES') ? "Y" : "N"}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_CUSTOMER_SUPPORT"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.renderUserName(cell.getData().Thread.CustomerSupport)}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_LAST_MESSAGE"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${$t.getTranslation(cell.getData().Thread.LastMsg || "---")}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_LAST_MESSAGE_DATE_TIME"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${(cell.getData().Thread.LastMsg) ? helpers.formatDateTime(cell.getData().Thread.LastModified) : "---"}
            </div>
          `;
        }
      }, 
      {
        title: $t.getTranslation("LBL_DATE_CREATED"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        }
      }
    ];

    const viewItem = (data) => {
      if (data?.value?.ThreadKey) {
        props.f7router.navigate({ name: "messageAllView", query: { UserKey : data.value.Thread.User.UserKey, ThreadKey : data.value.ThreadKey } });
      }
    };

    let url = `/admin/message/list`;
    return {
      columns,
      url,
      viewItem
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
