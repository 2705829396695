<template>
  <f7-popup class="popup-videoupload" :class="[params.type, params.name]" :tablet-fullscreen="false" :backdrop="backdrop">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation("LBL_UPLOAD_VIDEO") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="toggleFullScreen">
            <font-awesome-icon v-if="!isFullScreen" :icon="['far', 'expand']" fixed-width />
            <font-awesome-icon v-if="isFullScreen" :icon="['far', 'compress']" fixed-width />
          </f7-link>

          <f7-link @click="closePopup">
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block v-if="videoSource" class="upload-video-container">
        <video controls autoplay muted>
          <source :src="videoSource" type="video/mp4" />
          {{ $t.getTranslation("LBL_BROWSER_NO_SUPPORT_VIDEO") }}
        </video>

        <div class="button-container">
          <div>
            <f7-button large @click="changeVideo($refs)">
              <font-awesome-icon :icon="['fas', 'video']" fixed-width />
            </f7-button>
            <f7-button fill large @click="useVideo"> {{ $t.getTranslation("LBL_USE_VIDEO") }} </f7-button>
          </div>
        </div>
      </f7-block>

      <f7-block v-if="!videoSource" class="upload-video-container select-video" @click="selectVideo($refs)">
        <img src="@/assets/images/svg/upload-add.svg" alt="Select Video" />

        <h1>{{ title || $t.getTranslation("LBL_BROWSE_SELECT_VIDEO") }}</h1>

        <p>
          {{ description || $t.getTranslation("LBL_BROWSE_SELECT_VIDEO_SUB") }}<br />
          <strong>{{ $t.getTranslation("LBL_MAXIMUM_UPLOAD_IMAGE_SIZE_IS") }} {{ videoSizeLimit }}MB.</strong>
        </p>
      </f7-block>

      <input ref="fileInput" capture="user" type="file" accept="video/*" @change="loadVideo($event)" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";

import { helpers } from "@/utils/helpers.js";

export default defineComponent({
  name: "VideoUploadPopUpComponent2",
  components: {},
  props: {
    title: {
      type: String,
    },
    description: String,
    videoSizeLimit: Number,
    backdrop: {
      type: Boolean,
      default: true,
    },
    params: Object,
  },
  emits: ["opened", "closed", "selected"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    let file = false;

    const isFullScreen = ref(false);
    const videoSource = ref(false);

    onMounted(() => reset);

    const reset = () => {
      videoSource.value = false;
    };

    const closePopup = () => {
      f7.popup.close(".popup-videoupload");

      emit("closed", true);
      setTimeout(reset, 300);
    };

    const toggleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value;

      if (Dom7(".popup.popup-videoupload.modal-in").length > 0) {
        if (isFullScreen.value === true) {
          Dom7(".popup.popup-videoupload.modal-in").addClass("popup-fullscreen");
        } else {
          Dom7(".popup.popup-videoupload.modal-in").removeClass("popup-fullscreen");
        }
      }
    };

    const selectVideo = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const loadVideo = (event) => {
      let input = event.target;

      if (input?.files?.[0]) {
        if (input?.files?.[0]?.size > props.videoSizeLimit * 1000 * 1024) {
          throw new Error(`${$t.getTranslation("LBL_ERROR_IMAGE_FILE_SIZE_EXCEEDED")} ${props.videoSizeLimit} MB`);
        }

        file = input?.files?.[0];
        let reader = new FileReader();

        reader.onload = (e) => {
          videoSource.value = e.target.result;
        };

        reader.readAsDataURL(input.files[0]);
      }
    };

    const changeVideo = (refs) => {
      refs.fileInput.click();
      videoSource.value = false;
      refs.fileInput.value = "";
    };

    const useVideo = () => {
      closePopup();
      emit("selected", { ...props.params, file, data: videoSource.value });
    };

    return {
      props,
      isFullScreen,
      closePopup,
      toggleFullScreen,
      videoSource,
      selectVideo,
      loadVideo,
      changeVideo,
      useVideo,
    };
  },
  methods: {
    openPopup(type, name) {
      f7.popup.open(`.popup-videoupload${type && !helpers.isBlank(type) ? "." + type : ""}${name && !helpers.isBlank(name) ? "." + name : ""}`);

      this.$emit("opened", true);
    },
  },
});
</script>

<style scoped>
.popup.popup-videoupload {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;

  /* POPUP CONFIG */
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 500px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;

  transition: all 300ms ease-in-out;
}
.popup.popup-videoupload.popup-fullscreen {
  --f7-popup-tablet-width: 100%;
  transform: translate3d(100vw, 0, 0);
}
.popup.popup-videoupload.modal-in {
  transform: translate3d(0, 0, 0);
}
.popup.popup-videoupload.modal-out {
  transform: translate3d(100vh, 0, 0);
}
.popup.popup-videoupload .navbar a.link {
  padding: 0 10px;
  min-width: 20px;
}
.popup.popup-videoupload .navbar .title {
  padding-left: 10px;
}
.upload-video-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 20px 0px 0px;
  padding: 20px 30px 0px;
}
.select-video {
  margin-top: 40px;
}
.upload-video-container video {
  width: calc(100% - 20px);
  margin: 20px auto;
  border-radius: 10px;
}
.upload-video-container img {
  width: 200px;
}
.upload-video-container h1 {
  font-size: 28px;
  margin: 60px 0px 0px;
  font-weight: 400;
  text-align: center;
}
.upload-video-container p {
  margin: 20px 0px;
  padding: 10px 50px;
  font-size: 14px;
  text-align: center;
}
input[type="file"] {
  display: none;
}
.button-container {
  display: flex;
  margin: 20px 0px;
  width: 100%;
  justify-content: space-evenly;
}
.button-container div {
  display: flex;
}
.button-container div:first-child {
  justify-content: flex-start;
}
.button-container div:last-child {
  justify-content: flex-end;
}
.button-container .button {
  min-width: 50px;
  margin-right: 10px;
}
.button-container .button:last-child {
  margin-right: 0px;
}
.button-container .button-fill {
  min-width: 160px;
}
@media only screen and (max-width: 899px) {
  .popup .navbar .title {
    margin-left: 20px;
  }
  .upload-video-container img {
    width: 180px;
    margin-top: 60px;
  }
  .upload-video-container h1 {
    font-size: 24px;
    margin: 60px 0px 0px;
  }
  .upload-video-container p {
    padding: 10px 0px;
  }
  .upload-video-container p strong {
    display: block;
    margin-top: 10px;
  }
  .upload-video-container .button-container {
    justify-content: center;
    flex-direction: column;
  }
  .upload-video-container .button-container div {
    justify-content: center;
    margin-bottom: 20px;
  }
}
</style>
