<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation("LBL_TRANSLATION") }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button @click="download()">{{ $t.getTranslation("LBL_DOWNLOAD_IMPORT_TEMPLATE") }}</f7-button>
        <f7-button large fill raised @click="createItem">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
        <f7-button large fill raised @click="loadLoadTranslation($refs)" :disabled="isUploading">{{ $t.getTranslation("LBL_IMPORT") }}</f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :url="url" :size="15" @editItem="editItem" @deleteItem="deleteItem" />
    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="importTranslation($event, $refs)" />
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

import DataTableComponent from "@/components/DataTableComponent.vue";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { f7 } from 'framework7-vue';


export default defineComponent({
  name: "SettingTranslationListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const url = "/admin/translation/list";
    const isUploading = ref(false);

    const columns = [
      {
        title: $t.getTranslation("LBL_TRANSLATION_CODE"),
        field: "TranslationCode",
        frozen: true,
        minWidth: 220,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData()?.TranslationCode?.toUpperCase() || "--"}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_LANGUAGE_CODE"),
        field: "LanguageCode",
        minWidth: 180,
      },
      {
        title: $t.getTranslation("LBL_LANGUAGE_NAME"),
        minWidth: 360,
        maxWidth: 440,
        field: "TranslationName",
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
              <div><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const createItem = () => {
      props.f7router.navigate({ name: "settingTranslationSave", params: { code: "new" } });
    };

    const editItem = (data) => {
      if (data?.value?.TranslationCode) {
        props.f7router.navigate({ name: "settingTranslationSave", params: { code: data.value.TranslationCode } });
      }
    };

    const deleteItem = async (data) => {
      try {
        helpers.showLoader();

        let res = await $HTTP.post("/admin/translation/delete", {
          TranslationId: data.value.TranslationId,
          Remarks: data.value.Remarks,
        });

        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();
          return;
        }

        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };


    const loadLoadTranslation = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    }

    const importTranslation = async (event, refs) => {
      let input = event.target;

      if (input?.files?.[0]) {
        let file = input?.files?.[0];
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/);
        if (!isValidFile) throw new Error($t.getTranslation("LBL_ERR_INVALID_FILE"));

        //upload now
        isUploading.value = true;
        f7.preloader.show();

        const formData = new FormData();
        formData.append("file", file);

        let ret = await $HTTP.post("/admin/translation/import", formData);
        isUploading.value = false;
        f7.preloader.hide();

        if (ret) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_UPLOAD") });
           setTimeout(()=>{
            $refs.datatable.initTabulator();
          }, 300);
        }
      }
    };

    const download = () => {
      window.location.href = "/excel/translation/Translation.xlsx";
      return;
    }

    return {
      url,
      columns,
      createItem,
      editItem,
      deleteItem,
      loadLoadTranslation,
      importTranslation,
      isUploading,
      download
    };
  },
});
</script>
<style>
input[type="file"] {
    display: none;
}
</style>
