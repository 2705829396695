<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'store']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation('LBL_MERCHANT_LIST') }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button @click="download()">{{ $t.getTranslation('LBL_DOWNLOAD_IMPORT_TEMPLATE') }}</f7-button>
        <f7-button large fill raised :disabled="isUploading" @click="importData($refs)">{{ $t.getTranslation('LBL_IMPORT') }}</f7-button>
        <f7-button large fill raised @click="create">{{ $t.getTranslation('LBL_CREATE') }}</f7-button>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <MainFooterComponent />

    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcel($event, $refs)" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, inject } from 'vue'

import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import DataTableComponent2 from '@/components/DataTableComponent2.vue'

import { post } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { useStore } from '@/store'

export default defineComponent({
  name: 'MerchantListPage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()

    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])

    const isUploading = ref(false)

    const url = ref(`/admin/merchant/datalist?LanguageCode=${currentLanguageCode.value}`)

    const actions = [
      {
        groupText: 'Perform Actions',
        list: [
          { text: 'Edit', type: 'EDIT', bold: true },
          { text: 'View Products', type: 'VIEWPRODUCTS' }
        ]
      }
    ]

    const columns = [
      {
        title: $t.getTranslation('LBL_ID'),
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().MerchantId}</strong></div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_IMAGE'),
        maxWidth: 70,
        minWidth: 70,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${helpers.getImage(cell.getData().MerchantImage, 'MERCHANT')}" />
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_TABLE_MERCHANT_INFO'),
        frozen: true,
        minWidth: 300,
        maxWidth: 360,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().MerchantCode || '--'}</strong></div>
              <div>${cell.getData().MerchantName || ''}</div>
              <div>Merchant Status: ${cell.getData()?.StatusCode || '--'}</div>
              <div>Merchant Shipping Status: ${cell.getData()?.ShippingStatusCode || '--'}</div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_SUPPLIER_INFO'),
        minWidth: 180,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().SupplierCode || '--'}</strong></div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_COMPANY_INFO'),
        minWidth: 180,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div>${cell.getData().CompanyName || ''}</div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_SHIPPING_INFO'),
        minWidth: 300,
        maxWidth: 360,
        formatter(cell) {
          return `
            <div>
              <div>${$t.getTranslation('LBL_SHIPPING_FACTOR')}: <strong>${cell.getData().ShippingFee + '%' || ''}</strong></div>
              <div>${$t.getTranslation('LBL_SHIPPING_1ST_BOTTLE')}: <strong>${cell.getData().Shipping1stBottle || ''}</strong></div>
              <div>${$t.getTranslation('LBL_SHIPPING_SUCCEEDING_BOTTLE')}: <strong>${cell.getData().ShippingSucceedingBottle || ''}</strong></div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.ShippingStatusCode)} ${cell.getData()?.ShippingStatusCode}">
                <div class="chip-label">${cell.getData()?.ShippingStatusCode || '--'}</div>
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_STATUS_INFO'),
        minWidth: 120,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div>${$t.getTranslation('LBL_PRIORITY')}: <strong>${cell.getData().Priority || 0}</strong></div>
              ${cell.getData()?.IsCrossBorder > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation('LBL_CROSS_BORDER') + '</div></div>' : ''}
              ${cell.getData()?.IsFeaturedMerchant > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation('LBL_FEATURED') + '</div></div>' : ''}
              ${cell.getData()?.IsListedMerchant > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation('LBL_LISTED') + '</div></div>' : ''}
              ${cell.getData()?.IsRecommendedMerchant > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation('LBL_RECOMMENDED') + '</div></div>' : ''}
              ${cell.getData()?.IsSearchableMerchant > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation('LBL_SEARCHABLE') + '</div></div>' : ''}
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_DATES'),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="${$t.getTranslation('LBL_DATE_CREATED')}"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="${$t.getTranslation('LBL_DATE_UPDATED')}"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `
        }
      }
    ]

    const download = () => {
      window.location.href = '/excel/merchant/MerchantShippingImport.xlsx'
      return
    }

    const uploadExcel = async (event, $refs) => {
      let input = event.target

      if (input?.files?.[0]) {
        let file = input?.files?.[0]
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/)
        if (!isValidFile) throw new Error($t.getTranslation('LBL_ERR_INVALID_FILE'))

        //upload now
        isUploading.value = true

        const formData = new FormData()
        formData.append('file', file)

        let ret = await post('/admin/merchant/import', formData)
        isUploading.value = false

        if (ret) {
          helpers.createNotification({ type: 'success', title: $t.getTranslation('LBL_SUCCESS'), message: $t.getTranslation('MSG_SUCCESS_UPLOAD') })
        }

        if ($refs) {
          setTimeout(() => {
            $refs.dataTableComponent.initTabulator()
          }, 300)
        }
      }
    }

    const importData = ($refs) => {
      $refs.fileInput.value = ''
      $refs.fileInput.click()
    }

    return {
      url,
      columns,
      actions,
      importData,
      uploadExcel,
      isUploading,
      download
    }
  },
  methods: {
    create() {
      const self = this
      self.f7router.navigate({ name: 'merchantView' })
    },
    async performAction(type, data) {
      const self = this

      switch (type) {
        case 'EDIT':
          if (data?.value?.MerchantId) {
            self.f7router.navigate({ name: 'merchantView', query: { MerchantId: data.value.MerchantId } })
          }

          break
        case 'VIEWPRODUCTS':
          if (data?.value?.SupplierCode) {
            self.f7router.navigate({ name: 'productList', query: { SupplierCode: data.value.SupplierCode } })
          }

          break
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator()
      }
    }
  }
})
</script>

<style scoped>
input[type='file'] {
  display: none;
}
</style>
