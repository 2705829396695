<template>
  <f7-popup id="releaseGroupCommissionPopup" class="popup-side popup-view" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="true">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="$t.getTranslation('LBL_USER_GROUP_COMMISSION_INFO')" @close-popup="closePopup" />
      </template>

      <div class="container">
        <ViewItemComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_USER_INFORMATION')" :data="viewInfo" :fields="userFields" fullscreen-width="third" />

        <ViewTableComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_REFERRED_ORDER_LIST')" :data="viewInfo?.ReferredOrderList" :fields="orderTableFields" fullscreen-width="full" @performAction="performOrderAction" />
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { ref, defineComponent, inject } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";

import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import ViewItemComponent2 from "@/components/ViewItemComponent2.vue";
import ViewTableComponent2 from "@/components/ViewTableComponent2.vue";

export default defineComponent({
  name: "ReleaseGroupCommissionPartial",
  components: {
    NavigationPopupSideComponent,
    ViewItemComponent2,
    ViewTableComponent2,
  },
  props: { f7router: Object },
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    let isFetching = false;

    const UserId = ref(false);
    const viewInfo = ref(false);

    const userFields = ref([
      { field: "UserId", name: "auto", format: "number" },
      { field: "Email", name: "auto", format: "string" },
      { field: "UserName", name: "auto", format: "string" },
      { field: "FirstName", name: "auto", format: "string" },
      { field: "LastName", name: "auto", format: "string" },
      { field: "PendingGroupCommission", name: "auto", format: "currency" },
      { field: "AvailableGroupCommission", name: "auto", format: "currency" },
      { field: "RedeemedGroupCommission", name: "auto", format: "currency" },
    ]);

    const orderTableFields = ref([
      {
        field: "Options",
        name: "",
        format: "buttons",
        list: [
          {
            type: "RELEASE",
            text: $t.getTranslation("LBL_RELEASE"),
            isEnabled: (data) => {
              return ["CLAIMABLE", "PENDING"].indexOf(data.Status) > -1 ? true : false;
            },
          },
        ],
      },
      { field: "OrderNumber", name: "auto", format: "string" },
      { field: "GroupCommission", name: "auto", format: "currency" },
      { field: "Status", name: "auto", format: "string" },
    ]);

    const getInfo = async (UserId) => {
      if (isFetching) return;
      isFetching = true;

      let getResponse = await get("/admin/user/groupcommission/view", { UserId });
      viewInfo.value = getResponse;
      isFetching = false;
    };

    const closeAllPopup = () => {
      Dom7(".popup").forEach((element) => {
        f7.popup.close(element);
      });
    };

    return {
      getInfo,
      UserId,
      viewInfo,
      userFields,
      orderTableFields,
      closeAllPopup,
    };
  },
  methods: {
    performOrderAction(type, data) {
      const self = this;

      switch (type) {
        case "RELEASE":
          if (data?.OrderId) {
            helpers.createRemarks({
              message: self.$t.getTranslation("LBL_CONFIRM_RELEASE_GROUP_COMMISSION"),
              confirm: async (Remarks) => {
                if (Remarks) {
                  let returnData = await post("/admin/user/groupcommission/release", { OrderId: data?.OrderId, Remarks });
                  if (returnData) {
                    helpers.createNotification({
                      type: "info",
                      title: "INFO",
                      message: "Successfully release user group commission.",
                    });
                  }
                }

                self.getInfo(this.UserId);
              },
            });
          }
          break;
      }
    },
    showGroupCommission() {
      f7.popup.open("#userGroupCommission");
    },
    openPopup(UserId) {
      this.UserId = UserId;
      this.getInfo(this.UserId);

      f7.popup.open("#releaseGroupCommissionPopup");
      this.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#releaseGroupCommissionPopup");
      this.$emit("closed", data);
    },
  },
});
</script>

<style>
#releaseGroupCommissionPopup .container {
  padding-top: 0px;
}
</style>
