<template>
  <f7-popup id="saveProductReview" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="false">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <FormComponent ref="formComponent" @successSave="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { f7 } from "framework7-vue";

import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";

const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "ProductReviewSavePartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const title = ref("");
    const $t = inject("$translation");

    const formComponentData = {
      buttonText: "",
      getLink: "/admin/productreview/view",
      getParams: {},
      validation: "productreview",
      saveLink: "/admin/productreview/save",
      saveParams: {},
      successCreateMessage: $t.getTranslation("LBL_SUCCESS_CREATE_PRODUCT_REVIEW"),
      successUpdateMessage: $t.getTranslation("LBL_SUCCESS_UPDATE_PRODUCT_REVIEW"),
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("LBL_PRODUCT_REVIEW_INFORMATION"),
        type: "input",
        list: [
          { name: $t.getTranslation("LBL_CONTENT"), type: "textarea", value: "Content", required: true },
          { name: $t.getTranslation("LBL_RATING"), type: "number", value: "Rating", required: true },
          {
            name: $t.getTranslation("LBL_PRIVACY"),
            type: "select",
            value: "IsPrivate",
            items: store.getters["config/getData"]?.binaryChoiceList,
            required: true,
            hideClearButton: true,
            nameSelector: "name",
            valueSelector: "value",
            defaultValue: "0",
          },
          {
            name: $t.getTranslation("LBL_HIDDEN"),
            type: "select",
            value: "IsHidden",
            items: store.getters["config/getData"]?.binaryChoiceList,
            required: true,
            hideClearButton: true,
            nameSelector: "name",
            valueSelector: "value",
            defaultValue: "0",
          },
        ],
      },
      {
        name: $t.getTranslation("LBL_STATUS_INFORMATION"),
        type: "input",
        list: [{ name: "Status", type: "select", value: "StatusCode", required: false, hideClearButton: true, items: store.getters["config/getData"]?.statusList, nameSelector: "Name", valueSelector: "Code", defaultValue: "ACTIVE" }],
      },
    ];

    return {
      title,
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup(data) {
      const self = this;
      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);

      if (data?.ProductReviewId) {
        self.title = this.$t.getTranslation("LBL_UPDATE_PRODUCT_REVIEW");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_UPDATE_PRODUCT_REVIEW");
        self.formComponentData.getParams.ProductReviewId = data?.ProductReviewId;
        self.formComponentData.saveParams.ProductReviewId = data?.ProductReviewId;

        self.$refs.formComponent.setFormComponentData(self.formComponentData, true);
      } else {
        self.title = this.$t.getTranslation("LBL_CREATE_PRODUCT_REVIEW");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_CREATE_PRODUCT_REVIEW");
        self.formComponentData.getParams = {};
        self.formComponentData.saveParams = {};

        self.$refs.formComponent.setFormComponentData(self.formComponentData);
      }

      f7.popup.open("#saveProductReview");
      self.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#saveProductReview");
      this.$emit("closed", data);
    },
  },
});
</script>
