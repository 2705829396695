<template>
  <f7-popup id="userGroupViewPopup" class="popup-side popup-view" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="true">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="$t.getTranslation('LBL_USER_GROUP_INFO')" @close-popup="closePopup" />
      </template>

      <div class="container">
        <ViewItemComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_USER_GROUP_INFORMATION')" :data="viewInfo" :fields="userGroupFields" fullscreen-width="third" />
        <ViewItemComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_USER_GROUP_LINK')" :link="viewInfo?.groupLink" fullscreen-width="third" />
        <ViewItemComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_USER_GROUP_COUNT_INFORMATION')" :data="viewInfo" :fields="userGroupCounterFields" fullscreen-width="third" />

        <ViewTableComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_USER_LIST')" :data="viewInfo?.UserList" :fields="userFields" fullscreen-width="full" @performAction="performUserAction" />
      </div>

      <UserViewPartial ref="userViewPartial" :f7router="f7router" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { ref, defineComponent, inject } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";

import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import ViewItemComponent2 from "@/components/ViewItemComponent2.vue";
import ViewTableComponent2 from "@/components/ViewTableComponent2.vue";

import UserViewPartial from "@/partials/user/UserViewPartial.vue";

export default defineComponent({
  name: "UserGroupViewPartial",
  components: {
    NavigationPopupSideComponent,
    ViewItemComponent2,
    ViewTableComponent2,
    UserViewPartial,
  },
  props: { f7router: Object },
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    let isFetching = false;

    const UserGroupId = ref(false);
    const viewInfo = ref(false);

    const closeAllPopup = () => {
      Dom7(".popup").forEach((element) => {
        f7.popup.close(element);
      });
    };

    const userGroupFields = ref([
      { field: "Name", name: "auto", format: "string" },
      { field: "LinkCode", name: "auto", format: "string" },
      { field: "Commission", name: "auto", format: "decimal" },
      { field: "CommissionType", name: "auto", format: "string" },
      { field: "StatusCode", name: "auto", format: "status" },
      { field: "LastCreated", name: "auto", format: "datetime" },
      { field: "LastModified", name: "auto", format: "datetime" },
    ]);

    const userGroupCounterFields = ref([
      {
        field: "PendingGroupCommission",
        name: "auto",
        format: "currency",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrergroup=${viewInfo.value.LinkCode}&commission_status=pending`);
        },
      },
      {
        field: "AvailableGroupCommission",
        name: "auto",
        format: "currency",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrergroup=${viewInfo.value.LinkCode}&commission_status=available`);
        },
      },
      {
        field: "RedeemedGroupCommission",
        name: "auto",
        format: "currency",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrergroup=${viewInfo.value.LinkCode}&commission_status=redeemed`);
        },
      },
      {
        field: "TotalUsers",
        name: "auto",
        format: "decimal",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/user/list/?referrergroup=${viewInfo.value.LinkCode}`);
        },
      },
      {
        field: "TotalOrders",
        name: "auto",
        format: "decimal",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrergroup=${viewInfo.value.LinkCode}`);
        },
      },
      {
        field: "RedeemedOrders",
        name: "auto",
        format: "decimal",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrergroup=${viewInfo.value.LinkCode}&commission_status=redeemed`);
        },
      },
      {
        field: "UnredeemedOrders",
        name: "auto",
        format: "decimal",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrergroup=${viewInfo.value.LinkCode}&commission_status=pending,available`);
        },
      },
    ]);

    const userFields = ref([
      {
        field: "Options",
        name: "",
        format: "buttons",
        list: [
          { type: "VIEW", text: $t.getTranslation("LBL_VIEW") },
          { type: "DELETE", text: $t.getTranslation("LBL_DELETE"), color: "red" },
        ],
      },
      { field: "UserId", name: "auto", format: "string" },
      { field: "Title", name: "Name", format: "string" },
      { field: "Referrer", name: "Referrer", format: "string" },
      { field: "Description", name: "Info", format: "string" },
    ]);

    const getInfo = async (UserGroupId) => {
      if (isFetching) return;
      isFetching = true;

      let getResponse = await get("/admin/usergroup/view", { UserGroupId });
      viewInfo.value = getResponse;
      isFetching = false;
    };

    return {
      getInfo,
      UserGroupId,
      viewInfo,
      userGroupFields,
      userGroupCounterFields,
      userFields,
    };
  },
  methods: {
    performUserAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.UserId) {
            self.$refs.userViewPartial.openPopup(data?.UserId);
          }
          break;
        case "DELETE":
          if (data?.UserId) {
            helpers.createConfirmation({
              message: self.$t.getTranslation("LBL_CONFIRM_USER_USER_GROUP_DELETE"),
              title: self.$t.getTranslation("LBL_CONFIRM"),
              confirm: async () => {
                let returnData = await post("/admin/usergroup/user/delete", { UserId: data?.UserId });
                if (returnData) {
                  helpers.createNotification({
                    type: "info",
                    title: self.$t.getTranslation("LBL_INFO"),
                    message: self.$t.getTranslation("LBL_SUCCESS_USER_USER_GROUP_DELETE"),
                  });

                  self.getInfo(self.UserGroupId);
                }
              },
            });
          }
          break;
      }
    },
    openPopup(UserGroupId) {
      this.UserGroupId = UserGroupId;
      this.getInfo(this.UserGroupId);

      f7.popup.open("#userGroupViewPopup");
      this.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#userGroupViewPopup");
      this.$emit("closed", data);
    },
  },
});
</script>

<style>
#userGroupViewPopup .container {
  padding-top: 0px;
}
</style>
