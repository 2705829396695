<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_PRODUCT_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised popover-open=".popover-batch">{{ $t.getTranslation("LBL_BATCH") }}</f7-button>
        <f7-popover class="popover-batch">
          <f7-list>
            <!-- <f7-list-item link="#" popover-close :title="$t.getTranslation('LBL_ENABLE')" @click="onBatchAction('ENABLE')"></f7-list-item> -->
            <f7-list-item link="#" popover-close :title="$t.getTranslation('LBL_DISABLE')" @click="onBatchAction('DISABLE')"></f7-list-item>
          </f7-list>
        </f7-popover>

        <f7-button v-if="!hideImport" preloader large fill raised :loading="isUploading" :disabled="isUploading" @click="upload($refs)">{{ $t.getTranslation("LBL_IMPORT") }}</f7-button>
        <f7-button @click="download()">{{ $t.getTranslation("LBL_DOWNLOAD_IMPORT_TEMPLATE") }}</f7-button>
        <!-- <f7-button large icon="far fa-fw fa-cog"></f7-button> -->
      </div>
    </div>

    <DataTableComponent ref="dataTable" :columns="columns" :size="10" :url="url" @viewItem="editItem"></DataTableComponent>

    <MainFooterComponent />

    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcel($event, $refs)" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject } from "vue";
import { Dom7 } from "framework7";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

import { $HTTP, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

export default defineComponent({
  name: "ProductListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const isUploading = ref(false);
    const hideImport = ref(false);

    const columns = [
      {
        frozen: true,
        title: `
          <label class="checkbox no-ripple">
            <input class="main-checkbox" type="checkbox" />
            <i class="icon-checkbox"></i>
          </label>
        `,
        minWidth: 60,
        maxWidth: 60,
        formatter(cell) {
          return `
            <div>
              <label class="checkbox">
                <input class="check-item" type="checkbox" value="${cell.getData()?.ProductId}" />
                <i class="icon-checkbox"></i>
              </label>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IMAGE"),
        maxWidth: 90,
        minWidth: 90,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${cell.getData().DefaultProductImage || require("@/assets/images/placeholder.jpg")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRODUCT_NAME"),
        maxWidth: 320,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Name}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_INVENTORY"),
        field: "Inventory",
        hozAlign: "center",
        maxWidth: 130,
        minWidth: 130,
      },
      {
        title: $t.getTranslation("LBL_WAREHOUSE"),
        field: "WareHouses",
      },
      {
        title: $t.getTranslation("LBL_WAREHOUSE_REGION"),
        field: "WareHouseRegions",
        hozAlign: "center",
      },
      {
        title: $t.getTranslation("LBL_CATEGORY"),
        field: "Category",
      },
      {
        title: $t.getTranslation("LBL_RATING"),
        formatter(cell) {
          return `
            <div>
              ${cell.getData().Rating}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_REGULAR_SELLING_PRICE"),
        field: "RegularSellingPrice",
      },
      {
        title: $t.getTranslation("LBL_DISCOUNT_PRICE"),
        field: "DiscountPrice",
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRODUCT_CREATED"),
        field: "LastCreated",
      },
    ];

    const editItem = (data) => {
      props.f7router.navigate({ name: "productMarketing", query: { key: data.value.ProductKey } });
      return;
    };

    const upload = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const uploadExcel = async (event, refs) => {
      let input = event.target;

      if (input?.files?.[0]) {
        let file = input?.files?.[0];
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/);
        if (!isValidFile) throw new Error($t.getTranslation("LBL_ERR_INVALID_FILE"));

        //upload now
        isUploading.value = true;

        const formData = new FormData();
        formData.append("file", file);

        let ret = await $HTTP.post("/admin/product/special/import", formData);
        isUploading.value = false;

        if (ret) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_UPLOAD") });
          refs.dataTable.initTabulator();
        }
      }
    };

    const download = () => {
      window.location.href = "/excel/product/618.xlsx";
      return;
    };

    let url = `/admin/product/list?LanguageCode=${currentLanguageCode.value}&mode=SPECIAL_PRICE`;

    const getCheckList = () => {
      let checkList = [];

      Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
        let isChecked = Dom7(item).prop("checked");
        if (isChecked) {
          checkList.push(parseInt(Dom7(item).value()));
        }
      });

      return checkList;
    };

    const onBatchAction = async (type) => {
      let checkList = getCheckList();

      console.log("checkList", checkList);

      if (checkList.length > 0) {
        helpers.createRemarks({
          message: $t.getTranslation("LBL_CONFIRM_BATCH_ACTION"),
          confirm: async (Remarks) => {
            if (Remarks) {
              await post("/admin/product/specialprice/batch", { ProductIdList: checkList, Remarks, Type: type });

              helpers.createNotification({
                type: "info",
                title: "INFO",
                message: "Successfully updated special price.",
              });

              window.location.reload();
            }
          },
        });
      }
    };

    onMounted(() => {
      Dom7("body").on("change", ".main-checkbox", (e) => {
        let isChecked = Dom7(".main-checkbox").prop("checked");
        if (isChecked) {
          Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
            Dom7(item).prop("checked", true);
          });
        } else {
          Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
            Dom7(item).prop("checked", false);
          });
        }
      });

      Dom7("body").on("click", ".tabulator-page", (e) => {
        Dom7(".main-checkbox").prop("checked", false);
      });
    });

    return {
      isUploading,
      uploadExcel,
      upload,
      columns,
      url,
      editItem,
      currentLanguageCode,
      hideImport,
      download,
      onBatchAction,
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}

input[type="file"] {
  display: none;
}
</style>
