<template>
  <div class="orderitem" @click="onViewOrder">
    <span class="time">{{ helpers.formatDateTime(data.LastCreated) }}</span>
    <span class="status" v-if="!hidestatus">{{ data.OrderStatusName }}</span>
    <span class="status" v-if="!hideCourier">{{ data.CourierTrackingNo }}</span>
    <h1>{{ data.Ref }}</h1>
    <div class="info" v-if="!hideCourier">
      <h4>{{ $t.getTranslation('LBL_COURIER') }}:</h4>
      <strong>{{ data.CourierCode }}</strong>
    </div>
    <div class="info">
      <h4>{{ $t.getTranslation('LBL_TOTAL_QUANTITY') }}:</h4>
      <strong>{{ data.TotalItems }} {{ $t.getTranslation('LBL_PCS') }}</strong>
    </div>
    <div class="info">
      <h4>{{ $t.getTranslation('LBL_GRAND_TOTAL') }}:</h4>
      <strong>{{ helpers.formatPrice(data.GrandTotal, data.CurrencyCode) }}</strong>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from 'vue'
import { helpers } from '@/utils/helpers.js'

export default defineComponent({
  name: 'OrderItemCardComponent',
  components: {},
  props: {
    data: Object,
    hidestatus: { type: Boolean, default: false },
    hideCourier: { type: Boolean, default: true },
    f7router: Object,
    f7route: Object,
    sendOrder: Function
  },
  setup(props) {
    const $f7router = inject('$f7router')
    const $t = inject('$translation')

    const confirm = async () => {
      let message = $t.getTranslation('MSG_CONFIRM_ADD_ORDER_CHAT')
      helpers.createConfirmation({
        message: message,
        title: $t.getTranslation('LBL_CONFIRM_ADD_ORDER_CHAT'),
        confirm: () => {
          props.sendOrder(props.data.OrderKey)
        }
      })
    }

    const onViewOrder = async () => {
      if (props.sendOrder) {
        confirm()
        return true
      }

      props.f7router.navigate({ name: 'orderView', params: { id: props.data.OrderKey } })
    }

    return { helpers, onViewOrder }
  }
})
</script>

<style>
.orderitem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px;
  padding: 15px 15px;
  border: 1px solid #f7f7f7;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 10%);
  position: relative;
  cursor: pointer;
}
.orderitem span.time {
  color: rgb(119, 119, 119);
  font-size: 12px;
  margin: 0px;
  display: block;
}
.orderitem h1 {
  font-weight: 400;
  font-size: 20px;
  margin: 0px 0px 5px;
  padding: 0px;
}
.orderitem div.info {
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  margin: 0px 0px 2px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.orderitem div.info h4 {
  font-weight: 100;
  font-size: 12px;
}
.orderitem span.status {
  background: var(--f7-theme-color);
  color: #fff;
  padding: 2px 10px;
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 12px;
  border-radius: 4px 0px 0px 4px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
}
</style>
