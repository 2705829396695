<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_INVENTORY_UPDATE') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        
         
        <f7-list no-hairlines>
          <ViewItemComponent :title="$t.getTranslation('LBL_PRODUCT_DETAILS')" :data="viewData" :fields="inventoryFields" />
          <f7-list-item divider>{{ $t.getTranslation("LBL_INVENTORY_DETAILS") }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.IncreaseQty"
            name="Increase"
            :required="validationRules?.Increase?.required"
            :minlength="validationRules?.Increase?.minimumLength"
            :maxlength="validationRules?.Increase?.maximumLength"
            :label="$t.getTranslation('LBL_INCREASE_QTY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            validate
            clear-button
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.DecreaseQty"
            name="Decrease"
            :required="validationRules?.Increase?.required"
            :minlength="validationRules?.Increase?.minimumLength"
            :maxlength="validationRules?.Increase?.maximumLength"
            :label="$t.getTranslation('LBL_DECREASE_QTY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            validate
            clear-button
          >
          </f7-list-input>
        
  

          <f7-button large fill raised preloader :loading="isButtonProcessing" @click="confirm">
              {{ $t.getTranslation("LBL_SAVE") }}
          </f7-button>

        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";
import _ from "lodash";
import { useStore } from "@/store";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";
import ViewItemComponent from "@/components/ViewItemComponent.vue";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "CustomerSavePage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    ViewItemComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const formData = reactive({
      DecreaseQty: 0,
      IncreaseQty: 0,
      LanguageCode: currentLanguageCode.value
    });

    let viewData = ref({});
    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const inventoryFields = ref([
      { field: "Name", name: $t.getTranslation('LBL_NAME'), format: "string" },
      { field: "Sku", name: $t.getTranslation('LBL_SKU'), format: "string" },
      { field: "ProductInventory", name: $t.getTranslation('LBL_INVENTORY_QTY'), format: "string" }
    ]);

    onMounted(async() => {
      if (props?.f7route?.query && props?.f7route?.query?.key) {
        await getData();
      }
    });

    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/product/view?&LanguageCode=${currentLanguageCode.value || 'EN'}&ProductKey=${props?.f7route?.query?.key}`);
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();
          let pickinventoryFields = _.pick(res.data.data, ['SupplierCode','Name', 'ProductInventory', 'Sku']);
          viewData.value = {...pickinventoryFields,  ...viewData }
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const confirm = async() => {
      let message = $t.getTranslation('MSG_CONFIRM_INVENTORY_UPDATE_MESSAGE');
      let TotalQty = parseInt(viewData.value.ProductInventory) + parseInt(formData.IncreaseQty)
          TotalQty = (TotalQty - formData.DecreaseQty);
      message = message.replace('{TotalQty}', TotalQty);
      helpers.createConfirmation({
        message: message, 
        title:  $t.getTranslation('LBL_CONFIRM_INVENTORY_UPDATE'),
        confirm: () => {
          addRemarks();
        }
      });
    }


    const addRemarks = (status) => {
      helpers.createRemarks({
        message: $t.getTranslation('LBL_INVENTORY_ADD_REMARKS'),
        confirm: (remarks) => {
          if (remarks == "") {
            helpers.createNotification({
              type: "error",
              title: "Error!",
              message: $t.getTranslation('ERR_INVENTORY_ADD_REMARKS'),
            });
          } else {
            save(remarks)
          }
        },
      });
    };

    const save = async (remarks) => {
      try {
        let isValid = validate(true);
        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader();
          formData.ProductKey = props?.f7route?.query?.key;


          if (remarks) {
            formData.Remarks = remarks;
          }

          //send update to server
          let res = await $HTTP.post("/admin/product/inventory", formData);
          if (res?.status === 200 && res?.data?.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();
            props.f7router.navigate({ name: "productView", params: { key:  props?.f7route?.query?.key } });
            helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_PRODUCT_INVENTORY") });
            return;
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    return { isUpdate, formData, validationRules, isButtonProcessing, isDisableSaveButton, save, inventoryFields, viewData, confirm};
  }
});
</script>
