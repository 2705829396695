import { checkMeta, checkIsAlreadyLogin } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'login',
    path: '/login/',
    meta: {
      navigation: {
        isShown: false
      },
      isSecured: false,
      hideLeftPanel: true,
      hideRightPanel: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta, checkIsAlreadyLogin],
    asyncComponent: () => import('@/pages/LoginPage.vue')
  }
]

export default routes
