<template>
  <div class="view-info-container" :class="fullscreenWidth">
    <div class="view-info">
      <div class="title">
        <h2>{{ title }}</h2>
        <div class="actions">
          <f7-button v-if="button == 'CREATE'" fill small @click="onPerformAction(button)">Create</f7-button>

          <f7-toggle v-model:checked="isVisible" class="toggle-visibility"></f7-toggle>
        </div>
      </div>

      <transition name="slide-fade">
        <div v-if="isVisible">
          <template v-if="data && data.length > 0">
            <div class="data-table">
              <table>
                <thead>
                  <tr>
                    <th v-for="field in fields" :key="'tbh_' + field.field">
                      {{ field.name === "auto" ? $h.formatPascalCase(field.field) : field.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(datum, index) in data" :key="'tbr_' + index">
                    <td v-for="field in fields" :key="'tbd_' + field.field">
                      <div v-if="field.format === 'buttons' && field?.list?.length > 0" class="button-container">
                        <template v-for="buttonItem in field?.list" :key="'fbtn_' + buttonItem.type">
                          <f7-button v-if="$h.checkTableButtonEnabled(buttonItem, datum)" fill small :color="buttonItem?.color" @click="onPerformAction(buttonItem?.type, datum)">
                            <template v-if="buttonItem?.icon?.length > 0">
                              <font-awesome-icon :icon="buttonItem?.icon" fixed-width />
                            </template>
                            <template v-if="buttonItem?.text != ''">
                              {{ buttonItem.text }}
                            </template>
                          </f7-button>
                        </template>
                      </div>
                      <div v-else-if="field.format === 'image'" class="image-container">
                        <img :src="$h.formatField(field, datum)" :alt="field.field + 'Image'" @click="$h.openImage($h.formatField(field, datum))" />
                      </div>

                      <span v-else>{{ $h.formatField(field, datum) }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <slot name="footer"></slot>
          </template>

          <NoDataFoundComponent v-else size="sm" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

export default defineComponent({
  name: "ViewTableComponent2",
  components: {
    NoDataFoundComponent,
  },
  props: {
    title: String,
    button: String,
    fields: Array,
    data: Array,
    fullscreenWidth: String,
  },
  emits: ["performAction"],
  setup(props, { emit }) {
    let isVisible = ref(true);

    const onPerformAction = (type, data) => {
      emit("performAction", type, data);
    };

    return {
      isVisible,
      onPerformAction,
    };
  },
});
</script>
