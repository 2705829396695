import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'marketing',
    path: '/marketing/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'users-crown',
        title: translation.getTranslation('LBL_MARKETING'),
        activeLink: 'marketing'
      },
      isSecured: true,
      hasAnyRoles: ['MANAGE_COUPON', 'MANAGE_BANNER', 'MANAGE_KOL', 'MANAGE_SURVEY']
    },
    redirect: '/marketing/coupon/list/',
    routes: [
      {
        name: 'notificationList',
        path: 'notification/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_NOTIFICATIONS'),
            activeLink: 'notificationList'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/notification/NotificationListPage.vue')
      },
      {
        name: 'notificationSave',
        path: 'notification/save/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'notificationSave'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/notification/NotificationSavePage.vue')
      },
      {
        name: 'couponList',
        path: 'coupon/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PROMOTION_DISCOUNT'),
            activeLink: 'couponList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/coupon/CouponListPage.vue')
      },
      {
        name: 'couponSave',
        path: 'coupon/save/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'marketing'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/coupon/CouponSavePage.vue')
      },
      {
        name: 'couponSaveTier',
        path: 'coupon/save/tier/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'couponList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/coupon/CouponSaveTierPage.vue')
      },
      {
        name: 'couponListTier',
        path: 'coupon/list/tier/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'couponList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/coupon/CouponListTierPage.vue')
      },
      {
        name: 'groupBuyList',
        path: 'groupbuy/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_GROUP_BUY'),
            activeLink: 'groupBuyList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_GROUP_BUY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/groupbuy/GroupBuyListPage.vue')
      },
      // {
      //   name: 'popupBannerList',
      //   path: 'popup/list/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('LBL_POPUP_BANNER'),
      //       activeLink: 'popupBannerList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_BANNER']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/popupbanner/BannerListPage.vue')
      // },
      // {
      //   name: 'popupBannerSave',
      //   path: 'popup/save/',
      //   meta: {
      //     navigation: {
      //       isShown: false,
      //       activeLink: 'popupBannerList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_BANNER']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/popupbanner/BannerSavePage.vue')
      // },

      {
        name: 'kolList',
        path: 'kol/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_KOL_LIST'),
            activeLink: 'kolList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_KOL']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/kol/KolListPage.vue')
      },
      {
        name: 'kolView',
        path: 'kol/view/:id',
        meta: {
          navigation: {
            isShown: false,
            title: translation.getTranslation('LBL_KOL_VIEW'),
            activeLink: 'kolList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_KOL']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/kol/KolViewPage.vue')
      }
      // {
      //   name: 'surveyList',
      //   path: 'survey/list/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('LBL_SURVEY'),
      //       activeLink: 'surveyList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_SURVEY']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/survey/SurveyListPage.vue')
      // },
      // {
      //   name: 'surveySave',
      //   path: 'survey/save/',
      //   meta: {
      //     navigation: {
      //       isShown: false,
      //       activeLink: 'surveySave'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_SURVEY']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/survey/SurveySavePage.vue')
      // },
      // {
      //   name: 'surveyView',
      //   path: 'survey/view/',
      //   meta: {
      //     navigation: {
      //       isShown: false,
      //       activeLink: 'surveyView'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_SURVEY']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/survey/SurveyViewPage.vue')
      // }
    ]
  }
]

export default routes
