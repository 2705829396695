<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_INFLUENCER_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @editItem="editItem"></DataTableComponent>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "BlankPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const columns = [
      {
        title: "Image",
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${helpers.getImage(cell.getData().Image, "USER")}" />
              </div>
            </div>
          `;
        },
        minWidth: 100,
        maxWidth: 100,
      },
      {
        title: $t.getTranslation("LBL_USERNAME"),
        field: "UserName",
        minWidth: 160,
        maxWidth: 160,
      },
      {
        title: $t.getTranslation("LBL_OPEN_ID"),
        field: "OpenId",
        minWidth: 140,
        maxWidth: 140,
      },
      {
        title: $t.getTranslation("LBL_EMAIL"),
        field: "Email",
      },
      {
        title: $t.getTranslation("LBL_FULL_NAME"),
        field: "FullName",
      },
      {
        title: $t.getTranslation("LBL_OFFICIAL"),
        field: "IsPJFOfficial",
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.IsPJFOfficial === 1 ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.IsPJFOfficial === 1 ? "YES" : "NO"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_INFLUENCER_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.InfluencerStatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.InfluencerStatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.UserStatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.UserStatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
    ];

    const editItem = (data) => {
      if (data?.value?.InfluencerId) {
        props.f7router.navigate({ name: "influencerSave", params: { id: data.value.InfluencerId } });
      }
    };

    const create = () => {
      props.f7router.navigate({ name: "influencerSave", params: { id: 0 } });
    };

    let url = "/admin/influencer/list?";

    return {
      columns,
      url,
      editItem,
      create,
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
