<template>
  <div class="product-list" style="min-width: 225px;">
    <div class="prod-image-list-same-size" :style="{ 'background-image': $h.getStyleImage(data.PrimaryImage, 'PRODUCT') }" @click="onSelectProduct(data)"></div>

    <div @click="onSelectProduct(data)" style="width: calc(100% - 95px);">
      <h4 v-if="shop">{{ data?.Name }}</h4>
      <h4 v-else>{{ $h.getAttribute(data, $t.getLanguage(), 'Name') }}</h4>

      <div v-if="shop">
        <div v-if="data?.DiscountPrice && data?.DiscountPrice < data?.RegularSellingPrice">
          <h4 class="price sale-price">{{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}</h4>
          <h4 class="price">{{ $h.formatPrice(data?.DiscountPrice, data?.CurrencyCode) }}</h4>
        </div>
        <h4 class="price" v-else>{{ $h.formatPrice(data?.RegularSellingPrice, data?.CurrencyCode) }}</h4>
      </div>

      <div v-else>
        <div v-if="data?.Price?.DiscountPrice && data?.Price.DiscountPrice < data?.Price?.RegularSellingPrice">
          <h4 class="price sale-price">{{ $h.formatPrice(data?.Price?.RegularSellingPrice, data?.Price?.CurrencyCode) }}</h4>
          <h4 class="price">{{ $h.formatPrice(data?.Price?.DiscountPrice, data?.Price?.CurrencyCode) }}</h4>
        </div>
        <h4 class="price" v-else>{{ $h.formatPrice(data?.Price?.RegularSellingPrice, data?.Price?.CurrencyCode) }}</h4>
      </div>

      <star-rating :increment="0.1" :star-size="13" :show-rating="false" :rating="data.Rating || 0" :read-only="true"></star-rating>
      <!-- sticker -->
      <div class="notif-content" v-if="!category">
        <a href="#" class="notification-new notif" v-if="data?.IsNew && data?.IsNew > 0"
          ><span>{{ $t.getTranslation('LBL_ISNEW') }}</span></a
        >
        <a href="#" class="notification-sal notif" v-if="data?.IsSale && data?.IsSale > 0"
          ><span>{{ $t.getTranslation('LBL_ISSALE') }}</span></a
        >
        <a href="#" class="notification-hot notif" v-if="data?.IsHot && data?.IsHot > 0"
          ><span>{{ $t.getTranslation('LBL_ISHOT') }}</span></a
        >
      </div>
    </div>

    <span v-if="tagline">{{ tagline }}</span>
    <span class="year" v-else>{{ data.Year }}</span>
    <!-- <span v-if="data.Rating" class="rating">{{ data.Rating || 0 }}</span> -->

    <span class="rating" @click="action(data)" v-if="type == 'cellar'">
      <font-awesome-icon :icon="['far', 'times']" />
    </span>

    <span class="rating" v-if="isSale && data?.ProductSale">
      {{ $t.getTranslation('LBL_PRODUCT_ON_SALE') }}
    </span>

    <span class="rating" v-if="isSale && data?.GroupBuy">
      {{ $t.getTranslation('LBL_PRODUCT_ON_GROUP_BUY') }}
    </span>
  </div>
</template>

<style scoped>
.price {
  font-weight: 300 !important;
}
.prod-image-list-same-size {
  width: 80px;
  height: 80px;
  border: 1px solid #f7f7f7;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 15px;
}
</style>

<script>
import { defineComponent, inject, ref } from 'vue'
import StarRating from '@/components/rating/star-rating.vue'
import { helpers } from '@/utils/helpers.js'

export default defineComponent({
  name: 'ProductListCardComponent',
  components: {
    StarRating
  },
  props: {
    tagline: String,
    category: {
      type: Boolean,
      default: false
    },
    data: Object,
    type: String,
    action: Function,
    isShop: {
      type: Boolean,
      default: false
    },
    isSale: {
      type: Boolean,
      default: false
    },
    f7router: Object,
    f7route: Object,
    sendProduct: Function,
    directSend: {
      type: Boolean,
      default: false
    },
    recommendStatus: Function,
    viewProductStatus: Function,
    attachProduct: Function
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const $f7router = inject('$f7router')
    const $t = inject('$translation')
    const shop = ref(false)
    const warehouseregions = ref([])

    if (props?.isShop) {
      shop.value = true
    }

    if (props?.data?.WareHouseRegions) {
      let region = props?.data?.WareHouseRegions || []
      for (let index = 0; index < region.length; index++) {
        let item = region[index]
        warehouseregions.value.push({
          Code: item,
          Name: item
        })
      }
    }

    const confirm = async () => {
      if (props.directSend) {
        return props.sendProduct(props.data)
      }

      let message = $t.getTranslation('MSG_CONFIRM_ADD_PRODUCT_CHAT')
      helpers.createConfirmation({
        message: message,
        title: $t.getTranslation('LBL_CONFIRM_ADD_PRODUCT_CHAT'),
        confirm: () => {
          props.sendProduct(props.data.ProductKey)
        }
      })
    }

    const onSelectProduct = (data) => {
      if (props?.isSale && props?.data.ProductSale) {
        helpers.createNotification({ type: 'error', title: $t.getTranslation('LBL_ERROR'), message: $t.getTranslation('ERR_PRODUCT_ALREADY_ON_SALE') })
        return
      }

      if (props?.isSale && props?.data.GroupBuy) {
        helpers.createNotification({ type: 'error', title: $t.getTranslation('LBL_ERROR'), message: $t.getTranslation('ERR_PRODUCT_ALREADY_ON_GROUP_BUY') })
        return
      }

      if (props.sendProduct) {
        confirm()
        return true
      }

      if (props.attachProduct) {
        let obj = JSON.stringify(data)
        props.attachProduct(obj)
        return
      }

      if (props.recommendStatus) {
        props.recommendStatus(props?.data?.ProductKey)
        return
      }

      if (props.viewProductStatus) {
        props.viewProductStatus(props?.data?.ProductKey)
        return
      }

      if (props?.type && props?.type === 'select') {
        emit('selected', data)
        return
      }

      if (props.f7router) {
        props.f7router.navigate({ name: 'productView', params: { key: data.ProductKey } })
      }
    }

    return { onSelectProduct, shop, warehouseregions }
  }
})
</script>

<style>
.product-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 10px;
  padding: 10px 10px;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 10%);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}
.prod-image-disp {
  width: 100px;
  height: 100px;
  padding-top: 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 10px;
}
.product-list img {
  width: 50px;
  margin-right: 15px;
}
.product-list h3 {
  font-weight: 700;
  width: 25ch;
  font-size: 14px;
}
.product-list h4 {
  font-size: 14px;
  font-weight: 700;
}
.product-list h4.price {
  font-weight: 100;
}
.product-list span.rating {
  background: var(--f7-theme-color);
  color: #fff;
  padding: 2px 10px;
  position: absolute;
  top: 10px;
  right: 0px;
  border-radius: 4px 0px 0px 4px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
}
.product-list span.year {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 32px;
  line-height: 1.2em;
  margin: 0px;
  padding: 0px;
  color: #e8e8e8;
}
</style>
