import dayjs from 'dayjs'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { configs } from '@/utils/configs'
import { socket } from "@/utils/socket"

const data = {
  UserKey: null,
  Token: '',
  TokenStartDate: '',
  IsRememberMe: 0,
  Expiration: ''
}

export const state = {
  data
}

export const getters = {
  getToken: (state) => {
    const expirationTime = state?.data?.IsRememberMe === 1 ? configs.tokenExpirationRememberMe : configs.tokenExpiration
    const expirationDate = state.data.TokenStartDate + expirationTime
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (isExpired) {
      state.data.Token = ''
      state.data.TokenStartDate = ''
      return ''
    } else {
      return state.data.Token
    }
  },
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  connect: ({ state })=>{
    if(state?.data?.UserKey){
      socket.join(configs.customerSupport);
      socket.join(state.data.UserKey);
    };
  },
  fetchData: async ({ state, commit, dispatch }, payload) => {
    const userToken = state.data.Token
    const expirationDate = state.data.Expiration
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (payload?.force) {
      isExpired = true
    }

    if (userToken && userToken !== '') {
      if (expirationDate == '' || isExpired) {
        helpers.showLoader('Getting user information...')

        try {
          const res = await $HTTP.get('/user/info')

          if (res && res.status === 200 && res.data && res.data.data) {
            helpers.hideLoader()

            const userInfo = res.data.data
            userInfo.Expiration = dayjs()
              .add(30, 'minute')
              .valueOf()

            commit('UPDATE_DATA', userInfo)
            dispatch('translation/setLanguage', { languageCode: userInfo.LanguageCode }, { root: true })
            return
          }

          throw new Error('Server not responding, Please try again later.')
        } catch (err) {
          helpers.hideLoader()
          helpers.catchError(err, true)
        }
      } else {
        return
      }
    }
  }
}

export const user = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
