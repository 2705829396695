<template>
    <f7-page>
      <f7-navbar title="Filter">
        <f7-nav-right>
          <f7-link popup-close><font-awesome-icon :icon="['far', 'times']" fixed-width /></f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div>
          <f7-list no-hairlines-md class="popup-content">
         
              <f7-list-input
                  label="Time Period"
                  type="datepicker"
                  placeholder="Please choose Two Dates"
                  outline
                  readonly
                  :calendar-params="{ dateFormat: 'M dd yyyy', rangePicker: true }"
                >
                  <f7-icon icon="demo-list-icon"></f7-icon>
                </f7-list-input>    

                   <div class="user-container">
        
          <div class="profile-info" >
              <img class="icon-profile" :src="require('@/assets/images/profile-picture.png')" />
            <div class="info">
              <h1>BusinessName</h1>
              <h2>Merchant ID</h2>
            </div>
          </div>
        
      </div>
                  <f7-button fill raised popup-open=".select-popup" class="fil-btn-sel" @click="showFilterPopUp">
                   Select Merchant
            
                </f7-button>
                
           <div class="user-container">
        
          <div class="profile-info" >
              <img class="icon-profile" :src="require('@/assets/images/profile-picture.png')" />
            <div class="info">
              <h1>FullName</h1>
              <h2>User ID</h2>
            </div>
          </div>
        
      </div>

                 <f7-button fill raised popup-open=".select-popup" class="fil-btn-sel" @click="showFilterPopUp">
                   Select User
            
                </f7-button>

               
          </f7-list>
          
         <div class="filter-btn">
                <f7-button class="fil-btn" raised fill>Reset</f7-button>
                <f7-button class="fil-btn" raised fill>Save</f7-button>
        </div>
      
                

                <f7-popup class="select-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
                    <f7-page>
      <f7-navbar><f7-nav-title> {{ $t.getTranslation("LBL_SELECT_USER") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close><font-awesome-icon :icon="['far', 'times']" fixed-width /></f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar v-model:value="searchValue" :disable-button="false" :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')" :clear-button="true"></f7-searchbar>

       <div class="user-container">
        
          <div class="profile-info" >
              <img class="icon-profile" :src="require('@/assets/images/profile-picture.png')" />
            <div class="info">
              <h1>FullName</h1>
              <h2>User ID</h2>
            </div>
          </div>
        
      </div>
    </f7-page>

                </f7-popup>

      </div>
    </f7-page>

</template>

<script>
import {defineComponent, ref, computed, reactive, onMounted, inject   } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";



export default defineComponent({
  name: "FilterPopUpComponent",
  components: {
  },
  props: { data: Object },
  setup() {

   
    

    return {};
  },

 
 
 

});
</script>

<style scoped>
.filter-btn {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.fil-btn {
    margin: 15px 0;
    width: 45%;
}
.fil-btn-sel{
    margin: 25px 15px;
    padding: 0 20px;
    height: 50px;
}

.popup-content {
    width: 100%;
    height: 450px;
}
.profile-info {
  cursor: pointer;
} 
.profile-info img {
    width: 60px;
    border-radius: 100%;
    border: 1px solid #f7f7f7;
    margin-right: 10px;
}
.user-container {
    width: calc(100% - 40px);
    margin: 20px auto;
}
</style>
