<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_COUPON_TIER_SAVE') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation("LBL_COUPON_TIER_DETAILS") }}</f7-list-item>

         <f7-list-input
            v-model:value="formData.Limit"
            name="Limit"
            :required="validationRules?.Limit?.required"
            :minlength="validationRules?.Limit?.minimumLength"
            :maxlength="validationRules?.Limit?.maximumLength"
            :label="$t.getTranslation('LBL_LIMIT')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Amount"
            name="Amount"
            :required="validationRules?.Amount?.required"
            :minlength="validationRules?.Amount?.minimumLength"
            :maxlength="validationRules?.Amount?.maximumLength"
            :label="$t.getTranslation('LBL_AMOUNT')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.CouponTierTranslation.EN"
            name="Name"
            :required="validationRules?.Name?.required"
            :minlength="validationRules?.Name?.minimumLength"
            :maxlength="validationRules?.Name?.maximumLength"
            :label="$t.getTranslation('LBL_COUPON_NAME_EN')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            placeholder=""
            validate
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.CouponTierTranslation.CHS"
            name="Name"
            :required="validationRules?.Name?.required"
            :minlength="validationRules?.Name?.minimumLength"
            :maxlength="validationRules?.Name?.maximumLength"
            :label="$t.getTranslation('LBL_COUPON_NAME_CHS')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            placeholder=""
            validate
          >
          </f7-list-input>


  
          <f7-button large fill raised preloader @click="saveData">
              {{ $t.getTranslation("LBL_SAVE") }}
          </f7-button>

           <f7-button large fill raised preloader @click="back">
              {{ $t.getTranslation("LBL_BACK") }}
          </f7-button>

        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "BannerSavePage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const statusList = computed(() => store.getters["config/getData"]?.statusList);
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const uploadedImages = ref([]);
    const couponId = ref(null);
    const couponTierId = ref(null);
    const formData = reactive({
      CouponTierTranslation: {},
      StatusCode: ""
    });
 
    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }


    onMounted(async() => {
      couponId.value = props?.f7route?.query?.CouponId;
      if (props?.f7route?.query && props?.f7route?.query?.CouponId && props?.f7route?.query?.CouponTierId) {
        isUpdate.value = true;
        couponTierId.value = props?.f7route?.query?.CouponTierId;
        await getData();
      }
    });

    
    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/coupon/view/tier?CouponTierId=${couponTierId.value}&LanguageCode=${currentLanguageCode.value || 'EN'}`);
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();

          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key];
          }
      
          helpers.hideLoader();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };


    const saveData = async () => {
      try {
        helpers.showLoader();
        formData.CouponId = couponId.value;
        let res = await $HTTP.post(`/admin/coupon/save/tier`, formData);
        if (res?.status === 200 && res?.data?.data) {
          formData.CouponTierId = res.data.data.CouponTierId;
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("LBL_INFO_SUCCESSFULLY_SAVE_COUPON_TIER") });
          await getData();
          helpers.hideLoader();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };



    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const back = () => {
      props.f7router.navigate({ name: "couponListTier", query: { CouponId: couponId.value } });
    }

    

    return { isUpdate, formData, validationRules, isButtonProcessing, isDisableSaveButton, back, statusList, saveData };
  },
});
</script>
