<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_WINERY_SAVE') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation("LBL_WINERY_DETAILS") }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.WineryCode"
            name="WineryCode"
            :required="validationRules?.WineryCode?.required"
            :minlength="validationRules?.WineryCode?.minimumLength"
            :maxlength="validationRules?.WineryCode?.maximumLength"
            :label="$t.getTranslation('LBL_WINERY_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            :disabled="formData.WineryId"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.WineryName['EN']"
            :name="WineryName"
            :required="validationRules?.WineryName?.required"
            :minlength="validationRules?.WineryName?.minimumLength"
            :maxlength="validationRules?.WineryName?.maximumLength"
            :label="$t.getTranslation('LBL_WINERY_NAME_EN')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.WineryName['CHS']"
            :name="WineryName"
            :required="validationRules?.WineryName?.required"
            :minlength="validationRules?.WineryName?.minimumLength"
            :maxlength="validationRules?.WineryName?.maximumLength"
            :label="$t.getTranslation('LBL_WINERY_NAME_CHS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>


          <div v-if="formData?.WineryId">
            <f7-list-item>
               <ViewImageListComponent :title="$t.getTranslation('LBL_UPLOADED_IMAGES')" :data="uploadedImages" />
            </f7-list-item>

            <f7-segmented raised tag="p">
              <f7-button @click="upload('WINERY_IMAGE')">{{  $t.getTranslation('LBL_UPLOAD_WINERY_IMAGE') }}</f7-button>
            </f7-segmented>

          </div>
         
          <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
              {{ $t.getTranslation("LBL_SAVE") }}
          </f7-button>

        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import ViewImageListComponent from "@/components/ViewImageListComponent.vue";

export default defineComponent({
  name: "GrapesSavePage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    ViewImageListComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const uploadedImages = ref([]);
    const wineryId = ref(null);
    const formData = reactive({
      WineryName: {
        EN: "",
        CHS: ""
      },
    });

    // uploadedImages.value.push({fields: "Identification", name: "Identification", link: helpers.getImage('http://localhost:3003/img/avatar_male.1e0b4bfd.svg', null, null, true)});

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(async() => {
      if (props?.f7route?.query && props?.f7route?.query?.WineryId) {
        isUpdate.value = true;
        wineryId.value = props?.f7route?.query?.WineryId;
        await getData();
      }
    });

    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/winery/view?WineryId=${wineryId.value}`);
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();
          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key];
          }

          for(const img of res.data.data.WineryImages){
            let name;
            if (img.FieldName == "WineryImage") {
              name = $t.getTranslation("LBL_WINERY_IMAGE")
            }
            uploadedImages.value.push({fields: img.FieldName, name: name, link: helpers.getImage(img.FileName, "WINERY")});
          }

          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const upload = (type) => {
      props.f7router.navigate({ name: "wineryUpload", params: {id: wineryId.value }, query: {type: type } });
    }

    const save = async () => {
      try {
        let isValid = validate(true);
        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader();
          formData.LanguageCode = currentLanguageCode.value;
          //send update to server
          let res = await $HTTP.post("/admin/winery/save", formData);
          if (res?.status === 200 && res?.data?.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();
            //props.f7router.back({ force: true, ignoreCache: true });
            helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS") , message: $t.getTranslation("LBL_SUCCESS_SAVE_WINERY") });
            return;
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    return { isUpdate, formData, validationRules, isButtonProcessing, isDisableSaveButton, save, uploadedImages, upload };
  },
});
</script>
