import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'merchant',
    path: '/merchant/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'store',
        title: translation.getTranslation('LBL_MERCHANT'),
        activeLink: 'merchant'
      },
      isSecured: true,
      hasRoles: ['MANAGE_MERCHANT']
    },
    redirect: '/merchant/list/',
    routes: [
      {
        name: 'merchantList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_MERCHANT_LIST'),
            activeLink: 'merchantList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_MERCHANT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/merchant/MerchantListPage.vue')
      },
      {
        name: 'merchantView',
        path: 'save/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'merchantList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_MERCHANT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/merchant/MerchantSavePage.vue')
      },
      {
        name: 'merchantUpload',
        path: 'upload/:id',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'merchantList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_MERCHANT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/merchant/MerchantUploadPage.vue')
      }
      // {
      //   name: 'merchantFeaturedList',
      //   path: 'featured/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('LBL_MERCHANT_FEATURED_LIST'),
      //       activeLink: 'merchantFeaturedList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_MERCHANT']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/merchant/MerchantFeaturedListPage.vue')
      // },
      //  {
      //   name: 'merchantFeaturedSave',
      //   path: 'featured/save',
      //   meta: {
      //     navigation: {
      //       isShown: false,
      //       activeLink: 'merchantFeaturedList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_MERCHANT']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/merchant/MerchantFeaturedSavePage.vue')
      // },
    ]
  }
]

export default routes
