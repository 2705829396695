<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("BTN_EXCLUDE_SKU") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button
          large
          fill
          raised
          :loading="isUploading"
          :disabled="isUploading"
          @click="upload($refs)"
          >{{ $t.getTranslation("LBL_IMPORT") }}</f7-button
        >
        <f7-button @click="download()">{{
          $t.getTranslation("LBL_DOWNLOAD_IMPORT_TEMPLATE")
        }}</f7-button>
      </div>
    </div>

    <DataTableComponent
      ref="dataTable"
      :columns="columns"
      :size="10"
      :url="url"
      @deleteItem="(data) => deleteItem(data, $refs)"
      :skipRemark="true"
    ></DataTableComponent>

    <MainFooterComponent />
    <input
      ref="fileInput"
      capture="user"
      type="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      @change="uploadExcel($event, $refs)"
    />
  </f7-page>
</template>

<script>
import { defineComponent, inject, computed, ref } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { useStore } from "@/store";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
export default defineComponent({
  name: "BlankPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const isUploading = ref(false);

    const columns = [
      {
        title: $t.getTranslation("LBL_PRODUCT_ID"),
        field: "ProductId",
      },
      {
        title: $t.getTranslation("LBL_SKU"),
        field: "Sku",
      },
      {
        title: $t.getTranslation("LBL_NAME"),
        field: "ProductName",
      },
      {
        title: $t.getTranslation("LBL_DATE_CREATED"),
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        },
      },
    ];

    const upload = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const deleteItem = async (data, refs) => {
      await $HTTP.post("/admin/voucher/exclude/remove", {
        ProductId: data.value.ProductId,
      });
      refs.dataTable.initTabulator();
    };

    const uploadExcel = async (event, refs) => {
      let input = event.target;

      if (input?.files?.[0]) {
        let file = input?.files?.[0];
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/);
        if (!isValidFile) throw new Error($t.getTranslation("LBL_ERR_INVALID_FILE"));

        //upload now
        isUploading.value = true;

        const formData = new FormData();
        formData.append("file", file);

        let ret = await $HTTP.post("/admin/voucher/exclude/import", formData);
        isUploading.value = false;

        if (ret) {
          helpers.createNotification({
            type: "success",
            title: $t.getTranslation("LBL_SUCCESS"),
            message: $t.getTranslation("MSG_SUCCESS_UPLOAD"),
          });
          refs.dataTable.initTabulator();
        }
      }
    };

    const download = () => {
      window.location.href = "/excel/product/exclude.xlsx";
      return;
    };

    let url = `/admin/voucher/exclude/list`;
    return {
      isUploading,
      columns,
      download,
      deleteItem,
      url,
      uploadExcel,
      upload,
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
