<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_USER_DESIGNATED_COUPON") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @editItem="editItem" @viewOrders="viewOrders"></DataTableComponent>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, computed } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { useStore } from "@/store";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
export default defineComponent({
  name: "BlankPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const columns = [
      {
        title: $t.getTranslation("LBL_COUPON_ID"),
        minWidth: 160,
        field: "VoucherId",
      },
      {
        title: $t.getTranslation("LBL_CRITERIA"),
        minWidth: 160,
        field: "VoucherTypeName",
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusName || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_NAME"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.renderUserName(cell.getData(), true)}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_COUPON_AMOUNT"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.formatPrice(cell.getData().Amount, "RMB")}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_MIN_ORDER_AMOUNT"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.formatPrice(cell.getData().MinOrderAmount, "RMB")}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_VALID_FROM"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.formatDateTime(cell.getData().StartDate)}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_VALID_TO"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.formatDateTime(cell.getData().EndDate)}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_REDEEM_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${cell.getData().IsRedeemed ? "Y" : "N"}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATE_CREATED"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        },
      },
    ];

    const editItem = (data) => {
      if (data?.value?.VoucherId) {
        props.f7router.navigate({ name: "userVoucherSave", query: { VoucherId: data.value.VoucherId } });
      }
    };

    const viewOrders = (data) => {
      if (data?.value?.VoucherId) {
        props.f7router.navigate({ name: "voucherOrders", query: { VoucherId: data.value.VoucherId } });
      }
    };

    const create = () => {
      props.f7router.navigate({ name: "userVoucherSave", query: {} });
    };

    let url = `/admin/voucher/user/list`;
    return {
      viewOrders,
      columns,
      url,
      editItem,
      create,
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
