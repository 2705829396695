import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { configs } from '@/utils/configs'
import translation from '@/utils/translation'
import { socket } from "@/utils/socket"

import _ from 'lodash'

const data = {
  ThreadKey : null,
  Unread : 0,
  ThreadList : [],
  UnansweredList : [],
  Unanswered : 0,
  SearchFields: ""
}

export const state = {
  data
}

export const getters = {
  totalUnread : (state) =>{
    return _.sumBy(_.filter(state.data.ThreadList, (t)=>{
      return t.ThreadKey != state.data.ThreadKey
    }), (r)=>{ return r.Unread })
  },
  filtered : (state)=>{
    return _.sortBy(_.filter(state.data.ThreadList, (t)=>{
        return t.ThreadKey != state.data.ThreadKey
      }), (d)=>{
      return new Date(d.LastModified).getTime()
    }).reverse()
  },
  search : (state)=>{
    return _.filter(state.data.ThreadList, (t)=>{
      if (t.ThreadKey != state.data.ThreadKey) {

        let FirstName = "";
        let LastName = "";
        if (t?.User?.FirstName) {
           FirstName = t.User.FirstName.toLowerCase();
        }

        if (t?.User?.LastName) {
           LastName = t.User.LastName.toLowerCase();
        }
      
        if (FirstName.indexOf(state.data.SearchFields) > -1 || LastName.indexOf(state.data.SearchFields) > -1) {
          return t;
        }
      }
    })
  },
  active : (state)=>{
    return _.find(state.data.ThreadList, (t)=>{
      return t.ThreadKey == state.data.ThreadKey
    })
  },
  currentCount : (state) => {
    let Total = _.sumBy(_.filter(state.data.ThreadList, (t)=>{
      return t.ThreadKey != state.data.ThreadKey
    }), (r)=>{ return r.Unread });
    return Total + state.data.Unanswered
  },
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('UPDATE_DATA', data)
  }
}

export const chat = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
