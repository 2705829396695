<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_COUNTRY_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>

      <div class="button-container">
          <f7-button large fill raised @click="position()">{{ $t.getTranslation("LBL_TOP_COUNTRIES") }}
        </f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @viewItem="editItem" @editItem="editItem"></DataTableComponent>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, reactive } from "vue";

import _ from "lodash";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "ProductDetailsPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
     const $t = inject("$translation");
     const store = useStore();
     const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
     const categoyList = ref([]);
     let url = `/admin/country/list?&LanguageCode=${currentLanguageCode.value || 'EN'}`;

     if (props?.f7route?.query?.isFeatured) {
      url += `&mode=NONFEATURED`;
     }

     const columns = [
      {
        title: $t.getTranslation("LBL_COUNTRY_CODE"),
        field: 'CountryCode'
      },
      {
        title: $t.getTranslation("LBL_COUNTRY_NAME"),
        field: "CountryName"
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_LAST_CREATED"),
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        },
      }
    ];

    onMounted(async () => {
  
    });

    const editItem = (data) => {
        if (data?.value?.CountryId ) {
          let query = {CountryId: data.value.CountryId};
          if (props?.f7route?.query?.isFeatured) {
            query['isFeatured'] = 1;
          }
          props.f7router.navigate({ name: "countrySave", query: query});
        }
    };

    const position = () => {
      props.f7router.navigate({ name: "countryPositionList", query: {} });
    }

    return {
        categoyList,
        columns,
        url,
        editItem,
        position
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img{
  width: 100px;
  height: 100px;
}
.tab-container{
  margin-top: 30px;
}
</style>
