import { configs } from '@/utils/configs'
import { io } from 'socket.io-client'
import { useStore } from '@/store'
import _ from 'lodash'

/* MAIN INSTANCE HERE */
let socketReceiveCallback = {}
let main = io(configs.chatServer, { reconnection: true, autoConnect: true })
main.open()

main.on('connect', () => {
  if (!main.connected) return
  console.log('is socket connected', main.connected)
})

main.on('connect_error', (error) => {
  console.log('connection failed')
})

main.on('disconnect', () => {
  console.log('socket disconnected')
})

main.on('message', (data) => {
  console.log('message', data)
  if (socketReceiveCallback['RECEIVED_MSG']) {
    socketReceiveCallback['RECEIVED_MSG'](data)
  }
})

main.on('notification', (data) => {
  let store = useStore()
  //console.log('notification', data)

  if (data.Mode && data.Mode == 'BADGE') {
    store.dispatch('chat/setData', { Unread: data.Unread })
  }

  if (data.Mode && data.Mode == 'THREAD') {
    store.dispatch('chat/setData', { ThreadList: data.ThreadList })
  }

  if (data.Mode && data.Mode == 'UNANSWERED') {
    store.dispatch('chat/setData', { Unanswered: data.Unanswered, UnansweredList: data.UnansweredList })
  }

  if (data.Mode && data.Mode == 'REPORT_GENERATED') {
    store.dispatch('report/setData', { FileName: data.FileName, FileType: data.FileType })
  }
})

//global function here, like join, leave, etc.
let socket = {
  emit(emit, data) {
    main.emit(emit, data)
  },
  on(on, data) {
    main.on(on, data)
  },
  unassignSocketCallback(type) {
    delete socketReceiveCallback[type]
  },
  assignSocketCallback(type, cb) {
    socketReceiveCallback[type] = cb
  },
  log(desc) {
    console.log('[SOCKET]', desc)
  },
  leave(room) {
    socket.log('Leaving room ' + room)
    main.emit('leave', room)
  },
  join(room) {
    socket.log('Joining room ' + room)
    main.emit('join', room)
  }
}

const install = (app) => {
  app.config.globalProperties.$socket = socket
}

export { install as default, socket }
