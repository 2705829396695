<template>
  <f7-popup class="userselect-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation('LBL_PRODUCTS') }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar v-model:value="searchValue" :disable-button="false" :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')" :clear-button="true"></f7-searchbar>

      <div class="user-container">
        <template v-for="product in Products" :key="'prditem_' + product.ProductId">
          <ProductListCardComponent :directSend="true" :data="product.Product" :category="true" :f7router="f7router" :sendProduct="sendProduct" :isSale="isSale" style="width: calc(100% - 23px)" />
        </template>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import ProductListCardComponent from '@/components/cards/ProductListCardComponent.vue'
import { ref, onMounted, inject, watch } from 'vue'
import { f7 } from 'framework7-vue'

import { get } from '@/utils/axios'

export default {
  name: 'SkuSelectionPopUpComponent',
  components: { ProductListCardComponent },
  props: {
    open: Boolean,
    attachSku: Function,
    isSale: {
      type: Boolean,
      default: false
    }
  },
  emits: ['opened', 'closed', 'selected'],
  setup(props, { emit }) {
    const $t = inject('$translation')

    let size = 15
    let page = 1
    let lastPage = 1

    const Products = ref([])
    const showPreloader = ref(false)
    const searchValue = ref('')
    const SearchFields = ref(['p.Sku', 'Name'])

    const getProductList = async () => {
      let ret = await get('/public/product/datalist', {
        isChat: 1,
        LanguageCode: $t.getLanguage(),
        page,
        size,
        SearchField: SearchFields.value.join(','),
        SearchType: 'LIKE',
        SearchValue: searchValue.value,
        search: searchValue.value
      })

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter]

          if (item.WareHouseRegions && item.WareHouseRegions != '') {
            item.WareHouseRegions = item.WareHouseRegions.split(',')
          }

          let region = { WareHouseRegions: item.WareHouseRegions }

          item.Product = { ...item.Product, ...region }
          Products.value.push(item)
        }
      }
    }

    onMounted(getProductList)

    watch(searchValue, (newValue, oldValue) => {
      Products.value = []
      page = 1
      getProductList()
    })

    const openPopup = () => {
      emit('opened', true)
    }

    const closePopup = () => {
      emit('closed', true)
    }

    const sendProduct = (data) => {
      f7.popup.close()

      if (props.attachSku) {
        let obj = JSON.stringify(data)
        props.attachSku(obj)
        return
      }

      emit('selected', data)
    }

    return {
      openPopup,
      closePopup,
      Products,
      sendProduct,
      showPreloader,
      searchValue
    }
  }
}
</script>

<style scoped>
.popup .navbar {
  height: 80px;
}
.userselect-popup.popup .navbar .title {
  margin-left: 15px;
}
.userselect-popup .user-container {
  width: calc(100% - 40px);
  margin: 20px auto;
}
.profile-info {
  cursor: pointer;
}
</style>
