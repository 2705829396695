<template>
  <f7-page class="home-page">
    <MainHeaderComponent type="None" />

    <section class="not-found">
      <div class="container">
        <img src="@/assets/images/svg/404.svg" alt="404 Not Found" />
        <h1>{{ $t.getTranslation("LBL_404_PAGE_NOT_FOUND") }}</h1>
        <p>{{ $t.getTranslation("LBL_404_SUB_CONTENT") }}</p>

        <f7-button href="/dashboard/" fill large raised>{{ $t.getTranslation("LBL_DASHBOARD") }}</f7-button>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "NotFoundPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
  },
  props: { f7router: Object },
  setup(props) {
    return {};
  },
});
</script>

<style scoped>
.not-found .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0px;
}
.not-found img {
  width: 280px;
}
.not-found h1 {
  font-size: 28px;
  margin: 50px 0px 0px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}
.not-found h1 small {
  font-size: 20px;
  display: block;
  color: #7e7e7e;
}
.not-found p {
  padding: 10px 40px;
  font-size: 16px;
  text-align: center;
}
.not-found .button {
  margin-top: 40px;
  width: 300px;
}
@media only screen and (max-width: 899px) {
  br {
    display: none;
  }
  .not-found .container {
    padding: 60px 0px 100px;
  }
  .not-found img {
    width: 200px;
  }
  .not-found h1 {
    font-size: 22px;
    padding: 0px 40px;
    margin-bottom: 0px;
  }
  .not-found h1 small {
    font-size: 16px;
  }
  .not-found p {
    padding: 15px 25px;
    font-size: 15px;
  }
  .not-found .button {
    width: 270px;
    margin-top: 20px;
  }
}
</style>
