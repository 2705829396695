// import Framework7 from 'framework7/lite-bundle'
import Framework7 from 'framework7'
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle'

// FRAMEWORK7 STYLING
import 'framework7/framework7-bundle.min.css'

// import Framework7 components
import Dialog from 'framework7/components/dialog'
import Popup from 'framework7/components/popup'
import Popover from 'framework7/components/popover'
import Actions from 'framework7/components/actions'
import Sheet from 'framework7/components/sheet'
import Toast from 'framework7/components/toast'
import Preloader from 'framework7/components/preloader'
import Progressbar from 'framework7/components/progressbar'
import Panel from 'framework7/components/panel'
import Card from 'framework7/components/card'
import Form from 'framework7/components/form'
import Input from 'framework7/components/input'
import Checkbox from 'framework7/components/checkbox'
import Radio from 'framework7/components/radio'
import Toggle from 'framework7/components/toggle'
import Grid from 'framework7/components/grid'
import Calendar from 'framework7/components/calendar'
import Picker from 'framework7/components/picker'
import InfiniteScroll from 'framework7/components/infinite-scroll'
import PullToRefresh from 'framework7/components/pull-to-refresh'
import DataTable from 'framework7/components/data-table'
import Searchbar from 'framework7/components/searchbar'
import Notification from 'framework7/components/notification'
import Tooltip from 'framework7/components/tooltip'
import PhotoBrowser from 'framework7/components/photo-browser'
import Swiper from 'framework7/components/swiper'
import TextEditor from 'framework7/components/text-editor'
import Treeview from 'framework7/components/treeview'
import SmartSelect from 'framework7/components/smart-select'
import MessageBar from 'framework7/components/messagebar'
import Tabs from 'framework7/components/tabs'

Framework7.use([
  Actions,
  Card,
  Dialog,
  Popup,
  Preloader,
  Progressbar,
  Popover,
  Form,
  Input,
  Checkbox,
  Radio,
  Toggle,
  Grid,
  Calendar,
  Picker,
  InfiniteScroll,
  PullToRefresh,
  DataTable,
  Searchbar,
  Notification,
  Tooltip,
  Panel,
  PhotoBrowser,
  Sheet,
  Swiper,
  Toast,
  TextEditor,
  Treeview,
  SmartSelect,
  MessageBar,
  Tabs
])

Framework7.use(Framework7Vue)

export { registerComponents, Framework7, Framework7Vue }
