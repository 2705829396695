<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation("LBL_COUPON_SAVE") }}</h1>
        </div>
      </div>
      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{
            $t.getTranslation("LBL_COUPON_DETAILS")
          }}</f7-list-item>

          <template v-if="formData.VoucherTypeCode != 'REDEEMABLE'">
            <!-- for specific sku -->
            <f7-list-item>
              <div class="speci-product">
                <h3>{{ $t.getTranslation("LBL_SPECIFIC_SKU") }}</h3>

                <div class="speci-alert" v-if="!formData?.SpecificSku?.length">
                  {{ $t.getTranslation("LBL_NO_PRODUCTS_ADDED") }}
                </div>

                <template v-for="item in formData.SpecificSku" :key="item.ProductId">
                  <ProductListCardComponent
                    :tagline="$t.getTranslation('BTN_TAP_TO_REMOVE')"
                    :directSend="true"
                    :sendProduct="removeProduct"
                    :data="item"
                    :category="true"
                    style="width: calc(100% - 23px)"
                  />
                </template>

                <div class="speci-btn">
                  <f7-button fill raised @click="selectSku()">
                    {{ $t.getTranslation("LBL_ADD_PRODUCT") }}
                  </f7-button>
                </div>
              </div>
            </f7-list-item>

            <!-- for exclude sku -->
            <f7-list-item>
              <div class="speci-product">
                <h3>{{ $t.getTranslation("BTN_EXCLUDE_SKU") }}</h3>

                <div class="speci-alert" v-if="!formData?.ExcludeSku?.length">
                  {{ $t.getTranslation("LBL_NO_PRODUCTS_ADDED") }}
                </div>

                <template v-for="item in formData.ExcludeSku" :key="item.ProductId">
                  <ProductListCardComponent
                    :tagline="$t.getTranslation('BTN_TAP_TO_REMOVE')"
                    :directSend="true"
                    :sendProduct="(data) => removeProduct(data, true)"
                    :data="item"
                    :category="true"
                    style="width: calc(100% - 23px)"
                  />
                </template>

                <div class="speci-btn">
                  <f7-button fill raised @click="selectSku(true)">
                    {{ $t.getTranslation("LBL_ADD_PRODUCT") }}
                  </f7-button>
                </div>
              </div>
            </f7-list-item>
          </template>

          <f7-list-input
            v-model:value="formData.VoucherTypeCode"
            name="VoucherTypeCode"
            :required="validationRules?.VoucherTypeCode?.required"
            :minlength="validationRules?.VoucherTypeCode?.minimumLength"
            :maxlength="validationRules?.VoucherTypeCode?.maximumLength"
            :label="$t.getTranslation('LBL_CRITERIA')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="type in typeList" :key="'st_' + type.Code" :value="type.Code">
              {{ type.Name }}
            </option>
          </f7-list-input>

          <f7-list-input
            v-if="formData.VoucherTypeCode == 'REDEEMABLE'"
            v-model:value="formData.RedeemableAmount"
            name="RedeemableAmount"
            :required="validationRules?.RedeemableAmount?.required"
            :minlength="validationRules?.RedeemableAmount?.minimumLength"
            :maxlength="validationRules?.RedeemableAmount?.maximumLength"
            :label="$t.getTranslation('LBL_REDEEMABLE_AMOUNT')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="number"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-if="!(['GENERIC', 'REDEEMABLE'].indexOf(formData.VoucherTypeCode) > -1)"
            v-model:value="formData.AssignedQty"
            name="AssignedQty"
            :required="validationRules?.AssignedQty?.required"
            :minlength="validationRules?.AssignedQty?.minimumLength"
            :maxlength="validationRules?.AssignedQty?.maximumLength"
            :info="$t.getTranslation('LBL_SET_0_FOR_UNLIMITED')"
            :label="$t.getTranslation('LBL_TOTAL_COUPON_QTY')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="number"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Amount"
            name="Amount"
            :required="validationRules?.Amount?.required"
            :minlength="validationRules?.Amount?.minimumLength"
            :maxlength="validationRules?.Amount?.maximumLength"
            :label="$t.getTranslation('LBL_COUPON_AMOUNT')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="number"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.MinOrderAmount"
            name="MinOrderAmount"
            :required="validationRules?.MinOrderAmount?.required"
            :minlength="validationRules?.MinOrderAmount?.minimumLength"
            :maxlength="validationRules?.MinOrderAmount?.maximumLength"
            :label="$t.getTranslation('LBL_MIN_ORDER_AMOUNT')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="number"
            placeholder=""
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-if="formData.VoucherTypeCode == 'REDEEMABLE'"
            v-model:value="formData.UserType"
            name="UserType"
            :required="validationRules?.UserType?.required"
            :minlength="validationRules?.UserType?.minimumLength"
            :maxlength="validationRules?.UserType?.maximumLength"
            :label="$t.getTranslation('LBL_ELIGIBLE_USER_TYPE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="select"
            validate
          >
            <option
              v-for="coupon in userTypeList"
              :key="'ct_' + coupon.Code"
              :value="coupon.Code"
            >
              {{ coupon.Name }}
            </option>
          </f7-list-input>

          <f7-list-input
            v-if="formData.VoucherTypeCode == 'REDEEMABLE'"
            v-model:value="formData.AmountType"
            name="AmountType"
            :required="validationRules?.AmountType?.required"
            :minlength="validationRules?.AmountType?.minimumLength"
            :maxlength="validationRules?.AmountType?.maximumLength"
            :label="$t.getTranslation('LBL_COUPON_METHOD')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="select"
            validate
          >
            <option
              v-for="coupon in amountTypeList"
              :key="'ct_' + coupon.Code"
              :value="coupon.Code"
            >
              {{ coupon.Name }}
            </option>
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.StartDate"
            name="StartDate"
            :required="validationRules?.StartDate?.required"
            :minlength="validationRules?.StartDate?.minimumLength"
            :maxlength="validationRules?.StartDate?.maximumLength"
            :label="$t.getTranslation('LBL_VALID_FROM')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="datetime-local"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.EndDate"
            name="StartDate"
            :required="validationRules?.EndDate?.required"
            :minlength="validationRules?.EndDate?.minimumLength"
            :maxlength="validationRules?.EndDate?.maximumLength"
            :label="$t.getTranslation('LBL_VALID_TO')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="datetime-local"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.IssuingDateFrom"
            name="IssuingDateFrom"
            :required="validationRules?.IssuingDateFrom?.required"
            :minlength="validationRules?.IssuingDateFrom?.minimumLength"
            :maxlength="validationRules?.IssuingDateFrom?.maximumLength"
            :label="$t.getTranslation('LBL_ISSUING_DATE_FROM')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="datetime-local"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.IssuingDateTo"
            name="IssuingDateTo"
            :required="validationRules?.IssuingDateTo?.required"
            :minlength="validationRules?.IssuingDateTo?.minimumLength"
            :maxlength="validationRules?.IssuingDateTo?.maximumLength"
            :label="$t.getTranslation('LBL_ISSUING_DATE_TO')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="datetime-local"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.StatusCode"
            name="StatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="select"
            validate
          >
            <option
              v-for="status in statusList"
              :key="'st_' + status.Code"
              :value="status.Code"
            >
              {{ status.Name }}
            </option>
          </f7-list-input>

          <f7-button large fill raised preloader @click="saveData">
            {{ $t.getTranslation("LBL_SAVE") }}
          </f7-button>

          <f7-button large fill raised preloader @click="back">
            {{ $t.getTranslation("LBL_BACK") }}
          </f7-button>
        </f7-list>
      </div>
    </section>

    <SkuSelectionPopUpComponent
      :open="openSkuPopup"
      @closed="onCloseSkuPopup"
      @selected="onSkuSelected"
    />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

import SkuSelectionPopUpComponent from "@/components/SkuSelectionPopUpComponent.vue";
import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";

export default defineComponent({
  name: "BannerSavePage",
  components: {
    SkuSelectionPopUpComponent,
    ProductListCardComponent,
    MainHeaderComponent,
    MainFooterComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const statusList = computed(() => store.getters["config/getData"]?.statusList);

    const typeList = ref([
      { Code: "NEW", Name: "New User" },
      { Code: "REFERRAL", Name: "Referral" },
      { Code: "GENERIC", Name: "Generic" },
      { Code: "REDEEMABLE", Name: "Redeemable Coupon" },
    ]);

    const amountTypeList = ref([
      { Code: "PERC", Name: "Percentage Amount" },
      { Code: "FIXED", Name: "Fixed Amount" },
    ]);

    const userTypeList = ref([
      { Code: "ALL", Name: "All User" },
      { Code: "NEW", Name: "New User" },
      { Code: "ORDERED", Name: "User have existing order" },
    ]);

    const formData = reactive({
      RedeemableAmount: "",
      StatusCode: "",
      AmountType: "FIXED",
      UserType: "ALL",
      VoucherTypeCode: "NEW",
      SpecificSku: [],
      ExcludeSku: [],
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(async () => {
      if (props?.f7route?.query && props?.f7route?.query?.VoucherId) {
        formData.VoucherId = props?.f7route?.query?.VoucherId;
        await getData();
      }
    });

    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/voucher/view?VoucherId=${formData.VoucherId}`);
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();

          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key];
          }

          formData.StartDate = helpers.formatDatimeTimePicker(formData.StartDate);
          formData.EndDate = helpers.formatDatimeTimePicker(formData.EndDate);

          if (formData.IssuingDateFrom) {
            formData.IssuingDateFrom = helpers.formatDatimeTimePicker(
              formData.IssuingDateFrom
            );
          }
          if (formData.IssuingDateTo) {
            formData.IssuingDateTo = helpers.formatDatimeTimePicker(
              formData.IssuingDateTo
            );
          }

          helpers.hideLoader();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const saveData = async () => {
      try {
        helpers.showLoader();

        formData.StartDate = helpers.formatSQLDateTime(formData.StartDate);
        formData.EndDate = helpers.formatSQLDateTime(formData.EndDate);

        let StartDate = new Date(formData.StartDate).getTime();
        let EndDate = new Date(formData.EndDate).getTime();

        if (EndDate < StartDate) {
          throw new Error($t.getTranslation("ERR_INVALID_DATE"));
        }

        let res = await $HTTP.post(`/admin/voucher/save`, formData);

        if (res?.status === 200 && res?.data?.data) {
          helpers.createNotification({
            type: "success",
            title: $t.getTranslation("LBL_SUCCESS"),
            message: $t.getTranslation("LBL_INFO_SUCCESSFULLY_SAVE_COUPON"),
          });
          helpers.hideLoader();
          back();
          return;
        }

        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const back = () => {
      props.f7router.navigate({ name: "voucherList", params: {}, query: {} });
    };

    const openSkuPopup = ref(false);
    const onCloseSkuPopup = () => {
      openSkuPopup.value = false;
    };
    const selectSku = (isExclude = false) => {
      openSkuPopup.value = true;
      formData.isExclude = isExclude ? true : false;
    };
    const onSkuSelected = (data) => {
      let found = _.find(
        formData.isExclude ? formData.ExcludeSku : formData.SpecificSku,
        (r) => {
          return r.ProductId == data.ProductId;
        }
      );
      if (found) return;
      let addedAgaints = _.find(
        formData.isExclude ? formData.SpecificSku : formData.ExcludeSku,
        (r) => {
          return r.ProductId == data.ProductId;
        }
      );
      if (addedAgaints) {
        removeProduct(data, !formData.isExclude);
      }
      if (!formData.isExclude) {
        formData.SpecificSku.push(data);
      } else {
        formData.ExcludeSku.push(data);
      }
    };

    const removeProduct = (data, isExclude = false) => {
      let index = _.findIndex(
        isExclude ? formData.ExcludeSku : formData.SpecificSku,
        (r) => {
          return r.ProductId == data.ProductId;
        }
      );
      if (index > -1) {
        if (isExclude) {
          formData.ExcludeSku.splice(index, 1);
        } else {
          formData.SpecificSku.splice(index, 1);
        }
      }
    };

    return {
      openSkuPopup,
      onCloseSkuPopup,
      selectSku,
      onSkuSelected,
      removeProduct,
      formData,
      typeList,
      validationRules,
      back,
      userTypeList,
      statusList,
      amountTypeList,
      saveData,
    };
  },
});
</script>
