<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'credit-card']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation('LBL_PAYMENT_LIST') }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button v-if="showBatchCustomPush" large fill raised @click="onBatchCustomPush">{{ $t.getTranslation('LBL_BATCH_CUSTOM_PUSH') }}</f7-button>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref, onMounted } from 'vue'
import { Dom7 } from 'framework7'

import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import DataTableComponent2 from '@/components/DataTableComponent2.vue'

import { $HTTP } from '@/utils/axios'

import { helpers } from '@/utils/helpers'

export default defineComponent({
  name: 'PaymentListPage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')

    const showBatchCustomPush = ref(true)
    const dataTableComponent = ref(false)

    // const actions = [
    //   {
    //     groupText: $t.getTranslation('LBL_PERFORM_ACTIONS'),
    //     list: [
    //       { text: $t.getTranslation('LBL_VIEW'), type: 'VIEW', bold: true },
    //       { text: $t.getTranslation('LBL_CUSTOM_PUSH'), type: 'CUSTOM_PUSH', bold: false }
    //     ]
    //   }
    // ]
    const actions = false

    const columns = [
      {
        frozen: true,
        title: ``,
        minWidth: 50,
        maxWidth: 50,
        formatter(cell) {
          let tempData = JSON.parse(JSON.stringify(cell.getData()))

          let customsData = tempData?.OrderCustomsData.map((item) => {
            let parseData = JSON.parse(item.RawData)
            return parseData;
          })

          delete tempData.OrderCustomsData

          return `
            <div class="toggle-collapse" data-page-data='${JSON.stringify(tempData)}' data-custom-data='${JSON.stringify(customsData)}'>
              <i class="far fa-fw fa-angle-up open"></i>
              <i class="far fa-fw fa-angle-down close"></i>
            </div>
          `
        }
      },
      {
        frozen: true,
        title: `
          <label class="checkbox no-ripple">
            <input class="main-checkbox" type="checkbox" />
            <i class="icon-checkbox"></i>
          </label>
        `,
        minWidth: 35,
        maxWidth: 35,
        formatter(cell) {
          return `
            <div>
              <label class="checkbox">
                <input class="check-item" type="checkbox" value="${cell.getData()?.OrderKey}" />
                <i class="icon-checkbox"></i>
              </label>
            </div>
          `
        }
      },
      {
        title: 'Id',
        frozen: false,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().OrderId}</strong></div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_ORDER_NUMBER'),
        minWidth: 220,
        maxWidth: 320,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().OrderNumber}</strong></div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_ORDER_TIME'),
        minWidth: 200,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.formatDateTime(cell.getData().LastCreated)}</strong></div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_RECIPIENT_INFORMATION'),
        minWidth: 200,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div>
                <strong>${cell.getData().FullName}</strong><br />
                <span>${cell.getData().IdNumber}</span>
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_PAYMENT_AMOUNT'),
        minWidth: 80,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div>${cell.getData().PaymentAmount || 0}</div>
            </div>
          `
        }
      },
      // {
      //   title: $t.getTranslation('LBL_PAYMENT_DECLARATION'),
      //   minWidth: 80,
      //   frozen: false,
      //   formatter(cell) {
      //     return `
      //       <div>
      //         <div class="chip ${helpers.getStatusColor(cell.getData()?.CustomsStatus)}">
      //           <div class="chip-label">${cell.getData().CustomsStatus === 'DECLARED' ? `${$t.getTranslation('LBL_DECLARED')}` : $t.getTranslation('LBL_NOT_DECLARED')}</div>
      //         </div>
      //       </div>
      //     `
      //   }
      // },
      {
        title: $t.getTranslation('LBL_CUSTOMS_PUSH_STATUS'),
        minWidth: 80,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.CustomsPushStatus)}">
                <div class="chip-label">${cell.getData().CustomsPushStatusName || cell.getData().CustomsPushStatus || $t.getTranslation('LBL_NOT_DECLARED')}</div>
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_OPERATE'),
        minWidth: 80,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <a class="button button-fill button-small button-raised button-declare" data-key="${cell.getData()?.OrderKey}">Declare</a>
            </div>
          `
        }
      }
    ]

    const url = ref('/admin/order/customs/datalist')

    const onOrderCustomPush = async (orderKey) => {
      try {
        helpers.showLoader()
        let res = await $HTTP.post(`/webhook/payeco/push/customs`, { OrderKey: orderKey })
        if (res?.status === 200 && res?.data?.data) {
          helpers.createNotification({
            type: 'success',
            title: $t.getTranslation('LBL_SUCCESS'),
            message: 'Order Push to customs'
          })
        }
        helpers.hideLoader()
        dataTableComponent.value.initTabulator()

        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    onMounted(() => {
      Dom7('body').on('change', '.main-checkbox', (e) => {
        let isChecked = Dom7('.main-checkbox').prop('checked')
        if (isChecked) {
          Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
            Dom7(item).prop('checked', true)
          })
        } else {
          Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
            Dom7(item).prop('checked', false)
          })
        }
      })

      Dom7('body').on('click', '.tabulator-page', (e) => {
        Dom7('.main-checkbox').prop('checked', false)
      })

      Dom7('body').on('click', '.button-declare', (e) => {
        const orderKey = Dom7(e.target).data('key')
        onOrderCustomPush(orderKey)
      })

      Dom7('body').on('click', '.toggle-collapse', (e) => {
        const data = JSON.parse(Dom7(e.target).data('page-data'))
        const customData = JSON.parse(Dom7(e.target).data('custom-data'))
        const isToggled = Dom7(e.target).hasClass('toggled')

        let tableData = ``
        for (let counter = 0; counter < data.OrderItems.length; counter++) {
          let item = data.OrderItems[counter]
          tableData += `
            <tr>
              <td>${counter + 1}</td>
              <td>${item.Name || ''}</td>
              <td>${item.Sku || ''}</td>
              <td>${item.Sku || ''}</td>
              <td>${item.UnitPrice || 0}</td>
              <td>${item.QtyOrdered || 0}</td>
            </tr>
          `
        }

        let tableCustomsData = ``
        for (let counter = 0; counter < customData.length; counter++) {
          let item = customData[counter]
          tableCustomsData += `
            <tr>
              <td>${item.merch_order_no || ''}</td>
              <td>${item.merchant_no || ''}</td>
              <td>${item.order_no || ''}</td>
              <td>${item.push_status_name || item.push_status || ''}</td>
              <td>${item.request_no || ''}</td>
              <td>${item.request_time || ''}</td>
              <td>${item.verify_id || ''}</td>
            </tr>
          `
        }

        Dom7(e.target).toggleClass('toggled')
        if (!isToggled) {
          let htmlContent = `
            <div class="toggle-data-container">
              <div class="toggle-row">
                <table class="table-plain">
                  <tbody>
                    <tr>
                      <td><strong>${$t.getTranslation('LBL_NAME_OF_ORDER_PAYER')}:</strong> ${data.FullName || ''}</td>
                      <td><strong>${$t.getTranslation('LBL_ORDER_PAYER_PHONE_NUMBER')}:</strong> ${data.MobileCode || ''}${data.MobileNumber || ''}</td>
                      <td><strong>${$t.getTranslation('LBL_ID_CARD_OF_ORDERER_PAYER')}:</strong> ${data.IdNumber || ''}</td>
                    </tr>
                    <tr>
                      <td><strong>${$t.getTranslation('LBL_CONSIGNEE_NAME')}:</strong> ${data.AddressFirstName || ''}${data.AddressLastName || ''}</td>
                      <td><strong>${$t.getTranslation('LBL_CONSIGNEE_PHONE_NUMBER')}:</strong> ${data.AddressMobileCode || ''}${data.AddressMobileNumber || ''}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="toggle-row">
                <table class="table-plain">
                  <tbody>
                    <tr>
                      <td><strong>${$t.getTranslation('LBL_CONSIGNEE_AREA')}:</strong> ${data.PrimaryAddress || ''} ${data.SecondaryAddress || ''} ${data.City || ''} ${data.District || ''} ${data.Province || ''} ${data.CountryCode ||
            ''} ${data.PostalCode || ''}</td>
                    </tr>
                    <tr>
                      <td><strong>${$t.getTranslation('LBL_RECIPIENT_ADDRESS')}:</strong> ${data.PrimaryAddress || ''} ${data.SecondaryAddress || ''} ${data.City || ''} ${data.District || ''} ${data.Province || ''} ${data.CountryCode ||
            ''} ${data.PostalCode || ''}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="toggle-row">
                <table class="table-plain">
                  <tbody>
                    <tr>
                      <td><strong>${$t.getTranslation('LBL_PAYMENT_AMOUNT')}:</strong> ${data.PaymentAmount || 0}</td>
                      <td><strong>${$t.getTranslation('LBL_ORDER_AMOUNT')}:</strong> ${data.GrandTotal || 0}</td>
                      <td><strong>${$t.getTranslation('LBL_DEDUCTION_AMOUNT')}:</strong> ${data.UserVoucherAmount + data.OrderDiscount || 0}</td>
                    </tr>
                    <tr>
                      <td><strong>${$t.getTranslation('LBL_AMOUNT_OF_GOODS')}:</strong> ${data.SubTotal || 0}</td>
                      <td><strong>${$t.getTranslation('LBL_TAXES')}:</strong> ${data.TotalOrderTax || 0}</td>
                      <td><strong>${$t.getTranslation('LBL_FREIGHT')}:</strong> ${data.ShippingTotal || 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="toggle-row">
                <table class="table-styled">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>${$t.getTranslation('LBL_PRODUCT_NAME')}</td>
                      <td>${$t.getTranslation('LBL_PRODUCT_CODE')}</td>
                      <td>${$t.getTranslation('LBL_SPECIFICATION_CODE')}</td>
                      <td>${$t.getTranslation('LBL_COMMODITY_PRICE')}</td>
                      <td>${$t.getTranslation('LBL_QUANTITY')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    ${tableData}
                  </tbody>
                </table>
              </div>
              <div class="toggle-row">
                <table class="table-styled">
                  <thead>
                    <tr>
                      <td>${$t.getTranslation('LBL_MERCH_ORDER_NUMBER')}</td>
                      <td>${$t.getTranslation('LBL_MERCHANT_NUMBER')}</td>
                      <td>${$t.getTranslation('LBL_ORDER_NUMBER')}</td>
                      <td>${$t.getTranslation('LBL_PUSH_STATUS')}</td>
                      <td>${$t.getTranslation('LBL_REQUEST_NUMBER')}</td>
                      <td>${$t.getTranslation('LBL_REQUEST_TIME')}</td>
                      <td>${$t.getTranslation('LBL_VERIFY_ID')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    ${tableCustomsData}
                  </tbody>
                </table>
              </div>
            </div>
          `

          Dom7(e.target)
            .closest('.tabulator-row')
            .append(htmlContent)
        } else {
          Dom7(e.target)
            .closest('.tabulator-row')
            .find('.toggle-data-container')
            .remove()
        }
      })
    })

    const getCheckList = () => {
      let checkList = []

      Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
        let isChecked = Dom7(item).prop('checked')
        if (isChecked) {
          checkList.push(Dom7(item).value())
        }
      })

      return checkList
    }

    const onBatchCustomPush = async () => {
      let checkList = getCheckList()

      if (checkList.length > 0) {
        helpers.createConfirmation({
          message: $t.getTranslation('LBL_ARE_YOU_SURE_YOU_WANT_TO_PUSH_THE_SELECTED_ORDER_TO_THE_CUSTOMS'),
          confirm: async () => {
            let doneCounter = 0
            for (let OrderKey of checkList) {
              await onOrderCustomPush(OrderKey)
            }

            if (doneCounter == checkList.length) {
              helpers.createNotification({
                type: 'info',
                title: 'INFO',
                message: $t.getTranslation('LBL_SUCCESSFULLY_PUSH_TO_CUSTOMS')
              })

              window.location.reload()
            }
          }
        })
      }
    }

    return {
      url,
      columns,
      actions,
      dataTableComponent,
      showBatchCustomPush,
      onBatchCustomPush,
      onOrderCustomPush
    }
  },
  methods: {
    async performAction(type, data) {
      const self = this

      switch (type) {
        case 'VIEW':
          if (data?.value?.OrderKey) {
            self.f7router.navigate({ name: 'orderView', params: { id: data.value.OrderKey } })
          }
          break
        case 'CUSTOM_PUSH':
          if (data?.value?.OrderKey) {
            await self.onOrderCustomPush(data.value.OrderKey)
          }
          break
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator()
      }
    }
  }
})
</script>

<style>
.toggle-collapse {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.toggle-collapse svg {
  font-size: 22px;
  pointer-events: none;
}
.toggle-collapse.toggled .open {
  display: none;
}
.toggle-collapse.toggled .close {
  display: block;
}
.toggle-collapse .close {
  display: none;
}
.toggle-collapse .open {
  display: block;
}

/* TOGGLE DATA */
.toggle-data-container {
  background: #fff;
  width: calc(100% - 100px);
  margin: 10px 0px 30px;
  padding: 20px 30px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}
.toggle-data-container .toggle-row {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.toggle-data-container .toggle-row .table-plain {
  width: 100%;
  font-size: 14px;
}
.toggle-data-container .toggle-row .table-plain tr td {
  padding: 0px 10px;
}
.toggle-data-container .toggle-row .table-styled {
  width: 100%;
  font-size: 14px;
}
.toggle-data-container .toggle-row .table-styled thead {
  background: #f5f5f5;
  font-weight: bold;
}
.toggle-data-container .toggle-row .table-styled tr td {
  padding: 5px 10px;
}

.chip-label .default {
  background: red;
}
</style>
