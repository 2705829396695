<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_PRODUCT_DETAILS') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list no-hairlines>
            <f7-list-item divider>{{ $t.getTranslation('FRM_HK_RETAIL_PRICE_REF') }}</f7-list-item>

            <f7-list-input
              v-model:value="formData.HKRetailPrice"
              name="Increase"
              :required="validationRules?.HKRetailPrice?.required"
              :minlength="validationRules?.HKRetailPrice?.minimumLength"
              :maxlength="validationRules?.HKRetailPrice?.maximumLength"
              :label="$t.getTranslation('FRM_HK_RETAIL_PRICE_REF')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
              clear-button
            >
            </f7-list-input>

            <f7-list-item divider>{{ $t.getTranslation('LBL_MARKETING_DETAILS') }}</f7-list-item>

            <f7-list-input
              v-model:value="formData.IsFeatured"
              name="Status"
              :required="validationRules?.IsFeatured?.required"
              :minlength="validationRules?.IsFeatured?.minimumLength"
              :maxlength="validationRules?.IsFeatured?.maximumLength"
              :label="$t.getTranslation('LBL_FEATURED_STATUS')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.IsHot"
              name="IsHot"
              :required="validationRules?.IsHot?.required"
              :minlength="validationRules?.IsHot?.minimumLength"
              :maxlength="validationRules?.IsHot?.maximumLength"
              :label="$t.getTranslation('LBL_ISHOT')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.IsBest"
              name="IsBest"
              :required="validationRules?.IsBest?.required"
              :minlength="validationRules?.IsBest?.minimumLength"
              :maxlength="validationRules?.IsBest?.maximumLength"
              :label="$t.getTranslation('LBL_ISBEST')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.IsNew"
              name="IsNew"
              :required="validationRules?.IsNew?.required"
              :minlength="validationRules?.IsNew?.minimumLength"
              :maxlength="validationRules?.IsNew?.maximumLength"
              :label="$t.getTranslation('LBL_ISNEW')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.IsSale"
              name="IsSale"
              :required="validationRules?.IsSale?.required"
              :minlength="validationRules?.IsSale?.minimumLength"
              :maxlength="validationRules?.IsSale?.maximumLength"
              :label="$t.getTranslation('LBL_ISSALE')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.IsListed"
              name="IsListed"
              :required="validationRules?.IsListed?.required"
              :minlength="validationRules?.IsListed?.minimumLength"
              :maxlength="validationRules?.IsListed?.maximumLength"
              :label="$t.getTranslation('LBL_IS_LISTED')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.IsNotDiscounted"
              name="IsNotDiscounted"
              :required="validationRules?.IsNotDiscounted?.required"
              :minlength="validationRules?.IsNotDiscounted?.minimumLength"
              :maxlength="validationRules?.IsNotDiscounted?.maximumLength"
              :label="$t.getTranslation('LBL_IS_NOT_DISCOUNTED')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.IsSpecialPrice"
              name="IsSpecialPrice"
              :required="validationRules?.IsSpecialPrice?.required"
              :minlength="validationRules?.IsSpecialPrice?.minimumLength"
              :maxlength="validationRules?.IsSpecialPrice?.maximumLength"
              :label="$t.getTranslation('LBL_IS_SPECIAL_PRICE')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-item divider>{{ $t.getTranslation('FRM_PRODUCT_SHIPPING') }}</f7-list-item>

            <f7-list-input
              v-model:value="formData.ShippingStatusCode"
              name="ShippingStatusCode"
              :required="validationRules?.ShippingStatusCode?.required"
              :minlength="validationRules?.ShippingStatusCode?.minimumLength"
              :maxlength="validationRules?.ShippingStatusCode?.maximumLength"
              :label="$t.getTranslation('LBL_SHIPPING_STATUS')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in shippingStatus" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.ShippingFee"
              name="Increase"
              :required="validationRules?.ShippingFee?.required"
              :minlength="validationRules?.ShippingFee?.minimumLength"
              :maxlength="validationRules?.ShippingFee?.maximumLength"
              :label="$t.getTranslation('FRM_SHIPPING_FEE')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
              clear-button
            >
            </f7-list-input>

            <f7-list-input
              v-model:value="formData.ShippingPerBottle"
              name="ShippingPerBottle"
              :required="validationRules?.ShippingPerBottle?.required"
              :minlength="validationRules?.ShippingPerBottle?.minimumLength"
              :maxlength="validationRules?.ShippingPerBottle?.maximumLength"
              :label="$t.getTranslation('FRM_SHIPPING_PER_ITEM')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
              clear-button
            >
            </f7-list-input>
          </f7-list>

          <f7-button large fill raised preloader @click="saveData">
            {{ $t.getTranslation('LBL_SAVE') }}
          </f7-button>

          <f7-button large fill raised preloader @click="back">
            {{ $t.getTranslation('LBL_BACK') }}
          </f7-button>
        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import _ from 'lodash'
import { defineComponent, ref, computed, reactive, onMounted, inject } from 'vue'
import { useStore } from '@/store'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'

import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'

import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'

export default defineComponent({
  name: 'ProductCriticSave',
  components: {
    MainHeaderComponent,
    MainFooterComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const isButtonProcessing = ref(false)
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const formData = reactive({})

    const statusList = ref([
      {
        Code: 1,
        Name: $t.getTranslation('LBL_YES')
      },
      {
        Code: 0,
        Name: $t.getTranslation('LBL_NO')
      }
    ])

    const shippingStatus = computed(() => store.getters['config/getData']?.statusList)

    let validationRules = {}
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys]
      }
    }

    onMounted(async () => {
      if (props?.f7route?.query?.key) {
        await getData()
      }
    })

    const getData = async () => {
      try {
        helpers.showLoader()
        let res = await $HTTP.get(`/admin/product/view?ProductKey=${props?.f7route?.query?.key}&LanguageCode=${currentLanguageCode.value}`)
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()

          let pick = _.pick(res.data.data, ['IsBest', 'IsSale', 'IsHot', 'IsNew', 'IsFeatured', 'HKRetailPrice', 'FeaturedPriority', 'IsListed', 'IsNotDiscounted', 'IsSpecialPrice', 'ShippingFee', 'ShippingStatusCode', 'ShippingPerBottle'])

          for (var k in pick) {
            formData[k] = pick[k]
            //formData.CriticScore[keys] = pick.Critic[k].UDF;
          }

          helpers.hideLoader()
          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const saveData = async () => {
      try {
        helpers.showLoader()

        formData.ProductKey = props?.f7route?.query?.key
        formData.LanguageCode = currentLanguageCode.value

        // if (formData.IsSale > 0 && formData.IsNew > 0) {
        //   throw new Error($t.getTranslation("ERR_MARKETING_PRODUCT"));
        // }

        let res = await $HTTP.post(`/admin/product/marketing`, formData)
        if (res?.status === 200 && res?.data?.data) {
          helpers.createNotification({ type: 'success', title: $t.getTranslation('LBL_SUCCESS'), message: $t.getTranslation('LBL_INFO_SUCCESSFULLY_SAVE_PRODUCT_INFO') })
          helpers.hideLoader()

          if (props?.f7route?.query?.isFeatured) {
            props.f7router.navigate({ name: 'productListFeatured', params: {} })
            return
          }

          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false)

      return !isValid
    })

    const back = () => {
      if (props?.f7route?.query?.isFeatured) {
        props.f7router.navigate({ name: 'productListFeatured', params: {} })
        return
      }
      props.f7router.navigate({ name: 'productView', params: { key: props?.f7route?.query?.key } })
    }

    return { formData, validationRules, isButtonProcessing, isDisableSaveButton, back, saveData, statusList, shippingStatus }
  }
})
</script>
