<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_FUTURE_WINES") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised :disabled="!(excelFile?.FileName)" @click="download_wine()">{{ $t.getTranslation("LBL_DOWNLOAD_TOP_WINES") }}</f7-button>
        <f7-button large fill raised :disabled="isUploading" @click="upload($refs)">{{ $t.getTranslation("LBL_IMPORT_FUTURE_WINES") }}</f7-button>
         <f7-button large fill raised :disabled="isUploading" @click="uploadSearch($refs)">{{ $t.getTranslation("LBL_SEARCH_WINE") }}</f7-button>
        <!-- <f7-button large icon="far fa-fw fa-cog"></f7-button> -->
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @viewItem="editItem" ref="datatable"></DataTableComponent>

    <MainFooterComponent />
    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcel($event, $refs)" />
    <input ref="fileInputSearch" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcelSearch($event, $refs)" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject } from "vue";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "ProductFutureWineListComponent",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const excelFile = computed(() => store.getters["config/getData"]?.topWine);
    const isUploading = ref(false);
    const hideImport = ref(false);

    const download_wine = () => {
      if (excelFile.value?.FileName) {
         window.location.href = helpers.returnBaseURL() + "/excel?name="+ excelFile.value.FileName +"&type=TOPWINES";
      }else{
        return;
      }
     
    }

     const columns = [
      {
        title: $t.getTranslation("LBL_IMAGE"),
        maxWidth: 90,
        minWidth: 90,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${cell.getData().DefaultProductImage || require("@/assets/images/placeholder.jpg")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRODUCT_NAME"),
        maxWidth: 320,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Name}</strong>
            </div>
          `;
        },
      },
       {
        title: $t.getTranslation("LBL_SKU"),
        maxWidth: 320,
        frozen: true,
        formatter(cell) {
          return `
            <div>
             ${cell.getData().Sku}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_INVENTORY"),
        field: "Inventory",
        hozAlign: "center",
        maxWidth: 130,
        minWidth: 130,
      },
      {
        title: $t.getTranslation("LBL_CATEGORY"),
        field: "Category",
      },
      {
        title: $t.getTranslation("LBL_RATING"),
        formatter(cell) {
          return `
            <div>
              ${cell.getData().Rating}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_STORE_CODE"),
        field: "StoreCode",
      },
      {
        title: $t.getTranslation("LBL_REGULAR_SELLING_PRICE"),
        field: "RegularSellingPrice",
      },
      {
        title: $t.getTranslation("LBL_DISCOUNT_PRICE"),
        field: "DiscountPrice",
      },
      {
        title: $t.getTranslation("LBL_GOLD_PRICE"),
        field: "GoldPrice",
      },
      {
        title: $t.getTranslation("LBL_SILVER_PRICE"),
        field: "SilverPrice",
      },
      {
        title: $t.getTranslation("LBL_BRONZE_PRICE"),
        field: "BronzePrice",
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRODUCT_CREATED"),
        field: "LastCreated",
      },
    ];

    const editItem = (data) => {
      props.f7router.navigate({ name: "productView", params: { key: data.value.ProductKey } });
    };


    const upload = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const uploadSearch = (refs) => {
      refs.fileInputSearch.value = "";
      refs.fileInputSearch.click();
    };


    const uploadExcel = async (event, $refs) => {
      let input = event.target;

      if (input?.files?.[0]) {
        let file = input?.files?.[0];
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/);
        if (!isValidFile) throw new Error($t.getTranslation("LBL_ERR_INVALID_FILE"));

        //upload now
        isUploading.value = true;
        const formData = new FormData();
        formData.append("file", file);
        helpers.showLoader('Loading...')
        let ret = await $HTTP.post("/admin/product/future/wines/import", formData);
        isUploading.value = false;

        

        if (ret) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_IMPORT_WINES") });

          // if (ret?.data?.data?.FileName) {
          //   window.location.href = helpers.returnBaseURL() + "/excel?name="+ ret.data.data.FileName +"&type=WINESEARCH";
          //   console.log(ret?.data?.data?.FileName)
          // }
          if($refs){
            setTimeout(()=>{
              $refs.datatable.initTabulator();
            }, 300);
          };
        }

        helpers.hideLoader();
        return;
      }
    };


    const uploadExcelSearch = async (event, $refs) => {
      let input = event.target;
      try {
        if (input?.files?.[0]) {
          let file = input?.files?.[0];
          let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/);
          if (!isValidFile) throw new Error($t.getTranslation("LBL_ERR_INVALID_FILE"));

          //upload now
          isUploading.value = true;
          const formData = new FormData();
          formData.append("file", file);
          helpers.showLoader('Loading...')
          let ret = await $HTTP.post("/admin/product/future/wines/import/search", formData);
          isUploading.value = false;

          if (ret) {
            helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_IMPORT_WINES") });
            if (ret?.data?.data?.FileName) {
              window.location.href = helpers.returnBaseURL() + "/excel?name="+ ret.data.data.FileName +"&type=WINESEARCH"
            }
            // if($refs){
            //   setTimeout(()=>{
            //     $refs.datatable.initTabulator();
            //   }, 300);
            // };
          }

          helpers.hideLoader()
        }
      } catch (err) {
        isUploading.value = false;
        helpers.hideLoader();
        helpers.catchError(err, true)
      }

      
    };


   let url = `/admin/product/list?mode=FUTUREWINE&LanguageCode=${currentLanguageCode.value}`;
    
    return {
      columns,
      url,
      editItem,
      download_wine,
      excelFile,
      isUploading,
      upload,
      uploadExcel,
      uploadExcelSearch,
      uploadSearch
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}

input[type="file"] {
    display: none;
}
</style>
