import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'user',
    path: '/user/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'users',
        title: translation.getTranslation('LBL_USER'),
        activeLink: 'user'
      },
      isSecured: true,
      hasAnyRoles: ['MANAGE_USER', 'MANAGE_USER_GROUP']
    },
    redirect: '/user/list',
    routes: [
      {
        name: 'userList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_USER_LIST'),
            activeLink: 'userList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_USER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/user/UserListPage.vue')
      },
      {
        name: 'userUserVoucherList',
        path: 'voucher/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_USER_VOUCHER_LIST'),
            activeLink: 'userUserVoucherList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_USER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/user/UserVoucherListPage.vue')
      },
      {
        name: 'userGroup',
        path: 'group/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_USER_GROUP'),
            activeLink: 'userGroup'
          },
          isSecured: true,
          hasRoles: ['MANAGE_USER_GROUP']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/user/UserGroupListPage.vue')
      },
      {
        name: 'userActivityLog',
        path: 'activity/log/',
        meta: {
          navigation: {
            isShown: false,
            title: translation.getTranslation('LBL_USER_ACTIVITY_LOG'),
            activeLink: 'userActivityLog'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/user/UserActivityLogListPage.vue')
      },
      {
        name: 'userChatLog',
        path: 'chat/log/',
        meta: {
          navigation: {
            isShown: false,
            title: translation.getTranslation('LBL_USER_CHAT_LOG'),
            activeLink: 'userChatLog'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/user/UserChatLogListPage.vue')
      },
      // INFLUENCER
      {
        name: 'influencerList',
        path: 'influencer/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_INFLUENCER_LIST'),
            activeLink: 'influencerList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_INFLUENCER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/influencer/ListPage.vue')
      },
      {
        name: 'influencerSave',
        path: 'save/:id',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'influencerSave'
          },
          isSecured: true,
          hasRoles: ['MANAGE_INFLUENCER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/influencer/SavePage.vue')
      }
    ]
  }
]

export default routes
