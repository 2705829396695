import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'dashboard',
    path: '/dashboard/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'chart-area',
        title: translation.getTranslation('LBL_DASHBOARD'),
        activeLink: 'dashboard'
      },
      isSecured: true,
      hasRoles: ['MANAGE_DASHBOARD']
    },
    redirect: '/dashboard/main/',
    routes: [
      {
        name: 'dashboardMain',
        path: 'main/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_DASHBOARD'),
            activeLink: 'dashboardMain'
          },
          isSecured: true,
          hasRoles: ['MANAGE_DASHBOARD']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/dashboard/MainPage.vue')
      },
      {
        name: 'dashboardPostVideStats',
        path: 'post/video/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_POST_VIDEO_STATS'),
            activeLink: 'dashboardPostVideStats'
          },
          isSecured: true,
          hasRoles: ['MANAGE_DASHBOARD']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/dashboard/PostVideoStatsPage.vue')
      },
      {
        name: 'pageviewList',
        path: 'pageview/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PAGE_VIEW'),
            activeLink: 'pageviewList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PAGE_VIEW']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/pageview/PageViewListPage.vue')
      },
      {
        name: 'pageviewView',
        path: 'pageview/view/',
        meta: {
          navigation: {
            isShown: false,
            title: translation.getTranslation('LBL_PAGE_VIEW'),
            activeLink: 'pageviewView'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PAGE_VIEW']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/pageview/PageViewPage.vue')
      },
      // REPORT
      {
        name: 'report',
        path: 'report/',
        meta: {
          navigation: {
            isShown: true,
            
            title: translation.getTranslation('LBL_REPORT'),
            activeLink: 'report'
          },
          isSecured: true,
          hasRoles: ['MANAGE_REPORT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/ReportPage.vue')
      },
      {
        name: 'totalUsers',
        path: 'users/total/',
        meta: {
          navigation: {
            isShown: true,
            icon: 'users',
            title: translation.getTranslation('LBL_TOTAL_USERS'),
            activeLink: 'totalUsers'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/user/TotalUsersPage.vue')
      },
    ]
  }
]

export default routes
