<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'users']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_USER_GROUP_LIST") }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent2" :columns="columns" :actions="actions" :url="url" :size="15" @performAction="performAction"></DataTableComponent2>

    <UserGroupSavePartial ref="userGroupSavePartial" @closed="initDataTableComponent2" />
    <UserGroupViewPartial ref="userGroupViewPartial" :f7router="f7router" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import UserGroupSavePartial from "@/partials/user/UserGroupSavePartial.vue";
import UserGroupViewPartial from "@/partials/user/UserGroupViewPartial.vue";

import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { configs } from "@/utils/configs";

export default defineComponent({
  name: "UserGroupListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    // PARTIALS
    UserGroupSavePartial,
    UserGroupViewPartial,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    let url = "/admin/usergroup/list";

    const actions = [
      {
        groupText: "Perform Actions",
        list: [
          { text: "View", type: "VIEW", bold: true },
          { text: "Edit", type: "EDIT", bold: false },
          { text: "Generate Report", type: "REPORT", bold: false },
        ],
      },
      {
        groupText: "",
        list: [{ text: "Delete", type: "DELETE", color: "red" }],
      },
    ];

    const columns = [
      {
        title: $t.getTranslation("LBL_GROUP_NAME"),
        field: "Name",
      },
      {
        title: $t.getTranslation("LBL_GROUP_LINK_CODE"),
        field: "LinkCode",
      },
      {
        title: $t.getTranslation("LBL_COMMISSION"),
        field: "Commission",
      },
      {
        title: $t.getTranslation("LBL_COMMISSION_TYPE"),
        field: "CommissionTypeCode",
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.StatusCode)} ${cell.getData()?.StatusCode}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <strong>${$t.getTranslation("LBL_DATE_CREATED")}:</strong> ${helpers.formatDateTime(cell.getData().LastCreated)} <br />
              <strong>${$t.getTranslation("LBL_DATE_UPDATED")}:</strong> ${helpers.formatDateTime(cell.getData().LastModified)}
            </div>
          `;
        },
      },
    ];

    return {
      columns,
      url,
      actions,
    };
  },
  methods: {
    create() {
      this.$refs.userGroupSavePartial.openPopup(false);
    },
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.value?.UserGroupId) {
            self.$refs.userGroupViewPartial.openPopup(data?.value?.UserGroupId);
          }
          break;
        case "EDIT":
          if (data?.value?.UserGroupId) {
            self.$refs.userGroupSavePartial.openPopup(data.value);
          }
          break;
        case "REPORT":
          if (data?.value?.UserGroupId) {
            let returnData = await post(`/report/generate/table`, {
              ReportType: "usergroupuserlist",
              UserGroupId: data?.value?.UserGroupId,
            });

            if (returnData?.FileName) {
              let reportFileLink = `${configs.baseURL}/report/download?FileName=${returnData?.FileName}&FileType=${returnData?.FileType}`;
              window.open(reportFileLink);
            }
          }
          break;
        case "DELETE":
          if (data?.value?.UserGroupId) {
            helpers.createConfirmation({
              message: "Are you sure you want to delete selected user group?",
              confirm: async () => {
                let returnData = await post("/admin/usergroup/delete", { UserGroupId: data?.value?.UserGroupId });
                if (returnData)
                  helpers.createNotification({
                    type: "info",
                    title: "INFO",
                    message: "Successfully deleted selected user group.",
                  });

                self.$refs.dataTableComponent2.initTabulator();
              },
            });
          }
          break;
      }
    },
    initDataTableComponent2(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent2.initTabulator();
      }
    },
  },
});
</script>
