<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_TOTAL_USERS") }}</h1>
          <h3></h3>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="search-container" style="padding-left:18px;padding-right:18px;">
        <f7-list no-hairlines>

          <f7-list-input
            v-model:value="formData.rangeValue"
            :label="$t.getTranslation('LBL_SEARCH_VALUE')"
            floating-label
            outline
            type="datepicker"
            :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')"
            readonly
            :calendar-params="{ dateFormat: 'yyyy-mm', rangePicker: true }"
          >
          </f7-list-input>
          <f7-list-item>
            <f7-button fill raised @click="onFilter($refs)">
              <font-awesome-icon :icon="['far', 'search']" fixed-width />
              {{ $t.getTranslation("LBL_SEARCH") }}
            </f7-button>
          </f7-list-item>
          <f7-list-item>
          </f7-list-item>
        </f7-list>
      </div>

      <f7-row>
        <f7-col width="100" medium="80">
            <f7-card :title="$t.getTranslation('LBL_USERS')">
              <VueApexCharts width="100%" type="line" :options="options" :series="series"></VueApexCharts>
            </f7-card>
        </f7-col>
        <f7-col width="100" medium="20">
            <f7-card
              :title="$t.getTranslation('TTL_TOTAL')">
              <div class="card-count">{{ $h.formatNumber(Total) }}</div>
            </f7-card>
        </f7-col>
      </f7-row>
    </div>

   
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, reactive } from "vue";

import dayjs from 'dayjs'
import VueApexCharts from "vue3-apexcharts";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
//import DataTableComponent from "@/components/DataTableComponent.vue";


import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "TotalUserPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    VueApexCharts
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const isUploading = ref(false);

   
    const options = ref({
      chart: {
        toolbar : {
          show : false
        }
      },
      xaxis: {
        type: 'datetime'
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
        let date =  dayjs(w.globals.seriesX[0][dataPointIndex]).format('YYYY-MM');
        return (
             `${dataPointIndex > -1 ? date : '' } : Total - ${series[seriesIndex][dataPointIndex]}`
        );
        }
    }
    });

    const series = ref([]);
    const Total = ref(0);

    const formData = reactive({
      rangeValue : ['2021']
    });


    //get the default range value...
    const url = ref("");

    const onFilter = async($refs)=>{
      getChartData($refs) //call???
    };

    const getQuery = ()=>{
      let Start = dayjs(formData.rangeValue[0] || '2021-01').format('YYYY-MM')
      let End = (formData.rangeValue.length > 1) ? dayjs(formData.rangeValue[formData.rangeValue.length - 1]).format('YYYY-MM') : dayjs().format('YYYY-MM')
      return `?StartMonth=${Start}&EndMonth=${End}`;
    }

    const getChartData = async($refs)=>{
      let res = await $HTTP.get(`/admin/user/monthly${getQuery()}`)
      let mapValues = res.data.data.Data.map((d) => {
          return {x: d.EndDateTime, y: d.Total}
      });

      let mapDates = res.data.data.Data.map((d) => {
          return dayjs(d.StartDateTime).format('YYYY-MM');
      });

      let mapTotal = res.data.data.Data.map((d) => {
          return d.Total;
      });
    
      //formData.rangeValue = [...mapDates];
      formData.rangeValue = [ dayjs(mapDates[0]).format('YYYY-MM'), dayjs(mapDates[mapDates.length - 1]).format('YYYY-MM')]
      series.value = [{name: 'users', data: mapValues}]
      Total.value = mapTotal[mapTotal.length - 1] || 0;
    };

    onMounted(getChartData);

    return {
      formData,
      onFilter,
      options, 
      series,
      isUploading,
      url,
      Total
    };
  },
});
</script>

<style scoped>
.card-count{
  padding:10px;
  font-size:18px;
}
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}
</style>
