<template>
  <f7-page class="login-page">
    <section class="custom-container">
      <div class="container">
        <!-- <img src="@/assets/images/svg/login.svg" alt="Login" /> -->
        <img class="logo" src="@/assets/images/logo.png" alt="Logo" />

        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.UserName"
            name="UserName"
            :required="validationRules?.UserName?.required"
            :minlength="validationRules?.UserName?.minimumLength"
            :maxlength="validationRules?.UserName?.maximumLength"
            :label="$t.getTranslation('LBL_USERNAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_USERNAME')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.Password"
            name="Password"
            :required="validationRules?.Password?.required"
            :minlength="validationRules?.Password?.minimumLength"
            :maxlength="validationRules?.Password?.maximumLength"
            :label="$t.getTranslation('LBL_PASSWORD')"
            :info="$t.getTranslation('LBL_INFO_PASSWORD')"
            :type="isPasswordShown ? 'text' : 'password'"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            placeholder="********"
            validate
            @keyup.enter="login"
          >
            <template #content-end>
              <f7-link tabindex="-1" class="show-password" @mousedown="showPassword" @mouseup="hidePassword" @touchstart="showPassword" @touchend="hidePassword">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
              </f7-link>
            </template>
          </f7-list-input>
        </f7-list>

        <f7-button fill large raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableLoginButton" @click="login"> {{ $t.getTranslation("LBL_LOGIN") }} </f7-button>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";

import { helpers } from "@/utils/helpers.js";

import { login as authLogin } from "@/logics/auth.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "LoginPage",
  components: { MainFooterComponent },
  props: { f7router: Object },
  setup(props) {
    const isPasswordShown = ref(false);
    const isButtonProcessing = ref(false);

    const formData = reactive({
      UserName: "",
      Password: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const showPassword = (event) => {
      isPasswordShown.value = true;
    };

    const hidePassword = (event) => {
      isPasswordShown.value = false;
    };

    const isDisableLoginButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const login = async () => {
      try {
        let isValid = validate(true);

        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader("Processing Request...");

          let isLogin = await authLogin("/admin/auth/login", formData);

          if (isLogin) {
            isButtonProcessing.value = false;
            helpers.hideLoader();

            props.f7router.navigate("/");
            return;
          }

          throw new Error("Server not responding, Please try again later.");
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();

        helpers.catchError(err, true);
      }
    };

    return {
      formData,
      validationRules,
      isButtonProcessing,
      isPasswordShown,
      showPassword,
      hidePassword,
      isDisableLoginButton,
      login,
    };
  },
});
</script>
