const data = {
  inputList: []
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getInputList: (state) => {
    return state?.data?.inputList || []
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  setInputList: (context, payload) => {
    context.commit('UPDATE_DATA', { inputList: payload })
  }
}

export const form = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
