<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_KOL") + " - " + (isUpdate ? $t.getTranslation("LBL_VIEW") : $t.getTranslation("LBL_CREATE")) }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>


    <f7-popup id="popupOptionMenu" class="options-popup with-tabs" push>

          <f7-navbar>
            <f7-nav-left>
              <f7-link @click="closePopup()">
                <font-awesome-icon :icon="['far', 'long-arrow-left']" fixed-width />
              </f7-link>
            </f7-nav-left>
          </f7-navbar>

           <f7-list no-hairlines style="width: 100%;">
                  <f7-list-input
                    v-model:value="formData.Search.Product"
                    name="Search"
                    :required="validationRules?.Search?.required"
                    :minlength="validationRules?.Search?.minimumLength"
                    :maxlength="validationRules?.Search?.maximumLength"
                    :label="$t.getTranslation('LBL_SEARCH')"
                    :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
                    :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                    floating-label
                    outline
                    type="text"
                    validate
                    v-on:keyup.enter="searchProduct()" 
                  >
                  </f7-list-input>
              </f7-list>

              <div class="list-wrap list-prod-search-max-height">
               <template v-for="product in Products" :key="'prditem_'+ product.ProductId" >
                  <ProductListCardComponent :data="product.Product" :category="true" :f7router="f7router" :attachProduct="attachProduct" style="width: calc(100% - 23px);" />
               </template>
               <NoDataFoundComponent v-if="Products.length == 0" :size="'sm'" :title="$t.getTranslation('LBL_NO_PRODUCTS_FOUND')" />
              </div>

               
    </f7-popup>

    <section class="form-container">
      <div class="container">
        
        <div v-if="!isUpdate">
          <f7-button class="select-user" large fill raised @click="selectUser()">
            {{ $t.getTranslation("LBL_SELECT_USER") }}
          </f7-button>
        </div>

        <div v-if="formData.User" class="profile-info">
          <img :src="$h.getImage(formData.User.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')"  />

          <div class="info">
            <h1>{{ $h.getFullName(formData.User) }}</h1>
            <h2>{{ formData.User.UserName || formData.User.Email || formData.User.OpenId }}</h2>
          </div>
        </div>

        

        <div v-if="!isUpdate">
          <f7-button class="select-user" large fill raised @click="attach()">
            {{ $t.getTranslation("LBL_SELECT_PRODUCT") }}
          </f7-button>
        </div>


        <f7-list no-hairlines>
          <div v-for="(item, index) in formData.Products" :key="index">
            <ProductListCardComponent :data="item.Product" :f7router="f7router"></ProductListCardComponent>
          </div>
          <f7-list-input
            v-model:value="formData.CommissionRatio"
            name="CommissionRatio"
            :required="validationRules?.CommissionRatio?.required"
            :minlength="validationRules?.CommissionRatio?.minimumLength"
            :maxlength="validationRules?.CommissionRatio?.maximumLength"
            :label="$t.getTranslation('LBL_COMMISION_RATIO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            placeholder="0.00"
            validate
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.KolCommissionTypeCode"
            name="KolCommissionTypeCode"
            :required="validationRules?.KolCommissionTypeCode?.required"
            :minlength="validationRules?.KolCommissionTypeCode?.minimumLength"
            :maxlength="validationRules?.KolCommissionTypeCode?.maximumLength"
            :label="$t.getTranslation('LBL_COMMISSION_TYPE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="commision in commisionTypeList" :key="'c_' + commision.Code" :value="commision.Code">{{ commision.Name }}</option>
          </f7-list-input>


          <f7-list-input
            v-if="isUpdate"
            v-model:value="formData.DeepLink"
            name="DeepLink"
            :required="validationRules?.DeepLink?.required"
            :minlength="validationRules?.DeepLink?.minimumLength"
            :maxlength="validationRules?.DeepLink?.maximumLength"
            :label="$t.getTranslation('LBL_DEEPLINK')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            placeholder=""
            validate
            :disabled="isUpdate"
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.StartDate"
            name="StartDate"
            :required="validationRules?.StartDate?.required"
            :minlength="validationRules?.StartDate?.minimumLength"
            :maxlength="validationRules?.StartDate?.maximumLength"
            :label="$t.getTranslation('LBL_VALID_FROM')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="datetime-local"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>

         <f7-list-input
            v-model:value="formData.EndDate"
            name="StartDate"
            :required="validationRules?.EndDate?.required"
            :minlength="validationRules?.EndDate?.minimumLength"
            :maxlength="validationRules?.EndDate?.maximumLength"
            :label="$t.getTranslation('LBL_VALID_TO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="datetime-local"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.StatusCode"
            name="StatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-list-item>
            <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
              {{ $t.getTranslation("LBL_SAVE") }}
            </f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </section>

    <UserSelectionPopUpComponent :all="true" :open="openUserPopup" @closed="onCloseUserPopup" @selected="onUserSelected" :attachUser="attachUser" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { post, get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";
import { $HTTP } from "@/utils/axios";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";
import { f7 } from "framework7-vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import UserSelectionPopUpComponent from "@/components/UserSelectionPopUpComponent.vue";
import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";

export default defineComponent({
  name: "KolViewPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    UserSelectionPopUpComponent,
    ProductListCardComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const kol = ref(false);
    const Products = ref([]);

    const statusList = computed(() => store.getters["config/getData"]?.statusList);
    const commisionTypeList = computed(() => store.getters["config/getData"]?.commissionTypeList);
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const formData = reactive({
      Search: {
        Product : ""
      },
      Products: [],
      User: null
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      if (props?.f7route?.params?.id && parseInt(props?.f7route?.params?.id) > 0) {
        isUpdate.value = true;
        getData();
      }
    });

    const getData = async () => {
      let getDataReturn = await get(`/admin/kol/view`, { KolUserProductId: props?.f7route?.params?.id });
       for (let keys in getDataReturn) {
        formData[keys] = getDataReturn[keys];
      }

      formData.User.Image = null;
      formData.StartDate = helpers.formatDatimeTimePicker(formData.StartDate)
      formData.EndDate =  helpers.formatDatimeTimePicker(formData.EndDate)
    };

    const openUserPopup = ref(false);

    const onCloseUserPopup = () => {
      openUserPopup.value = false;
    };

    const selectUser = () => {
      openUserPopup.value = true;
    };

    const onUserSelected = (data) => {
      formData.UserId = data?.UserId;
    };

    const attachUser = (user) => {
      let data = JSON.parse(user);
      formData.User = data;
    };

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const save = async () => {
      let isValid = validate(true);
      if (isValid) {

       if(formData.Products.length == 0){
         helpers.createNotification({ type: "error", title: $t.getTranslation("LBL_ERROR"), message: $t.getTranslation("ERR_KOL_SELECT_PRODUCT") });
         return
       }

       if(!(formData?.User?.UserId)){
         helpers.createNotification({ type: "error", title: $t.getTranslation("LBL_ERROR"), message: $t.getTranslation("ERR_KOL_SELECT_USER") });
         return
       }

       if(helpers.isBlank(formData.CommissionRatio)){
         helpers.createNotification({ type: "error", title: $t.getTranslation("LBL_ERROR"), message: $t.getTranslation("ERR_KOL_COMMISION") });
         return
       }

       if(helpers.isBlank(formData.StartDate)){
         helpers.createNotification({ type: "error", title: $t.getTranslation("LBL_ERROR"), message: $t.getTranslation("ERR_KOL_START_DATE") });
         return
       }

       if(helpers.isBlank(formData.EndDate)){
         helpers.createNotification({ type: "error", title: $t.getTranslation("LBL_ERROR"), message: $t.getTranslation("ERR_KOL_END_DATE") });
         return
       }

       let StartDate = new Date(formData.StartDate).getTime();
       let EndDate = new Date(formData.EndDate).getTime();


       if (EndDate < StartDate) {
        helpers.createNotification({ type: "error", title: $t.getTranslation("LBL_ERROR"), message: $t.getTranslation("ERR_KOL_INVALID_DATE") });
         return
       }

        isButtonProcessing.value = true;
        let obj = {};
        //
        obj['KolUserProductId'] = formData.KolUserProductId;
        obj['ProductId'] = formData.Products[0].Product.ProductId;
        obj['UserId'] = formData.User.UserId;
        obj['StatusCode'] = formData.StatusCode;
        obj['KolCommissionTypeCode'] = formData.KolCommissionTypeCode;
        obj['StartDate'] = formData.StartDate;
        obj['EndDate'] = formData.EndDate;
        obj['CommissionRatio'] = formData.CommissionRatio;

        let formSaveReturn = await post("/admin/kol/save", obj);
        isButtonProcessing.value = false;

        if (formSaveReturn) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("LBL_SUCCESSFULLY_SAVE_KOL") });
        }

        if (!isUpdate.value) {
          //means create and redirect to viewpage..
          props.f7router.navigate({ name: "kolView", params: { id: formSaveReturn.KolUserProductId } }, { reloadCurrent  : true });
        }
      }
    };

    const status = async(productkey) => {
      props.f7router.navigate({ name: "productMarketing", query: { key: productkey, isFeatured: 1 } });
    }

    const attach = ()=>{
      getProducts();
      f7.popup.get("#popupOptionMenu").open();
    };

    const getProducts = async() => {
      try {
          helpers.showLoader();
          let res = await $HTTP.get(`/admin/product/list?&SearchField=p.Sku,pat.Name&SearchType=LIKE&SearchValue=${formData.Search.Product}&isChat=1&&LanguageCode=${currentLanguageCode.value}&page=1&size=10`, {});
          if (res?.status === 200 && res.data.data.data) {
            helpers.hideLoader();
            for (var i = 0; i < res.data.data.data.length; i++) {
              let item =  res.data.data.data[i];
              item.Product['ProductKey'] = item.ProductKey;
            }
            Products.value = res.data.data.data;
            return;
          }
          throw new Error($t.getTranslation("ERR_PRODUCT_LIST"));
        } catch (err) {
          helpers.hideLoader();
          helpers.catchError(err, true);
        }
    }

    const searchProduct = () => {
      getProducts();
    }

    const attachProduct = async(product)=>{
      let data = JSON.parse(product);
      formData.Products[0] = {
        ProductKey: data.ProductKey,
        Product: {}
      }
      formData.Products[0].Product = data;
      f7.popup.get("#popupOptionMenu").close();
    };

    const closePopup = ()=>{
      f7.popup.get("#popupOptionMenu").close();
    }

    return {
      isUpdate,
      formData,
      validationRules,
      selectUser,
      openUserPopup,
      onCloseUserPopup,
      onUserSelected,
      isButtonProcessing,
      isDisableSaveButton,
      save,
      statusList,
      Products,
      attach,
      searchProduct,
      closePopup,
      attachProduct,
      attachUser,
      commisionTypeList
    };
  },
});
</script>

<style scoped>

.list-prod-search-max-height{
  max-height: calc(100% - 150px);
  overflow: auto;
}

.select-user {
  width: 300px;
  margin-left: 15px;
  margin-bottom: 20px;
}
</style>
