<template>
  <div class="empty-container" :class="size">
    <img :src="require('@/assets/images/svg/' + img)" :alt="$t.getTranslation('LBL_EMPTY_IMAGE')" />
    <p>{{ title || $t.getTranslation("LBL_NO_DATA_FOUND") }}</p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmptyContainerComponent",
  props: {
    size: String,
    img: {
      type: String,
      default: "empty.svg",
    },
    title: String,
  },
});
</script>
<style>
.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px 0px;
}
.empty-container img {
  width: 260px;
}
.empty-container p {
  font-size: 24px;
  margin-top: 30px;
}
/* SMALL */
.empty-container.sm {
  padding: 30px 0px 0px;
}
.empty-container.sm img {
  width: 160px;
}
.empty-container.sm p {
  font-size: 16px;
  margin-top: 20px;
}
</style>
