<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_USER_PICKED") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>
    </div>

    <DataTableComponent v-if="url" :columns="columns" :size="10" :url="url"></DataTableComponent>
     
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref, onMounted } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
export default defineComponent({
  name: "OrderListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
     const $t = inject("$translation");
     const columns = [
      {
        title: $t.getTranslation("LBL_USER_NAME"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.renderUserName(cell.getData())}
            </div>
          `;
        }
      }, 
      {
        title: $t.getTranslation("LBL_CRITERIA"),
        minWidth: 160,
        field : "VoucherTypeName"
      }, 
      {
        title: $t.getTranslation("LBL_COUPON_AMOUNT"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.formatPrice(cell.getData().Amount, "RMB")}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_VALID_FROM"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.formatDateTime(cell.getData().StartDate)}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_VALID_TO"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.formatDateTime(cell.getData().EndDate)}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_REDEEM_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${(cell.getData().IsRedeemed) ? "Y" : "N"}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_PICKED_DATE"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
                ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        }
      }
     ];

    let url = ref("");

    onMounted(async() => {
      if (props?.f7route?.query && props?.f7route?.query?.VoucherId) {
        url.value = `/admin/voucher/picked/list?VoucherId=${props.f7route.query.VoucherId}`;
      }
    });

    return {
      columns,
      url
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
