<template>
  <section class="table-container">
    <div class="container">
      <div v-if="options?.search?.enabled" class="search-container">
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.SearchField"
            class="search-field"
            name="SearchField"
            :label="$t.getTranslation('LBL_SEARCH_FIELD')"
            floating-label
            outline
            type="select"
          >
            <!-- :info="formatSearchFieldInfo()" @change="onAddSearchField" -->
            <option
              v-for="(item, key) in options?.search?.list"
              :key="'sf_' + key"
              :value="key"
            >
              {{ item?.Name || $h.formatConfigValue(key) }}
            </option>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.SearchType"
            name="SearchType"
            :label="$t.getTranslation('LBL_TYPE')"
            floating-label
            outline
            type="select"
          >
            <option
              v-for="item in options?.search?.type"
              :key="'sf_' + item"
              :value="item"
            >
              {{ item }}
            </option>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.SearchValue"
            name="SearchValue"
            :label="$t.getTranslation('LBL_SEARCH_VALUE')"
            floating-label
            outline
            type="text"
            :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-item>
            <f7-button
              fill
              raised
              :disabled="formData.SearchType === '' || formData.SearchValue === ''"
              @click="onSearch"
            >
              <font-awesome-icon :icon="['far', 'search']" fixed-width />
              {{ $t.getTranslation("LBL_SEARCH") }}
            </f7-button>
            <f7-button
              v-if="
                searchFieldList.length > 0 ||
                (formData.SearchType !== '' && formData.SearchValue !== '')
              "
              @click="onReset"
            >
              <font-awesome-icon :icon="['far', 'undo']" fixed-width />
              {{ $t.getTranslation("LBL_RESET") }}
            </f7-button>
          </f7-list-item>
          <f7-list-item>
            <f7-button raised @click="showOptionMenu">
              <font-awesome-icon :icon="['far', 'cogs']" fixed-width
            /></f7-button>
          </f7-list-item>
        </f7-list>
      </div>

      <div id="tabulator" ref="tableRef"></div>
      <div class="table-footer">
        <p>
          {{ $t.getTranslation("LBL_TOTAL_RECORD") || "Total Record" }}:
          <strong>{{ tableInfo.totalData || 0 }}</strong>
        </p>
        <p>
          {{ $t.getTranslation("LBL_TOTAL_PAGE") || "Total Page" }}:
          <strong>{{ tableInfo.totalPage || 0 }}</strong>
        </p>
      </div>

      <f7-actions id="actionsTableRowClick">
        <f7-actions-group>
          <f7-actions-label>{{
            $t.getTranslation("LBL_PERFORM_ACTIONS")
          }}</f7-actions-label>
          <f7-actions-button v-if="actions?.orders?.enabled" bold @click="viewOrders">
            <font-awesome-icon :icon="['far', 'eye']" fixed-width />
            {{ $t.getTranslation("LBL_VIEW_ORDERS") }}</f7-actions-button
          >
          <f7-actions-button v-if="actions?.picked?.enabled" bold @click="viewPicked">
            <font-awesome-icon :icon="['far', 'eye']" fixed-width />
            {{ $t.getTranslation("LBL_VIEW_PICKED") }}</f7-actions-button
          >
          <f7-actions-button v-if="actions?.view?.enabled" bold @click="viewItem">
            <font-awesome-icon :icon="['far', 'eye']" fixed-width />
            {{ $t.getTranslation("LBL_VIEW") }}</f7-actions-button
          >
          <f7-actions-button v-if="actions?.edit?.enabled" @click="editItem">
            <font-awesome-icon :icon="['far', 'edit']" fixed-width />
            {{ $t.getTranslation("LBL_EDIT") }}</f7-actions-button
          >
          <f7-actions-button v-if="actions?.download?.enabled" @click="downloadItem">
            <font-awesome-icon :icon="['far', 'download']" fixed-width />
            {{ $t.getTranslation("LBL_DOWNLOAD") }}</f7-actions-button
          >
        </f7-actions-group>
        <f7-actions-group>
          <template
            v-if="
              actions?.status?.enabled &&
              $h.isArray(actions?.status?.list) &&
              actions?.status?.list.length > 0
            "
          >
            <template v-for="status in actions?.status?.list" :key="'st_' + status">
              <f7-actions-button
                v-if="selectedRowData[actions?.status?.identifier] !== status"
                @click="changeStatusItem(status)"
              >
                <font-awesome-icon :icon="['far', 'exchange']" fixed-width />
                {{ status }}
              </f7-actions-button>
            </template>
          </template>

          <f7-actions-button
            v-if="actions?.delete?.enabled"
            color="red"
            @click="deleteItem"
          >
            <font-awesome-icon :icon="['far', 'trash']" fixed-width />
            {{ $t.getTranslation("LBL_DELETE") }}</f7-actions-button
          >
        </f7-actions-group>
      </f7-actions>

      <f7-popup id="popupOptionMenu" class="options-popup" push>
        <f7-page>
          <div class="options-container">
            <div class="options-title">
              <h1>
                <font-awesome-icon :icon="['far', 'file']" fixed-width />
                {{ $t.getTranslation("LBL_GENERATE") }}
              </h1>
            </div>

            <div class="button-container">
              <f7-button
                v-if="options?.print?.enabled"
                popup-close
                large
                raised
                @click="onPrint"
                ><font-awesome-icon :icon="['far', 'print']" fixed-width />
                {{ $t.getTranslation("LBL_PRINT") }}
              </f7-button>
              <f7-button
                v-if="options?.export?.enabled"
                popup-close
                large
                raised
                @click="onExportCSV"
              >
                <font-awesome-icon :icon="['far', 'file-csv']" fixed-width />
                {{ $t.getTranslation("LBL_EXPORT_CSV") }}
              </f7-button>
              <f7-button
                v-if="options?.export?.enabled"
                popup-close
                large
                raised
                @click="onExportJSON"
              >
                <font-awesome-icon :icon="['far', 'code']" fixed-width />
                {{ $t.getTranslation("LBL_EXPORT_JSON") }}
              </f7-button>
              <f7-button
                v-if="options?.export?.enabled"
                popup-close
                large
                raised
                @click="onExportXLSX"
              >
                <font-awesome-icon :icon="['far', 'file-excel']" fixed-width />
                {{ $t.getTranslation("LBL_EXPORT_XLSX") }}
              </f7-button>
            </div>
          </div>

          <div class="options-container">
            <div class="options-title">
              <h1>
                <font-awesome-icon :icon="['far', 'funnel-dollar']" fixed-width />
                {{ $t.getTranslation("LBL_FILTER") }}
              </h1>
            </div>

            <f7-list no-hairlines>
              <f7-list-input
                v-model:value="formData.FilterType"
                name="FilterType"
                :label="$t.getTranslation('LBL_FILTER_TYPE')"
                floating-label
                outline
                type="select"
                :placeholder="$t.getTranslation('LBL_FILTER_TYPE_PLACEHOLDER')"
              >
                <template v-if="options?.filter?.list">
                  <option
                    v-for="(item, key) in options?.filter?.list"
                    :key="'frt_' + key"
                    :value="key"
                  >
                    {{ item.Name || $h.formatConfigValue(key) }}
                  </option>
                </template>
              </f7-list-input>

              <template v-if="filterInput?.InputType === 'datepicker'">
                <f7-list-input
                  v-model:value="formData.FilterValue"
                  :label="$t.getTranslation('LBL_FILTER_VALUE')"
                  :calendar-params="{ dateFormat: 'mm/dd/yy', rangePicker: true }"
                  :placeholder="$t.getTranslation('LBL_FILTER_VALUE_PLACEHOLDER')"
                  name="FilterValue"
                  floating-label
                  outline
                  type="datepicker"
                ></f7-list-input>
              </template>
              <template v-else-if="filterInput?.InputType === 'range'">
                <f7-list-input
                  v-model:value="formData.FilterValue[0]"
                  name="FilterValueMinimum"
                  :label="$t.getTranslation('LBL_MIN')"
                  floating-label
                  outline
                  type="number"
                  class="pricerange"
                  placeholder="0"
                >
                </f7-list-input>
                <f7-list-input
                  v-model:value="formData.FilterValue[1]"
                  name="FilterValueMaximum"
                  :label="$t.getTranslation('LBL_MAX')"
                  floating-label
                  outline
                  type="number"
                  class="pricerange"
                  placeholder="0"
                >
                </f7-list-input>
              </template>
              <template v-else>
                <f7-list-input
                  v-model:value="formData.FilterValue"
                  name="FilterValue"
                  :label="$t.getTranslation('LBL_FILTER_VALUE')"
                  floating-label
                  outline
                  :type="filterInput?.InputType"
                  :placeholder="$t.getTranslation('LBL_FILTER_VALUE_PLACEHOLDER')"
                >
                  <template
                    v-if="
                      filterInput?.InputItems &&
                      $h.isArray(filterInput?.InputItems) &&
                      filterInput?.InputItems.length > 0
                    "
                  >
                    <option
                      v-for="item in filterInput?.InputItems"
                      :key="'frv_' + item.Code"
                      :value="item.Code || item.Value"
                    >
                      {{ item.Name }}
                    </option>
                  </template>
                </f7-list-input>
              </template>
              <f7-list-item>
                <f7-button
                  fill
                  large
                  raised
                  :disabled="formData.FilterType === '' || formData.FilterValue === ''"
                  @click="onAddFilter"
                >
                  <font-awesome-icon :icon="['far', 'filter']" fixed-width />
                  {{ $t.getTranslation("LBL_APPLY") }}
                </f7-button>
              </f7-list-item>
            </f7-list>

            <ul v-if="filterList?.length > 0" class="filterlist-container">
              <li v-for="(filter, index) in filterList" :key="'frv_' + index">
                <strong>{{ $h.formatConfigValue(filter.FilterType) }}</strong>
                <p v-if="!Array.isArray(filter?.FilterValue)">{{ filter.FilterValue }}</p>
                <p v-else>
                  <template
                    v-if="
                      filter.FilterType.indexOf('Last') > -1 ||
                      filter.FilterType.indexOf('Date') > -1
                    "
                  >
                    {{ $h.formatDate(filter.FilterValue[0]) }} -
                    {{ $h.formatDate(filter.FilterValue[1]) }}
                  </template>
                  <template v-else>
                    {{ filter.FilterValue[0] }} - {{ filter.FilterValue[1] }}
                  </template>
                </p>

                <div class="button-container">
                  <f7-button small raised fill @click="onEditFilter(filter)"
                    >{{ $t.getTranslation("LBL_EDIT") }}
                  </f7-button>
                  <f7-button
                    small
                    raised
                    fill
                    color="red"
                    @click="onDeleteFilter(filter, index)"
                    >{{ $t.getTranslation("LBL_DELETE") }}
                  </f7-button>
                </div>
              </li>
            </ul>
          </div>

          <div v-if="options?.sort?.enabled" class="options-container options-sort">
            <div class="options-title">
              <h1>
                <font-awesome-icon :icon="['far', 'sort-size-down-alt']" fixed-width />{{
                  $t.getTranslation("LBL_SORT")
                }}
              </h1>
            </div>

            <f7-list no-hairlines>
              <f7-list-input
                v-model:value="formData.SortType"
                name="SortType"
                :label="$t.getTranslation('LBL_SORT_TYPE')"
                floating-label
                outline
                type="select"
                :placeholder="$t.getTranslation('LBL_SORT_TYPE_PLACEHOLDER')"
              >
                <option
                  v-for="type in options?.sort?.type"
                  :key="'srv_' + type"
                  :value="type"
                >
                  {{ type }}
                </option>
              </f7-list-input>
              <f7-list-input
                v-model:value="formData.SortValue"
                name="SortValue"
                :label="$t.getTranslation('LBL_SORT_VALUE')"
                floating-label
                outline
                type="select"
                :placeholder="$t.getTranslation('LBL_SORT_VALUE_PLACEHOLDER')"
              >
                <option
                  v-for="(item, key) in options?.sort?.list"
                  :key="'srt_' + key"
                  :value="key"
                >
                  {{ item?.Name || $h.formatConfigValue(key) }}
                </option>
              </f7-list-input>
            </f7-list>
          </div>

          <!-- <div class="options-container options-page">
            <div class="options-title">
              <h1><font-awesome-icon :icon="['far', 'sort-numeric-up-alt']" fixed-width /> {{ $t.getTranslation("LBL_SIZE_PAGE") }}</h1>
            </div>

            <f7-list no-hairlines>
              <f7-list-input v-model:value="formData.size" name="Size" :label="$t.getTranslation('LBL_SIZE')" floating-label outline type="number" :placeholder="$t.getTranslation('LBL_SIZE_PLACEHOLDER')" validate clear-button>
              </f7-list-input>
              <f7-list-input v-model:value="formData.page" name="Page" :label="$t.getTranslation('LBL_PAGE')" floating-label outline type="number" :placeholder="$t.getTranslation('LBL_PAGE_PLACEHOLDER')" validate clear-button>
              </f7-list-input>
            </f7-list>
          </div> -->

          <div class="options-container reset-cancel">
            <f7-list no-hairlines>
              <f7-list-item>
                <f7-button fill large raised popup-close @click="onApply">
                  <font-awesome-icon :icon="['far', 'filter']" fixed-width />
                  {{ $t.getTranslation("LBL_APPY") }}
                </f7-button>
                <f7-button large popup-close @click="onReset">
                  <font-awesome-icon :icon="['far', 'undo']" fixed-width />
                  {{ $t.getTranslation("LBL_RESET") }}
                </f7-button>
              </f7-list-item>
            </f7-list>
          </div>
        </f7-page>
      </f7-popup>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, watch, inject } from "vue";
import { Dom7 } from "framework7";
import { f7 } from "framework7-vue";

import Tabulator from "tabulator-tables";
import xlsx from "xlsx";

import { $HTTP } from "@/utils/axios";
import { configs } from "@/utils/configs";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

const FROZEN_WIDTH = 1000;

export default defineComponent({
  name: "DataTableComponent",
  props: {
    skipRemark: Boolean,
    static: Boolean,
    url: String,
    columns: [String, Array],
    nodata: String,
    size: Number,
  },
  emits: [
    "viewItem",
    "viewOrders",
    "viewPicked",
    "editItem",
    "downloadItem",
    "changeStatusItem",
    "deleteItem",
  ],
  setup(props, { emit }) {
    const $t = inject("$translation");

    let windowWidth = 0;
    let isAllowFrozen = true;

    let fileName = "";

    const store = useStore();

    const tableRef = ref();
    const tabulator = ref();
    const columns = ref([]);
    const tableInfo = ref(false);

    const actions = ref(false);
    const options = ref(false);

    const searchFieldList = ref([]);
    const searchFieldNameList = ref([]);
    const selectedRow = ref(false);
    const selectedRowData = ref(false);

    const filterList = ref([]);

    let defaultFormData = {
      size: options.value?.size?.default || props?.size || 15,
      page: 15,
      SearchField: "",
      SearchType: "",
      SearchValue: "",
      SortType: options.value?.sort?.default?.type || "",
      SortValue: options.value?.sort?.default?.value || "",
      FilterType: options.value?.filter?.default?.type || "",
      FilterValue: options.value?.filter?.default?.value || [],
    };

    const filterInput = reactive({
      InputType: options.value?.filter?.default?.input || "text",
      InputItems: options.value?.filter?.default?.items || [],
    });

    const formData = reactive({ ...defaultFormData });

    const getTableConfig = async () => {
      try {
        let isAdd = props.url.indexOf("?") > -1 ? "&" : "?";
        let res = await $HTTP.get(
          `${props.url}${isAdd}page=1&size=${props.size}&config=1`
        );
        if (res?.status === 200 && res?.data?.data) {
          actions.value = res?.data?.data.actions;
          options.value = res?.data.data?.options;
        }
      } catch (err) {
        helpers.catchError(err, true);
      }
    };

    const prepareColumns = () => {
      if (props?.columns && helpers.isArray(props.columns)) {
        columns.value = [];

        let defaultValue = {
          minWidth: 200,
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
        };

        for (let item of props?.columns) {
          let preparedItem = {
            ...defaultValue,
            ...item,
          };

          preparedItem.frozen = preparedItem.frozen && isAllowFrozen ? true : false;
          columns.value.push(preparedItem);
        }

        // prepare data for printing
        if (options.value?.print?.enabled && options.value?.print?.list) {
          for (let item of options.value?.print?.list) {
            columns.value.push({
              title: helpers.formatConfigValue(item),
              field: item,
              visible: false,
              print: true,
              download: false,
            });
          }
        }

        // prepare data for exporting
        if (options.value?.export?.enabled && options.value?.export?.list) {
          for (let item of options.value?.export?.list) {
            columns.value.push({
              title: helpers.formatConfigValue(item),
              field: item,
              visible: false,
              print: false,
              download: true,
            });
          }
        }
      }
    };

    //initialize table
    const initTabulator = () => {
      selectedRow.value = false;
      selectedRowData.value = false;

      let tabulatorConfig = {
        ajaxURL: `${configs.baseURL}${props.url}`,
        ajaxFiltering: true,
        ajaxSorting: true,
        paginationSize: formData.size,
        paginationDataReceived: {
          last_page: "lastPage",
        },
        ajaxURLGenerator: (url, config, params) => {
          let ajaxURL = `${configs.baseURL}${props.url}`;

          formData.page = params.page;
          formData.size = params.size;

          for (let key in formData) {
            if (formData[key] !== "") {
              if (formData[key] === "FilterType") {
                formData[key] = "MULTIPLE";
              }

              if (formData[key] === "FilterValue") {
                formData[key] = JSON.stringify(formData[key]);
              }

              formData[key] = encodeURIComponent(formData[key]);

              ajaxURL += ajaxURL.indexOf("?") > -1 ? "&" : "?";
              ajaxURL += `${key}=${formData[key]}`;
            }
          }

          return ajaxURL;
        },
        ajaxResponse: (url, params, response) => {
          let data = response?.data || {};

          tableInfo.value = {
            totalPage: data.lastPage,
            totalData: data.totalData,
          };

          return data;
        },
        ajaxError: async (err) => {
          let errorData = await err.json();
          helpers.catchError(errorData, true);
        },
        layout: "fitDataFill",
        tooltips: true,
        printAsHtml: true,
        printStyled: true,
        movableColumns: true,
        movableRows: false,
        headerSort: false,
        placeholder: props.nodata || "No records found",
        rowClick: (e, row) => {
          selectedRow.value = row;
          selectedRowData.value = row.getData();

          if (props.static) return;

          f7.actions.get("#actionsTableRowClick").open();
        },
        downloadReady: (fileContents, blob) => {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.classList.add("external");

          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();

          window.URL.revokeObjectURL(url);
          return false;
        },
        ajaxLoaderLoading: `<span>LOADING</span>`,
        ajaxLoaderError: `<span>ERROR</span>`,
        locale: true,
        langs: {
          en: {
            pagination: {
              page_size: $t.getTranslation("LBL_PAGE_SIZE"),
              page_title: $t.getTranslation("LBL_SHOW_PAGE"),
              first: $t.getTranslation("LBL_FIRST"),
              last: $t.getTranslation("LBL_LAST"),
              prev: $t.getTranslation("LBL_PREV"),
              next: $t.getTranslation("LBL_NEXT"),
              all: $t.getTranslation("LBL_ALL"),
              first_title: $t.getTranslation("LBL_FIRST_PAGE"),
              last_title: $t.getTranslation("LBL_LAST_PAGE"),
              prev_title: $t.getTranslation("LBL_PREV_PAGE"),
              next_title: $t.getTranslation("LBL_NEXT_PAGE"),
            },
          },
        },
      };

      const userToken = store.getters["user/getToken"];
      if (userToken && userToken !== "") {
        tabulatorConfig.ajaxConfig = {
          headers: {
            languagecode: $t.getLanguage(),
            Authorization: `Bearer ${userToken}`,
          },
        };
      }

      tabulatorConfig.pagination = "remote";
      tabulatorConfig.paginationSizeSelector = [15, 50, 100, 250];

      if (
        props?.columns &&
        helpers.isString(props.columns) &&
        props.columns == "autoColumns"
      ) {
        tabulatorConfig.autoColumns = true;
      } else {
        prepareColumns();
        tabulatorConfig.columns = columns.value;
      }

      tabulator.value = new Tabulator(tableRef.value, tabulatorConfig);
    };

    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        windowWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        isAllowFrozen = windowWidth >= FROZEN_WIDTH;

        initTabulator();
      });
    };

    const formatSearchFieldInfo = () => {
      let tmpSearchFieldNameList = [];
      searchFieldNameList.value.forEach((item) => {
        tmpSearchFieldNameList.push(helpers.formatConfigValue(item));
      });

      return tmpSearchFieldNameList.join(", ");
    };

    const onAddSearchField = (event, index) => {
      if (event?.target?.value) {
        searchFieldList.value.push(event?.target?.value);

        if (options?.value?.search?.list[event?.target?.value]?.Name) {
          searchFieldNameList.value.push(
            options?.value?.search?.list[event?.target?.value]?.Name
          );
        } else {
          searchFieldNameList.value.push(event?.target?.value);
        }
      }
    };

    const onSearch = () => {
      tabulator.value.setData();
    };

    const onAddFilter = () => {
      if (formData?.FilterType != "" && formData?.FilterValue != "") {
        for (let item of filterList.value) {
          if (item.FilterType == formData?.FilterType) {
            item.FilterValue = formData?.FilterValue;
            return;
          }
        }

        filterList.value.push({
          FilterType: formData?.FilterType,
          FilterValue: formData?.FilterValue,
        });
      }
    };

    const onEditFilter = (filter) => {
      formData.FilterType = filter.FilterType;
      setTimeout(() => {
        formData.FilterValue = filter.FilterValue;
      }, 100);
    };

    const onDeleteFilter = (filter, index) => {
      filterList.value.splice(index, 1);
    };

    const onApply = () => {
      tabulator.value.setData();
    };

    const onReset = () => {
      Object.assign(formData, defaultFormData);
      searchFieldList.value = [];
      searchFieldNameList.value = [];

      initTabulator();
    };

    const onPrint = () => {
      tabulator.value.print();
    };

    const onExportCSV = () => {
      fileName = `${options.value?.export?.fileName || "data"}.csv`;
      tabulator.value.download("csv", fileName);
    };

    const onExportJSON = () => {
      fileName = `${options.value?.export?.fileName || "data"}.json`;
      tabulator.value.download("json", fileName);
    };

    const onExportXLSX = () => {
      const win = window;
      win.XLSX = xlsx;
      fileName = `${options.value?.export?.fileName || "data"}.xlsx`;

      tabulator.value.download("xlsx", fileName);
    };

    const showOptionMenu = () => {
      f7.popup.get("#popupOptionMenu").open();
    };

    const viewItem = () => {
      emit("viewItem", selectedRowData);
    };

    const viewOrders = () => {
      emit("viewOrders", selectedRowData);
    };

    const viewPicked = () => {
      emit("viewPicked", selectedRowData);
    };

    const editItem = () => {
      emit("editItem", selectedRowData);
    };

    const downloadItem = () => {
      emit("downloadItem", selectedRowData);
    };

    const changeStatusItem = (status) => {
      helpers.createRemarks({
        message: "Enter a remarks why you want to change the status selected item.",
        confirm: (remarks) => {
          if (remarks == "") {
            helpers.createNotification({
              type: "error",
              title: "Error!",
              message: `Please input remarks before changing the status of the selected item.`,
            });
          } else {
            selectedRowData.value.Remarks = remarks;
            emit("changeStatusItem", status, selectedRowData);
          }
        },
      });
    };

    const deleteItem = () => {
      if (props.skipRemark) {
        return helpers.createConfirmation({
          message: "Are you sure you want to remove this item?",
          confirm: (remarks) => {
            emit("deleteItem", selectedRowData);
          },
        });
      }
      helpers.createRemarks({
        message: "Enter a remarks why you want to delete selected item.",
        title: "Delete",
        confirm: (remarks) => {
          if (remarks == "") {
            helpers.createNotification({
              type: "error",
              title: "Error!",
              message: `Please input remarks before deleting the selected item.`,
            });
          } else {
            selectedRow.value.delete();
            selectedRowData.value.Remarks = remarks;
            emit("deleteItem", selectedRowData);
          }
        },
      });
    };

    onMounted(async () => {
      windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      isAllowFrozen = windowWidth >= FROZEN_WIDTH;

      await getTableConfig();
      initTabulator();
      tabulator?.value?.setLocale("en");

      reInitOnResizeWindow();

      Dom7(".page").on("click", ".image", (e) => {
        helpers.openImage(e.target.src);
        f7.actions.get("#actionsTableRowClick").close();
      });

      Dom7(".page").on("click", ".checkbox", (e) => {
        f7.actions.get("#actionsTableRowClick").close();
      });
    });

    watch(
      () => formData.FilterType,
      (newValue) => {
        filterInput.InputType = options.value?.filter?.list?.[newValue]?.Type || "text";
        filterInput.InputItems = options.value?.filter?.list?.[newValue]?.Items || [];

        if (filterInput.InputType === "datepicker") {
          formData.FilterValue = [];
        } else if (filterInput.InputType === "range") {
          formData.FilterValue = [null, null];
        } else {
          formData.FilterValue = "";
        }
      }
    );

    return {
      tableRef,
      tableInfo,
      searchFieldList,
      searchFieldNameList,
      filterList,
      selectedRowData,
      formData,
      filterInput,
      actions,
      options,
      formatSearchFieldInfo,
      onAddSearchField,
      onSearch,
      onAddFilter,
      onEditFilter,
      onDeleteFilter,
      onApply,
      onReset,
      showOptionMenu,
      viewItem,
      viewOrders,
      viewPicked,
      editItem,
      downloadItem,
      changeStatusItem,
      deleteItem,
      onPrint,
      onExportCSV,
      onExportJSON,
      onExportXLSX,
      initTabulator,
    };
  },
});
</script>

<style>
.options-popup {
  --f7-popup-tablet-height: 650px;
}
.options-popup .page-content {
  padding: 30px 30px 20px;
}
.md .search-field .item-input-info {
  overflow: visible;
}
</style>
