<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation('LBL_ORDER_CUSTOMS_PUSH') }}</h1>
        </div>
      </div>
      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container" v-if="order">
        <div class="order-container">
          <h1>{{ order.OrderNumber }}</h1>
          <OrderItemCardComponent v-if="order?.OrderKey" :data="order" :hideStatus="true" :hideCourier="false" :f7router="f7router" />
        </div>
        <div class="button-container">
          <f7-button @click="send()" :disabled="order.IsCustomsSubmitted > 0" fill large>
            <font-awesome-icon :icon="['far', 'paper-plane']" fixed-width />
            {{ $t.getTranslation('LBL_SEND') }}
          </f7-button>
        </div>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from 'vue'
import OrderItemCardComponent from '@/components/cards/OrderItemCardComponent.vue'
import { useStore } from '@/store'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'

import validationRulesConfig from '@/validationRules'

import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'

export default defineComponent({
  name: 'BannerSavePage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    OrderItemCardComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const statusList = computed(() => store.getters['config/getData']?.statusList)
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const order = ref(null)
    let popupOpened = ref(false)

    const statusCouponList = ref([
      {
        Code: 1,
        Name: $t.getTranslation('LBL_YES')
      },
      {
        Code: 0,
        Name: $t.getTranslation('LBL_NO')
      }
    ])

    const formData = reactive({})
    let validationRules = {}
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys]
      }
    }

    onMounted(async () => {
      if (props?.f7route?.query?.key) {
        await getData()
      }
    })

    const send = async () => {
      let message = $t.getTranslation('MSG_CONFIRM_ORDER_PUSH_CUSTOMS')
      helpers.createConfirmation({
        message: message,
        title: $t.getTranslation('LBL_CONFIRM'),
        confirm: async () => {
          await sendOrder(order.value.OrderKey)
        }
      })
    }

    const getData = async () => {
      try {
        helpers.showLoader()
        let res = await $HTTP.get(`/admin/order/view?&LanguageCode=${currentLanguageCode.value || 'EN'}&OrderKey=${props?.f7route?.query?.key}`)
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()

          let { OrderKey, OrderStatusName, GrandTotal, CurrencyCode, Items, LastCreated, Shipment, OrderNumber, IsCustomsSubmitted } = res.data.data
          let Order = {
            OrderKey,
            OrderStatusName,
            GrandTotal,
            CurrencyCode,
            LastCreated,
            OrderNumber,
            IsCustomsSubmitted
          }

          let CourierCode = null
          let CourierTrackingNo = null

          if (Shipment && Shipment.Length) {
            CourierCode = Shipment[0].CourierCode
            CourierTrackingNo = Shipment[0].CourierTrackingNo
          }

          Order = {
            ...Order,
            TotalItems: Items.length,
            CourierCode: CourierCode,
            CourierTrackingNo: CourierTrackingNo
          }

          order.value = Order
          helpers.hideLoader()
          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const sendOrder = async (OrderKey) => {
      try {
        helpers.showLoader()
        let res = await $HTTP.post(`/webhook/payment/push/customs`, { OrderKey: OrderKey })
        if (res?.status === 200 && res?.data?.data) {
          helpers.createNotification({
            type: 'success',
            title: $t.getTranslation('LBL_SUCCESS'),
            message: 'Order Push to customs'
          })
          await getData()
          return
        }

        helpers.hideLoader()
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    return {
      formData,
      validationRules,
      order,
      send,
      popupOpened
    }
  }
})
</script>

<style scoped>
.form-container .container .order-container {
  max-width: 800px;
  min-width: 500px;
}

.form-container .container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-container h4 {
  font-weight: bold;
}
</style>
