import routes from '@/routes'
import { useStore } from '@/store'

const store = useStore()

const checkMeta = ({ to, from, resolve, reject, router }) => {
  store.dispatch('page/setData', { activeRoute: to.route })

  if (to?.route?.meta?.navigation?.activeLink) {
    store.dispatch('page/setData', {
      activeLink: to.route.meta.navigation.activeLink,
      activeLinkParent: to.route.meta.navigation.parentName
    })
  }

  if (to?.route?.meta?.isSecured) {
    const userToken = store.getters['user/getToken']

    if (userToken && userToken !== '') {
      store.dispatch('user/fetchData', { force: false })
    } else {
      reject()
      return router.navigate('/login/')
    }
  }
  
  resolve()
  return true
}

const checkIsAlreadyLogin = ({ to, from, resolve, reject, router }) => {
  const userToken = store.getters['user/getToken']

  if (userToken !== '') {
    reject()
    return router.navigate('/')
  }

  resolve()
  return true
}

const checkRouteRoles = (route, userInfo) => {
  let isValid = false;

  // CHECK hasAnyRoles => only 1 is required rest is optional
  if (route?.meta?.hasAnyRoles && Array.isArray(route?.meta?.hasAnyRoles) && route?.meta?.hasAnyRoles.length > 0) {
    isValid = false
    let hasRolesCounter = 0

    for (let role of route.meta.hasAnyRoles) {
      if (userInfo?.UserRoleList?.indexOf(role) > -1) {
        hasRolesCounter++
      }
    }

    isValid = hasRolesCounter > 0
  }



  // // CHECK hasRoles => everything is required
  if (route?.meta?.hasRoles && Array.isArray(route?.meta?.hasRoles) && route?.meta?.hasRoles.length > 0) {
    isValid = false
    let hasRolesCounter = 0;

    for (let role of route.meta.hasRoles) {
      if (userInfo?.UserRoleList?.indexOf(role) > -1) {
        hasRolesCounter++
      }
    }

    isValid = (hasRolesCounter === route.meta.hasRoles.length ? true : false)
  }


  // if (route?.meta?.hasRoles) {
  //   for (var i = 0; i < route?.meta?.hasRoles.length; i++) {
  //    let item = route?.meta?.hasRoles[i];
  //    if (userInfo.UserRoleList.indexOf(item) > -1) {
  //      isValid = true;
  //    }
  //   }
  // }

  if (userInfo?.IsSuperAdmin > 0) {
    isValid = true;
  }

  return isValid
}

const getNavigationLists = (userInfo) => {
  let navigationLists = []
  for (let route of routes) {
    if (route?.meta?.navigation) {
      let navigation = route?.meta?.navigation
      navigation.child = []
      navigation.name = route?.name
      navigation.link = route?.path

      // CHECK FOR ROLES
      let isValid = checkRouteRoles(route, userInfo);

      if (route?.routes && route?.routes.length > 0) {
        for (let childRoute of route?.routes) {
          if (childRoute?.meta?.navigation) {
            let childRouteNavigation = childRoute?.meta?.navigation
            childRouteNavigation.parentName = navigation?.name
            childRouteNavigation.name = childRoute?.name
            childRouteNavigation.link = `${navigation.link}${childRoute?.path}`

            // CHECK FOR ROLES
            let isValidChildRoute = checkRouteRoles(childRoute, userInfo)

            if (isValidChildRoute) {
              navigation.child.push(childRouteNavigation)
            }
          }
        }
      }


      if (isValid) {
        navigationLists.push(navigation)
      }

    }
  }

  store.dispatch('page/setData', { navigations: navigationLists });
  return navigationLists
}

export { checkMeta, checkIsAlreadyLogin, getNavigationLists }
