<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation("LBL_NOTIFICATIONS") }}</h1>
        </div>
      </div>
      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation("LBL_NOTIFICATION_DETAILS") }}</f7-list-item>

          <f7-list-input
            v-model:value="formData.Title"
            name="Title"
            :required="validationRules?.Title?.required"
            :minlength="validationRules?.Title?.minimumLength"
            :maxlength="validationRules?.Title?.maximumLength"
            :label="$t.getTranslation('LBL_NOTIFICATION_TITLE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Content"
            name="Content"
            :required="validationRules?.Content?.required"
            :minlength="validationRules?.Content?.minimumLength"
            :maxlength="validationRules?.Content?.maximumLength"
            :label="$t.getTranslation('LBL_NOTIFICATION_CONTENT')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="textarea"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Type"
            name="Type"
            :required="validationRules?.Type?.required"
            :minlength="validationRules?.Type?.minimumLength"
            :maxlength="validationRules?.Type?.maximumLength"
            :label="$t.getTranslation('LBL_NOTIFICATION_TYPE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option value="ALL">{{ $t.getTranslation("LBL_ALL_USER") }}</option>
            <option value="ORDERCUSTOMER">{{ $t.getTranslation("LBL_CUSTOMER_WITH_ORDERS") }}</option>
            <option value="USERGROUP">{{ $t.getTranslation("LBL_USER_GROUP") }}</option>
          </f7-list-input>

          <f7-list-input
            v-if="formData.Type === 'USERGROUP'"
            v-model:value="formData.LinkCode"
            name="LinkCode"
            :required="validationRules?.LinkCode?.required"
            :minlength="validationRules?.LinkCode?.minimumLength"
            :maxlength="validationRules?.LinkCode?.maximumLength"
            :label="$t.getTranslation('LBL_USER_GROUP')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="user in userGroupList" :key="'ug_' + user.LinkCode" :value="user.LinkCode">{{ user.Name }}</option>
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.StatusCode"
            name="StatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-button large fill raised preloader @click="saveData">
            {{ $t.getTranslation("LBL_SAVE") }}
          </f7-button>

          <f7-button large fill raised preloader @click="back">
            {{ $t.getTranslation("LBL_BACK") }}
          </f7-button>
        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "BannerSavePage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const statusList = computed(() => store.getters["config/getData"]?.statusList);
    const userGroupList = ref([]);

    const formData = reactive({
      StatusCode: "",
      UserGroupId: "",
      Type: "ALL",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(async () => {
      let res = await $HTTP.get("/admin/notification/groups");
      userGroupList.value = res.data.data;

      if (props?.f7route?.query && props?.f7route?.query?.NotificationId) {
        formData.NotificationId = props?.f7route?.query?.NotificationId;
        await getData();
      }
    });

    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/notification/view?NotificationId=${formData.NotificationId}`);
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();

          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key];
          }

          console.log(formData);

          helpers.hideLoader();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const saveData = async () => {
      try {
        helpers.showLoader();

        let res = await $HTTP.post(`/admin/notification/save`, formData);
        if (res?.status === 200 && res?.data?.data) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("LBL_INFO_SUCCESSFULLY_SAVE_NOTIFICATION") });
          helpers.hideLoader();
          back();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const back = () => {
      props.f7router.navigate({ name: "notificationList", params: {}, query: {} });
    };

    return { formData, userGroupList, validationRules, back, statusList, saveData };
  },
});
</script>
