<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation("LBL_EVENT_LIST") }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="createItem">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :url="url" :size="15" @editItem="editItem" @deleteItem="deleteItem" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

import DataTableComponent from "@/components/DataTableComponent.vue";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "EventListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const url = "/admin/event/list";

    const columns = [
      {
        title: $t.getTranslation("LBL_EVENT_ID"),
        field: "EventId",
        minWidth: 180,
      },
      {
        title: $t.getTranslation("LBL_EVENT_CODE"),
        field: "EventCode",
        minWidth: 500,
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        formatter(cell) {
          return `
            <div>
              <div><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const createItem = () => {
      props.f7router.navigate({ name: "eventSave", params: { id: 0 } });
    };

    const editItem = (data) => {
      if (data?.value?.EventId) {
        props.f7router.navigate({ name: "eventSave", params: { id: data.value.EventId } });
      }
    };

    const deleteItem = async (data) => {
      try {
        helpers.showLoader();

        let res = await $HTTP.post("/admin/event/delete", {
          EventId: data.value.EventId,
          Remarks: data.value.Remarks,
        });

        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();
          return;
        }

        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    return {
      url,
      columns,
      createItem,
      editItem,
      deleteItem,
    };
  },
});
</script>
