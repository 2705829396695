<template>
  <f7-popup id="saveUserCustomerSupport" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="false">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <FormComponent ref="formComponent" @successSave="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { f7 } from "framework7-vue";

import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";

const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "UserCustomerSupportSavePartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const title = ref("");
    const $t = inject("$translation");

    const formComponentData = {
      buttonText: "",
      getLink: "/admin/usercustomersupport/view",
      getParams: {},
      validation: "usercustomersupport",
      saveLink: "/admin/usercustomersupport/save",
      saveParams: {},
      successCreateMessage: $t.getTranslation("LBL_SUCCESS_CREATE_USER_CUSTOMER_SUPPORT"),
      successUpdateMessage: $t.getTranslation("LBL_SUCCESS_UPDATE_USER_CUSTOMER_SUPPORT"),
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("LBL_USER_LIST"),
        type: "selectmultiple",
        value: "User",
        required: false,
        buttonText: $t.getTranslation("LBL_SELECT_USER"),
        title: $t.getTranslation("LBL_SELECT_USER"),
        params: {
          type: "dynamic",
          getLink: "/public/user/datalist",
          getParams: { UserTypeCode: "ADMIN" },
          nameSelector: "Title",
          descriptionSelector: "Description",
          valueSelector: "UserId",
          imageSelector: "Image",
          imageBucket: "USER",
        },
      },
    ];

    return {
      title,
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup(data) {
      const self = this;
      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);

      if (data?.UserId) {
        self.title = this.$t.getTranslation("LBL_UPDATE_USER_CUSTOMER_SUPPORT");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_UPDATE_USER_CUSTOMER_SUPPORT");
        self.formComponentData.getParams.UserId = data?.UserId;
        self.formComponentData.saveParams.UserId = data?.UserId;

        self.$refs.formComponent.setFormComponentData(self.formComponentData, true);
      } else {
        self.title = this.$t.getTranslation("LBL_CREATE_USER_CUSTOMER_SUPPORT");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_CREATE_USER_CUSTOMER_SUPPORT");
        self.formComponentData.getParams = {};
        self.formComponentData.saveParams = {
          UserId: data.UserId,
        };

        self.$refs.formComponent.setFormComponentData(self.formComponentData);
      }

      f7.popup.open("#saveUserCustomerSupport");
      self.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#saveUserCustomerSupport");
      this.$emit("closed", data);
    },
  },
});
</script>
