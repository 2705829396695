<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_WAREHOUSE_SAVE') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation('LBL_WAREHOUSE_DETAILS') }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.WareHouseCode"
            name="WareHouseCode"
            :required="validationRules?.WareHouseCode?.required"
            :minlength="validationRules?.WareHouseCode?.minimumLength"
            :maxlength="validationRules?.WareHouseCode?.maximumLength"
            :label="$t.getTranslation('LBL_WAREHOUSE_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            :disabled="formData.WareHouseId"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.WareHouseName"
            name="WareHouseName"
            :required="validationRules?.WareHouseName?.required"
            :minlength="validationRules?.WareHouseName?.minimumLength"
            :maxlength="validationRules?.WareHouseName?.maximumLength"
            :label="$t.getTranslation('LBL_WAREHOUSE_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.WareHouseRegionCode"
            name="WareHouseRegionCode"
            :required="validationRules?.WareHouseRegionCode?.required"
            :minlength="validationRules?.WareHouseRegionCode?.minimumLength"
            :maxlength="validationRules?.WareHouseRegionCode?.maximumLength"
            :label="$t.getTranslation('LBL_WAREHOUSE_REGION_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="region in warehouseregionList" :key="'st_' + region.Code" :value="region.Code">{{ region.Name }}</option>
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.StatusCode"
            name="StatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
            {{ $t.getTranslation('LBL_SAVE') }}
          </f7-button>
        </f7-list>
        <DataTableComponent v-if="formData.WareHouseId" :columns="columns" :size="10" :url="url" @viewItem="editItem"></DataTableComponent>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from 'vue'

import { useStore } from '@/store'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'

import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'

import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import DataTableComponent from '@/components/DataTableComponent.vue'

export default defineComponent({
  name: 'WareHouseSavePage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const isUpdate = ref(false)
    const isButtonProcessing = ref(false)
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const warehouseregionList = computed(() => store.getters['config/getData']?.wareHouseRegionList)
    const statusList = computed(() => store.getters['config/getData']?.statusList)
    const formData = reactive({
      WareHouseCode: null,
      WareHouseName: null,
      WareHouseRegionCode: null
    })

    let url = `/admin/product/inventory/location/list?LanguageCode=${currentLanguageCode.value}&WareHouseCode=${props?.f7route?.query?.WareHouseCode || ''}`
    const columns = [
      {
        title: $t.getTranslation('LBL_IMAGE'),
        maxWidth: 90,
        minWidth: 90,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${cell.getData().DefaultProductImage || require('@/assets/images/placeholder.jpg')}" />
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_SKU'),
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Sku}</strong>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_INVENTORY'),
        field: 'SellableQty'
      },
      {
        title: $t.getTranslation('LBL_LAST_CREATED'),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `
        }
      }
    ]

    let validationRules = {}
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys]
      }
    }

    onMounted(async () => {
      if (props?.f7route?.query && props?.f7route?.query?.WareHouseId) {
        await getData()
      }
    })

    const getData = async () => {
      try {
        helpers.showLoader()
        let res = await $HTTP.get(`/admin/warehouse/view?WareHouseId=${props?.f7route?.query?.WareHouseId}`)
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()
          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key]
          }

          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false)
      return !isValid
    })

    const save = async () => {
      try {
        let isValid = validate(true)
        if (isValid) {
          isButtonProcessing.value = true
          helpers.showLoader()
          //send update to server
          let res = await $HTTP.post('/admin/warehouse/save', formData)
          if (res?.status === 200 && res?.data?.data) {
            isButtonProcessing.value = false
            helpers.hideLoader()
            //props.f7router.back({ force: true, ignoreCache: true });
            helpers.createNotification({ type: 'success', title: $t.getTranslation('LBL_SUCCESS'), message: $t.getTranslation('LBL_SUCCESS_WAREHOUSE') })
            return
          }

          throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
        }
      } catch (err) {
        isButtonProcessing.value = false
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const editItem = (data) => {
      props.f7router.navigate({ name: 'productView', params: { key: data.value.ProductKey } })
    }

    return { isUpdate, formData, validationRules, isButtonProcessing, isDisableSaveButton, save, warehouseregionList, columns, url, editItem, statusList }
  }
})
</script>
