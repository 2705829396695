<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_PRODUCT_RECOMMENDATION') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">


        <f7-list no-hairlines>
        
          <f7-list-item divider>{{ $t.getTranslation("LBL_RECOMMENDATION_STATUS") }}</f7-list-item>
           <f7-list-input
              v-model:value="formData.IsWeeklyRecommended"
              name="Status"
              :required="validationRules?.IsWeeklyRecommended?.required"
              :minlength="validationRules?.IsWeeklyRecommended?.minimumLength"
              :maxlength="validationRules?.IsWeeklyRecommended?.maximumLength"
              :label="$t.getTranslation('LBL_STATUS')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="select"
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>


          <f7-button large fill raised preloader @click="saveData">
              {{ $t.getTranslation("LBL_SAVE") }}
          </f7-button>

           <f7-button large fill raised preloader @click="back">
              {{ $t.getTranslation("LBL_BACK") }}
          </f7-button>

        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";
import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "ProductCriticSave",
  components: {
    MainHeaderComponent,
    MainFooterComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const isButtonProcessing = ref(false);
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const formData = reactive({});

    const statusList = ref(
    [
     {
      Code: 1,
      Name: $t.getTranslation('LBL_ACTIVE')
     },
     {
      Code: 0,
      Name: $t.getTranslation('LBL_DISABLED')
     }
    ]
    );
 
    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(async() => {
      if (props?.f7route?.params?.key) {
        await getData();
      }
    });


    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/product/view?ProductKey=${props?.f7route?.params?.key}&LanguageCode=${currentLanguageCode.value}`);
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();
        
          let pick = _.pick(res.data.data, ['IsWeeklyRecommended']);
 
          for(var k in pick){
            formData[k] = pick[k];
            //formData.CriticScore[keys] = pick.Critic[k].UDF;
          }

          helpers.hideLoader();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const saveData = async () => {
      try {
        helpers.showLoader();
        formData.ProductKey = props?.f7route?.params?.key;
        formData.LanguageCode = currentLanguageCode.value;
        let res = await $HTTP.post(`/admin/product/recommend`, formData);
        if (res?.status === 200 && res?.data?.data) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("LBL_INFO_SUCCESSFULLY_SAVE_PRODUCT_INFO") });
          back();
          helpers.hideLoader();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };


    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });
      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const back = () => {
       props.f7router.navigate({ name: "productRecommended", params: {} });
    }

    return {formData, validationRules, isButtonProcessing, isDisableSaveButton, back, saveData, statusList };
  },
});
</script>
