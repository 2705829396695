<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_PAGE_VIEW") }}</h1>
          <h3></h3>
        </div>
      </div>
    </div>

    <div class="container">


      <div class="search-container" style="padding-left:18px;padding-right:18px;">
        <f7-list no-hairlines>

          <f7-list-input
            v-model:value="formData.rangeValue"
            :label="$t.getTranslation('LBL_SEARCH_VALUE')"
            floating-label
            outline
            type="datepicker"
            :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')"
            readonly
            :calendar-params="{ dateFormat: 'yyyy-mm-dd', rangePicker: true }"
          >
          </f7-list-input>
           <f7-list-input
            v-model:value="formData.URL"
            name="URL"
            label="URL Search"
            placeholder="URL Search"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>


          <f7-list-item>

            <f7-button fill raised @click="onFilter($refs)">
              <font-awesome-icon :icon="['far', 'search']" fixed-width />
              {{ $t.getTranslation("LBL_SEARCH") }}
            </f7-button>

          </f7-list-item>
        </f7-list>
      </div>




      <f7-row  v-if="formData.URL == ''">
        <f7-col width="100" medium="33">
            <f7-card
              :title="$t.getTranslation('TTL_GUEST_LOGIN')">
              <div class="card-count">{{ $h.formatNumber(GuestLogin) }}</div>
            </f7-card>
        </f7-col>
        <f7-col width="100" medium="33">
            <f7-card
              :title="$t.getTranslation('TTL_SMS_LOGIN')">
              <div class="card-count">{{ $h.formatNumber(UserLogin) }}</div>
            </f7-card>
        </f7-col>
        <f7-col width="100" medium="33">
            <f7-card
              :title="$t.getTranslation('TTL_WECHAT_LOGIN')">
              <div class="card-count">{{ $h.formatNumber(WechatLogin) }}</div>
            </f7-card>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100" medium="60">
            <f7-card :title="$t.getTranslation('TTL_TOTAL_VISIT')">
              <VueApexCharts width="100%" type="line" :options="options" :series="series"></VueApexCharts>
            </f7-card>
        </f7-col>
        <f7-col width="100" medium="40">
            <f7-card
              :title="$t.getTranslation('TTL_TOTAL_VISIT')">
              <div class="card-count">{{ $h.formatNumber(TotalVisit) }}</div>
            </f7-card>

            <f7-card
              :title="$t.getTranslation('TTL_TOTAL_UNIQUE_VISIT')"  v-if="formData.URL == ''">
              <div class="card-count">{{ $h.formatNumber(UniqueVisit) }}</div>
            </f7-card>
        </f7-col>
      </f7-row>
    </div>

   

    <DataTableComponent v-if="url" ref="datatable" :columns="columns" :size="10" :url="url" @viewItem="editItem"></DataTableComponent>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, reactive } from "vue";

import dayjs from 'dayjs'
import VueApexCharts from "vue3-apexcharts";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";


import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "ProductListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
    VueApexCharts
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const isUploading = ref(false);

    const columns = [
      {
        title: $t.getTranslation("LBL_IMAGE"),
        maxWidth: 90,
        minWidth: 90,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${helpers.getImage(cell.getData().Image, "USER") || require("@/assets/images/placeholder.jpg")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_NAME"),
        maxWidth: 320,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <strong>${(cell.getData().UserId) ? (cell.getData().FullName || cell.getData().Username || cell.getData().OpenId || cell.getData().UserId || "---") : $t.getTranslation('LBL_GUEST_LOGIN')}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IP_ADDRESS"),
        formatter(cell) {
          return `
            <div>
              <strong>${(cell.getData().IpAddress) ? cell.getData().IpAddress.toString().replace(/[^\d.]/g, '') : "---"}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IS_MOBILE_BROWSER"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.IsMobile == 1 ? "color-blue" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.IsMobile == 1 ?   $t.getTranslation("LBL_YES") : $t.getTranslation("LBL_NO") || '---' }</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IS_WECHAT_BROWSER"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.IsWechat == 1 ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.IsWechat == 1 ?   $t.getTranslation("LBL_YES") : $t.getTranslation("LBL_NO") || '---' }</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PAGE_VISIT"),
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().TotalPageView}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_LAST_CREATED"),
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        },
      }
    ];

    const editItem = (data) => {
    	let Start = dayjs(formData.rangeValue[0]).format('YYYY-MM-DD');
      	let End = (formData.rangeValue.length > 1) ? dayjs(formData.rangeValue[1]).format('YYYY-MM-DD') : dayjs(formData.rangeValue[0]).format('YYYY-MM-DD');


       props.f7router.navigate({ name: "pageviewView", query: { Start, End, SearchKey : data.value.SearchKey } });
    };

    const options = ref({
      chart: {
        toolbar : {
          show : false
        }
      },
      xaxis: {
        type: 'datetime'
      } 
    });

    const series = ref([]);
    const GuestLogin = ref(0);
    const UserLogin = ref(0);
    const WechatLogin = ref(0);

    const TotalVisit = ref(0);
    const UniqueVisit = ref(0);

    const formData = reactive({
      rangeValue : [dayjs().add(-14, 'days').valueOf(), dayjs().valueOf()],
      URL: ""
    });

    const url = ref("");

    const onFilter = async($refs)=>{
      getChartData($refs) //call???
    };

    const getQuery = ()=>{
      let Start = dayjs(formData.rangeValue[0]).format('YYYY-MM-DD');
      let End = (formData.rangeValue.length > 1) ? dayjs(formData.rangeValue[1]).format('YYYY-MM-DD') : dayjs(formData.rangeValue[0]).format('YYYY-MM-DD');
      return `?from=${Start}&to=${End}${formData.URL != '' ? `&URL=${formData.URL}` : ``}`;
    }

    const getChartData = async($refs)=>{

      url.value = `/admin/pageview/list${getQuery()}&grouped=1`; //set url???

      if($refs){
        setTimeout(()=>{
        	$refs.datatable.initTabulator();
        }, 300);
      };


      let res = await $HTTP.get(`/admin/pageview/chart${getQuery()}`)
      console.log(res.data.data.Series, 'hey')

      series.value = res.data.data.Series

      TotalVisit.value = res.data.data.TotalVisit
      UniqueVisit.value = res.data.data.UniqueVisit

      GuestLogin.value = res.data.data.GuestLogin
      UserLogin.value = res.data.data.UserLogin
      WechatLogin.value = res.data.data.WechatLogin
    };

    onMounted(getChartData);

    return {
    	editItem,
      formData,
      onFilter,
      TotalVisit,
      UniqueVisit,
      GuestLogin,
      UserLogin,
      WechatLogin,
      options, series,
    	isUploading,
      	columns,
      	url
    };
  },
});
</script>

<style scoped>
.card-count{
  padding:10px;
  font-size:18px;
}
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}
</style>
