<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_MERCHANT_INQUIRY_VIEW') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation("LBL_MERCHANT_INQUIRY_DETAILS") }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.CompanyName"
            name="CompanyName"
            :required="validationRules?.Email?.required"
            :minlength="validationRules?.Email?.minimumLength"
            :maxlength="validationRules?.Email?.maximumLength"
            :label="$t.getTranslation('LBL_CNAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Name"
            name="Name"
            :required="validationRules?.Name?.required"
            :minlength="validationRules?.Name?.minimumLength"
            :maxlength="validationRules?.Name?.maximumLength"
            :label="$t.getTranslation('LBL_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Email"
            name="Email"
            :required="validationRules?.Email?.required"
            :minlength="validationRules?.Email?.minimumLength"
            :maxlength="validationRules?.Email?.maximumLength"
            :label="$t.getTranslation('LBL_EMAIL')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.Message"
            name="Message"
            :required="validationRules?.Message?.required"
            :minlength="validationRules?.Message?.minimumLength"
            :maxlength="validationRules?.Message?.maximumLength"
            :label="$t.getTranslation('LBL_MESSAGE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="textarea"
            validate
          >
          </f7-list-input>

          
          <f7-button large fill raised preloader @click="back">
              {{ $t.getTranslation("LBL_BACK") }}
          </f7-button>

        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "CustomerSavePage",
  components: {
    MainHeaderComponent,
    MainFooterComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const userStatusList = computed(() => store.getters["config/getData"]?.userStatusList);
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const ContactMerchantId = ref(null);
    const formData = reactive({
      
    });

   

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(async() => {
      if (props?.f7route?.query && props?.f7route?.query?.ContactMerchantId) {
        ContactMerchantId.value = props?.f7route?.query?.ContactMerchantId;
        await getData();
      }
    });

    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/contact/merchant/view?ContactMerchantId=${ContactMerchantId.value}`);
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();
          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key];
          }

          helpers.hideLoader();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });


    const back = () => {
      props.f7router.navigate({ name: "inquiryList", params: {}, query: {} });
    }
    

    return { isUpdate, formData, validationRules, isButtonProcessing, isDisableSaveButton, userStatusList, back };
  },
});
</script>
