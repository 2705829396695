<template>
  <f7-popup class="popup-setprice" :class="inputGroup?.value" :tablet-fullscreen="false" :backdrop="true">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ inputGroup?.title }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="closePopup">
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <section class="form-container">
        <div class="container">
          <f7-list id="formCurrency" class="formContainer" form no-hairlines no-hairlines-between>
            <f7-list-item divider> Currency Information </f7-list-item>

            <f7-list-input
              v-model:value="formData['CurrencyId']"
              type="select"
              name="CurrencyId"
              label="Currency"
              :required="true"
              :minlength="validationRules?.['CurrencyId']?.minimumLength"
              :maxlength="validationRules?.['CurrencyId']?.maximumLength"
              placeholder="Type Here"
              info="Please select a currency. This field is required and must be unique."
              floating-label
              outline
              validate
            >
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>

              <option v-for="currency in currencyList" :key="'cy_' + currency.CurrencyCode" :value="currency.CurrencyId">{{ currency.CurrencyCode }} - {{ currency.CurrencyName }}</option>
            </f7-list-input>

            <f7-list-item divider> Price Information </f7-list-item>
            <f7-list-input
              v-model:value="formData['PriceMerchant']"
              type="text"
              name="PriceMerchant"
              label="Price Merchant"
              :required="true"
              :minlength="validationRules?.['PriceMerchant']?.minimumLength"
              :maxlength="validationRules?.['PriceMerchant']?.maximumLength"
              placeholder="0"
              info="The price that merchant would earn."
              floating-label
              outline
              validate
              clear-button
            >
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>
            </f7-list-input>
            <f7-list-input
              v-model:value="formData['PriceRegular']"
              type="text"
              name="PriceRegular"
              label="Price Regular"
              :required="true"
              :minlength="validationRules?.['PriceRegular']?.minimumLength"
              :maxlength="validationRules?.['PriceRegular']?.maximumLength"
              placeholder="0"
              info="The retail price to be displayed in service page."
              floating-label
              outline
              validate
              clear-button
            >
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>
            </f7-list-input>
            <f7-list-input
              v-model:value="formData['PriceSale']"
              type="text"
              name="PriceSale"
              label="Price Sale"
              :required="false"
              :minlength="validationRules?.['PriceSale']?.minimumLength"
              :maxlength="validationRules?.['PriceSale']?.maximumLength"
              placeholder="0"
              info="This field is optional."
              floating-label
              outline
              validate
              clear-button
            >
            </f7-list-input>

            <f7-list-item divider> Status Information </f7-list-item>
            <f7-list-input
              v-model:value="formData['StatusCode']"
              type="select"
              name="StatusCode"
              label="Status"
              :required="true"
              :minlength="validationRules?.['StatusCode']?.minimumLength"
              :maxlength="validationRules?.['StatusCode']?.maximumLength"
              placeholder="Type Here"
              floating-label
              outline
              validate
            >
              <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
            </f7-list-input>
          </f7-list>
        </div>
      </section>

      <div class="footer-button-container">
        <f7-button class="button-select" fill large @click="setPrice">{{ inputGroup?.buttonText }}</f7-button>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed } from "vue";

import { f7 } from "framework7-vue";

import { get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { validations } from "@/utils/validations";

import { useStore } from "@/store";
const store = useStore();

export default defineComponent({
  name: "SetPricePopupComponent",
  components: {},
  props: {
    backdrop: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["opened", "closed", "set"],
  setup(props, { emit }) {
    let isFetching = false;

    const inputGroup = ref({});
    const currencyList = ref([]);

    const statusList = computed(() => store.getters["config/getData"]?.statusList);

    const formData = reactive({
      CurrencyId: "",
      PriceMerchant: "",
      PriceRegular: "",
      PriceSale: "",
      StatusCode: "ACTIVE",
    });

    let validationRules = {};

    onMounted(async () => {
      let validationRuleList = await get("/public/validation/list", { Type: "currency" });

      for (let keys in formData) {
        if (validationRuleList && validationRuleList[keys]) {
          validationRules[keys] = validationRuleList[keys];

          if (["CurrencyId", "PriceMerchant", "PriceRegular"].indexOf(keys) > -1) {
            validationRules[keys].required = true;
          }
        }
      }

      await getCurrencyList();
    });

    const validate = (showNotifications = false) => {
      let isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      if (parseFloat(formData.PriceMerchant) > parseFloat(formData.PriceRegular)) {
        isValid = false;
        if (showNotifications) {
          helpers.createNotification({
            type: "error",
            title: "Error!",
            message: "Price regular must be greater than or equal to price merchant.",
          });
        }
      }

      if (parseFloat(formData.PriceSale) > parseFloat(formData.PriceRegular)) {
        isValid = false;
        if (showNotifications) {
          helpers.createNotification({
            type: "error",
            title: "Error!",
            message: "Price sale must be less than or equal to price regular.",
          });
        }
      }

      return isValid;
    };

    const getCurrencyList = async () => {
      if (isFetching) return;
      isFetching = true;

      currencyList.value = await get("/public/currency/list");
      isFetching = false;
    };

    const setPrice = () => {
      let isValid = validate(true);

      if (isValid && formData?.CurrencyId > 0) {
        f7.popup.close(".popup-setprice");

        let currencyInfo;
        currencyList.value.forEach((currencyItem) => {
          if (currencyItem.CurrencyId == formData.CurrencyId) {
            currencyInfo = currencyItem;
          }
        });

        emit("set", {
          type: inputGroup.value.value,
          data: {
            ...formData,
            CurrencyName: currencyInfo?.CurrencyName,
            CurrencyCode: currencyInfo?.CurrencyCode,
            CurrencySymbol: currencyInfo?.CurrencySymbol,
          },
        });
      }
    };

    return {
      formData,
      validationRules,
      inputGroup,
      currencyList,
      statusList,
      getCurrencyList,
      setPrice,
    };
  },
  methods: {
    async openPopup(inputGroup, data) {
      const self = this;
      self.inputGroup = inputGroup;

      helpers.clearFormData(self.formData);
      helpers.resetForm("#formCurrency");

      window.setTimeout(() => {
        if (self.formData.CurrencyId == "" && self?.currencyList?.[0]?.CurrencyId) {
          self.formData.CurrencyId = self.currencyList[0].CurrencyId;
        }

        if (data && data?.CurrencyId) {
          Object.assign(self.formData, { ...data });
        }

        if (self.formData.StatusCode == "") {
          self.formData.StatusCode = "ACTIVE";
        }

        f7.popup.open(`.popup-setprice`);
        self.$emit("opened", true);
      }, 10);
    },
    closePopup() {
      f7.popup.close(".popup-setprice");
      this.$emit("closed", true);
    },
  },
});
</script>

<style scoped>
.popup.popup-setprice {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;

  /* POPUP CONFIG */
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 500px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;

  transition: all 300ms ease-in-out;
}
.popup.popup-setprice.popup-fullscreen {
  --f7-popup-tablet-width: 100%;
  transform: translate3d(100vw, 0, 0);
}
.popup.popup-setprice.modal-in {
  transform: translate3d(0, 0, 0);
}
.popup.popup-setprice.modal-out {
  transform: translate3d(100vh, 0, 0);
}
.popup.popup-setprice .navbar a.link {
  padding: 0 10px;
  min-width: 20px;
}
.popup.popup-setprice .navbar .title {
  padding-left: 10px;
}
.popup.popup-setprice .selectitem-container {
  width: calc(100% - 80px);
  margin: 10px auto;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 60px;
}
.popup.popup-setprice .selectitem-container .selectitem {
  padding: 12px 0px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.popup.popup-setprice .selectitem-container .selectitem .checkbox {
  margin-top: 5px;
  margin-right: 20px;
}
.popup.popup-setprice .selectitem-container .selectitem .checkbox.disabled {
  opacity: 1 !important;
}
.popup.popup-setprice .selectitem-container .selectitem img {
  width: 40px;
  height: auto;
  border-radius: 8px;
  margin-right: 15px;
}
.popup.popup-setprice .selectitem-container .selectitem h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
}
.popup.popup-setprice .selectitem-container .selectitem p {
  margin: 0px;
  padding: 0px;
}
.footer-button-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 55px);
  background: #fff;
  padding: 18px 30px;
  z-index: 999;
  border-top: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer-button-container .checkbox-container {
  font-weight: bold;
  text-transform: uppercase;
}
.footer-button-container .checkbox-container .checkbox {
  margin-right: 10px;
}
.footer-button-container .button {
  width: 100%;
}
.popup.popup-setprice .empty-container {
  margin-top: 60px;
}
</style>
