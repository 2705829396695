<template>
  <f7-popup id="saveUserGroup" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="false">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <FormComponent ref="formComponent" @successSave="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { f7 } from "framework7-vue";

import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";

const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "UserGroupSavePartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const title = ref("");
    const $t = inject("$translation");

    const formComponentData = {
      buttonText: "",
      getLink: "/admin/usergroup/view",
      getParams: {},
      validation: "usergroup",
      saveLink: "/admin/usergroup/save",
      saveParams: {},
      successCreateMessage: $t.getTranslation("LBL_SUCCESS_CREATE_USER_GROUP"),
      successUpdateMessage: $t.getTranslation("LBL_SUCCESS_UPDATE_USER_GROUP"),
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("LBL_USER_GROUP_INFORMATION"),
        type: "input",
        list: [
          { name: $t.getTranslation("LBL_GROUP_NAME"), type: "text", value: "Name", required: true },
          { name: $t.getTranslation("LBL_GROUP_LINK_CODE"), type: "text", value: "LinkCode", required: true },
          { name: $t.getTranslation("LBL_COMMISSION"), type: "number", value: "Commission", required: true },
          {
            name: $t.getTranslation("LBL_COMMISSION_TYPE"),
            type: "select",
            value: "CommissionTypeCode",
            required: true,
            items: store.getters["config/getData"]?.commissionTypeList,
            nameSelector: "Name",
            valueSelector: "Code",
          },
        ],
      },
      {
        name: $t.getTranslation("LBL_USER_LIST"),
        type: "selectmultiple",
        value: "User",
        required: false,
        buttonText: $t.getTranslation("LBL_SELECT_USER"),
        title: $t.getTranslation("LBL_SELECT_USER"),
        params: {
          type: "dynamic",
          getLink: "/public/user/datalist",
          getParams: {},
          nameSelector: "Title",
          descriptionSelector: "Description",
          valueSelector: "UserId",
          imageSelector: "Image",
          imageBucket: "USER",
        },
      },
      {
        name: $t.getTranslation("LBL_STATUS_INFORMATION"),
        type: "input",
        list: [{ name: "Status", type: "select", value: "StatusCode", required: false, hideClearButton: true, items: store.getters["config/getData"]?.statusList, nameSelector: "Name", valueSelector: "Code", defaultValue: "ACTIVE" }],
      },
    ];

    return {
      title,
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup(data) {
      const self = this;
      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);

      if (data?.UserGroupId) {
        self.title = this.$t.getTranslation("LBL_UPDATE_USER_GROUP");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_UPDATE_USER_GROUP");
        self.formComponentData.getParams.UserGroupId = data?.UserGroupId;
        self.formComponentData.saveParams.UserGroupId = data?.UserGroupId;

        self.$refs.formComponent.setFormComponentData(self.formComponentData, true);
      } else {
        self.title = this.$t.getTranslation("LBL_CREATE_USER_GROUP");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_CREATE_USER_GROUP");
        self.formComponentData.getParams = {};
        self.formComponentData.saveParams = {};

        self.$refs.formComponent.setFormComponentData(self.formComponentData);
      }

      f7.popup.open("#saveUserGroup");
      self.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#saveUserGroup");
      this.$emit("closed", data);
    },
  },
});
</script>
