<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'file']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_REPORT") }}</h1>
        </div>
      </div>
    </div>

    <div class="report-container">
      <div class="report-options-container">
        <h3>{{ $t.getTranslation("LBL_REPORT_TYPE") }}</h3>
        <f7-list form no-hairlines no-hairlines-between>
          <f7-list-input
            v-model:value="formData.ReportType"
            type="select"
            name="ReportType"
            :label="$t.getTranslation('LBL_REPORT_TYPE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_REPORT_TYPE_INFO')"
            floating-label
            outline
            validate
            @change="reportTypeChange"
          >
            <option v-for="reportType in reportTypeList" :key="'rpt_' + reportType.value" :value="reportType.value">{{ $t.getTranslation(reportType.name) }}</option>
          </f7-list-input>
        </f7-list>

        <template v-if="formData.ReportType != ''">
          <h3>{{ $t.getTranslation("LBL_REPORT_OPTIONS") }}</h3>

          <f7-list form no-hairlines no-hairlines-between>
            <f7-list-input
              v-if="reportOptionInfo?.dateRangeType && reportOptionInfo?.dateRangeType?.list"
              v-model:value="formData.DateRangeType"
              type="select"
              name="DateRangeType"
              :label="$t.getTranslation('LBL_DATE_RANGE_TYPE')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_DATE_RANGE_TYPE_INFO')"
              floating-label
              outline
              validate
            >
              <option v-for="dateRange in reportOptionInfo?.dateRangeType?.list" :key="'dr_' + dateRange.value" :value="dateRange.value">{{ $t.getTranslation(dateRange.name) }}</option>
            </f7-list-input>
            <f7-list-input
              v-model:value="formData.StartDate"
              type="datepicker"
              name="StartDate"
              :label="$t.getTranslation('LBL_START_DATE')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_START_DATE_INFO')"
              floating-label
              outline
              validate
            >
            </f7-list-input>
            <f7-list-input
              v-model:value="formData.EndDate"
              type="datepicker"
              name="EndDate"
              :label="$t.getTranslation('LBL_END_DATE')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_END_DATE_INFO')"
              floating-label
              outline
              validate
            >
            </f7-list-input>
            <f7-list-input
              v-model:value="formData.Limit"
              type="text"
              name="Limit"
              :label="$t.getTranslation('LBL_LIMIT_RANGE')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :info="$t.getTranslation('LBL_LIMIT_RANGE_INFO')"
              floating-label
              outline
              validate
            >
            </f7-list-input>
          </f7-list>

          <div class="filter-container">
            <h3>{{ $t.getTranslation("LBL_FILTER_OPTIONS") }}</h3>

            <f7-list form no-hairlines no-hairlines-between>
              <f7-list-input v-model:value="formData.FilterType" name="FilterType" :label="$t.getTranslation('LBL_FILTER_TYPE')" floating-label outline type="select" :placeholder="$t.getTranslation('LBL_FILTER_TYPE_PLACEHOLDER')">
                <template v-if="reportOptionInfo?.filter?.list">
                  <option v-for="(item, key) in reportOptionInfo?.filter?.list" :key="'frt_' + key" :value="key">{{ item.Name || $h.formatConfigValue(key) }}</option>
                </template>
              </f7-list-input>

              <template v-if="filterInput?.InputType === 'datepicker'">
                <f7-list-input
                  v-model:value="formData.FilterValue"
                  :label="$t.getTranslation('LBL_FILTER_VALUE')"
                  :calendar-params="{ dateFormat: 'mm/dd/yy', rangePicker: true }"
                  :placeholder="$t.getTranslation('LBL_FILTER_VALUE_PLACEHOLDER')"
                  name="FilterValue"
                  floating-label
                  outline
                  type="datepicker"
                ></f7-list-input>
              </template>
              <template v-else-if="filterInput?.InputType === 'range'">
                <f7-list-input v-model:value="formData.FilterValue[0]" name="FilterValueMinimum" :label="$t.getTranslation('LBL_MIN')" floating-label outline type="number" class="pricerange" placeholder="0"> </f7-list-input>
                <f7-list-input v-model:value="formData.FilterValue[1]" name="FilterValueMaximum" :label="$t.getTranslation('LBL_MAX')" floating-label outline type="number" class="pricerange" placeholder="0"> </f7-list-input>
              </template>
              <template v-else>
                <f7-list-input
                  v-model:value="formData.FilterValue"
                  name="FilterValue"
                  :label="$t.getTranslation('LBL_FILTER_VALUE')"
                  floating-label
                  outline
                  :type="filterInput?.InputType"
                  :placeholder="$t.getTranslation('LBL_FILTER_VALUE_PLACEHOLDER')"
                >
                  <template v-if="filterInput?.InputItems && $h.isArray(filterInput?.InputItems) && filterInput?.InputItems.length > 0">
                    <option v-for="item in filterInput?.InputItems" :key="'frv_' + item.Code" :value="item.Code || item.Value">{{ item.Name }}</option>
                  </template>
                </f7-list-input>
              </template>
            </f7-list>

            <f7-button fill large raised :disabled="formData.FilterType === '' || formData.FilterValue === ''" class="button-apply" @click="onAddFilter"> {{ $t.getTranslation("LBL_APPLY_FILTER") }} </f7-button>

            <ul v-if="filterList?.length > 0" class="filterlist-container">
              <li v-for="(filter, index) in filterList" :key="'frv_' + index">
                <strong>{{ $h.formatConfigValue(filter.FilterType) }}</strong>
                <p v-if="!Array.isArray(filter?.FilterValue)">{{ filter.FilterValue }}</p>
                <p v-else>
                  <template v-if="filter.FilterType.indexOf('Last') > -1 || filter.FilterType.indexOf('Date') > -1"> {{ $h.formatDate(filter.FilterValue[0]) }} - {{ $h.formatDate(filter.FilterValue[1]) }} </template>
                  <template v-else> {{ filter.FilterValue[0] }} - {{ filter.FilterValue[1] }} </template>
                </p>

                <div class="button-container">
                  <f7-button small raised fill @click="onEditFilter(filter)">{{ $t.getTranslation("LBL_EDIT") }} </f7-button>
                  <f7-button small raised fill color="red" @click="onDeleteFilter(filter, index)">{{ $t.getTranslation("LBL_DELETE") }} </f7-button>
                </div>
              </li>
            </ul>
          </div>

          <f7-button fill large raised class="button-generate" preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isButtonDisabled" @click="generateReport"> {{ $t.getTranslation("LBL_GENERATE_REPORT") }}</f7-button>

          <div v-if="generatedReportInfo && generatedReportInfo?.FileName != ''" class="generated-report-container">
            <h3>{{ $t.getTranslation("LBL_REPORT_GENERATED") }}</h3>
            <f7-button fill large raised class="button-generate" @click="downloadReport"> {{ $t.getTranslation("LBL_DOWNLOAD_REPORT") }}</f7-button>
          </div>
        </template>
      </div>

      <div v-if="formData.ReportType != ''" class="report-list-container">
        <h3>{{ $t.getTranslation("LBL_GENERATED_REPORT_LIST") }}</h3>
        <DataTableComponent ref="dataTableComponent" :columns="columns" :url="url" :size="15" @downloadItem="downloadItem" />
      </div>
    </div>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, inject, onMounted, computed, watch } from "vue";

import { post, get } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { configs } from "@/utils/configs";
import { socket } from "@/utils/socket";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

import DataTableComponent from "@/components/DataTableComponent.vue";

export default defineComponent({
  name: "ReportPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const reportTypeList = ref([]);
    const reportOptionInfo = ref({});
    const isButtonProcessing = ref(false);
    const generatedReportInfo = ref(false);

    const formData = reactive({
      ReportType: "",
      DateRangeType: "",
      StartDate: "",
      EndDate: "",
      Limit: "",
      FilterType: "",
      FilterValue: [],
    });

    const filterInput = reactive({
      InputType: "text",
      filterInput: [],
    });

    const filterList = ref([]);

    const getReportTypeList = async () => {
      reportTypeList.value = await get(`/report/type/list`);
      if (formData?.ReportType === "" && reportTypeList.value.length > 0) {
        formData.ReportType = reportTypeList.value[0].value;
        getReportOptionInfo();
      }
    };

    const getReportOptionInfo = async () => {
      generatedReportInfo.value = false;
      filterList.value = [];

      formData.FileType = "";
      formData.FilterValue = [];

      filterInput.InputType = "text";
      filterInput.filterInput = [];

      reportOptionInfo.value = await get(`/report/option/view`, { Type: formData.ReportType });
    };

    onMounted(() => {
      getReportTypeList();
    });

    const validate = () => {
      let isValid = false;
      if (formData.ReportType != "" && formData.DateRangeType != "" && formData.StartDate != "" && formData.EndDate != "") {
        isValid = true;
      }

      return isValid;
    };

    const isButtonDisabled = computed(() => {
      let isValid = validate();
      return !isValid;
    });

    const downloadItem = (data) => {
      if (data?.value?.StatusCode === "ACTIVE" && data?.value?.FileName != "") {
        let reportFileLink = `${configs.baseURL}/report/download?FileName=${data?.value?.FileName}&FileType=${data?.value?.Type}`;
        window.open(reportFileLink);
      }
    };

    const downloadReport = () => {
      if (generatedReportInfo?.value?.FileName != "") {
        let reportFileLink = `${configs.baseURL}/report/download?FileName=${generatedReportInfo?.value?.FileName}&FileType=${generatedReportInfo?.value?.FileType}`;
        window.open(reportFileLink);
      }
    };

    // FOR FILTER
    const onAddFilter = () => {
      if (formData?.FilterType != "" && formData?.FilterValue != "") {
        for (let item of filterList.value) {
          if (item.FilterType == formData?.FilterType) {
            item.FilterValue = formData?.FilterValue;
            return;
          }
        }

        filterList.value.push({ FilterType: formData?.FilterType, FilterValue: formData?.FilterValue });
      }
    };

    const onEditFilter = (filter) => {
      formData.FilterType = filter.FilterType;
      setTimeout(() => {
        formData.FilterValue = filter.FilterValue;
      }, 100);
    };

    const onDeleteFilter = (filter, index) => {
      filterList.value.splice(index, 1);
    };

    watch(
      () => formData.FilterType,
      (newValue) => {
        filterInput.InputType = reportOptionInfo?.value?.filter?.list?.[newValue]?.Type || "text";
        filterInput.InputItems = reportOptionInfo?.value?.filter?.list?.[newValue]?.Items || [];

        if (filterInput.InputType === "datepicker") {
          formData.FilterValue = [];
        } else if (filterInput.InputType === "range") {
          formData.FilterValue = [null, null];
        } else {
          formData.FilterValue = "";
        }
      }
    );

    // FOR DATA TABLE LIST
    const url = computed(() => {
      return `/report/list?Type=${formData.ReportType}`;
    });

    const columns = [
      {
        title: $t.getTranslation("LBL_REPORT_ID"),
        field: "ReportId",
        minWidth: 100,
      },
      {
        title: $t.getTranslation("LBL_REPORT_TYPE"),
        field: "Type",
        minWidth: 220,
      },
      {
        title: $t.getTranslation("LBL_DATE_RANGE"),
        minWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_START")}:</strong> ${helpers.formatDateTime(cell.getData().StartDate)}</div>
              <div><strong>${$t.getTranslation("LBL_END")}:</strong> ${helpers.formatDateTime(cell.getData().EndDate)}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_FILE_NAME"),
        field: "FileName",
        minWidth: 250,
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        formatter(cell) {
          return `
            <div>
              <div><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    return {
      reportTypeList,
      reportOptionInfo,
      generatedReportInfo,
      formData,
      filterInput,
      filterList,
      url,
      columns,
      isButtonProcessing,
      isButtonDisabled,
      getReportOptionInfo,
      downloadItem,
      downloadReport,
      onAddFilter,
      onEditFilter,
      onDeleteFilter,
    };
  },
  mounted() {
    const self = this;
    socket.assignSocketCallback("RECEIVED_MSG", (data) => {
      if (data?.Mode === "REPORT_GENERATED") {
        self.generatedReportInfo = data;
        self.reinitTable();
      }
    });
  },
  methods: {
    reinitTable() {
      this.$refs.dataTableComponent.initTabulator();
    },
    async generateReport() {
      this.isButtonProcessing = true;

      await post(`/report/generate`, {
        ...this.formData,
        FilterType: "MULTIPLE",
        FilterValue: JSON.stringify(this.filterList),
      });

      this.isButtonProcessing = false;

      this.reinitTable();
    },
    async reportTypeChange() {
      const self = this;
      self.getReportOptionInfo();

      window.setTimeout(() => {
        self.reinitTable();
      }, 100);
    },
  },
});
</script>

<style scoped></style>
