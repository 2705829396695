<template>
  <f7-panel id="leftPanel" left push :visible-breakpoint="1800">
    <f7-page>
      <f7-block class="logo">
        <img src="@/assets/images/logo.png" alt="Logo White" />
      </f7-block>

      <f7-block class="navigations">
        <f7-treeview>
          <f7-treeview-item
            v-for="navigation in navigationLists"
            v-show="navigation.isShown"
            :key="'nav_' + navigation.title"
            :selected="activeLink(navigation)"
            :opened="activeLink(navigation)"
            :link="navigation.link"
            :label="navigation.title"
            :item-toggle="haschild(navigation)"
            :class="{ haschild: haschild(navigation) }"
            :panel-close="!haschild(navigation)"
            popup-close
          >
            <template v-if="navigation?.icon" #media>
              <font-awesome-icon :icon="['fad', navigation.icon]" fixed-width />
            </template>

            <template v-if="haschild(navigation)">
              <f7-treeview-item
                v-for="childNavigation in navigation.child"
                v-show="childNavigation.isShown"
                :key="'nav_' + childNavigation.title"
                :selected="activeLink(childNavigation)"
                :link="childNavigation.link"
                :label="childNavigation.title"
                :item-toggle="haschild(childNavigation)"
                :class="{ haschild: haschild(childNavigation) }"
                :panel-close="!haschild(childNavigation)"
                popup-close
              ></f7-treeview-item>
            </template>
          </f7-treeview-item>
        </f7-treeview>
      </f7-block>

      <f7-link class="logout" @click="logout">
        <font-awesome-icon :icon="['far', 'sign-out']" fixed-width />
      </f7-link>
    </f7-page>
  </f7-panel>
</template>

<script>
import { getNavigationLists } from "@/routes";
import { computed } from "vue";

import { useStore } from "@/store";

import { logout } from "@/logics/auth.js";

export default {
  name: "LeftPanelComponent",
  components: {},
  props: {},
  setup() {
    const store = useStore();

    const navigationLists = computed(() => getNavigationLists(store.getters["user/getData"]));

    const activeLink = computed(() => {
      return (navigation) => {
        return store.getters["page/getActiveLink"] == navigation.name || store.getters["page/getActiveLinkParent"] == navigation.name;
      };
    });

    const haschild = (navigation) => {
      return Array.isArray(navigation?.child) && navigation?.child.length > 0;
    };

    return { activeLink, navigationLists, haschild, logout };
  },
};
</script>
