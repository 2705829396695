<template>
  <div class="view-info">
    <h2>{{ title }}</h2>

    <div v-if="data && data.length > 0" ref="imageMasonryRef" class="image-container">
      <template v-for="datum in data" :key="'img_' + datum.field">
        <div v-if="datum.link.indexOf('placeholder') <= -1" class="image">
          <img :src="datum.link" :alt="datum.field + 'Image'" @click="$h.openImage(datum.link)" />
          <h3>{{ datum.name === "auto" ? $h.formatPascalCase(datum.field) : datum.name }}</h3>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";

import Masonry from "masonry-layout";

export default defineComponent({
  name: "ViewImageListComponent",
  props: {
    title: String,
    data: Array,
  },
  setup() {
    const imageMasonry = ref();
    const imageMasonryRef = ref();

    const initMasonry = () => {
      imageMasonry.value = new Masonry(imageMasonryRef.value, {
        columnWidth: ".image",
      });
    };

    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        window.setTimeout(initMasonry, 1500);
      });
    };

    onMounted(() => {
      window.setTimeout(initMasonry, 1500);
      reInitOnResizeWindow();
    });

    return { imageMasonryRef };
  },
});
</script>
<style></style>
