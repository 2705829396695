<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation("LBL_DASHBOARD") }}</h1>
        </div>
      </div>
    </div>

    <BarChartComponent />
    <BarChartTableComponent :columns="columns" :size="10" :url="url" @viewItem="editItem"></BarChartTableComponent>

    <MainFooterComponent />

    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcel($event)" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject } from "vue";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import BarChartTableComponent from "@/components/BarChartTableComponent.vue";
import BarChartComponent from "@/components/BarChartComponent.vue";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "DashboardMainPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    BarChartTableComponent,
    BarChartComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const isUploading = ref(false);
    const hideImport = ref(false);

    const columns = [
      {
        title: $t.getTranslation("LBL_NAME"),
        maxWidth: 320,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Name}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRODUCT"),
        field: "Category",
      },
      {
        title: $t.getTranslation("LBL_PRICE"),
        field: "RegularSellingPrice",
      },
      {
        title: $t.getTranslation("LBL_TOTAL"),
        field: "LastCreated",
      },
    ];

    const editItem = (data) => {
      if (data?.value?.ProductKey && props?.f7route?.query?.isFeatured) {
        props.f7router.navigate({ name: "productMarketing", query: { isFeatured: 1, key: data.value.ProductKey } });
        return;
      }

      if (data?.value?.ProductKey && !props?.f7route?.query?.setWeekly) {
        props.f7router.navigate({ name: "productView", params: { key: data.value.ProductKey } });
        return;
      } else {
        props.f7router.navigate({ name: "productRecommendedSave", params: { key: data?.value?.ProductKey } });
      }
    };

    const uploadHKRetail = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const uploadExcel = async (event) => {
      let input = event.target;

      if (input?.files?.[0]) {
        let file = input?.files?.[0];
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/);
        if (!isValidFile) throw new Error($t.getTranslation("LBL_ERR_INVALID_FILE"));

        //upload now
        isUploading.value = true;

        const formData = new FormData();
        formData.append("file", file);

        let ret = await $HTTP.post("/admin/product/hkretail", formData);
        isUploading.value = false;

        if (ret) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_UPLOAD") });
        }
      }
    };

    let url = `/admin/product/list?LanguageCode=${currentLanguageCode.value}`;
    if (props?.f7route?.query?.setWeekly) {
      url += "&NonWeeklyRecommended=1";
      hideImport.value = true;
    }

    if (props?.f7route?.query?.setFeatured) {
      url += "&mode=NONFEATURED";
      hideImport.value = true;
    }

    return {
      isUploading,
      uploadExcel,
      uploadHKRetail,
      columns,
      url,
      editItem,
      currentLanguageCode,
      hideImport,
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}

input[type="file"] {
  display: none;
}
</style>
