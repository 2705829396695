<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'users']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_USER_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>
    </div>

    <div class="board-container">
      <div class="board">
        <h3>{{ $t.getTranslation("LBL_TOTAL_USER") }}</h3>
        <h1>{{ $h.formatNumber(userInfo?.TotalUser) || 0 }}</h1>
      </div>
      <div class="board">
        <h3>{{ $t.getTranslation("LBL_TOTAL_WEBSITE_USER") }}</h3>
        <h1>{{ $h.formatNumber(userInfo?.TotalWebsiteUser) || 0 }}</h1>
      </div>
      <div class="board">
        <h3>{{ $t.getTranslation("LBL_TOTAL_MINI_PROGRAM_USER") }}</h3>
        <h1>{{ $h.formatNumber(userInfo?.TotalMiniProgramUser) || 0 }}</h1>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" class="has-board" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <UserViewPartial ref="userViewPartial" :f7router="f7router" />
    <UserCustomerSupportSavePartial ref="userCustomerSupportSavePartial" :f7router="f7router" />
    <UserReferralCodeSavePartial ref="userReferralCodeSavePartial" :f7router="f7router" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

// PARTIALS
import UserViewPartial from "@/partials/user/UserViewPartial.vue";
import UserCustomerSupportSavePartial from "@/partials/user/UserCustomerSupportSavePartial.vue";
import UserReferralCodeSavePartial from "@/partials/user/UserReferralCodeSavePartial.vue";

import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "UserListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    // PARTIALS
    UserViewPartial,
    UserCustomerSupportSavePartial,
    UserReferralCodeSavePartial,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const userInfo = ref({
      TotalUser: 0,
      TotalWebsiteUser: 0,
      TotalMiniProgramUser: 0,
    });

    const actions = [
      {
        groupText: "Perform Actions",
        list: [
          { text: "View Information", type: "VIEW", bold: true },
          { text: "View Chat Log", type: "VIEWCHATLOG", bold: false },
          { text: "View Activity Log", type: "VIEWACTIVITYLOG", bold: false },
          { text: "View Marked Order List", type: "VIEWMARKORDER", bold: false },
          { text: "View User Voucher List", type: "VIEWUSERVOUCHER", bold: false },
        ],
      },
      {
        groupText: "",
        list: [
          { text: "Update Referral Code", type: "UPDATEREFERRALCODE", bold: false },
          { text: "Assign Customer Support", type: "ASSIGNCUSTOMERSUPPORT", bold: false },
          { text: "Send Message", type: "SENDMESSAGE", bold: true },
        ],
      },
    ];

    const columns = [
      {
        title: "Id",
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().UserId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: "Image",
        minWidth: 80,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${helpers.getImage(cell.getData().Image, "USER")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_INFO"),
        minWidth: 150,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.getFullName(cell.getData(), true)}</strong></div>
              <div><strong>${cell.getData().UserName || ""}</strong></div>
              <div>${cell.getData().UserKey}</div>
              <div>
                ${!helpers.isBlank(cell.getData()?.Gender) ? `<div class='chip ${cell.getData()?.Gender == "MALE" ? "color-teal" : "color-pink"} '><div class='chip-label'>${cell.getData()?.Gender}</div></div>` : ""}
                ${!helpers.isBlank(cell.getData()?.BirthDate) ? "<div class='chip color-gray'><div class='chip-label'>" + helpers.formatDate(cell.getData()?.BirthDate) + "</div></div>" : ""}
                ${!helpers.isBlank(cell.getData()?.RegistrationType) ? `<div class='chip color-teal'><div class='chip-label'>${cell.getData()?.RegistrationType}</div></div>` : ""}
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_MARKED_ORDER"),
        minWidth: 130,
        maxWidth: 130,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().TotalUserOrdered}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_CONTACT_INFO"),
        minWidth: 150,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().MobileCode || ""} ${cell.getData().MobileNumber || "0000000000"}</strong>
              <div>${cell.getData().Email || "--"}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_ADDRESS_INFO"),
        minWidth: 150,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().IdNumber || ""}</strong>
              <div>${cell.getData()?.CountryCode != null ? "<div class='chip color-blue'><div class='chip-label'>" + cell.getData()?.CountryCode + "</div></div>" : ""}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_GROUP_INFO"),
        minWidth: 150,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().UserGroupName || ""}</strong>
              <div>${cell.getData().LinkCode || "--"}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_REFERRER_INFO"),
        minWidth: 150,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_REFERRAL_NAME")}:</strong>
              ${cell.getData()?.ReferralUserId ? cell.getData()?.ReferralUserName || cell.getData()?.ReferralEmail : "---"}
              </div>
              <div><strong>${$t.getTranslation("LBL_REFERRAL_CODE")}:</strong> ${cell.getData()?.ReferralsCode || "---"}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_WECHAT_INFO"),
        minWidth: 150,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().OpenId || ""}</strong>
              <div>${cell.getData()?.IsCompleted > 0 ? "<div class='chip color-blue'><div class='chip-label'>COMPLETED</div></div>" : ""}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_ASSIGNED_CS"),
        minWidth: 150,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().AssignedCustomerSupport || ""}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.UserStatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.UserStatusCode || "--"}</div>
              </div>
              <br />
              ${cell.getData()?.IsNewsletter > 0 ? "<div class='chip color-blue'><div class='chip-label'>NEWSLETTER</div></div>" : ""}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="Date Created"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="Date Updated"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_COMMISSION_INFO"),
        minWidth: 150,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_KOL")}:</strong> <span title="${$t.getTranslation("LBL_PENDING")}">$${cell.getData()?.PendingKOLCommission || 0} </span> / <span title="${$t.getTranslation("LBL_AVAILABLE")}">$${
            cell.getData()?.AvailableKOLCommission || 0
          }</span></div>
              <div><strong>${$t.getTranslation("LBL_GROUP")}:</strong> <span title="${$t.getTranslation("LBL_PENDING")}">$${cell.getData()?.PendingGroupCommission || 0} </span> / <span title="${$t.getTranslation("LBL_AVAILABLE")}">$${
            cell.getData()?.AvailableGroupCommission || 0
          }</span></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_COUNT_INFO"),
        minWidth: 150,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_REFERRED_USERS")}:</strong> ${cell.getData()?.TotalReferredUsers || 0}</div>
              <div><strong>${$t.getTranslation("LBL_REFERRED_ORDERS")}:</strong> ${cell.getData()?.TotalReferredOrders || 0}</div>
            </div>
          `;
        },
      },
    ];

    const url = ref("/admin/user/datalist?UserTypeCode=USER");

    if (props?.f7route?.query?.referrer && props?.f7route?.query?.referrer != "") {
      url.value += `&ReferrerKey=${props?.f7route?.query?.referrer}`;
    } else if (props?.f7route?.query?.referrergroup && props?.f7route?.query?.referrergroup != "") {
      url.value += `&GroupLinkCode=${props?.f7route?.query?.referrergroup}`;
    }

    (async () => {
      let returnData = await get("/admin/user/counter/view");
      userInfo.value = returnData;
    })();

    return {
      url,
      columns,
      actions,
      userInfo,
    };
  },
  methods: {
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.value?.UserId) {
            self.$refs.userViewPartial.openPopup(data?.value?.UserId);
          }
          break;
        case "VIEWCHATLOG":
          if (data?.value?.UserId) {
            self.f7router.navigate({ name: "userChatLog", query: { UserId: data?.value?.UserId } });
          }
          break;
        case "VIEWACTIVITYLOG":
          if (data?.value?.UserId) {
            self.f7router.navigate({ name: "userActivityLog", query: { UserId: data?.value?.UserId } });
          }
          break;
        case "VIEWMARKORDER":
          if (data?.value?.UserId) {
            self.f7router.navigate({ name: "orderList", query: { type: "markedorder", UserId: data?.value?.UserId } });
          }
          break;
        case "VIEWUSERVOUCHER":
          if (data?.value?.UserId) {
            self.f7router.navigate({ name: "userUserVoucherList", query: { UserId: data?.value?.UserId } });
          }
          break;
        case "ASSIGNCUSTOMERSUPPORT":
          if (data?.value?.UserId) {
            self.$refs.userCustomerSupportSavePartial.openPopup(data?.value);
          }
          break;
        case "UPDATEREFERRALCODE":
          if (data?.value?.UserId) {
            self.$refs.userReferralCodeSavePartial.openPopup(data?.value);
          }
          break;
        case "SENDMESSAGE":
          if (data?.value?.UserKey) {
            helpers.createRemarks({
              title: "Send Message",
              message: "Enter a message to start conversation with selected user.",
              confirm: async (remarks) => {
                if (remarks != "") {
                  let ret = await post("/chat/admin/thread", { ThreadKey: data?.value?.UserKey });

                  await post("/chat/send", {
                    ThreadKey: ret?.ThreadKey,
                    Content: remarks,
                    ContentType: "TEXT",
                  });

                  self.f7router.navigate({ name: "messages", query: { ThreadKey: ret.ThreadKey } });
                }
              },
            });
          }
          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
