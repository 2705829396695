<template>
  <div class="footer-copyright">
    <div class="container">
      <div>
        <p>&copy; {{ currentYear }} {{ $t.getTranslation("LBL_PJF_WINE") }}. {{ $t.getTranslation("LBL_ALL_RIGHTS_RESERVED") }}.</p>
        <p>
          <f7-link :href="$configs.externalPageLinks.termsAndConditions" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_TERMS_AND_CONDITIONS") }}</f7-link>
          &
          <f7-link :href="$configs.externalPageLinks.privacyPolicy" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_PRIVACY_POLICY") }}</f7-link>
        </p>
      </div>

      <ul class="language">
        <li v-for="language in languageList" :key="'lg_' + language.Code" :class="{ active: language.Code === currentLanguageCode }" @click="changeLanguage(language.Code)">{{ language.Code }}</li>
      </ul>
    </div>
  </div>

  <transition name="fade">
    <div v-if="showScrollTop" class="scroll-top">
      <a href="javascript:;" @click="scrollToTop">
        <font-awesome-icon :icon="['far', 'angle-up']" fixed-width />
      </a>
    </div>
  </transition>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";
import { f7 } from "framework7-vue";

import { useStore } from "@/store";

export default defineComponent({
  name: "MainFooterComponent",
  components: {},
  setup() {
    const store = useStore();

    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const languageList = computed(() => store.getters["config/getData"]?.languageList);

    const currentYear = new Date().getFullYear();
    const showScrollTop = ref(false);

    const scrollToTop = () => {
      let page = f7.$(".page-content");
      page.scrollTo(0, 0);
    };

    const changeLanguage = (languageCode) => {
      store.dispatch("translation/setLanguage", { languageCode });
      store.dispatch("translation/fetchData", { force: true });

      window.location.reload();
    };

    onMounted(() => {
      f7.$(".page-content").on("scroll", (event) => {
        if (event && event.target && event.target.scrollTop && event.target.scrollTop > 300) {
          showScrollTop.value = true;
        } else {
          showScrollTop.value = false;
        }
      });
    });

    return {
      currentLanguageCode,
      languageList,
      changeLanguage,
      currentYear,
      showScrollTop,
      scrollToTop,
    };
  },
});
</script>
