<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation('LBL_PRODUCT_DETAILS') }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button @click="updateProductCritic">
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
          {{ $t.getTranslation('LBL_CRITIC_SCORE') }}
        </f7-button>
        <f7-button @click="updateInventory">
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
          {{ $t.getTranslation('LBL_INVENTORY') }}
        </f7-button>
        <f7-button @click="updateProductTax">
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
          {{ $t.getTranslation('LBL_PRODUCT_TAX') }}
        </f7-button>

        <f7-button @click="marketing">
          <font-awesome-icon :icon="['far', 'edit']" fixed-width />
          {{ $t.getTranslation('LBL_EDIT') }}
        </f7-button>

        <f7-button @click="confirm">
          <!-- <font-awesome-icon :icon="['far', 'edit']" fixed-width /> -->
          <span v-if="['DISABLED', 'PENDING', 'DELETED'].indexOf(StatusCode) > -1">
            {{ $t.getTranslation('LBL_SET_ACTIVE') }}
          </span>
          <span v-else>
            {{ $t.getTranslation('LBL_SET_DISABLE') }}
          </span>
        </f7-button>

        <f7-button @click="view">
          <!-- <font-awesome-icon :icon="['far', 'edit']" fixed-width /> -->
          {{ $t.getTranslation('LBL_VIEW') }}
        </f7-button>

        <!-- <f7-button @click="confirmFeatured">
           <span v-if="isFeatured == 1">
             {{ $t.getTranslation("LBL_REMOVE_FEATURED") }}
          </span>
          <span v-else>
            {{ $t.getTranslation("LBL_SET_FEATURED") }}
          </span>
        </f7-button> -->
      </div>
    </div>
    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_PRODUCT_DETAILS')" :data="productDetails" :fields="productDetailsFields" />
      </div>
    </section>
    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_PRODUCT_SIZE_AND_WEIGHT_DETAILS')" :data="productWeightandSize" :fields="productWeightandSizeFields" />
      </div>
    </section>
    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_PRODUCT_PRICING')" :data="pricing" :fields="pricingFields" />
      </div>
    </section>
    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_INVENTORY_LOCATION')" :data="inventoryLocation" :fields="productInventoryLocationFields" />
      </div>
    </section>
    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_PRODUCT_IMAGES')" :data="productImages" :fields="productImagesFields" />
      </div>
    </section>
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, reactive } from 'vue'

import _ from 'lodash'
import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import ViewTableComponent from '@/components/ViewTableComponent.vue'
import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'
import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { useStore } from '@/store'
import { configs } from '@/utils/configs'
export default defineComponent({
  name: 'ProductDetailsPage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    ViewTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const productDetails = ref([])
    const productWeightandSize = ref([])
    const pricing = ref([])
    const StatusCode = ref({})
    const productImages = ref([])
    const isFeatured = ref({})
    const inventoryLocation = ref([])

    const productDetailsFields = ref([
      { field: 'SupplierCode', name: 'auto', format: 'string' },
      { field: 'Sku', name: 'auto', format: 'string' },
      { field: 'ProductTax', name: 'auto', format: 'string' },
      { field: 'ProductInventory', name: 'auto', format: 'string' },
      { field: 'Barcode', name: 'auto', format: 'string' },
      { field: 'Brand', name: 'auto', format: 'string' },
      { field: 'Name', name: 'auto', format: 'string' },
      { field: 'Description', name: 'auto', format: 'string' },
      { field: 'Category', name: 'auto', format: 'string' },
      { field: 'StoreCode', name: 'auto', format: 'string' },
      { field: 'StatusCode', name: 'auto', format: 'string' },
      { field: 'CountryOfOrigin', name: 'auto', format: 'string' },
      { field: 'VideoLink', name: 'auto', format: 'string' },
      { field: 'BundleFlag', name: 'auto', format: 'string' },
      { field: 'Cost', name: 'auto', format: 'string' },
      { field: 'CostCurrency', name: 'auto', format: 'string' },
      { field: 'PackingQty', name: 'auto', format: 'string' },
      { field: 'Moq', name: 'auto', format: 'string' }
    ])

    const productWeightandSizeFields = ref([
      { field: 'Size', name: 'auto', format: 'string' },
      { field: 'Length', name: 'auto', format: 'string' },
      { field: 'Width', name: 'auto', format: 'string' },
      { field: 'NetWeight', name: 'auto', format: 'string' },
      { field: 'GrossWeight', name: 'auto', format: 'string' },
      { field: 'PackingLength', name: 'auto', format: 'string' },
      { field: 'PackingWidth', name: 'auto', format: 'string' },
      { field: 'PackingHeight', name: 'auto', format: 'string' },
      { field: 'PackingWeight', name: 'auto', format: 'string' }
    ])

    const pricingFields = ref([
      { field: 'CurrencyCode', name: 'auto', format: 'string' },
      { field: 'RegularSellingPrice', name: 'auto', format: 'string' },
      { field: 'DiscountPrice', name: 'auto', format: 'string' },
      { field: 'GoldPrice', name: 'auto', format: 'string' },
      { field: 'SilverPrice', name: 'auto', format: 'string' },
      { field: 'BronzePrice', name: 'auto', format: 'string' }
    ])

    const productAttributeFields = ref([])

    const productImagesFields = ref([
      { field: 'Image', name: 'Image', format: 'image', bucket: 'PRODUCT' },
      { field: 'Position', name: 'auto', format: 'string' },
      { field: 'Type', name: 'auto', format: 'string' },
      { field: 'IsCover', name: 'auto', format: 'string' },
      { field: 'IsSmall', name: 'auto', format: 'string' }
    ])

    const productInventoryLocationFields = ref([
      { field: 'WareHouseCode', name: 'auto', format: 'string' },
      { field: 'WareHouseRegionCode', name: 'auto', format: 'string' },
      { field: 'SellableQty', name: 'auto', format: 'string' },
      { field: 'DefectiveQty', name: 'auto', format: 'string' }
    ])

    const updateInventory = () => {
      if (props?.f7route?.params?.key) {
        props.f7router.navigate({ name: 'prodInventoryView', query: { key: props?.f7route?.params?.key } })
      }
    }

    const marketing = () => {
      if (props?.f7route?.params?.key) {
        props.f7router.navigate({ name: 'productMarketing', query: { key: props?.f7route?.params?.key } })
      }
    }

    const updateProductTax = () => {
      if (props?.f7route?.params?.key) {
        props.f7router.navigate({ name: 'productTaxView', query: { key: props?.f7route?.params?.key } })
      }
    }

    const updateProductCritic = () => {
      if (props?.f7route?.params?.key) {
        props.f7router.navigate({ name: 'productCriticScore', query: { key: props?.f7route?.params?.key } })
      }
    }

    const view = () => {
      window.open(`${configs.appHost}shop/product/view/${props?.f7route?.params?.key}`, '_blank')
    }

    const confirm = async () => {
      let message = $t.getTranslation('MSG_CONFIRM_UPDATE_PRODUCT_STATUS_ACTIVE')
      let title = $t.getTranslation('LBL_CONFIRM_PRODUCT_ACTIVE')

      if (StatusCode.value == 'ACTIVE') {
        message = $t.getTranslation('MSG_CONFIRM_UPDATE_PRODUCT_STATUS_DISABLED')
        title = $t.getTranslation('LBL_CONFIRM_PRODUCT_DISBALED')
      }

      helpers.createConfirmation({
        message: message,
        title: title,
        confirm: () => {
          updateStatus()
        }
      })
    }

    const confirmFeatured = async () => {
      let message = $t.getTranslation('MSG_CONFIRM_UPDATE_PRODUCT_FEATURED')
      let title = $t.getTranslation('LBL_CONFIRM_PRODUCT_FEATURED')

      if (isFeatured.value == 1) {
        message = $t.getTranslation('MSG_CONFIRM_REMOVE_PRODUCT_FEATURED')
        title = $t.getTranslation('LBL_CONFIRM_REMOVE_PRODUCT_FEATURED')
      }

      helpers.createConfirmation({
        message: message,
        title: title,
        confirm: () => {
          updateStatus(true)
        }
      })
    }

    const updateStatus = async (featured) => {
      try {
        helpers.showLoader()
        let status = StatusCode.value
        //send update to server
        let obj = { ProductKey: props?.f7route?.params?.key, LanguageCode: currentLanguageCode.value }
        let url = `/admin/product/status`

        if (featured) {
          url = `/admin/product/featured`
          obj['IsFeatured'] = isFeatured.value
        } else {
          obj['StatusCode'] = status
        }

        let res = await $HTTP.post(url, obj)
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()
          helpers.createNotification({ type: 'success', title: $t.getTranslation('LBL_SUCCESS'), message: $t.getTranslation('MSG_SUCCESS_PRODUCT_UPDATE_STATUS') })
          await getProductDetails()
          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    const getProductDetails = async () => {
      try {
        helpers.showLoader()
        let res = await $HTTP.get(`/admin/product/view?&LanguageCode=${currentLanguageCode.value || 'EN'}&ProductKey=${props?.f7route?.params?.key}`)
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()
          res.data.data['ProductTax'] = (res.data.data?.ProductCategory?.TaxRate) ? res.data.data.ProductCategory.TaxRate : 0;
          let pickProductDetails = _.pick(res.data.data, [
            'SupplierCode',
            'Barcode',
            'Brand',
            'Name',
            'Description',
            'Category',
            'StoreCode',
            'StatusCode',
            'CountryOfOrigin',
            'VideoLink',
            'BundleFlag',
            'Cost',
            'CostCurrency',
            'PackingQty',
            'Moq',
            'ProductInventory',
            'IsFeatured',
            'Sku',
            'InventoryLocation',
            'ProductTax'
          ]);


          let pickWeightandSize = _.pick(res.data.data, ['Size', 'Length', 'Width', 'NetWeight', 'GrossWeight', 'PackingLength', 'PackingWidth', 'PackingHeight', 'PackingWeight'])
          let pickPricing = _.pick(res.data.data, ['CurrencyCode', 'RegularSellingPrice', 'GoldPrice', 'SilverPrice', 'BronzePrice', 'DiscountPrice'])
          let pickImage = _.pick(res.data.data, ['FileName', 'Position', 'GoldPrice', 'SilverPrice', 'BronzePrice'])

          StatusCode.value = res.data.data.StatusCode
          productDetails.value = []
          productDetails.value.push(pickProductDetails)
          productWeightandSize.value = []
          productWeightandSize.value.push(pickWeightandSize)
          pricing.value = []
          pricing.value.push(pickPricing)
          inventoryLocation.value = res?.data?.data?.InventoryLocation || []
          isFeatured.value = pickProductDetails.IsFeatured

          let tmpArray = []

          for (var i = 0; i < res.data.data.ProductImages.length; i++) {
            let item = res.data.data.ProductImages[i]
            tmpArray.push({ Image: item.FileName, Position: item.Position, Type: item.Type, IsCover: item.IsCover, IsSmall: item.IsSmall })
          }

          let sortedData = _.sortBy(tmpArray, 'number')
          productImages.value = []
          productImages.value = sortedData

          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    onMounted(async () => {
      if (props?.f7route?.params?.key) {
        await getProductDetails()
      }
    })

    return {
      productDetails,
      productDetailsFields,
      productWeightandSize,
      productWeightandSizeFields,
      pricing,
      pricingFields,
      productImages,
      productImagesFields,
      updateInventory,
      updateProductTax,
      updateStatus,
      StatusCode,
      confirm,
      updateProductCritic,
      isFeatured,
      confirmFeatured,
      marketing,
      view,
      productInventoryLocationFields,
      inventoryLocation
    }
  }
})
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}
.tab-container {
  margin-top: 30px;
}
</style>
