<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation("LBL_LANGUAGE") + " - " + (isUpdate ? $t.getTranslation("LBL_UPDATE") : $t.getTranslation("LBL_CREATE")) }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.LanguageCode"
            name="LanguageCode"
            :required="validationRules?.LanguageCode?.required"
            :minlength="validationRules?.LanguageCode?.minimumLength"
            :maxlength="validationRules?.LanguageCode?.maximumLength"
            :label="$t.getTranslation('LBL_LANGUAGE_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LanguageName"
            name="LanguageName"
            :required="validationRules?.LanguageName?.required"
            :minlength="validationRules?.LanguageName?.minimumLength"
            :maxlength="validationRules?.LanguageName?.maximumLength"
            :label="$t.getTranslation('LBL_LANGUAGE_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-if="statusList && statusList.length > 0"
            v-model:value="formData.StatusCode"
            name="StatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-list-item>
            <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
              {{ $t.getTranslation("LBL_SAVE") }}
            </f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "BlankPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);

    const statusList = computed(() => store.getters["config/getData"]?.statusList);

    const formData = reactive({
      LanguageCode: "",
      LanguageName: "",
      StatusCode: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      if (props?.f7route?.params?.id && parseInt(props?.f7route?.params?.id) > 0) {
        isUpdate.value = true;
        getData();
      }
    });

    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/language/view?LanguageId=${props?.f7route?.params?.id}`);

        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();
          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key];
          }

          return;
        }

        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const save = async () => {
      try {
        let isValid = validate(true);

        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader();
          let res = await $HTTP.post("/admin/language/save", formData);

          if (res?.status === 200 && res?.data?.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();
            props.f7router.back({ force: true, ignoreCache: true });
            return;
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    return { isUpdate, statusList, formData, validationRules, isButtonProcessing, isDisableSaveButton, save };
  },
});
</script>
