<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_TOP_COUNTRIES_POSITION") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button preloader large fill raised @click="add()">{{ $t.getTranslation("LBL_ADD") }}</f7-button>
        <!-- <f7-button large icon="far fa-fw fa-cog"></f7-button> -->
      </div>
    </div>


    <section class="form-container" >
      <div class="container" >
         <f7-list media-list>
           <f7-list-item divider>{{ $t.getTranslation("LBL_COUNTRY_DRAG_MESSAGE") }}</f7-list-item>
           <draggable class="dragArea list-group w-full" :list="list" @change="log" :move="checkMove">
              <div
                class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center"
                v-for="(item, index) in list"
                :key="item.CountryId"
                style="position: relative;"
              >
              <!-- {{ element.id }} -->
               <div style="position: absolute;top:10px;left: 0px;">
                  <span class="badge color-blue"> {{ index + 1 }}</span>
               </div>
                  <f7-list-item :title="item.CountryName" subtitle="" @click="viewCountry(item.CountryId)">
                    <template #media>
                     <img :src="$h.getImage(item.FileName, 'COUNTRY')" width="40" />
                    </template>
                  </f7-list-item>
              </div>
            </draggable>
        </f7-list>
      </div>
    </section>
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject } from "vue";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";
import { VueDraggableNext } from 'vue-draggable-next';


import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

export default defineComponent({
  name: "CountryFeatureList",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    draggable: VueDraggableNext
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const list = ref([]);
    const drag = ref(null);
    let page = 1;
    let size = 30;

    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/country/list?page=${page}&size=${size}&mode=FEATURED&LanguageCode=${currentLanguageCode.value || 'EN'}`);
        if (res?.status === 200 && res?.data?.data.data) {
          helpers.hideLoader();
          for (var i = 0; i < res.data.data.data.length; i++) {
            let item = res.data.data.data[i];
            item.id = (i+1);
          }
          list.value = res.data.data.data;
          helpers.hideLoader();
          return;
        }
        //throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const log = (event) => {
      // console.log(event.moved.element)
      // console.log(event.moved)
      // console.log(drag.value)
      if (list.value) {
       savePosition(list.value); 
      }
    }

    const checkMove = (event) => {
      //console.log(event)
      //savePosition();
    }

    const viewCountry = (countryid) => {
      props.f7router.navigate({ name: "countrySave", query: { CountryId: countryid, isFeatured: 1 } });
    }

    const savePosition = async(list) => {
      //call the function to save positon..
      try {
        helpers.showLoader();
        let res = await $HTTP.post(`/admin/country/position`, { List: list});
        if (res?.status === 200 && res?.data?.data) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("LBL_SUCCESSFULLY_SAVE_COUNTRY_POSITION") });
          helpers.hideLoader();
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    }

    const status = async(merchantId) => {
      // if (merchantId) {
      //   props.f7router.navigate({ name: "merchantFeaturedSave", query: { MerchantId:  merchantId } });
      // }
    }

    const add = async() => {
      props.f7router.navigate({ name: "countryList", query: { isFeatured: 1 } });
    }

    onMounted(async() => {
      await getData();
    });
  
    return {
      list,
      log,
      checkMove,
      status,
      add,
      viewCountry
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}

.form-container .container{
  display: flex;
  margin: 0px 0px;
  /* padding: 40px 0px 40px 40px; */
  width: 90%;
  justify-content: center;
  align-items: center;
}

li::marker{
  color: #fff;
}

</style>
