<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_WINERY_IMAGE_UPLOAD') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
       
        <f7-list no-hairlines>

          <ViewImageListComponent :title="$t.getTranslation('LBL_UPLOADED_IMAGE')" :data="uploadedImages" />

          <ImageUploadPopUpComponent :imageSizeLimit="2" :description="test" :title="test" :open="isOpen" @cropped="onImageSelected"  />

          <f7-button large fill raised preloader @click="openUpload()" :disabled="uploadedImages.length > 0">
              {{ $t.getTranslation("LBL_UPLOAD_IMAGE") }}
          </f7-button>

          <f7-button large fill raised preloader @click="reset()" :disabled="!(uploadedImages.length > 0)">
              {{ $t.getTranslation("LBL_RESET") }}
          </f7-button>

          <f7-button large fill raised preloader @click="save()" :disabled="!(uploadedImages.length > 0)">
              {{ $t.getTranslation("LBL_SAVE") }}
          </f7-button>
         
         <!--  <f7-button large fill raised preloader @click="save">
              {{ $t.getTranslation("LBL_SAVE") }}
          </f7-button> -->

        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import ImageUploadPopUpComponent from "@/components/ImageUploadPopUpComponent.vue";
import ViewImageListComponent from "@/components/ViewImageListComponent.vue";

export default defineComponent({
  name: "MerchantImageUploadPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    ImageUploadPopUpComponent,
    ViewImageListComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const userStatusList = computed(() => store.getters["config/getData"]?.userStatusList);
    const uploadedImages = ref([]);
    const imageType = ref(null);
    const formData = reactive({
      Images: []
    });

    const types = {
      "WINERY_IMAGE": {
        field: "WineryImage",
        translation: $t.getTranslation('LBL_WINERY_IMAGE')
      }
    }

    onMounted(() => {
      if (props?.f7route?.query?.type) {
        imageType.value = types[props?.f7route?.query?.type];
      }
    });

    const isOpen = ref(false);

    const openUpload = () => {
      if (isOpen.value) {
        isOpen.value = false;
      }else{
        isOpen.value = true;
      }
    } 

    const reset = () => {
      uploadedImages.value = [];
    }

    const renderImageBlob = (blob) => {
      if (blob) {
        const objectURL = URL.createObjectURL(blob);
        return objectURL;
      }
    };

    const onImageSelected = (data) => {
      const objectURL = URL.createObjectURL(data.blob);
      formData[types[props?.f7route?.query?.type].field] = data;
      uploadedImages.value.push({fields: types[props?.f7route?.query?.type].field, name: types[props?.f7route?.query?.type].translation, link: helpers.getImage(objectURL, null, null, true), blob: data });
    };

    const save = async () => {
      try {
          let blobFormData = new FormData();
          blobFormData.append("WineryId", props?.f7route?.params?.id);
          blobFormData.append(types[props?.f7route?.query?.type].field, formData?.[types[props?.f7route?.query?.type].field]?.blob);
          await $HTTP.post("/admin/winery/upload", blobFormData);
          props.f7router.navigate({ name: "winerySave", query: { WineryId: props?.f7route?.params?.id } });
         
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    return { isUpdate, formData, save, userStatusList, uploadedImages, openUpload, isOpen, onImageSelected, reset };
  },
});
</script>
