<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_ADMIN_SAVE') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <f7-list no-hairlines>

          <f7-list-input
            v-model:value="formData.UserName"
            name="UserName"
            :required="validationRules?.UserName?.required"
            :minlength="validationRules?.UserName?.minimumLength"
            :maxlength="validationRules?.UserName?.maximumLength"
            :label="$t.getTranslation('LBL_USERNAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            v-on:keyup="removeWhiteSpace('UserName')"
            type="text"
            :disabled="formData.UserId"
            validate
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.Email"
            name="Email"
            :required="validationRules?.Email?.required"
            :minlength="validationRules?.Email?.minimumLength"
            :maxlength="validationRules?.Email?.maximumLength"
            :label="$t.getTranslation('LBL_EMAIL')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            v-on:keyup="removeWhiteSpace('Email')"
            type="text"
            :disabled="formData.UserId"
            validate
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.FirstName"
            name="FirstName"
            :required="validationRules?.FirstName?.required"
            :minlength="validationRules?.FirstName?.minimumLength"
            :maxlength="validationRules?.FirstName?.maximumLength"
            :label="$t.getTranslation('LBL_FIRST_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            :label="$t.getTranslation('LBL_LAST_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
          >
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.MobileCode"
            name="MobileCode"
            :required="validationRules?.MobileCode?.required"
            :minlength="validationRules?.MobileCode?.minimumLength"
            :maxlength="validationRules?.MobileCode?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="mobileCode in mobileCodeList" :key="'st_' + mobileCode.name" :value="mobileCode.value">{{ mobileCode.name }}</option>
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.MobileNumber"
            name="MobileNumber"
            :required="validationRules?.MobileNumber?.required"
            :minlength="validationRules?.MobileNumber?.minimumLength"
            :maxlength="validationRules?.MobileNumber?.maximumLength"
            :label="$t.getTranslation('LBL_MOBILE_NUMBER')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            v-on:keyup="removeWhiteSpace('MobileNumber')"
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.UserStatusCode"
            name="UserStatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="userstatus in userStatusList" :key="'st_' + userstatus.Code" :value="userstatus.Code">{{ userstatus.Name }}</option>
          </f7-list-input>


          <f7-list-input
            v-model:value="formData.Password"
            name="Password"
            :required="validationRules?.Password?.required"
            :minlength="validationRules?.Password?.minimumLength"
            :maxlength="validationRules?.Password?.maximumLength"
            :label="$t.getTranslation('LBL_PASSWORD')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="password"
            validate
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.ConfirmPassword"
            name="ConfirmPassword"
            :required="validationRules?.ConfirmPassword?.required"
            :minlength="validationRules?.ConfirmPassword?.minimumLength"
            :maxlength="validationRules?.ConfirmPassword?.maximumLength"
            :label="$t.getTranslation('LBL_CONFIRM_PASSWORD')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="password"
            validate
          >
          </f7-list-input>


          <!-- <div v-if="formData.UserId">
            <f7-list-item>
               <ViewImageListComponent :title="$t.getTranslation('LBL_UPLOADED_IMAGES')" :data="uploadedImages" />
            </f7-list-item>

            <f7-segmented raised tag="p">
              <f7-button @click="upload('ADMIN_IMAGE')">{{  $t.getTranslation('LBL_UPLOAD_IMAGE') }}</f7-button>
            </f7-segmented>
          </div> -->
         
          <!-- <f7-list-item v-for="userRole in userRoleList" :key="'ur_' + userRole.Code">
            <span>{{ userRole.name }}</span>
            <f7-toggle v-model:checked="roles[userRole.code]" @change="toggle(userRole.code)"></f7-toggle>
          </f7-list-item> -->

          <f7-list-item :title='$t.getTranslation("LBL_SELECT_ADMIN_ROLES")' smart-select :smart-select-params="{openIn: 'page'}">
            <select v-model="formData.UserRoles" :name='$t.getTranslation("LBL_ADMIN_ROLES")' multiple>
              <option v-for="userRole in userRoleList" :key="'ur_' + userRole.code" :value="userRole.code">{{ userRole.name }}</option>
            </select>
          </f7-list-item>
          
         <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
              {{ $t.getTranslation("LBL_SAVE") }}
         </f7-button>

          <!-- <f7-list-item>
            <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
              {{ $t.getTranslation("LBL_SAVE") }}
            </f7-button>
          </f7-list-item> -->
        </f7-list>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { useStore } from "@/store";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

export default defineComponent({
  name: "CustomerSavePage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const isSuperAdmin = ref(false)
    const userStatusList = computed(() => store.getters["config/getData"]?.userStatusList);
    const mobileCodeList = computed(() => store.getters["config/getData"]?.mobileCodeList);
    const userRoleList = computed(() => store.getters["config/getData"]?.userRoleList);
    const currentUserRole = computed(() => store.getters["user/getData"]);

    const userInfo = computed(() => store.getters["user/getData"]);
    if (userInfo.value?.IsSuperAdmin) {
      isSuperAdmin.value = true;
    }
    
    let formData = reactive({
      UserStatusCode: "",
      UserRoles: []
    });


    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      if (props?.f7route?.query?.id && parseInt(props?.f7route?.query?.id) > 0) {
        isUpdate.value = true;
        getData();
      }
    });

    const reloadUserData = () => {
      store.dispatch("user/fetchData", { force: true });
    }

    const getData = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/user/view?UserId=${props?.f7route?.query?.id}`);
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader();
          for (var key in res?.data?.data) {
            formData[key] = res?.data?.data[key];
          }
          return;
        }
        throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);
      return !isValid;
    });

    const removeWhiteSpace = (field) => {
      formData[field] = formData[field].trim();
      formData[field] = formData[field].replace(/ /g,"");
    }

    const save = async () => {
      try {
        let isValid = validate(true);

        if (formData.Password != formData.ConfirmPassword) {
          throw new Error($t.getTranslation("LBL_ERROR_PASSWORD_CONFIRM_PASSWORD_MATCH"));
        }

        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader();
          
          //send update to server
          let res = await $HTTP.post("/admin/user/save", formData);
          if (res?.status === 200 && res?.data?.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();
            formData['Password'] = "";
            formData['ConfirmPassword'] = "";
            helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_ADMIN_SAVE") });
            props.f7router.navigate({ name: "settingAdminUser", query: { } });
            //props.f7router.back({ force: true, ignoreCache: true });
            return;
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const upload = (type) => {
      props.f7router.navigate({ name: "settingAdminUserUpload", params: {id: props?.f7route?.query?.id}, query: {type: type } });
    }

    return { 
      isUpdate, 
      formData, 
      validationRules, 
      isButtonProcessing, 
      isDisableSaveButton, 
      save, 
      userStatusList, 
      mobileCodeList,
      upload,
      userRoleList,
      isSuperAdmin,
      removeWhiteSpace
    };
  },
});
</script>
