<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_COUPON_LIST_TIER") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>

       <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
        <f7-button large fill raised @click="back">{{ $t.getTranslation("LBL_BACK") }}</f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @editItem="editItem"></DataTableComponent>
     
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, computed } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { useStore } from "@/store";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
export default defineComponent({
  name: "BlankPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
     const $t = inject("$translation");
     const store = useStore();
     const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
     const columns = [
      {
        title: $t.getTranslation("LBL_COUPON_NAME"),
        field: "Name"
      },
      {
        title: $t.getTranslation("LBL_LIMIT"),
        field: "Limit"
      },
      {
        title: $t.getTranslation("LBL_AMOUNT"),
        field: "Amount"
      },
      {
        title: $t.getTranslation("LBL_DATE_CREATED"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        }
      }
    ];

    const editItem = (data) => {
      if (data?.value?.CouponId) {
        props.f7router.navigate({ name: "couponSaveTier", query: { CouponId: props?.f7route?.query?.CouponId, CouponTierId: data.value.CouponTierId } });
      }
    };

    const create = () => {
      props.f7router.navigate({ name: "couponSaveTier", query: { CouponId:props?.f7route?.query?.CouponId } });
    };

    const back = () => {
      props.f7router.navigate({ name: "couponList", query: {  } });
    };


    let url = `/admin/coupon/list/tier?CouponId=${props?.f7route?.query?.CouponId}&LanguageCode=${currentLanguageCode.value}`;
    return {
      columns,
      url,
      editItem,
      create,
      back
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
