<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'mobile']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_BANNER_LIST") }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" @performAction="performAction" />

    <BannerSavePartial ref="bannerSavePartial" @closed="initDataTableComponent" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

// PARTIALS
import BannerSavePartial from "@/partials/manage/BannerSavePartial.vue";

import { helpers } from "@/utils/helpers";
import { post } from "@/utils/axios";

export default defineComponent({
  name: "BannerListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    // PARTIALS
    BannerSavePartial,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const url = "/admin/banner/datalist";

    const actions = [
      {
        groupText: $t.getTranslation("LBL_PERFORM_ACTIONS"),
        list: [{ text: $t.getTranslation("LBL_EDIT"), type: "EDIT", bold: true }],
      },
      {
        groupText: "",
        list: [{ text: $t.getTranslation("LBL_DELETE"), type: "DELETE", color: "red" }],
      },
    ];

    const columns = [
      {
        title: $t.getTranslation("LBL_ID"),
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().BannerId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_TYPE"),
        minWidth: 220,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Type}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IMAGE"),
        minWidth: 120,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image wide">
                <img src="${helpers.getImage(cell?.getData()?.Image, "BANNER", require("@/assets/images/banner-placeholder.jpg"))}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_VIDEO"),
        minWidth: 120,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Video || "&mdash;"}</strong><br />
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_BANNER_INFO"),
        minWidth: 280,
        maxWidth: 380,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().BannerName || "--"}</strong><br />
              <strong>${cell.getData().BannerCode || "--"}</strong><br />
              ${cell.getData().Link || $t.getTranslation("LBL_NO_AVAILABLE_LINK")}<br />
              ${helpers.cutText(cell.getData().Description || "", 150)}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PROCESS_INFO"),
        minWidth: 280,
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Username}</strong> <br />
              ${helpers.getFullName(cell.getData(), true)}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 200,
        maxWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.StatusCode)} ${cell.getData()?.StatusCode}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
              <div class="chip color-teal">
                <div class="chip-label">${$t.getTranslation("LBL_POSITION")}: ${cell.getData()?.Position || 0}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        formatter(cell) {
          return `
            <div>
              <div title="${$t.getTranslation("LBL_DATE_CREATED")}"><i class="far fa-fw fa-paper-plane"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="${$t.getTranslation("LBL_DATE_UPDATED")}"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    return {
      url,
      columns,
      actions,
    };
  },
  methods: {
    create() {
      this.$refs.bannerSavePartial.openPopup(false);
    },
    performAction(type, data) {
      const self = this;

      switch (type) {
        case "EDIT":
          if (data?.value?.BannerId) {
            self.$refs.bannerSavePartial.openPopup(data.value);
          }
          break;
        case "DELETE":
          if (data?.value?.BannerId) {
            helpers.createConfirmation({
              message: self.$t.getTranslation("CNF_DELETE_ITEM"),
              confirm: async () => {
                let returnData = await post("/admin/banner/delete", { BannerId: data?.value?.BannerId });
                if (returnData)
                  helpers.createNotification({
                    type: "info",
                    title: self.$t.getTranslation("MSG_INFO"),
                    message: self.$t.getTranslation("MSG_SUCCESSFULLY_DELETED_SELECTED_ITEM"),
                  });

                self.$refs.dataTableComponent.initTabulator();
              },
            });
          }
          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
