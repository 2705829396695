<template>
  <f7-popup id="saveGroupBuy" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="false">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <FormComponent ref="formComponent" @successSave="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, inject, onMounted } from "vue";
import { f7 } from "framework7-vue";

import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";

import { get } from "@/utils/axios";

const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "GroupBuySavePartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const title = ref("");
    const $t = inject("$translation");

    const formComponentData = {
      buttonText: "",
      getLink: "/admin/groupbuy/view",
      getParams: {},
      validation: "groupbuy",
      saveLink: "/admin/groupbuy/save",
      saveParams: {},
      successCreateMessage: $t.getTranslation("LBL_SUCCESS_CREATE_GROUP_BUY"),
      successUpdateMessage: $t.getTranslation("LBL_SUCCESS_UPDATE_GROUP_BUY"),
    };

    const getWarehouseRegionList = async (ProductId) => {
      let warehouseRegionList = await get(`/public/warehouse/region/list`, { ProductId });

      // let inputList = store.getters["form/getInputList"];
      // inputList[1].list[0].items = warehouseRegionList;

      // store.dispatch("form/setInputList", inputList);
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("LBL_PRODUCT_INFORMATION"),
        type: "selectmultiple",
        value: "Product",
        required: false,
        buttonText: $t.getTranslation("LBL_SELECT_PRODUCT"),
        title: $t.getTranslation("LBL_SELECT_PRODUCT"),
        params: {
          type: "dynamic",
          getLink: "/public/product/datalist",
          getParams: { HasWareHouse: 1 },
          nameSelector: "ProductName",
          descriptionSelector: "Sku",
          valueSelector: "ProductId",
          imageSelector: "PrimaryImage",
          imageBucket: "PRODUCT",
          limit: 1,
          onSelectItem: async (item, index, selectedList) => {
            if (item?.IsProductSale == "ACTIVE") {
              selectedList.value.splice(index, 1);

              helpers.createNotification({
                type: "error",
                title: $t.getTranslation("LBL_ERROR"),
                message: $t.getTranslation("ERR_PRODUCT_ALREADY_ON_FLASH_SALE"),
              });
            }
          },
        },
        onChange: async (data, formData) => {
          let inputList = store.getters["form/getInputList"];
          inputList[1].params.getParams.ProductId = data?.[0]?.ProductId;

          store.dispatch("form/setInputList", inputList);
        },
      },
      {
        name: $t.getTranslation("LBL_WAREHOUSE_INFORMATION"),
        type: "selectmultiple",
        value: "WareHouseRegion",
        required: false,
        buttonText: $t.getTranslation("LBL_SELECT_WAREHOUSE"),
        title: $t.getTranslation("LBL_SELECT_WAREHOUSE"),
        params: {
          type: "dynamic",
          getLink: "/public/warehouse/region/datalist",
          getParams: {},
          nameSelector: "WareHouseRegionName",
          descriptionSelector: "WareHouseRegionCode",
          valueSelector: "WareHouseRegionId",
          limit: 99,
        },
      },
      {
        name: $t.getTranslation("LBL_GROUP_BUY_INFORMATION"),
        type: "input",
        list: [
          { name: $t.getTranslation("LBL_MOQ"), type: "number", value: "MOQ", required: true },
          { name: $t.getTranslation("LBL_START_DATE"), type: "datetimepicker", value: "StartDate", required: true, hideClearButton: true, params: { minDate: "today" } },
          { name: $t.getTranslation("LBL_END_DATE"), type: "datetimepicker", value: "EndDate", required: true, hideClearButton: true, params: { minDate: "today" } },
        ],
      },
      {
        name: $t.getTranslation("LBL_INITIAL_QUANTITY_INFORMATION"),
        type: "input",
        list: [
          { name: $t.getTranslation("LBL_MAXIMUM_INITIAL_QUANTITY"), type: "number", value: "InitialQuantityMaximum", required: false, placeholder: 0 },
          { name: $t.getTranslation("LBL_INITIAL_QUANTITY_INCREMENT"), type: "number", value: "InitialQuantityIncrement", required: false, placeholder: 0 },
          { name: $t.getTranslation("LBL_INCREMENT_INTERVAL_IN_HOUR"), type: "number", value: "IncrementInterval", required: false, placeholder: 0 },
          { name: $t.getTranslation("LBL_INITIAL_QUANTITY_START_DATE"), type: "datetimepicker", value: "InitialQuantityStartDate", required: false, hideClearButton: true, params: { minDate: "today" } },
        ],
      },
      {
        name: $t.getTranslation("LBL_STATUS_INFORMATION"),
        type: "input",
        list: [{ name: "Status", type: "select", value: "StatusCode", required: false, hideClearButton: true, items: store.getters["config/getData"]?.statusList, nameSelector: "Name", valueSelector: "Code", defaultValue: "ACTIVE" }],
      },
    ];

    return {
      title,
      formComponentData,
      defaultInputList,
      getWarehouseRegionList,
    };
  },
  methods: {
    async openPopup(data) {
      const self = this;
      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);

      if (data?.GroupBuyId) {
        self.title = this.$t.getTranslation("LBL_UPDATE_GROUP_BUY");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_UPDATE_GROUP_BUY");
        self.formComponentData.getParams.GroupBuyId = data?.GroupBuyId;

        self.formComponentData.saveParams.UserId = data?.UserId;
        self.formComponentData.saveParams.GroupBuyId = data?.GroupBuyId;

        self.$refs.formComponent.setFormComponentData(self.formComponentData, true);
        await self.getWarehouseRegionList(data?.ProductId);
      } else {
        self.title = this.$t.getTranslation("LBL_CREATE_GROUP_BUY");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_CREATE_GROUP_BUY");
        self.formComponentData.getParams = {};
        self.formComponentData.saveParams = {
          UserId: data.UserId,
        };

        self.$refs.formComponent.setFormComponentData(self.formComponentData);
        await self.getWarehouseRegionList();
      }

      f7.popup.open("#saveGroupBuy");
      self.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#saveGroupBuy");
      this.$emit("closed", data);
    },
  },
});
</script>
