<template>

 <div class="tab-container">
 
        <f7-button class="tab-btn" tab-link="#tab1" tab-link-active>{{ $t.getTranslation("LBL_ORDER_SUMMARY") }}</f7-button>
        <f7-button class="tab-btn" tab-link="#tab2">{{ $t.getTranslation("LBL_PRODUCT_SUMMARY") }}</f7-button>
        <f7-button class="tab-btn" tab-link="#tab3">{{ $t.getTranslation("LBL_SALE_AMOUNT") }}</f7-button>
        <f7-button class="tab-btn" tab-link="#tab4">{{ $t.getTranslation("LBL_COUNTRY_ORIGIN") }}</f7-button>
  
    </div>
  <f7-tabs>
    <f7-tab id="tab1" tab-active class="page-content">
     <div class="chart-container">
        <f7-list no-hairlines>
            <div class="search-bar">
                <f7-list-input class="list-serach"
                    outline
                    :label="$t.getTranslation('LBL_SEARCH')"
                    floating-label
                    type="text"
                    :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')"
                    clear-button>
                </f7-list-input>
                <f7-button fill raised popup-open=".demo-popup" class="search-btn" @click="showFilterPopUp">
                    {{ $t.getTranslation("LBL_FILTER") }}
                    <font-awesome-icon :icon="['far', 'filter']" fixed-width />
                </f7-button>
                <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
                  <FilterPopUpComponent />
                </f7-popup>
            </div>
        </f7-list>
    </div>
    <div class="chart-content">
        <VueApexCharts width="98%" height="100%" type="bar" :options="options" :series="series"></VueApexCharts>
    </div>
    </f7-tab>
    <f7-tab id="tab2" class="page-content">
    <div class="chart-container">
        <f7-list no-hairlines>
            <div class="search-bar">
                <f7-list-input class="list-serach"
                    outline
                    :label="$t.getTranslation('LBL_SEARCH')"
                    floating-label
                    type="text"
                    :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')"
                    clear-button>
                </f7-list-input>
                <f7-button fill raised popup-open=".demo-popup" class="search-btn" @click="showFilterPopUp">
                    {{ $t.getTranslation("LBL_FILTER") }}
                    <font-awesome-icon :icon="['far', 'filter']" fixed-width />
                </f7-button>
                <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
                  <FilterPopUpComponent />
                </f7-popup>
            </div>
        </f7-list>
    </div>
    <div class="chart-content">
        <VueApexCharts width="98%" height="100%" type="bar" :options="options" :series="series"></VueApexCharts>
    </div>
    </f7-tab>
    <f7-tab id="tab3" class="page-content">
    <div class="chart-container">
        <f7-list no-hairlines>
            <div class="search-bar">
                <f7-list-input class="list-serach"
                    outline
                    :label="$t.getTranslation('LBL_SEARCH')"
                    floating-label
                    type="text"
                    :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')"
                    clear-button>
                </f7-list-input>
                <f7-button fill raised popup-open=".demo-popup" class="search-btn" @click="showFilterPopUp">
                    {{ $t.getTranslation("LBL_FILTER") }}
                    <font-awesome-icon :icon="['far', 'filter']" fixed-width />
                </f7-button>
                <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
                  <FilterPopUpComponent />
                </f7-popup>
            </div>
        </f7-list>
    </div>
    <div class="chart-content">
        <VueApexCharts width="98%" height="100%" type="bar" :options="options" :series="series"></VueApexCharts>
    </div>
    </f7-tab>
     <f7-tab id="tab4" class="page-content">
    <div class="chart-container">
        <f7-list no-hairlines>
            <div class="search-bar">
                <f7-list-input class="list-serach"
                    outline
                    :label="$t.getTranslation('LBL_SEARCH')"
                    floating-label
                    type="text"
                    :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')"
                    clear-button>
                </f7-list-input>
                <f7-button fill raised popup-open=".demo-popup" class="search-btn" @click="showFilterPopUp">
                    {{ $t.getTranslation("LBL_FILTER") }}
                    <font-awesome-icon :icon="['far', 'filter']" fixed-width />
                </f7-button>
                <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
                  <FilterPopUpComponent />
                </f7-popup>
            </div>
        </f7-list>
    </div>
    <div class="chart-content">
        <VueApexCharts width="98%" height="100%" type="bar" :options="options" :series="series"></VueApexCharts>
    </div>
    </f7-tab>

  </f7-tabs>
 
    

</template>

<script>
import { defineComponent, ref, onMounted, reactive } from "vue";

import dayjs from 'dayjs'
import VueApexCharts from "vue3-apexcharts";
import FilterPopUpComponent from "@/components/FilterPopUpComponent.vue";

import { $HTTP } from "@/utils/axios";
export default defineComponent({
  name: "BarChartComponent",
  components: {
      VueApexCharts,
      FilterPopUpComponent
  },
  props: { f7router: Object },
  setup(props) {
    

    

    const options = ref({
      chart: {
        toolbar : {
          show : false
        }
      },
      xaxis: {
        type: 'datetime'
      } 
    });

    const series = ref([]);

    const formData = reactive({
      rangeValue : [dayjs().add(-14, 'days').valueOf(), dayjs().valueOf()]
    });

    const url = ref("");

 

    const getQuery = ()=>{
      let Start = dayjs(formData.rangeValue[0]).format('YYYY-MM-DD');
      let End = (formData.rangeValue.length > 1) ? dayjs(formData.rangeValue[1]).format('YYYY-MM-DD') : dayjs(formData.rangeValue[0]).format('YYYY-MM-DD');
      return `?from=${Start}&to=${End}`;
    }

    const getChartData = async($refs)=>{

      url.value = ``; //set url???

      if($refs){
        setTimeout(()=>{
        	$refs.datatable.initTabulator();
        }, 300);
      };


      let res = await $HTTP.get(`/admin/pageview/chart${getQuery()}`)

      series.value = res.data.data.Series
    };

    onMounted(getChartData);

    return {
      options, 
      series,
    };
  },
});
</script>
<style scoped>
.tab-container{
    width: calc(100% - 60px);
    margin: 0 auto;
    display:flex;
    padding-top: 30px;
}
.chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px 30px 0px;
}
.search-bar{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 30px;
}

.search-btn{
    width: 150px;
    height: 55px;
    margin-top: 10px;
}
.chart-content{
    padding: 0px 30px 0px;
    height: 460px
}
.vue-apexcharts{
    background: #F4F6F9;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}
.list-serach{
    list-style-type: none;
}
.tab-btn{
    width: 270px;
}
.tab {
    padding-top: 30px;
}
</style>
