<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation('LBL_WAREHOUSE_LIST') }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create()">{{ $t.getTranslation('LBL_CREATE') }}</f7-button>
        <!-- <f7-button large icon="far fa-fw fa-cog"></f7-button> -->
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @viewItem="editItem" @editItem="editItem" ref="datatable"></DataTableComponent>

    <MainFooterComponent />
    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcel($event, $refs)" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject } from 'vue'
import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import DataTableComponent from '@/components/DataTableComponent.vue'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { useStore } from '@/store'
export default defineComponent({
  name: 'WarehouseListPage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const isUploading = ref(false)
    const hideImport = ref(false)

    const columns = [
      {
        title: $t.getTranslation('LBL_WAREHOUSE_CODE'),
        field: 'WareHouseCode'
      },
      {
        title: $t.getTranslation('LBL_WAREHOUSE_NAME'),
        field: 'WareHouseName'
      },
      {
        title: $t.getTranslation('LBL_WAREHOUSE_REGION_CODE'),
        field: 'WareHouseRegionCode'
      },
      {
        title: $t.getTranslation('LBL_STATUS'),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === 'ACTIVE' ? 'color-green' : 'color-gray'}">
                <div class="chip-label">${cell.getData()?.StatusCode || '--'}</div>
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_LAST_CREATED'),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `
        }
      }
    ]

    const editItem = (data) => {
      props.f7router.navigate({ name: 'warehouseSave', query: { WareHouseId: data.value.WareHouseId, WareHouseCode: data.value.WareHouseCode } })
      return
    }

    const create = () => {
      props.f7router.navigate({ name: 'warehouseSave', query: {} })
      return
    }

    let url = `/admin/warehouse/list`

    return {
      columns,
      url,
      editItem,
      create
    }
  }
})
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}

input[type='file'] {
  display: none;
}
</style>
