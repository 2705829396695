import { library, dom } from '@fortawesome/fontawesome-svg-core'

// DECLARATION FOR SOLID ICONS
import { faStar as fasStar, faVideo as fasVideo, faImage as fasImage, faFile as fasFile } from '@fortawesome/pro-solid-svg-icons'

import {
  faBars,
  faSearch,
  faSignOut,
  faTimes,
  faBell,
  faSync,
  faExchangeAlt,
  faTrash,
  faImages,
  faAngleUp,
  faAngleDown,
  faPowerOff,
  faClock,
  faEdit,
  faUndo,
  faEye,
  faExchange,
  faFile,
  faFunnelDollar,
  faFilter,
  faSortSizeDownAlt,
  faSort,
  faSortNumericUpAlt,
  faSave,
  faCogs,
  faPrint,
  faFileCsv,
  faCode,
  faFileExcel,
  faLongArrowLeft,
  faCheck,
  faPaperPlane,
  faAngleLeft,
  faComment,
  faPlus,
  faDownload,
  faExpand,
  faCompress,
  faStar
} from '@fortawesome/pro-regular-svg-icons'

import {
  faChartArea,
  faFile as fadFile,
  faEnvelope as FadEnvelope,
  faShoppingCart,
  faCreditCard,
  faWineBottle,
  faMobile,
  faTicket,
  faStore,
  faImagePolaroid,
  faUsersCrown,
  faUsers,
  faUserShield,
  faList,
  faCog,
  faEnvelope
} from '@fortawesome/pro-duotone-svg-icons'

// DECLARATION FOR LIGHT ICONS
import { faLayerPlus, faVideoPlus } from '@fortawesome/pro-light-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// ADD SOLID ICONS
library.add(fasStar, fasVideo, fasImage, fasFile)

// ADD REGULAR ICONS
library.add(
  faBars,
  faSearch,
  faShoppingCart,
  faSignOut,
  faTimes,
  faBell,
  faSync,
  faExchangeAlt,
  faTrash,
  faImages,
  faAngleUp,
  faAngleDown,
  faPowerOff,
  faClock,
  faEdit,
  faUndo,
  faEye,
  faExchange,
  faFile,
  faFunnelDollar,
  faFilter,
  faSortSizeDownAlt,
  faSort,
  faSortNumericUpAlt,
  faSave,
  faCogs,
  faPrint,
  faFileCsv,
  faCode,
  faFileExcel,
  faLongArrowLeft,
  faCheck,
  faPaperPlane,
  faAngleLeft,
  faComment,
  faPlus,
  faDownload,
  faExpand,
  faCompress,
  faStar
)

// ADD DUOTONS ICONS
library.add(faChartArea, fadFile, FadEnvelope, faShoppingCart, faCreditCard, faWineBottle, faMobile, faTicket, faStore, faImagePolaroid, faUsersCrown, faUsers, faUserShield, faList, faCog, faEnvelope)

// INCLUDE TO LIBRARY FOR LIGHT ICONS
library.add(faLayerPlus, faVideoPlus)

dom.watch()

export { FontAwesomeIcon }
