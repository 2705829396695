import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'


const routes = [
  {
    name: 'message',
    path: '/message/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'envelope',
        title: translation.getTranslation('LBL_MESSAGES'),
        activeLink: 'message'
      },
      isSecured: true,
      hasRoles: ['MANAGE_CUSTOMER_SUPPORT', 'MANAGE_CUSTOMER_SUPPORT_ALL']
    },
    redirect: '/message/list/',
    routes: [
      {
        name: 'messages',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_MESSAGES'),
            activeLink: 'messages'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CUSTOMER_SUPPORT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/message/MessagePage.vue')
      },
      {
        name: 'messageAll',
        path: 'list/all/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_MESSAGES_LIST'),
            activeLink: 'messageAll'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CUSTOMER_SUPPORT_ALL']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/message/MessageList.vue')
      },
      {
        name: 'messageAllView',
        path: 'list/all/view/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'messageAllView'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CUSTOMER_SUPPORT_ALL']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/message/MessageView.vue')
      }

    ]
  }
]

export default routes
