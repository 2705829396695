import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'payment',
    path: '/payment/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'credit-card',
        title: translation.getTranslation('LBL_PAYMENT'),
        activeLink: 'payment'
      },
      isSecured: true,
      hasRoles: ['MANAGE_PAYMENT']
    },
    redirect: '/payment/list/',
    routes: [
      {
        name: 'paymentList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PAYMENT_PAYECO'),
            activeLink: 'paymentList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PAYMENT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/payment/PaymentListPage.vue')
      }
    ]
  }
]

export default routes
