<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'users-crown']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_PLATFORM_REVIEW_LIST") }}</h1>
          <h3>List of available platform reviews made by users.</h3>
        </div>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "PlatformReviewListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    // PARTIALS
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const actions = [];

    const columns = [
      {
        title: "Id",
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().UserPlatformReviewId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_INFO"),
        minWidth: 320,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.getFullName(cell.getData(), true)}</strong></div>
              <div><strong>${cell.getData().UserName || ""}</strong></div>
              <div>${cell.getData().UserKey}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_WINE_SELECTION"),
        minWidth: 120,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().WineSelection}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PLATFORM_FRIENDLINESS"),
        minWidth: 120,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().PlatformFriendliness}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_COMMENT"),
        minWidth: 280,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().Comment}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="Date Created"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="Date Updated"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const url = ref("/admin/platformreview/datalist");

    return {
      url,
      columns,
      actions,
    };
  },
  methods: {
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
