import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'

import { useStore } from '@/store'
const store = useStore()

const login = async (apiLink, formData) => {
  let res = await $HTTP.post(apiLink, formData)

  if (res && res.status === 200 && res?.data?.data && res?.data?.data.Token != '') {
    let returnData = res.data.data
    returnData.TokenStartDate = new Date().getTime()
    store.dispatch('user/setData', returnData)
    store.dispatch('user/connect')

    return true
  }

  return false
}

const checkHasRoles = (Roles) => {
  const userRoles = store.getters['user/getData'].UserRoleList

  if (Roles && Roles.length > 0) {
    let hasRolesCounter = 0

    Roles.forEach((role) => {
      if (userRoles.indexOf(role) > -1) {
        hasRolesCounter++
      }
    })

    return hasRolesCounter === Roles.length
  } else {
    return true
  }
}

const checkHasAnyRoles = (Roles) => {
  const userRoles = store.getters['user/getData'].UserRoleList

  if (Roles && Roles.length > 0) {
    let hasRolesCounter = 0

    Roles.forEach((role) => {
      if (userRoles.indexOf(role) > -1) {
        hasRolesCounter++
      }
    })

    return hasRolesCounter > 0
  } else {
    return true
  }
}

const logout = ({ f7router, loginLink = '/login/' }) => {
  helpers.createConfirmation({
    message: 'Are you sure you want to logout?',
    confirm: () => {
      store.dispatch('user/resetData')
      store.dispatch('chat/resetData')
      if (f7router && loginLink) {
        f7router.navigate(loginLink)
      } else {
        window.location.href = loginLink
      }
    }
  })
}

export { login, logout }
