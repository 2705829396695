<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_ADMIN_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>

    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @editItem="editItem"></DataTableComponent>
     
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
export default defineComponent({
  name: "BlankPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object },
  setup(props) {
     const $t = inject("$translation");
     const columns = [
      {
        title: $t.getTranslation("LBL_USER_NAME"),
        field: "UserName"
      },
      {
        title: $t.getTranslation("LBL_EMAIL"),
        field: "Email"
      },
      {
        title: $t.getTranslation("LBL_FIRST_NAME"),
        field: "FirstName"
      },
      {
        title: $t.getTranslation("LBL_LAST_NAME"),
        field: "LastName"
      },
      {
        title: $t.getTranslation("LBL_MOBILE_CODE"),
        field: "MobileCode"
      },
      {
        title: $t.getTranslation("LBL_MOBILE_NUMBER"),
        field: "MobileNumber"
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.UserStatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.UserStatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      }
    ];

    const editItem = (data) => {
      if (data?.value?.UserId) {
        props.f7router.navigate({ name: "settingAdminUserView", query: { id: data.value.UserId } });
      }
    };

    const create = (data) => {
      props.f7router.navigate({ name: "settingAdminUserView", query: { } });
    };

    let url = '/admin/user/list?UserTypeCode=ADMIN';
    return {
      columns,
      url,
      editItem,
      create
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
