<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'wine-bottle']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_PRODUCT_LIST") }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button v-if="!hideImport" large fill raised preloader :loading="isUploading" :disabled="isUploading" @click="uploadHKRetail($refs)">
          {{ $t.getTranslation("LBL_UPLOAD_HK_RETAIL_PRICE") }}
        </f7-button>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <MainFooterComponent />

    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcel($event)" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

export default defineComponent({
  name: "ProductListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);

    const isUploading = ref(false);
    const hideImport = ref(false);

    const actions = [
      {
        groupText: "Perform Actions",
        list: [{ text: "View", type: "VIEW", bold: true }],
      },
    ];

    const columns = [
      {
        title: $t.getTranslation("LBL_ID"),
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().ProductId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IMAGE"),
        maxWidth: 70,
        minWidth: 70,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${cell.getData().DefaultProductImage || require("@/assets/images/placeholder.jpg")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRODUCT_INFO"),
        frozen: true,
        minWidth: 220,
        maxWidth: 300,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().Sku || "--"}</strong></div>
              <div>${cell.getData().ProductName || ""}</div>
              <div>${cell.getData().ProductKey || ""}</div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.StatusCode)} ${cell.getData()?.StatusCode}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },

      {
        title: $t.getTranslation("LBL_TABLE_MERCHANT_INFO"),
        minWidth: 180,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().SupplierCode || "--"}</strong></div>
              <div>${cell.getData().MerchantName || ""}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_WAREHOUSE_INFO"),
        minWidth: 180,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().WareHouseCodeList || "--"}</strong></div>
              <div>${cell.getData().WareHouseRegionCodeList || ""}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_INVENTORY_INFO"),
        minWidth: 180,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_SELLABLE")}:</strong> ${cell.getData().TotalSellableQty || 0}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRICE_INFO"),
        minWidth: 180,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_STORE")}:</strong> ${cell.getData().StoreCode || ""}</div>
              <div><strong>${$t.getTranslation("LBL_CURRENCY")}:</strong> ${cell.getData().CurrencyCode || ""}</div>
              <div><strong>${$t.getTranslation("LBL_REGULAR")}:</strong> ${helpers.formatNumber(cell.getData().RegularSellingPrice) || 0}</div>
              <div><strong>${$t.getTranslation("LBL_DISCOUNT")}:</strong> ${helpers.formatNumber(cell.getData().DiscountPrice) || 0}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_CATEGORY_INFO"),
        minWidth: 120,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().CategoryName || "--"}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_STATUS_INFO"),
        minWidth: 120,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              ${cell.getData()?.IsNew > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_NEW") + "</div></div>" : ""}
              ${cell.getData()?.IsHot > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_HOT") + "</div></div>" : ""}
              ${cell.getData()?.IsSale > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_SALE") + "</div></div>" : ""}
              ${cell.getData()?.IsListed > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_LISTED") + "</div></div>" : ""}
              ${cell.getData()?.IsBest > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_BEST") + "</div></div>" : ""}
              ${cell.getData()?.IsNotDiscounted > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_NOT_DISCOUNTED") + "</div></div>" : ""}
              ${cell.getData()?.IsFuture > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_FUTURE") + "</div></div>" : ""}
              ${cell.getData()?.IsSpecialPrice > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_SPECIAL_PRICE") + "</div></div>" : ""}
              ${cell.getData()?.IsWeeklyRecommended > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_WEEKLY_RECOMMENDED") + "</div></div>" : ""}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="${$t.getTranslation("LBL_DATE_CREATED")}"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="${$t.getTranslation("LBL_DATE_UPDATED")}"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const uploadHKRetail = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const uploadExcel = async (event) => {
      let input = event.target;

      if (input?.files?.[0]) {
        let file = input?.files?.[0];
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/);
        if (!isValidFile) throw new Error($t.getTranslation("LBL_ERR_INVALID_FILE"));

        //upload now
        isUploading.value = true;

        const formData = new FormData();
        formData.append("file", file);

        let ret = await $HTTP.post("/admin/product/hkretail", formData);
        isUploading.value = false;

        if (ret) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_UPLOAD") });
        }
      }
    };

    const url = ref(`/admin/product/datalist?LanguageCode=${currentLanguageCode.value}`);

    if (props?.f7route?.query?.setWeekly) {
      url.value += "&NonWeeklyRecommended=1";
      hideImport.value = true;
    }

    if (props?.f7route?.query?.setFeatured) {
      url.value += "&mode=NONFEATURED";
      hideImport.value = true;
    }

    if (props?.f7route?.query?.SupplierCode) {
      url.value += "&SupplierCode=" + props?.f7route?.query?.SupplierCode;
      hideImport.value = true;
    }

    if (props?.f7route?.query?.CategoryId) {
      url.value += "&CategoryId=" + props?.f7route?.query?.CategoryId;
      hideImport.value = true;
    }

    return {
      url,
      columns,
      actions,
      isUploading,
      uploadExcel,
      uploadHKRetail,
      hideImport,
    };
  },
  methods: {
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.value?.ProductKey && self?.f7route?.query?.isFeatured) {
            self.f7router.navigate({ name: "productMarketing", query: { isFeatured: 1, key: data.value.ProductKey } });
            return;
          }

          if (data?.value?.ProductKey && !self?.f7route?.query?.setWeekly) {
            self.f7router.navigate({ name: "productView", params: { key: data.value.ProductKey } });
            return;
          } else {
            self.f7router.navigate({ name: "productRecommendedSave", params: { key: data?.value?.ProductKey } });
          }

          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>
