<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_GROUP_COUPON") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent ref="data" :skipRemark="true" :columns="columns" :size="10" :url="url" @editItem="editItem" @deleteItem="deleteItem"></DataTableComponent>
    <SelectMultiplePopupComponent ref="selectMultiplePopupComponent" :backdrop="true" @selected="onMultipleSelected" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, computed, ref } from "vue";

import SelectMultiplePopupComponent from "@/components/SelectMultiplePopupComponent.vue";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

import { useStore } from "@/store";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import _ from "lodash";

export default defineComponent({
  name: "VoucherGroupListPage",
  components: {
    SelectMultiplePopupComponent,
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const selectMultiplePopupComponent = ref("selectMultiplePopupComponent");
    const $t = inject("$translation");
    const store = useStore();
    const data = ref("data");

    const columns = [
      {
        title: $t.getTranslation("LBL_COUPON_ID"),
        minWidth: 500,
        formatter(cell) {
          return `
            <div style="max-width:800px;">
              ${_.map(cell.getData()?.List, (r) => {
                return `${r.VoucherName} (ID: ${r.VoucherId})`;
              }).join(", ")}
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusName || "--"}</div>
              </div>
            </div>
          `;
        },
      },
    ];

    const deleteItem = async (item) => {
      await $HTTP.post("/admin/voucher/group/delete", { VoucherGroupId: item.value.VoucherGroupId });
      data.value.initTabulator();
    };

    const onMultipleSelected = async ({ type, list }) => {
      if (!list.length) return;

      let VoucherGroupId = type || "";
      let res = await $HTTP.post(`/admin/voucher/group/save`, {
        VoucherGroupId,
        List: _.map(list, (r) => {
          return r.VoucherId;
        }),
      });

      data.value.initTabulator();
    };

    let url = `/admin/voucher/group/list`;
    return {
      data,
      deleteItem,
      onMultipleSelected,
      columns,
      url,
    };
  },
  methods: {
    editItem(item) {
      this.$refs.selectMultiplePopupComponent.openPopup(
        {
          value: item.value.VoucherGroupId,
          title: "Update Voucher",
          buttonText: "Update",
          params: {
            valueSelector: "VoucherId",
            nameSelector: "VoucherName",
            descriptionSelector: "VoucherDescription",
            getParams: {},
            getLink: "/admin/voucher/all/list",
            type: "dynamic",
          },
        },
        JSON.parse(JSON.stringify(item.value.List))
      );
    },
    create() {
      this.$refs.selectMultiplePopupComponent.openPopup(
        {
          value: null,
          title: "Select Voucher",
          buttonText: "Confirm",
          params: {
            valueSelector: "VoucherId",
            nameSelector: "VoucherName",
            descriptionSelector: "VoucherDescription",
            getParams: {},
            getLink: "/admin/voucher/all/list",
            type: "dynamic",
          },
        },
        []
      );
    },
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
