<template>
  <f7-popup class="imageupload-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation("LBL_UPLOAD_IMAGE") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block v-if="imageSource" class="upload-image-container">
        <cropper v-if="open" ref="cropper" class="cropper" :src="imageSource" :stencil-props="stencil" @change="cropperChange"></cropper>

        <div class="button-container">
          <div>
            <f7-button outline large @click="rotateImage($refs)">
              <font-awesome-icon :icon="['far', 'sync']" fixed-width />
            </f7-button>
            <f7-button outline large @click="flipImage($refs)">
              <font-awesome-icon :icon="['far', 'exchange-alt']" fixed-width />
            </f7-button>
            <f7-button outline large @click="removeImage($refs)">
              <font-awesome-icon :icon="['far', 'trash']" fixed-width />
            </f7-button>
          </div>
          <div>
            <f7-button large @click="changeImage($refs)">
              <font-awesome-icon :icon="['far', 'images']" fixed-width />
            </f7-button>
            <f7-button fill large @click="useImage"> {{ $t.getTranslation("LBL_USE_IMAGE") }} </f7-button>
          </div>
        </div>
      </f7-block>

      <f7-block v-if="!imageSource" class="upload-image-container select-image" @click="selectImage($refs)">
        <img src="@/assets/images/svg/upload-add.svg" alt="Select Image" />
        <h1>{{ title || $t.getTranslation("LBL_BROWSE_SELECT_IMAGE") }}</h1>
        <p>
          {{ description || $t.getTranslation("LBL_BROWSE_SELECT_IMAGE_SUB") }}<br />
          <strong>{{ $t.getTranslation("LBL_MAXIMUM_UPLOAD_IMAGE_SIZE_IS") }} {{ imageSizeLimit }}MB.</strong>
        </p>
      </f7-block>

      <input ref="fileInput" capture="user" type="file" accept="image/*" @change="loadImage($event)" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import { f7 } from "framework7-vue";

import { helpers } from "@/utils/helpers.js";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ImageUploadPopUpComponent",
  components: { Cropper },
  props: {
    open: Boolean,
    title: String,
    description: String,
    imageSizeLimit: Number,
    stencil: Object,
  },
  emits: ["opened", "closed", "cropped"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    let mainCoordinates = false;
    let mainCanvas = false;
    let file = false;

    const imageSource = ref(false);

    onMounted(() => reset);

    const reset = () => {
      imageSource.value = false;
      mainCoordinates = false;
      mainCanvas = false;
    };

    const openPopup = () => {
      emit("opened", true);
    };

    const closePopup = () => {
      emit("closed", true);
      setTimeout(reset, 300);
    };

    const selectImage = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const loadImage = (event) => {
      let input = event.target;

      if (input?.files?.[0]) {
        if (input?.files?.[0]?.size > props.imageSizeLimit * 1000 * 1024) {
          throw new Error(`${$t.getTranslation("LBL_ERROR_IMAGE_FILE_SIZE_EXCEEDED")} ${props.imageSizeLimit} MB`);
        }

        file = input?.files?.[0];
        let reader = new FileReader();

        reader.onload = (e) => {
          imageSource.value = e.target.result;
        };

        reader.readAsDataURL(input.files[0]);
      }
    };

    const changeImage = (refs) => {
      refs.fileInput.click();
    };

    const flipImage = (refs) => {
      refs.cropper.flip(true, false);
    };

    const rotateImage = (refs) => {
      refs.cropper.rotate(-90);
    };

    const removeImage = (refs) => {
      imageSource.value = false;
    };

    const useImage = () => {
      let converted = false;
      let imageQuality = 0.75;
      let isPNG = imageSource.value?.indexOf("image/png") > -1 ? true : false;

      const toBlob = (blob) => {
        try {
          let currentSize = blob?.size;
          let sizeLimit = props.imageSizeLimit * 1000 * 1024;

          if (currentSize > sizeLimit) {
            mainCanvas.toBlob(toBlob, isPNG ? "image/png" : "image/jpeg", imageQuality);
            if (imageQuality > 0) {
              imageQuality -= 0.1;
            }
          } else {
            converted = true;
          }

          if (converted) {
            f7.popup.close();
            emit("cropped", { blob, file });
          }
        } catch (error) {
          helpers.catchError(error, true);
        }
      };

      mainCanvas.toBlob(toBlob, isPNG ? "image/png" : "image/jpeg");
    };

    const cropperChange = ({ coordinates, canvas }) => {
      mainCoordinates = coordinates;
      mainCanvas = canvas;
    };

    return {
      props,
      openPopup,
      closePopup,
      imageSource,
      selectImage,
      loadImage,
      cropperChange,
      changeImage,
      rotateImage,
      flipImage,
      removeImage,
      useImage,
    };
  },
};
</script>

<style scoped>
.imageupload-popup.popup .navbar .title {
  margin-left: 15px;
}
.upload-image-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 20px 0px 0px;
  padding: 20px 30px 0px;
}
.select-image {
  margin-top: 40px;
}
.upload-image-container img {
  width: 250px;
}
.upload-image-container h1 {
  font-size: 28px;
  margin: 60px 0px 0px;
  font-weight: 400;
  text-align: center;
}
.upload-image-container p {
  margin: 20px 0px;
  padding: 10px 50px;
  font-size: 14px;
  text-align: center;
}
input[type="file"] {
  display: none;
}
.button-container {
  display: flex;
  margin: 20px 0px;
  width: 100%;
  justify-content: space-evenly;
}
.button-container div {
  display: flex;
}
.button-container div:first-child {
  justify-content: flex-start;
}
.button-container div:last-child {
  justify-content: flex-end;
}
.button-container .button {
  min-width: 50px;
  margin-right: 10px;
}
.button-container .button:last-child {
  margin-right: 0px;
}
.button-container .button-fill {
  min-width: 160px;
}
.cropper {
  width: 460px;
  height: 380px;
}
@media only screen and (max-width: 899px) {
  .upload-image-container img {
    width: 180px;
    margin-top: 60px;
  }
  .upload-image-container h1 {
    font-size: 24px;
    margin: 60px 0px 0px;
  }
  .upload-image-container p {
    padding: 10px 0px;
  }
  .upload-image-container p strong {
    display: block;
    margin-top: 10px;
  }
  .upload-image-container .button-container {
    justify-content: center;
    flex-direction: column;
  }
  .upload-image-container .button-container div {
    justify-content: center;
    margin-bottom: 20px;
  }
}
</style>
