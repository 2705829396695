<template>
  <f7-popup id="saveBannerPopup" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="false">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <FormComponent ref="formComponent" @successSave="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { f7 } from "framework7-vue";

import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";

const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "BannerSavePartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    const title = ref("");

    const formComponentData = {
      buttonText: "",
      getLink: "/admin/banner/view",
      getParams: {},
      validation: "banner",
      saveLink: "/admin/banner/save",
      saveParams: {},
      successCreateMessage: $t.getTranslation("MSG_SUCCESSFULLY_CREATED_NEW_ITEM"),
      successUpdateMessage: $t.getTranslation("MSG_SUCCESSFULLY_UPDATED_ITEM_INFORMATION"),
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("LBL_BANNER_INFORMATION"),
        type: "input",
        list: [
          {
            name: $t.getTranslation("LBL_BANNER_TYPE"),
            type: "select",
            value: "Type",
            required: true,
            hideClearButton: true,
            items: store.getters["config/getData"]?.bannerTypeList,
            nameSelector: "name",
            valueSelector: "value",
            defaultValue: "",
            showHelper: true,
            onChange: async (event) => {
              let inputList = store.getters["form/getInputList"];

              if (event.target.value === "SHOPVIDEO") {
                inputList[0].list[1].hidden = true;
                inputList[0].list[2].hidden = true;

                inputList[2].hidden = true;
                inputList[3].hidden = false;
              } else {
                inputList[0].list[1].hidden = false;
                inputList[0].list[2].hidden = false;

                inputList[2].hidden = false;
                inputList[3].hidden = true;
              }

              store.dispatch("form/setInputList", inputList);
            },
          },
          { name: $t.getTranslation("LBL_LINK"), type: "text", value: "Link", required: false, hidden: false },
          { name: $t.getTranslation("LBL_POSITION"), type: "number", value: "Position", required: false, acceptinput: "number", hidden: false },
        ],
      },
      {
        name: $t.getTranslation("LBL_CONTENT"),
        type: "translation",
        value: "Translation",
        title: $t.getTranslation("LBL_SET_TRANSLATION_CONTENT"),
        buttonText: $t.getTranslation("LBL_SET_TRANSLATION_CONTENT"),
        list: [
          { name: $t.getTranslation("LBL_BANNER_NAME"), type: "text", value: "BannerName", required: false },
          { name: $t.getTranslation("LBL_DESCRIPTION"), type: "textarea", value: "Description", required: false },
        ],
      },
      { name: $t.getTranslation("LBL_IMAGE"), type: "image", value: "Image", required: false, limit: 3, sizeLimit: 1, canvas: { maxHeight: 900, maxWidth: 1800 }, hidden: false },
      { name: $t.getTranslation("LBL_VIDEO"), type: "video", value: "Video", required: false, limit: 1, hidden: false },
      {
        name: $t.getTranslation("LBL_STATUS_INFORMATION"),
        type: "input",
        list: [
          {
            name: $t.getTranslation("LBL_STATUS"),
            type: "select",
            value: "StatusCode",
            required: false,
            hideClearButton: true,
            items: store.getters["config/getData"]?.statusList,
            nameSelector: "Name",
            valueSelector: "Code",
            defaultValue: "ACTIVE",
          },
        ],
      },
    ];

    return {
      title,
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup(data) {
      const self = this;
      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);

      if (data?.BannerId) {
        self.title = self.$t.getTranslation("LBL_UPDATE_BANNER");
        self.formComponentData.buttonText = self.$t.getTranslation("LBL_UPDATE");
        self.formComponentData.getParams.BannerId = data?.BannerId;
        self.formComponentData.saveParams.BannerId = data?.BannerId;

        self.$refs.formComponent.setFormComponentData(self.formComponentData, true);
      } else {
        self.title = self.$t.getTranslation("LBL_CREATE_BANNER");
        self.formComponentData.buttonText = self.$t.getTranslation("LBL_CREATE");
        self.formComponentData.getParams = {};
        self.formComponentData.saveParams = {};

        self.$refs.formComponent.setFormComponentData(self.formComponentData);
      }

      f7.popup.open("#saveBannerPopup");
      self.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#saveBannerPopup");
      this.$emit("closed", data);
    },
  },
});
</script>
