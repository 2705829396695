<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <f7-popup id="datepopup" class="options-popup with-tabs" push>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="closePopup()">
            <font-awesome-icon :icon="['far', 'long-arrow-left']" fixed-width />
          </f7-link>
        </f7-nav-left>
      </f7-navbar>

      <f7-toolbar tabbar bottom :no-shadow="true">
        <f7-link tab-link="#tab-3" tab-link-active>
          <div class="flex">{{ $t.getTranslation("LBL_ECORE_INVENTORY_LOCATION_IMPORT") }}</div>
        </f7-link>
      </f7-toolbar>

      <section class="form-container">
        <div class="container">
             <f7-list no-hairlines>
                <f7-list-item divider>{{ $t.getTranslation("LBL_DATE_TO_TRIGGER") }}</f7-list-item>
                <f7-list-input
                  v-model:value="formData.StartDate"
                  name="StartDate"
                  :required="validationRules?.StartDate?.required"
                  :minlength="validationRules?.StartDate?.minimumLength"
                  :maxlength="validationRules?.StartDate?.maximumLength"
                  :label="$t.getTranslation('LBL_VALID_FROM')"
                  :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                  floating-label
                  outline
                  type="datetime-local"
                  placeholder="YYYY/MM/DD"
                  validate
                >
                </f7-list-input>
               <f7-list-input
                  v-model:value="formData.EndDate"
                  name="StartDate"
                  :required="validationRules?.EndDate?.required"
                  :minlength="validationRules?.EndDate?.minimumLength"
                  :maxlength="validationRules?.EndDate?.maximumLength"
                  :label="$t.getTranslation('LBL_VALID_TO')"
                  :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
                  floating-label
                  outline
                  type="datetime-local"
                  placeholder="YYYY/MM/DD"
                  validate
                >
                </f7-list-input>

                <f7-button large fill raised preloader :disabled="isUploading" @click="triggerEcore($refs)">
                    {{ $t.getTranslation("LBL_TRIGGER") }}
                </f7-button>
            </f7-list>
        </div>
      </section>

     
    </f7-popup>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_PRODUCT_INVENTORY_LOCATION") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="openPopup($refs)">{{ $t.getTranslation("LBL_TRIGGER_ECORE_INVENTORY_LOCATION") }}</f7-button>
        <!-- <f7-button large icon="far fa-fw fa-cog"></f7-button> -->
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @viewItem="editItem" ref="datatable"></DataTableComponent>

    <MainFooterComponent />
    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcel($event, $refs)" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, reactive } from "vue";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
import { f7 } from "framework7-vue";
export default defineComponent({
  name: "ProductListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const isUploading = ref(false);
    const hideImport = ref(false);
    const formData = reactive({
      EndDate: null,
      StartDate: null
    })

    const columns = [
      {
        title: $t.getTranslation("LBL_SKU"),
        field: "Sku",
      },
      {
        title: $t.getTranslation("LBL_QTY"),
        field: "SellableQty",
      },
      {
        title: $t.getTranslation("LBL_WAREHOUSE"),
        field: "WareHouseCode"
      },
      {
        title: $t.getTranslation("LBL_WAREHOUSE_REGION"),
        field: "WareHouseRegionCode"
      },
      {
        title: $t.getTranslation("LBL_LAST_CREATED"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        },
      }
    ];

    const editItem = (data) => {
      return props.f7router.navigate({ name: "productInventoryView", query: { id:  data?.value?.InventoryLocationId } });
    };


    const upload = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    const uploadExcel = async (event, $refs) => {
      let input = event.target;

      if (input?.files?.[0]) {
        let file = input?.files?.[0];
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/);
        if (!isValidFile) throw new Error($t.getTranslation("LBL_ERR_INVALID_FILE"));

        //upload now
        isUploading.value = true;
        let ret = await $HTTP.post("/admin/product/inventory/location/import", formData);
        isUploading.value = false;

        if (ret) {
          helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_TRIGGER_ECORE") });

          if($refs){
            setTimeout(()=>{
              $refs.datatable.initTabulator();
            }, 300);
          };
        }
      }
    };



    const triggerEcore = async ($refs) => {

      if (helpers.isBlank(formData.StartDate)) {
        helpers.createNotification({ type: "error", title: $t.getTranslation("LBL_ERROR"), message: $t.getTranslation("ERR_INVALID_DATE") });
        return;
      }

      if (helpers.isBlank(formData.EndDate)) {
        helpers.createNotification({ type: "error", title: $t.getTranslation("LBL_ERROR"), message: $t.getTranslation("ERR_INVALID_DATE") });
        return;
      }

      isUploading.value = true;
      let ret = await $HTTP.post("/admin/product/inventory/location/ecore", formData);
      isUploading.value = false;
      closePopup();

      if (ret) {

        formData.StartDate = null;
        formData.EndDate = null;

        helpers.createNotification({ type: "success", title: $t.getTranslation("LBL_SUCCESS"), message: $t.getTranslation("MSG_SUCCESS_UPLOAD") });

        if($refs){
          setTimeout(()=>{
            $refs.datatable.initTabulator();
          }, 300);
        };
      }
    };

    const openPopup = () => {
      f7.popup.get("#datepopup").open();
    };

    const closePopup = () => {
      f7.popup.get("#datepopup").close();
    };

    let url = `/admin/product/inventory/location/list`;

    return {
      columns,
      url,
      editItem,
      currentLanguageCode,
      hideImport,
      upload,
      uploadExcel,
      formData,
      openPopup,
      closePopup,
      triggerEcore,
      isUploading
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}

input[type="file"] {
    display: none;
}
</style>
