<template>
  <f7-page class="home-page chat-page">

    <f7-photo-browser
        :photos="photos"
        id="popup"
        ref="popup"
        theme="dark"
        type="popup"
        :toolbar="false"
        :popup-close-link-text="$t.getTranslation('BTN_CLOSE')"
        :routable-modals="false"
     ></f7-photo-browser>



    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1> {{ $t.getTranslation("LBL_MESSAGES") }}</h1>
        </div>
      </div>

      <div class="button-container" v-if="ThreadData">
        <f7-button @click="reAssign" large fill raised>{{ $t.getTranslation('BTN_RE_ASSIGN') }}</f7-button>
      </div>
    </div>


    <div class="content">
      
        <div class="message-wrap" id="msg-content">

           <f7-messages-title v-if="HavePrevious">
              <f7-link @click="loadPrev">
                {{ $t.getTranslation('LBL_LOAD_PREVIOUS_MESSAGES') }}
              </f7-link>
           </f7-messages-title>


        <div class="empty-msg-list"  v-if="MsgList.length == 0" >
           <NoDataFoundComponent :size="'sm'" :title="$t.getTranslation('LBL_EMPTY_MESSAGES')" />
        </div>

        <template v-for="item in MsgList" :key="item.ThreadMsgKey">

             
            <div class="message-list me" v-if="item.ThreadMsgTypeCode == 'SYSTEM'">
                 <div class="chat-msg">
                    <div class="msg">
                       <p>
                         {{ $h.replaceData($t.getTranslation(item.Content), item.Data) }}
                       </p>
                    </div>
                    <div class="time-me">{{ $t.getTranslation('LBL_SYSTEM_GENERATED_MSG') }} - {{ $h.formatDateTime(item.LastCreated) }}</div>
                 </div>

                 <div class="chat-images-me">
                    <img class="msg-image" :src="$h.getImage(null, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
                 </div>
            </div>

            <template v-else>
                

                <div class="message-list" v-if="item.UserKey == UserKey">

                 <div class="chat-images">
                    <img class="msg-image" :src="$h.getImage(item.User?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
                 </div>

                 <div class="chat-msg">
                    <div :class="{'msg': (item.ThreadMsgTypeCode == 'TEXT')}">
                      
                       <p v-if="item.ThreadMsgTypeCode == 'TEXT'">
                          {{ item.Content }}
                       </p>

                       <ProductListCardComponent v-if="item.ThreadMsgTypeCode == 'PRODUCT'" :data="item.Product" :category="true" :f7router="f7router" />

                       <OrderItemCardComponent v-if="item.ThreadMsgTypeCode == 'ORDER'" :data="item.Order" class="min-width-chat" :hidestatus="true" :f7router="f7router"/>

                       <img @click="viewImage($refs, item.Content)" v-if="item.ThreadMsgTypeCode == 'IMAGE'" :src="$h.getImage(item.Content, 'CHAT')" class="chat-image-msg" />

     
                    </div>
                    <div class="time"><span style="display: block;opacity: 0.5;font-size: 10px;">UserID: {{ item.User.UserId }} - {{ item.Source || "shop" }}</span>{{ $h.formatDateTime(item.LastCreated) }} - {{ item?.User?.UserName || "---" }}</div>
                 </div>
              </div>

      
              <div class="message-list me" v-else>
                 <div class="chat-msg">
                    <div :class="{'msg': (item.ThreadMsgTypeCode == 'TEXT')}">
                      
                       <p v-if="item.ThreadMsgTypeCode == 'TEXT'">
                          {{ item.Content }}
                       </p>

                       <ProductListCardComponent v-if="item.ThreadMsgTypeCode == 'PRODUCT'" :data="item.Product" :category="true" :f7router="f7router" />


                        <OrderItemCardComponent v-if="item.ThreadMsgTypeCode == 'ORDER'" :data="item.Order" class="min-width-chat" :hidestatus="true" :f7router="f7router"/>

                        <div style="text-align: right;">
                          <img @click="viewImage($refs, item.Content)" v-if="item.ThreadMsgTypeCode == 'IMAGE'" :src="$h.getImage(item.Content, 'CHAT')" class="chat-image-msg" />
                        </div>

                    </div>
                    <div class="time-me"><span style="display: block;opacity: 0.5;font-size: 10px;">UserID: {{ item.User.UserId }} - {{ item.Source || "shop" }}</span>{{ item?.User?.UserName || "---" }} - {{ $h.formatDateTime(item.LastCreated) }}</div>
                 </div>

                 <div class="chat-images-me">
                    <img class="msg-image" :src="$h.getImage(item.User?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
                 </div>

              </div>


            </template>

          </template>
       </div>

    </div>


     <UserSelectionPopUpComponent v-if="ThreadData" :exclude="ThreadData?.CustomerSupport?.UserKey" role="MANAGE_CUSTOMER_SUPPORT" usertype="ADMIN" :all="true" :open="openUserPopup" @closed="onCloseUserPopup" @selected="onUserSelected" />

  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, onUnmounted, onMounted, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
import OrderItemCardComponent from "@/components/cards/OrderItemCardComponent.vue";
import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";
import UserSelectionPopUpComponent from "@/components/UserSelectionPopUpComponent.vue";

import { socket } from "@/utils/socket.js";
import { get, post } from "@/utils/axios";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers";
import { f7 } from "framework7-vue";
import { $HTTP } from "@/utils/axios";

export default defineComponent({
  name: "MessageView",
  components: {
    MainHeaderComponent,
    ProductListCardComponent,
    OrderItemCardComponent,
    NoDataFoundComponent,
    UserSelectionPopUpComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const ThreadKey = ref(null);
    const MsgList = ref([]);
    const store = useStore();
    const HavePrevious = ref(false);
    const UserKey = ref(null);
    const ThreadData = ref(null);

    const loadPrev = async()=>{
      HavePrevious.value = false;
      //get now
      getAll(MsgList.value[0].ThreadMsgKey);
    };

    const getAll = async(LastKey)=>{
      let ret = await get('/chat/history', { LastKey : LastKey, ThreadKey : ThreadKey.value })
      HavePrevious.value = (ret.HavePrevious) ? true : false;
      if(ret.MsgList && ret.MsgList.length){
        if(!LastKey){
          _.each(ret.MsgList.reverse(), (Msg)=>{
             MsgList.value.push(Msg);
          });
        }else{
          _.each(ret.MsgList, (Msg)=>{
             MsgList.value.unshift(Msg);
          });
        }
      };
    }; 

    const getData = async()=>{
      let ret = await get('/chat/details', { ThreadKey : ThreadKey.value })
      ThreadData.value = ret?.ThreadData || null;
    };

    const photos = ref([]);
    const viewImage = ($refs, image)=>{
      photos.value = [helpers.getImage(image, 'CHAT')];
      setTimeout(()=>{
        $refs.popup.open()
      }, 300);
    };


    const openUserPopup = ref(false);
    const reAssign = ()=>{
      openUserPopup.value = true;
    }

    const onUserSelected = (data)=>{
      let message = $t.getTranslation('MSG_CONFIRM_RE_ASSIGN_CHAT');
      helpers.createConfirmation({
        message: message, 
        title:  $t.getTranslation('BTN_RE_ASSIGN'),
        confirm: async () => {
            let ret = await post("/chat/ressign", {
              ThreadKey : ThreadData.value.ThreadKey,
              UserKey : data.UserKey
            });
            if(!ret) return;
            props.f7router.navigate({ name: "messageAllView", query : { UserKey : ThreadData.value.User.UserKey, ThreadKey : ThreadData.value.ThreadKey } }, { reloadCurrent  : true });
        }
      });
    }

    const onCloseUserPopup = () => {
      openUserPopup.value = false;
    };


    onMounted(()=>{
       if (props?.f7route?.query && props?.f7route?.query?.UserKey) {
        UserKey.value = props.f7route.query.UserKey;
      }

      if (props?.f7route?.query && props?.f7route?.query?.ThreadKey) {
        ThreadKey.value = props.f7route.query.ThreadKey;
        getAll();
        getData();
      }
    })

    return {
      onUserSelected,
      openUserPopup,
      onCloseUserPopup,
      ThreadData,
      reAssign,
      photos,
      viewImage,
      UserKey,
      loadPrev,
      HavePrevious,
      MsgList
    };
  },
});


</script>

<style>

/*.system-message-cont{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.system-message-cont div{
  padding:10px;
  border-radius: 20px;
  margin:20px;
  margin-bottom: 10px;
  background: #e1e1e1;
}
.system-message-cont span{
  color: #999;
  font-size: 0.75em;
  margin-bottom: 20px;
}
*/
input[type="file"]{
  opacity: 0;
  position: fixed;
  bottom:0px;
  z-index:-1;
}

.chat-image-msg{
  max-width: 80%;
  border-radius: 5px;
  cursor: pointer;
}

.with-tabs .tabs{
  height: calc(100% - 120px);
}

.with-tabs .tabs .tab{
  height: 100%;
}

.list-search-max-height{
  max-height: calc(100% - 80px);
  overflow: auto;
}

.empty-msg-list{
  width: 100%;
  display: flex;align-items: center;justify-content: center;
}

.isRight{
  position : absolute;right: 20px;
}
.icon-file{
  padding: 3px;
  background-color: #1B3D61;
  color: white;
  border-radius: 50%;
}
.icon-submit{
  padding: 10px;
}
/*.messagebar-area textarea {
  border: none;
  background-color: #ccc;
  border-radius: 20px;
}
*/
.attachment-container{
  height: 300px;padding:10px 10px;
}

.product-card-chat{
  padding: 30px 30px;
  height: 300px;
  overflow-y: auto;
}

.flex{
  display: flex;
  flex-direction: row;
}

.list-wrap > .list.active{
  border-left: 5px solid #122941
}
.container-chat {
  display: flex;
  height: calc(100vh - 180px);
  overflow: hidden !important;
}
.chat-header{
  margin: 0 30px ;
}
sidebar {
  width: 300px;
  min-width: 300px;
  display: flex;
  background: #fff;
  flex-direction: column;
  border-right: 1px solid #ccc;
  transition: 500ms all;
}

sidebar .tabs{
  height: 100%;
  overflow: auto;
}

.logo {
  display: flex;
  padding: 25px 10px 0px;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  font-size: 1.5em;
}
sidebar .list-wrap {
  width: 100%;
  overflow: auto;
}
sidebar .list-wrap .list {
  border-bottom: 1px solid #ccc;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 80px;
  cursor: pointer;
  /*width: 295px;*/
  width: 285px;
}
sidebar .list-wrap .list:hover, sidebar .list-wrap .list.active {
  background: #f2f3f5;
  border-left: 5px solid #1B3D61;
}
sidebar .list-wrap .list img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  /*box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);*/
}
sidebar .list-wrap .list .info {
  flex: 1;
  width: calc(100% - 102px);
}
sidebar .list-wrap .list .info .user {
  font-weight: 700;
}
sidebar .list-wrap .list .info .text {
  display: flex;
  margin-top: 3px;
  font-size: 0.85em;
}
sidebar .list-wrap .list .time {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 0.75em;
  color: #a9a9a9;
}
sidebar .list-wrap .list .count {
  font-size: 0.75em;
  background: #1B3D61;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.7);
  padding: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.content header {
  height: 70px;
  background: #f2f3f5;
  border-bottom: 1px solid #ccc;
  display: flex;
  padding: 10px;
  align-items: center;
}
.content header img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}
.content header .info {
  flex: 1;
}
.content header .info .user {
  font-weight: 700;
}
.content header .info .time {
  display: flex;
  margin-top: 3px;
  font-size: 0.85em;
}
.content header .open {
  display: none;
}
.content header .open a {
  color: #000;
  letter-spacing: 3px;
}
.message-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow: auto;
}
.message-wrap::before {
  content: "";
 /* margin-bottom: auto;*/
}
.message-wrap .message-list {
  align-self: flex-start;
  max-width: 70%;
  display: flex;
}
.message-wrap .message-list.me {
  align-self: flex-end;
}
.message-wrap .message-list.me .msg {
  background: #122941;
  color: #fff;
}
.message-wrap .message-list .msg {
  background: #e1e1e1;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  padding: px 10px;
  margin: 5px;
  border-radius: 25px;
}
.message-wrap .message-list .msg p {
  margin: 5px 15px;
  padding: 5px 0;

}
.message-wrap .message-list .time {
  text-align: left;
  color: #999;
  font-size: 0.75em;
}
.message-wrap .message-list .time-me {
  text-align: right;
  color: #999;
  font-size: 0.75em;
}
.message-footer {
  padding: 10px;
  display: flex;
  height: 60px;
}
/*.message-footer input {
  flex: 1;
  padding: 0 20px;
  border-radius: 20px;
  border: 1px solid #ccc;
  height: 40px;
  font-size: 18px;
}*/
.msg-image{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.chat-images{
  padding-right:20px
}
.chat-images-me{
  padding-left:20px
}

.count-time {
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  height: 45px;
}

.modal-chat-attach-backdrop{
	position: fixed;
	z-index:999999;
	background: rgba(0,0,0,0.5);
	width: 100%;
	height: 100%;
}

.modal-chat-attach{
	background: #fff;

	max-width:500px;
	width: 80%;
}

.chat-page .page-content{
	padding-bottom:0px !important;
}

.show-mobile{
	display: none !important;
}

@media only screen and (max-width: 767px) {
   
  .hide-on-mobile{
  	display: none;
  }

  .show-mobile{
  	display: block !important;
  }

  sidebar{
  	width: 100%;
  }

  sidebar .list-wrap .list {
  	width: calc(100% - 10px) !important;
  }
}

</style>
