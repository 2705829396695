<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'users']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_USER_ACTIVITY_LOG_LIST") }}</h1>
          <h3>List of available activity log by selected user.</h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <UserActivityLogSavePartial ref="userActivityLogSavePartial" @closed="initDataTableComponent" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import UserActivityLogSavePartial from "@/partials/user/UserActivityLogSavePartial.vue";

import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "UserActivityLogListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    // PARTIALS
    UserActivityLogSavePartial,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const actions = [
      {
        groupText: "Perform Actions",
        list: [{ text: "Edit", type: "EDIT", bold: false }],
      },
      {
        groupText: "",
        list: [{ text: "Delete", type: "DELETE", color: "red" }],
      },
    ];

    const columns = [
      {
        title: "Id",
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().UserActivityLogId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_INFO"),
        minWidth: 220,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.getFullName(cell.getData(), true)}</strong></div>
              <div><strong>${cell.getData().UserName || ""}</strong></div>
              <div>${cell.getData().UserKey}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PROCESS_INFO"),
        minWidth: 220,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.getFullName({ FirstName: cell.getData().ProcessFirstName, LastName: cell.getData().ProcessLastName }, true)}</strong></div>
              <div><strong>${cell.getData().ProcessUserName || ""}</strong></div>
              <div>${cell.getData().ProcessUserKey}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_TYPE"),
        minWidth: 200,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().Type}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_CONTENT"),
        minWidth: 350,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().Content}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_ACTIVITY_DATE"),
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.formatDateTime(cell.getData().ActivityDate)}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="Date Created"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="Date Updated"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const url = ref("/admin/useractivitylog/datalist");

    if (props?.f7route?.query?.UserId && props?.f7route?.query?.UserId != "") {
      url.value += `?UserId=${props?.f7route?.query?.UserId}`;
    }

    return {
      url,
      columns,
      actions,
    };
  },
  mounted() {
    this.UserId = this.f7route?.query?.UserId;
  },
  methods: {
    create() {
      this.$refs.userActivityLogSavePartial.openPopup({ UserId: this.UserId });
    },
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "EDIT":
          if (data?.value?.UserActivityLogId) {
            self.$refs.userActivityLogSavePartial.openPopup(data.value);
          }
          break;
        case "DELETE":
          if (data?.value?.UserActivityLogId) {
            helpers.createConfirmation({
              message: "Are you sure you want to delete selected user activity log?",
              confirm: async () => {
                let returnData = await post("/admin/useractivitylog/delete", { UserActivityLogId: data?.value?.UserActivityLogId });
                if (returnData)
                  helpers.createNotification({
                    type: "info",
                    title: "INFO",
                    message: "Successfully deleted selected user activity log.",
                  });

                self.$refs.dataTableComponent.initTabulator();
              },
            });
          }
          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
