<template>
  <f7-popup id="saveCategoryPopup" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="false">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <FormComponent ref="formComponent" @successSave="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { f7 } from "framework7-vue";

import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";

const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "CategorySavePartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    const title = ref("");

    const formComponentData = {
      buttonText: "",
      getLink: "/admin/category/view",
      getParams: {},
      validation: "category",
      saveLink: "/admin/category/save",
      saveParams: {},
      successCreateMessage: $t.getTranslation("MSG_SUCCESSFULLY_CREATED_NEW_ITEM"),
      successUpdateMessage: $t.getTranslation("MSG_SUCCESSFULLY_UPDATED_ITEM_INFORMATION"),
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("LBL_CATEGORY_INFORMATION"),
        type: "input",
        list: [
          { name: $t.getTranslation("LBL_CATEGORY_CODE"), type: "text", value: "CategoryCode", required: true },
          { name: $t.getTranslation("LBL_POSITION"), type: "number", value: "Position", required: false, acceptinput: "number", hidden: false },
        ],
      },
      {
        name: $t.getTranslation("LBL_CONTENT"),
        type: "translation",
        value: "Translation",
        title: $t.getTranslation("LBL_SET_TRANSLATION_CONTENT"),
        buttonText: $t.getTranslation("LBL_SET_TRANSLATION_CONTENT"),
        list: [{ name: $t.getTranslation("LBL_CATEGORY_NAME"), type: "text", value: "CategoryName", required: true }],
      },
      {
        name: $t.getTranslation("LBL_TAX_INFORMATION"),
        type: "input",
        list: [{ name: $t.getTranslation("LBL_TAX_RATE"), type: "text", value: "TaxRate", required: false, acceptinput: "number", hidden: false }],
      },
      { name: $t.getTranslation("LBL_IMAGE"), type: "image", value: "Image", required: false, limit: 3, sizeLimit: 1, canvas: { maxHeight: 900, maxWidth: 1800 }, hidden: false },
      {
        name: $t.getTranslation("LBL_STATUS_INFORMATION"),
        type: "input",
        list: [
          {
            name: $t.getTranslation("LBL_IS_FEATURED"),
            type: "select",
            value: "IsFeatured",
            required: false,
            hideClearButton: true,
            items: store.getters["config/getData"]?.binaryChoiceList,
            nameSelector: "name",
            valueSelector: "value",
            defaultValue: 0,
            rowWidth: "col-50",
          },
          {
            name: $t.getTranslation("LBL_STATUS"),
            type: "select",
            value: "StatusCode",
            required: false,
            hideClearButton: true,
            items: store.getters["config/getData"]?.statusList,
            nameSelector: "Name",
            valueSelector: "Code",
            defaultValue: "ACTIVE",
          },
        ],
      },
    ];

    return {
      title,
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup(data) {
      const self = this;
      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);

      if (data?.CategoryId) {
        self.title = self.$t.getTranslation("LBL_UPDATE_CATEGORY");
        self.formComponentData.buttonText = self.$t.getTranslation("LBL_UPDATE");
        self.formComponentData.getParams.CategoryId = data?.CategoryId;
        self.formComponentData.saveParams.CategoryId = data?.CategoryId;

        self.$refs.formComponent.setFormComponentData(self.formComponentData, true);
      } else {
        self.title = self.$t.getTranslation("LBL_CREATE_CATEGORY");
        self.formComponentData.buttonText = self.$t.getTranslation("LBL_CREATE");
        self.formComponentData.getParams = {};
        self.formComponentData.saveParams = {};

        self.$refs.formComponent.setFormComponentData(self.formComponentData);
      }

      f7.popup.open("#saveCategoryPopup");
      self.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#saveCategoryPopup");
      this.$emit("closed", data);
    },
  },
});
</script>
