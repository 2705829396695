<template>
  <section class="table-container">
    <div class="container">
    
        <div>
            <h1 class="table-title">{{ $t.getTranslation("LBL_TOP") }}</h1>
        </div>
      <div id="tabulator" ref="tableRef"></div>
   

      

    </div>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, watch, inject } from "vue";
import { Dom7 } from "framework7";
import { f7 } from "framework7-vue";
import Tabulator from "tabulator-tables";


import { $HTTP } from "@/utils/axios";
import { configs } from "@/utils/configs";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

const FROZEN_WIDTH = 1000;

export default defineComponent({
  name: "BarChartTableComponent",
  props: {
    static: Boolean,
    url: String,
    columns: [String, Array],
    nodata: String,
    size: Number,
  },
  emits: [],
  setup(props, { emit }) {
    const $t = inject("$translation");

    let windowWidth = 0;
    let isAllowFrozen = true;

    let fileName = "";

    const store = useStore();

    const tableRef = ref();
    const tabulator = ref();
    const columns = ref([]);
    const tableInfo = ref(false);

    const actions = ref(false);
    const options = ref(false);

    const searchFieldList = ref([]);
    const searchFieldNameList = ref([]);
    const selectedRow = ref(false);
    const selectedRowData = ref(false);

    const filterList = ref([]);

    let defaultFormData = {
      size: options.value?.size?.default || props?.size || 15,
      page: 15,
      SearchField: searchFieldList,
      SearchType: "",
      SearchValue: "",
      SortType: options.value?.sort?.default?.type || "",
      SortValue: options.value?.sort?.default?.value || "",
      FilterType: options.value?.filter?.default?.type || "",
      FilterValue: options.value?.filter?.default?.value || [],
    };

 

    const formData = reactive({ ...defaultFormData });

    const getTableConfig = async () => {
      try {
        let isAdd = props.url.indexOf("?") > -1 ? "&" : "?";
        let res = await $HTTP.get(`${props.url}${isAdd}page=1&size=${props.size}&config=1`);
        if (res?.status === 200 && res?.data?.data) {
          actions.value = res?.data?.data.actions;
          options.value = res?.data.data?.options;
        }
      } catch (err) {
        helpers.catchError(err, true);
      }
    };

    const prepareColumns = () => {
      if (props?.columns && helpers.isArray(props.columns)) {
        columns.value = [];

        let defaultValue = {
          minWidth: 200,
          hozAlign: "left",
          vertAlign: "middle",
          print: false,
          download: false,
        };

        for (let item of props?.columns) {
          let preparedItem = {
            ...defaultValue,
            ...item,
          };

          preparedItem.frozen = preparedItem.frozen && isAllowFrozen ? true : false;
          columns.value.push(preparedItem);
        }

        // prepare data for printing
        if (options.value?.print?.enabled && options.value?.print?.list) {
          for (let item of options.value?.print?.list) {
            columns.value.push({
              title: helpers.formatConfigValue(item),
              field: item,
              visible: false,
              print: true,
              download: false,
            });
          }
        }

        // prepare data for exporting
        if (options.value?.export?.enabled && options.value?.export?.list) {
          for (let item of options.value?.export?.list) {
            columns.value.push({
              title: helpers.formatConfigValue(item),
              field: item,
              visible: false,
              print: false,
              download: true,
            });
          }
        }
      }
    };

    //initialize table
    const initTabulator = () => {
      selectedRow.value = false;
      selectedRowData.value = false;

      let tabulatorConfig = {
        ajaxURL: `${configs.baseURL}${props.url}`,
        ajaxFiltering: true,
        ajaxSorting: true,
        paginationSize: formData.size,
        paginationDataReceived: {
          last_page: "lastPage",
        },
        ajaxURLGenerator: (url, config, params) => {
          let ajaxURL = `${configs.baseURL}${props.url}`;

          formData.page = params.page;
          formData.size = params.size;

          for (let key in formData) {
            if (formData[key] !== "") {
              if (formData[key] === "FilterType") {
                formData[key] = "MULTIPLE";
              }

              if (formData[key] === "FilterValue") {
                formData[key] = JSON.stringify(formData[key]);
              }

              ajaxURL += ajaxURL.indexOf("?") > -1 ? "&" : "?";
              ajaxURL += `${key}=${formData[key]}`;
            }
          }

          return ajaxURL;
        },
        ajaxResponse: (response) => {
          let data = response?.data || {};

          tableInfo.value = {
            totalPage: data.lastPage,
            totalData: data.totalData,
          };

          return data;
        },
        ajaxError: async (err) => {
          let errorData = await err.json();
          helpers.catchError(errorData, true);
        },
        layout: "fitDataFill",
        tooltips: true,
        printAsHtml: true,
        printStyled: true,
        movableColumns: true,
        movableRows: false,
        headerSort: false,
        placeholder: props.nodata || "No records found",
        rowClick: (e, row) => {
          selectedRow.value = row;
          selectedRowData.value = row.getData();

          if (props.static) return;

          f7.actions.get("#actionsTableRowClick").open();
        },
        downloadReady: (fileContents, blob) => {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.classList.add("external");

          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();

          window.URL.revokeObjectURL(url);
          return false;
        },
        ajaxLoaderLoading: `<span></span>`,
        ajaxLoaderError: `<span></span>`,
        locale: true,
        langs: {
          en: {
            pagination: {
              page_size: $t.getTranslation("LBL_PAGE_SIZE"),
              page_title: $t.getTranslation("LBL_SHOW_PAGE"),
              first: $t.getTranslation("LBL_FIRST"),
              last: $t.getTranslation("LBL_LAST"),
              prev: $t.getTranslation("LBL_PREV"),
              next: $t.getTranslation("LBL_NEXT"),
              all: $t.getTranslation("LBL_ALL"),
              first_title: $t.getTranslation("LBL_FIRST_PAGE"),
              last_title: $t.getTranslation("LBL_LAST_PAGE"),
              prev_title: $t.getTranslation("LBL_PREV_PAGE"),
              next_title: $t.getTranslation("LBL_NEXT_PAGE"),
            },
          },
        },
      };

      const userToken = store.getters["user/getToken"];
      if (userToken && userToken !== "") {
        tabulatorConfig.ajaxConfig = {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };
      }

      tabulatorConfig.pagination = "remote";
      tabulatorConfig.paginationSizeSelector = [15, 50, 100, 250];

      if (props?.columns && helpers.isString(props.columns) && props.columns == "autoColumns") {
        tabulatorConfig.autoColumns = true;
      } else {
        prepareColumns();
        tabulatorConfig.columns = columns.value;
      }

      tabulator.value = new Tabulator(tableRef.value, tabulatorConfig);
    };

    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        isAllowFrozen = windowWidth >= FROZEN_WIDTH;

        initTabulator();
      });
    };


    


  

    onMounted(async () => {
      windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      isAllowFrozen = windowWidth >= FROZEN_WIDTH;

      await getTableConfig();
      initTabulator();
      tabulator.value.setLocale("en");

      reInitOnResizeWindow();

      Dom7(".page").on("click", ".image", (e) => {
        helpers.openImage(e.target.src);
        f7.actions.get("#actionsTableRowClick").close();
      });
    });


    return {
      tableRef,
      tableInfo,
      searchFieldList,
      searchFieldNameList,
      filterList,
      selectedRowData,
      formData,
      actions,
      options,
      initTabulator,
    };
  },
});
</script>

<style>
.options-popup {
  --f7-popup-tablet-height: 650px;
}
.options-popup .page-content {
  padding: 30px 30px 20px;
}
.md .search-field .item-input-info {
  overflow: visible;
}
.table-title{
    font-weight: 900;
    padding: 25px 0;
}
</style>
