<template>
  <f7-page class="home-page chat-page">
    <f7-photo-browser :photos="photos" id="popup" ref="popup" theme="dark" type="popup" :toolbar="false" :popup-close-link-text="$t.getTranslation('BTN_CLOSE')" :routable-modals="false"></f7-photo-browser>

    <f7-popup id="popupOptionMenu" class="options-popup with-tabs" push>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="closePopup()">
            <font-awesome-icon :icon="['far', 'long-arrow-left']" fixed-width />
          </f7-link>
        </f7-nav-left>
      </f7-navbar>

      <f7-toolbar tabbar bottom :no-shadow="true">
        <f7-link tab-link="#tab-3" tab-link-active>
          <div class="flex">{{ $t.getTranslation("LBL_ORDERS") }}</div>
        </f7-link>

        <f7-link tab-link="#tab-4">
          <div class="flex">{{ $t.getTranslation("LBL_PRODUCTS") }}</div>
        </f7-link>
      </f7-toolbar>

      <f7-tabs>
        <f7-tab ref="tab1" id="tab-3" class="chat-tab-1" tab-active>
          <f7-list no-hairlines style="width: 100%">
            <f7-list-input
              v-model:value="formData.Search.Order"
              name="Search"
              :required="validationRules?.Search?.required"
              :minlength="validationRules?.Search?.minimumLength"
              :maxlength="validationRules?.Search?.maximumLength"
              :label="$t.getTranslation('LBL_SEARCH')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
              v-on:keyup.enter="searchOrder()"
            >
            </f7-list-input>
          </f7-list>

          <div class="list-wrap list-search-max-height">
            <template v-for="order in Orders" :key="'ord_' + order.OrderId">
              <OrderItemCardComponent :data="order.Order" class="min-width-chat" :hidestatus="true" :f7router="f7router" :sendOrder="sendOrder" />
            </template>
            <NoDataFoundComponent v-if="Orders.length == 0" :size="'sm'" :title="$t.getTranslation('LBL_NO_ORDERS_FOUND')" />
          </div>
        </f7-tab>

        <f7-tab ref="tab2" id="tab-4" class="chat-tab-2">
          <f7-list no-hairlines style="width: 100%">
            <f7-list-input
              v-model:value="formData.Search.Product"
              name="Search"
              :required="validationRules?.Search?.required"
              :minlength="validationRules?.Search?.minimumLength"
              :maxlength="validationRules?.Search?.maximumLength"
              :label="$t.getTranslation('LBL_SEARCH')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              floating-label
              outline
              type="text"
              validate
              v-on:keyup.enter="searchProduct()"
            >
            </f7-list-input>
          </f7-list>

          <div class="list-wrap list-search-max-height">
            <template v-for="product in Products" :key="'prditem_' + product.ProductId">
              <ProductListCardComponent :data="product.Product" :category="true" :f7router="f7router" :sendProduct="sendProduct" style="width: calc(100% - 23px)" />
            </template>
            <NoDataFoundComponent v-if="Products.length == 0" :size="'sm'" :title="$t.getTranslation('LBL_NO_PRODUCTS_FOUND')" />
          </div>
        </f7-tab>
      </f7-tabs>
    </f7-popup>

    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_MESSAGES") }}</h1>
          <!--   <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3> -->
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>
    </div>

    <div class="container-chat">
      <sidebar :class="{ 'hide-on-mobile': ThreadKey }">
        <f7-toolbar tabbar bottom>
          <f7-link tab-link="#tab-1" :tab-link-active="!activeThreadKey">
            <div class="flex">
              {{ $t.getTranslation("LBL_UNASWERED") }} <f7-badge v-if="Unanswered" color="blue">{{ Unanswered }}</f7-badge>
            </div>
          </f7-link>
          <f7-link tab-link="#tab-2" :tab-link-active="activeThreadKey">
            <div class="flex">
              {{ $t.getTranslation("LBL_MESSAGES") }} <f7-badge v-if="Unread" color="blue">{{ Unread }}</f7-badge>
            </div>
          </f7-link>
        </f7-toolbar>

        <f7-tabs>
          <f7-tab ref="tab1" id="tab-1" class="chat-tab-1" :tab-active="!activeThreadKey">
            <div class="list-wrap">
              <template v-for="item in UnansweredList" :key="item.ThreadKey">
                <ThreadComponent :data="item" @click="check(item, 'UNANSWERED')" />
              </template>

              <NoDataFoundComponent v-if="UnansweredList.length == 0" :size="'sm'" :title="$t.getTranslation('LBL_EMPTY_THREAD')" />
            </div>
          </f7-tab>

          <f7-tab ref="tab2" id="tab-2" class="chat-tab-2" :tab-active="activeThreadKey">
            <div class="list-wrap">
              <ThreadComponent v-if="ActiveThread" :active="true" :data="ActiveThread" />

              <template v-for="item in ThreadList" :key="item.ThreadKey">
                <ThreadComponent :data="item" @click="check(item, 'THREAD')" />
              </template>

              <NoDataFoundComponent v-if="ThreadList.length == 0 && !ActiveThread" :size="'sm'" :title="$t.getTranslation('LBL_EMPTY_THREAD')" />
            </div>
          </f7-tab>
        </f7-tabs>
      </sidebar>

      <div class="content hide-on-mobile" v-if="!ThreadKey" style="margin-top: 20px">
        <NoDataFoundComponent :size="'sm'" :title="$t.getTranslation('LBL_EMPTY_MESSAGES')" />
      </div>

      <div class="content" v-else>
        <header>
          <span @click="back" style="margin-right: 20px; cursor: pointer; font-size: 25px" class="show-mobile">
            <font-awesome-icon :icon="['far', 'angle-left']" />
          </span>

          <img :src="$h.getImage(ActiveThread?.User?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
          <div class="info">
            <span class="user">
              {{ $h.formatName(ActiveThread?.User) || ActiveThread?.User?.UserName || ActiveThread?.User?.Email || $t.getTranslation("LBL_CUSTOMER") }}
            </span>
            <span class="time">
              {{ $t.getTranslation("LBL_CUSTOMER") }}
            </span>
          </div>
          <div class="open">
            <a href="javascript:;">adsa</a>
          </div>
        </header>
        <div class="message-wrap" id="msg-content">
          <f7-messages-title v-if="HavePrevious">
            <f7-link @click="loadPrev">
              {{ $t.getTranslation("LBL_LOAD_PREVIOUS_MESSAGES") }}
            </f7-link>
          </f7-messages-title>

          <div class="empty-msg-list" v-if="MsgList.length == 0">
            <NoDataFoundComponent :size="'sm'" :title="$t.getTranslation('LBL_EMPTY_MESSAGES')" />
          </div>

          <template v-for="item in MsgList" :key="item.ThreadMsgKey">
            <div class="message-list me" v-if="item.ThreadMsgTypeCode == 'SYSTEM'">
              <div class="chat-msg">
                <div class="msg">
                  <p>
                     {{ $h.replaceData($t.getTranslation(item.Content), item.Data) }}
                  </p>
                </div>
                <div class="time-me">{{ $t.getTranslation("LBL_SYSTEM_GENERATED_MSG") }} - {{ $h.formatDateTime(item.LastCreated) }}</div>
              </div>

              <div class="chat-images-me">
                <img class="msg-image" :src="$h.getImage(null, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
              </div>
            </div>

            <template v-else>
              <div class="message-list" v-if="item.UserKey != UserKey">
                <div class="chat-images">
                  <img class="msg-image" :src="$h.getImage(item.User?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
                </div>

                <div class="chat-msg">
                  <div :class="{ msg: item.ThreadMsgTypeCode == 'TEXT' }">
                    <p v-if="item.ThreadMsgTypeCode == 'TEXT'">
                      {{ item.Content }}
                    </p>

                    <ProductListCardComponent v-if="item.ThreadMsgTypeCode == 'PRODUCT'" :data="item.Product" :category="true" :f7router="f7router" />

                    <OrderItemCardComponent v-if="item.ThreadMsgTypeCode == 'ORDER'" :data="item.Order" class="min-width-chat" :hidestatus="true" :f7router="f7router" />

                    <img @click="viewImage($refs, item.Content)" v-if="item.ThreadMsgTypeCode == 'IMAGE'" :src="$h.getImage(item.Content, 'CHAT')" class="chat-image-msg" />
                  </div>
                  <div class="time"><span style="display: block;opacity: 0.5;font-size: 10px;">UserID: {{ item.User.UserId }} - {{ item.Source || "shop" }}</span>{{ $h.formatDateTime(item.LastCreated) }} - {{ item?.User?.UserName || "---" }}</div>
                </div>
              </div>

              <div class="message-list me" v-else>
                <div class="chat-msg">
                  <div :class="{ msg: item.ThreadMsgTypeCode == 'TEXT' }">
                    <p v-if="item.ThreadMsgTypeCode == 'TEXT'">
                      {{ item.Content }}
                    </p>

                    <ProductListCardComponent v-if="item.ThreadMsgTypeCode == 'PRODUCT'" :data="item.Product" :category="true" :f7router="f7router" />

                    <OrderItemCardComponent v-if="item.ThreadMsgTypeCode == 'ORDER'" :data="item.Order" class="min-width-chat" :hidestatus="true" :f7router="f7router" />

                    <div style="text-align: right">
                      <img @click="viewImage($refs, item.Content)" v-if="item.ThreadMsgTypeCode == 'IMAGE'" :src="$h.getImage(item.Content, 'CHAT')" class="chat-image-msg" />
                    </div>
                  </div>
                  <div class="time-me"><span style="display: block;opacity: 0.5;font-size: 10px;">UserID: {{ item.User.UserId }} - {{ item.Source || "shop" }}</span>{{ item?.User?.UserName || "---" }} - {{ $h.formatDateTime(item.LastCreated) }}</div>
                </div>

                <div class="chat-images-me">
                  <img class="msg-image" :src="$h.getImage(item.User?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
                </div>
              </div>
            </template>
          </template>

          <input ref="fileInput" capture="user" type="file" accept="image/*" @change="uploadImage($event)" />
        </div>
        <div class="message-footer">
          <f7-messagebar v-model:value="messageText" ref="chat" :placeholder="$t.getTranslation('LBL_MESSAGE_CONTENT')">
            <template #inner-start>
              <f7-link @click="selectImage($refs)">
                <font-awesome-icon :icon="['far', 'images']" fixed-width />
              </f7-link>

              <f7-link @click="attach">
                <font-awesome-icon :icon="['far', 'plus']" fixed-width />
              </f7-link>
            </template>

            <f7-link @click="onSubmit(ThreadKey)">
              <font-awesome-icon :icon="['far', 'paper-plane']" fixed-width />
            </f7-link>
          </f7-messagebar>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, onUnmounted, onMounted, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import ThreadComponent from "@/components/chat/ThreadComponent.vue";
import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
import OrderItemCardComponent from "@/components/cards/OrderItemCardComponent.vue";
import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

import { socket } from "@/utils/socket.js";
import { get, post } from "@/utils/axios";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers";
import { f7 } from "framework7-vue";
import { $HTTP } from "@/utils/axios";

export default defineComponent({
  name: "MessagePage",
  components: {
    MainHeaderComponent,
    ThreadComponent,
    ProductListCardComponent,
    OrderItemCardComponent,
    NoDataFoundComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const ThreadKey = ref(null);
    const MsgList = ref([]);

    const $t = inject("$translation");

    const store = useStore();
    store.dispatch("chat/setData", { ThreadKey: null });

    const formData = reactive({
      Search: {
        Order: "",
        Product: "",
        Chat: "",
      },
    });

    const statusList = computed(() => store.getters["config/getData"]?.statusList);
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const Unread = computed(() => store.getters["chat/totalUnread"]);
    const Unanswered = computed(() => store.getters["chat/getData"]?.Unanswered);

    const UnansweredList = computed(() => store.getters["chat/getData"]?.UnansweredList);
    const ThreadList = computed(() => store.getters["chat/filtered"]);
    const ActiveThread = computed(() => store.getters["chat/active"]);
    const HavePrevious = ref(false);

    const userInfo = computed(() => store.getters["user/getData"]);
    const UserKey = userInfo.value.UserKey;

    const Orders = ref([]);
    const Products = ref([]);
    const activeThreadKey = ref(false);

    const SearchChat = ref([]);

    const attach = () => {
      getOrders();
      getProducts();
      f7.popup.get("#popupOptionMenu").open();
    };

    const closePopup = () => {
      f7.popup.get("#popupOptionMenu").close();
    };

    const sendOrder = async (OrderKey) => {
      let ret = await post("/chat/send", {
        ThreadKey: ThreadKey.value,
        Content: OrderKey,
        ContentType: "ORDER",
      });
      f7.popup.get("#popupOptionMenu").close();
    };

    const sendProduct = async (ProductKey) => {
      let ret = await post("/chat/send", {
        ThreadKey: ThreadKey.value,
        Content: ProductKey,
        ContentType: "PRODUCT",
      });
      f7.popup.get("#popupOptionMenu").close();
    };

    const markRead = async () => {
      await post("/chat/read/all", { ThreadKey: ThreadKey.value });
    };

    const scrollToBottom = async () => {
      setTimeout(() => {
        let parent = document.getElementById("msg-content");
        parent.scrollTop = parent.scrollHeight;
      }, 200);
    };

    const loadPrev = async () => {
      HavePrevious.value = false;
      //get now
      getAll(MsgList.value[0].ThreadMsgKey);
    };

    const searchOrder = () => {
      getOrders();
    };

    const searchProduct = () => {
      getProducts();
    };

    const searchThread = (type) => {
      store.dispatch("chat/setData", { SearchFields: formData.Search.Chat });
      let findChat = computed(() => store.getters["chat/search"]);
      SearchChat.value = findChat.value;
    };

    const getOrders = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/order/list?&SearchField=OrderNumber&SearchType=LIKE&SearchValue=${formData.Search.Order || ""}&page=1&size=10&isChat=1&user=${ActiveThread?.value?.User?.UserKey}`, {});
        if (res?.status === 200 && res.data.data.data) {
          helpers.hideLoader();
          Orders.value = res.data.data.data;
          return;
        }
        throw new Error($t.getTranslation("ERR_ORDER_LIST"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const getProducts = async () => {
      try {
        helpers.showLoader();
        let res = await $HTTP.get(`/admin/product/list?&SearchField=p.Sku,pat.Name&SearchType=LIKE&SearchValue=${formData.Search.Product}&isChat=1&LanguageCode=${currentLanguageCode.value}&page=1&size=10`, {});
        if (res?.status === 200 && res.data.data.data) {
          helpers.hideLoader();

          for (var i = 0; i < res.data.data.data.length; i++) {
            let item = res.data.data.data[i];
            item.Product["ProductKey"] = item.ProductKey;
          }
          Products.value = res.data.data.data;
          return;
        }
        throw new Error($t.getTranslation("ERR_PRODUCT_LIST"));
      } catch (err) {
        helpers.hideLoader();
        helpers.catchError(err, true);
      }
    };

    const receivedMessage = async (data) => {
      if (data.Room != ThreadKey.value) return;
      MsgList.value.push(data.Msg); //after push scroll to bottom
      markRead();
      scrollToBottom();
    };

    const getAll = async (LastKey) => {
      let ret = await get("/chat/history", { LastKey: LastKey, ThreadKey: ThreadKey.value });
      HavePrevious.value = ret.HavePrevious ? true : false;
      if (ret.MsgList && ret.MsgList.length) {
        if (!LastKey) {
          _.each(ret.MsgList.reverse(), (Msg) => {
            MsgList.value.push(Msg);
          });
          //scroll to bottom
          scrollToBottom();
        } else {
          _.each(ret.MsgList, (Msg) => {
            MsgList.value.unshift(Msg);
          });
        }
      }
    };

    const join = async (t) => {
      if (ThreadKey.value == t) return;

      leaveChat(); //leave first

      MsgList.value = []; //reset
      ThreadKey.value = t;
      store.dispatch("chat/setData", { ThreadKey: t });
      socket.assignSocketCallback("RECEIVED_MSG", receivedMessage);
      socket.join(t);
      //get history here and call markRead
      markRead();
      getAll();
    };

    const check = (item, mode) => {
      let message = $t.getTranslation("MSG_CONFIRM_JOIN_CHAT");
      if (mode == "UNANSWERED") {
        helpers.createConfirmation({
          message: message,
          confirm: async () => {
            let ret = await post("/chat/join", {
              ThreadKey: item.ThreadKey,
            });
            join(item.ThreadKey);
            f7.tab.show(".chat-tab-2");
          },
        });
      } else {
        join(item.ThreadKey);
      }
    };

    const leaveChat = () => {
      if (ThreadKey.value) {
        socket.leave(ThreadKey.value);
      }

      ThreadKey.value = null; //set to null
      store.dispatch("chat/setData", { ThreadKey: null }); //set to null
    };

    const back = () => {
      leaveChat();
    };

    onUnmounted(() => {
      socket.unassignSocketCallback("RECEIVED_MSG");
      leaveChat();

      store.dispatch("chat/setData", { ThreadKey: null });
    });

    onMounted(() => {
      if (props?.f7route?.query?.ThreadKey) {
        activeThreadKey.value = props.f7route.query.ThreadKey;
        join(activeThreadKey.value);
      }
    });

    const photos = ref([]);
    const viewImage = ($refs, image) => {
      photos.value = [helpers.getImage(image, "CHAT")];
      setTimeout(() => {
        $refs.popup.open();
      }, 300);
    };

    const uploadImage = async (event) => {
      let input = event.target;
      if (input?.files?.[0]) {
        const formData = new FormData();
        formData.append("file", input?.files?.[0]);
        let ret = await post("/chat/upload", formData);
        _.each((ret && ret.files) || [], async (r) => {
          let send = await post("/chat/send", {
            ThreadKey: ThreadKey.value,
            Content: r,
            ContentType: "IMAGE",
          });
        });
      }
    };

    const selectImage = (refs) => {
      refs.fileInput.value = "";
      refs.fileInput.click();
    };

    return {
      closePopup,
      selectImage,
      uploadImage,
      photos,
      viewImage,
      back,
      attach,
      HavePrevious,
      UserKey,
      MsgList,
      ActiveThread,
      loadPrev,
      check,
      UnansweredList,
      ThreadList,
      Unread,
      Unanswered,
      ThreadKey,
      formData,
      statusList,
      Orders,
      Products,
      sendOrder,
      sendProduct,
      searchOrder,
      searchProduct,
      searchThread,
      SearchChat,
      activeThreadKey,
    };
  },
  data() {
    return {
      messageText: null,
    };
  },
  methods: {
    async onSubmit(ThreadKey) {
      let toSend = this.messageText.trim(); //replace(/\n/g, '<br>')
      //send now
      //add loader here
      let ret = await post("/chat/send", {
        ThreadKey: ThreadKey,
        Content: toSend,
        ContentType: "TEXT",
      });
      //remove sloader here
      //clear message
      this.messageText = null;
    },
  },
});
</script>

<style>
input[type="file"] {
  opacity: 0;
  position: fixed;
  bottom: 0px;
  z-index: -1;
}

.chat-image-msg {
  max-width: 80%;
  border-radius: 5px;
  cursor: pointer;
}

.with-tabs .tabs {
  height: calc(100% - 120px);
}

.with-tabs .tabs .tab {
  height: 100%;
}

.list-search-max-height {
  max-height: calc(100% - 80px);
  overflow: auto;
}

.empty-msg-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isRight {
  position: absolute;
  right: 20px;
}
.icon-file {
  padding: 3px;
  background-color: #1b3d61;
  color: white;
  border-radius: 50%;
}
.icon-submit {
  padding: 10px;
}
/*.messagebar-area textarea {
  border: none;
  background-color: #ccc;
  border-radius: 20px;
}
*/
.attachment-container {
  height: 300px;
  padding: 10px 10px;
}

.product-card-chat {
  padding: 30px 30px;
  height: 300px;
  overflow-y: auto;
}

.flex {
  display: flex;
  flex-direction: row;
}

.list-wrap > .list.active {
  border-left: 5px solid #122941;
}
.container-chat {
  display: flex;
  height: calc(100vh - 180px);
  overflow: hidden !important;
}
.chat-header {
  margin: 0 30px;
}
sidebar {
  width: 300px;
  min-width: 300px;
  display: flex;
  background: #fff;
  flex-direction: column;
  border-right: 1px solid #ccc;
  transition: 500ms all;
}

sidebar .tabs {
  height: 100%;
  overflow: auto;
}

.logo {
  display: flex;
  padding: 25px 10px 0px;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  font-size: 1.5em;
}
sidebar .list-wrap {
  width: 100%;
  overflow: auto;
}
sidebar .list-wrap .list {
  border-bottom: 1px solid #ccc;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 80px;
  cursor: pointer;
  /*width: 295px;*/
  width: 285px;
}
sidebar .list-wrap .list:hover,
sidebar .list-wrap .list.active {
  background: #f2f3f5;
  border-left: 5px solid #1b3d61;
}
sidebar .list-wrap .list img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  /*box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);*/
}
sidebar .list-wrap .list .info {
  flex: 1;
  width: calc(100% - 102px);
}
sidebar .list-wrap .list .info .user {
  font-weight: 700;
}
sidebar .list-wrap .list .info .text {
  display: flex;
  margin-top: 3px;
  font-size: 0.85em;
}
sidebar .list-wrap .list .time {
  margin-right: 5px;
  margin-left: 5px;
  font-size: 0.75em;
  color: #a9a9a9;
}
sidebar .list-wrap .list .count {
  font-size: 0.75em;
  background: #1b3d61;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.7);
  padding: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.content header {
  height: 70px;
  background: #f2f3f5;
  border-bottom: 1px solid #ccc;
  display: flex;
  padding: 10px;
  align-items: center;
}
.content header img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
}
.content header .info {
  flex: 1;
}
.content header .info .user {
  font-weight: 700;
}
.content header .info .time {
  display: flex;
  margin-top: 3px;
  font-size: 0.85em;
}
.content header .open {
  display: none;
}
.content header .open a {
  color: #000;
  letter-spacing: 3px;
}
.message-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow: auto;
}
.message-wrap::before {
  content: "";
  /* margin-bottom: auto;*/
}
.message-wrap .message-list {
  align-self: flex-start;
  max-width: 70%;
  display: flex;
}
.message-wrap .message-list.me {
  align-self: flex-end;
}
.message-wrap .message-list.me .msg {
  background: #122941;
  color: #fff;
}
.message-wrap .message-list .msg {
  background: #e1e1e1;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  padding: px 10px;
  margin: 5px;
  border-radius: 25px;
}
.message-wrap .message-list .msg p {
  margin: 5px 15px;
  padding: 5px 0;
}
.message-wrap .message-list .time {
  text-align: left;
  color: #999;
  font-size: 0.75em;
}
.message-wrap .message-list .time-me {
  text-align: right;
  color: #999;
  font-size: 0.75em;
}
.message-footer {
  padding: 10px;
  display: flex;
  height: 60px;
}
/*.message-footer input {
  flex: 1;
  padding: 0 20px;
  border-radius: 20px;
  border: 1px solid #ccc;
  height: 40px;
  font-size: 18px;
}*/
.msg-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.chat-images {
  padding-right: 20px;
}
.chat-images-me {
  padding-left: 20px;
}

.count-time {
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  height: 45px;
}

.modal-chat-attach-backdrop {
  position: fixed;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.modal-chat-attach {
  background: #fff;

  max-width: 500px;
  width: 80%;
}

.chat-page .page-content {
  padding-bottom: 0px !important;
}

.show-mobile {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }

  .show-mobile {
    display: block !important;
  }

  sidebar {
    width: 100%;
  }

  sidebar .list-wrap .list {
    width: calc(100% - 10px) !important;
  }
}
</style>
