<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'users-crown']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_GROUP_BUY_LIST") }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <GroupBuySavePartial ref="groupBuySavePartial" @closed="initDataTableComponent" />
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import GroupBuySavePartial from "@/partials/groupbuy/GroupBuySavePartial.vue";

import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "GroupBuyListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    // PARTIALS
    GroupBuySavePartial,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const actions = [
      {
        groupText: "Perform Actions",
        list: [
          { text: "View Orders", type: "VIEWORDERS", bold: true },
          { text: "Edit", type: "EDIT", bold: false },
        ],
      },
    ];

    const columns = [
      {
        title: "Id",
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().GroupBuyId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRODUCT_INFO"),
        minWidth: 220,
        maxWidth: 320,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().Sku} - ${cell.getData().SupplierCode}</strong></div>
              <div>${cell.getData().ProductName}</div>
              <div><strong>ID:</strong> ${cell.getData().ProductId}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_WAREHOUSE_INFO"),
        minWidth: 220,
        maxWidth: 320,
        formatter(cell) {
          let tmpHtml = `<div>`;
          if (cell.getData().WarehouseInfo) {
            let splittedWarehouse = cell.getData().WarehouseInfo.split(",");

            splittedWarehouse.forEach((item) => {
              tmpHtml += `
                <div class="chip color-primary">
                  <div class="chip-label">${item}</div>
                </div>
              `;
            });
          }

          tmpHtml += `</div>`;
          return tmpHtml;
        },
      },
      {
        title: $t.getTranslation("LBL_START_DATE"),
        minWidth: 200,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.formatDateTime(cell.getData().StartDate)}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_END_DATE"),
        minWidth: 200,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.formatDateTime(cell.getData().EndDate)}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_MOQ"),
        minWidth: 80,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().MOQ || 0}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_TOTAL_ORDERS"),
        minWidth: 80,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().TotalOrders || 0}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_INITIAL_QUANTITY_INFO"),
        minWidth: 220,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_MAXIMUM")}:</strong> ${cell.getData().InitialQuantityMaximum || 0}</div>
              <div><strong>${$t.getTranslation("LBL_INCREMENT")}:</strong> ${cell.getData().InitialQuantityIncrement || 0}</div>
              <div><strong>${$t.getTranslation("LBL_INTERVAL")}:</strong> ${cell.getData().IncrementInterval || 0}</div>
              <div><strong>${$t.getTranslation("LBL_START_DATE")}:</strong> ${helpers.formatDateTime(cell.getData().InitialQuantityStartDate)}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PROCESS_INFO"),
        minWidth: 220,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.getFullName(cell.getData(), true)}</strong></div>
              <div><strong>${cell.getData().UserName || ""}</strong></div>
              <div>${cell.getData().UserKey}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.StatusCode)} ${cell.getData()?.StatusCode}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="Date Created"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="Date Updated"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const url = ref("/admin/groupbuy/datalist");

    return {
      url,
      columns,
      actions,
    };
  },
  methods: {
    create() {
      this.$refs.groupBuySavePartial.openPopup(false);
    },
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEWORDERS":
          if (data?.value?.GroupBuyId) {
            self.f7router.navigate(`/order/list/?groupbuy=${data?.value?.GroupBuyId}`);
          }
          break;
        case "EDIT":
          if (data?.value?.GroupBuyId) {
            self.$refs.groupBuySavePartial.openPopup(data.value);
          }
          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
