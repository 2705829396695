<template>
  <section class="table-container table2">
    <div class="container">
      <div class="search-container">
        <f7-list no-hairlines>
          <f7-list-input v-model:value="formData.search" name="SearchValue" label="Quick Search" floating-label outline type="text" placeholder="Type Here" @keyup.enter="onApply">
            <template #content-end>
              <f7-button fill large @click="onApply">
                <font-awesome-icon :icon="['far', 'search']" fixed-width />
              </f7-button>
              <f7-button fill large color="red" @click="onSearchReset">
                <font-awesome-icon :icon="['far', 'times']" fixed-width />
              </f7-button>

              <div v-if="options" class="custom-filter-container">
                <f7-button v-if="options?.filter?.list" fill large class="button-filter" @click="showFilterOptionMenu"> Filter </f7-button>
                <f7-button v-if="options?.sort?.list" fill large class="button-sort" @click="showSortOptionMenu"> Sort </f7-button>
                <f7-button v-if="options?.filter?.list || options?.sort?.list" fill large color="red" class="button-reset" @click="onReset"> Reset </f7-button>
              </div>
            </template>
          </f7-list-input>
          <f7-list-item>
            <f7-button fill large raised class="button-options" @click="showTableOptionMenu">
              <font-awesome-icon :icon="['far', 'cogs']" fixed-width />
              Options
            </f7-button>
          </f7-list-item>
        </f7-list>
      </div>

      <div ref="tableReference" class="dataTableComponent"></div>
      <div class="table-footer">
        <p>
          Total Page: <strong>{{ tableInfo.totalPage || 0 }}</strong>
        </p>
        <p>
          Total Record: <strong>{{ tableInfo.totalData || 0 }}</strong>
        </p>
      </div>

      <f7-actions v-if="!$h.isEmpty(actions)" id="actionsTableRowClick">
        <f7-actions-group v-for="actionGroup in actions" :key="'' + actionGroup?.groupText">
          <f7-actions-label v-if="actionGroup?.groupText">{{ actionGroup?.groupText }}</f7-actions-label>
          <template v-for="action in actionGroup.list" :key="'ac_' + action.type">
            <f7-actions-button v-if="$h.checkTableButtonEnabled(action, selectedRowData)" :bold="action?.bold" :color="action?.color" @click="onPerformAction(action?.type)">
              {{ action.text }}
            </f7-actions-button>
          </template>
        </f7-actions-group>
      </f7-actions>

      <f7-popup v-if="options?.filter?.list" id="popupFilterMenu" class="popup-customfilter">
        <f7-page>
          <template #fixed>
            <div class="options-container">
              <f7-button fill large :disabled="isCustomFilterDisabled" @click="onApplyCustomFilter"> Apply </f7-button>
              <f7-button fill large color="red" @click="onResetCustomFilter"> Reset </f7-button>
            </div>
          </template>

          <f7-navbar>
            <f7-nav-right>
              <f7-link popup-close>
                <font-awesome-icon :icon="['far', 'times']" fixed-width />
              </f7-link>
            </f7-nav-right>
            <f7-nav-title> Filter Menu </f7-nav-title>
          </f7-navbar>

          <div class="content">
            <p>
              Filter table data based on the value you input in each specific fields below. You can toggle specificity type by clicking the button inside the input field, <strong>EXACT</strong> value meaning the search result should contain
              the exact item while; <strong>LIKE</strong> should contain a portion of the value you are trying to search.
            </p>

            <p>For a more detailed and customized filtering you can used the filter functions in the <strong>Options Toolkit</strong> by clicking the Options button on the right side of the table.</p>
          </div>

          <f7-list no-hairlines>
            <li class="row">
              <template v-for="(item, key) in options?.filter?.list" :key="'frf_' + key">
                <template v-if="item?.type == 'datepicker'">
                  <f7-list-input
                    v-model:value="customFilterData[key].value"
                    :name="key"
                    :label="item.name || $h.formatConfigValue(key)"
                    :calendar-params="{ dateFormat: 'mm/dd/yy', rangePicker: true }"
                    class="col-100 medium-50 large-33"
                    placeholder="Select date."
                    type="datepicker"
                    floating-label
                    outline
                  >
                    <template #content-end>
                      <div class="filter-buttons">
                        <f7-button v-if="customFilterData[key]?.value != '' && customFilterData[key]?.value?.length > 0" fill color="red" @click="onClearCustomFilterValue(key, customFilterData[key])">
                          <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                        </f7-button>
                      </div>
                    </template>
                  </f7-list-input>
                </template>

                <template v-else>
                  <f7-list-input
                    v-model:value="customFilterData[key].value"
                    :name="key"
                    :label="item.name || $h.formatConfigValue(key)"
                    :type="item?.type || 'text'"
                    class="col-100 medium-50 large-33"
                    placeholder="Type here."
                    floating-label
                    outline
                  >
                    <template v-if="item?.type == 'select' && item?.items && $h.isArray(item?.items) && item?.items.length > 0">
                      <option v-for="optionItem in item?.items" :key="'frv_' + optionItem.Code || optionItem.Value || optionItem.value" :value="optionItem.Code || optionItem.Value || optionItem.value">
                        {{ optionItem.Name || optionItem.name || optionItem.Value || optionItem.value || optionItem.Code }}
                      </option>
                    </template>

                    <template #content-end>
                      <div class="filter-buttons">
                        <f7-button v-if="item?.type != 'select'" fill @click="onToggleCustomFilterValue(key)"> {{ customFilterData[key].type }} </f7-button>
                        <f7-button v-if="customFilterData[key]?.value != '' && customFilterData[key]?.value?.length > 0" fill color="red" @click="onClearCustomFilterValue(key, customFilterData[key])">
                          <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                        </f7-button>
                      </div>
                    </template>
                  </f7-list-input>
                </template>
              </template>
            </li>
          </f7-list>
        </f7-page>
      </f7-popup>

      <f7-popup v-if="options?.sort?.list" id="popupSortMenu" class="popup-customsort">
        <f7-page>
          <template #fixed>
            <div class="options-container">
              <f7-button fill large :disabled="isCustomSortDisabled" @click="onApplyCustomSort"> Apply </f7-button>
              <f7-button fill large color="red" @click="onResetCustomSort"> Reset </f7-button>
            </div>
          </template>

          <f7-navbar>
            <f7-nav-right>
              <f7-link popup-close>
                <font-awesome-icon :icon="['far', 'times']" fixed-width />
              </f7-link>
            </f7-nav-right>
            <f7-nav-title> Sort Menu </f7-nav-title>
          </f7-navbar>

          <div class="content">
            <p>Sort table data based on the value you selected and the type of ordering you specify. <strong>ASC</strong> sort the data from smallest to biggest while, <strong>DESC</strong> sort it from the highest value.</p>

            <f7-list no-hairlines>
              <f7-list-input v-model:value="customSortData.SortField" name="SortField" label="Sort Field" floating-label outline type="select" placeholder="Select sort field.">
                <option v-for="(item, key) in options?.sort?.list" :key="'srt_' + key" :value="key">{{ item?.name || $h.formatConfigValue(key) }}</option>
              </f7-list-input>
              <f7-list-input v-model:value="customSortData.SortType" name="SortType" label="Sort Type" floating-label outline type="select" placeholder="Select sort type.">
                <option v-for="type in options?.sort?.type" :key="'srv_' + type.value" :value="type.value">{{ type.name }}</option>
              </f7-list-input>
            </f7-list>

            <p>For a more detailed, customized and multiple sorting you can used the sorting functions in the <strong>Options Toolkit</strong> by clicking the Options button on the right side of the table.</p>
          </div>
        </f7-page>
      </f7-popup>

      <f7-popup id="popupOptionMenu" class="popup-options">
        <f7-page>
          <f7-navbar>
            <f7-nav-right>
              <f7-link popup-close>
                <font-awesome-icon :icon="['far', 'times']" fixed-width />
              </f7-link>
            </f7-nav-right>
            <f7-nav-title> Options </f7-nav-title>
          </f7-navbar>

          <div class="options-container">
            <div class="options-title">
              <h1>Generate</h1>
            </div>

            <div class="button-container">
              <f7-button v-if="options?.print?.enabled" popup-close fill @click="onPrint"> Print </f7-button>
              <f7-button v-if="options?.export?.enabled" popup-close fill @click="onExportCSV"> Export CSV </f7-button>
              <f7-button v-if="options?.export?.enabled" popup-close fill @click="onExportJSON"> Export JSON </f7-button>
              <f7-button v-if="options?.export?.enabled" popup-close fill @click="onExportXLSX"> Export XLSX</f7-button>
            </div>
          </div>

          <div v-if="options?.filter?.enabled" class="options-container filter">
            <div class="options-title">
              <h1>Filter</h1>

              <f7-segmented strong class="filter-join-toggle">
                <f7-button :active="formData.filterJoinString == 'AND'" @click="onUpdateFilterToggle('AND')">AND</f7-button>
                <f7-button :active="formData.filterJoinString == 'OR'" @click="onUpdateFilterToggle('OR')">OR</f7-button>
              </f7-segmented>
            </div>

            <ul v-if="formData?.filter?.length > 0" class="options-list">
              <li v-for="(filterItem, index) in formData.filter" :key="'fi_' + index">
                <div class="content">
                  <strong>{{ $h.formatConfigValue(filterItem.FilterField) }} - {{ getFilterTypeName(filterItem.FilterType) }}</strong>
                  <p>
                    <template v-for="(value, vindex) in filterItem.FilterValue" :key="'fiv_' + vindex">
                      <span>{{ getFilterValueName(value, filterItem.FilterField) }}</span>
                    </template>
                  </p>
                </div>

                <div class="button-container">
                  <f7-button fill color="blue" @click="onEditFilter(filterItem, index)">
                    <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                  </f7-button>
                  <f7-button fill color="red" @click="onDeleteFilter(filterItem, index)">
                    <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                  </f7-button>
                </div>
              </li>
            </ul>

            <f7-button fill large @click="onShowAddFilter">Add Filter</f7-button>
          </div>

          <div v-if="options?.sort?.enabled" class="options-container sort">
            <div class="options-title">
              <h1>Sort</h1>
            </div>

            <ul v-if="formData?.sort?.length > 0" class="options-list">
              <li v-for="(sortItem, index) in formData.sort" :key="'fi_' + index">
                <div class="content">
                  <strong>{{ $h.formatConfigValue(sortItem.SortField) }} </strong>
                  <p>{{ sortItem.SortType }}</p>
                </div>

                <div class="button-container">
                  <f7-button fill color="blue" @click="onEditSort(sortItem, index)">
                    <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                  </f7-button>
                  <f7-button fill color="red" @click="onDeleteSort(sortItem, index)">
                    <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                  </f7-button>
                </div>
              </li>
            </ul>

            <f7-button fill large @click="onShowAddSort">Add Sorting</f7-button>
          </div>

          <div class="options-container pagesize">
            <div class="options-title">
              <h1>Size/Page</h1>
            </div>

            <f7-list no-hairlines>
              <f7-list-input v-model:value="sizePageData.size" name="Size" label="Size" floating-label outline type="number" placeholder="Select total items to show." validate clear-button> </f7-list-input>
              <f7-list-input v-model:value="sizePageData.page" name="Page" label="Page" floating-label outline type="number" placeholder="Jump to certain page." validate clear-button> </f7-list-input>
            </f7-list>
          </div>

          <div class="options-container reset-cancel">
            <f7-button fill large popup-close class="button-reset" @click="onApply"> Apply </f7-button>
            <f7-button fill large popup-close color="gray" class="button-reset" @click="onReset"> Reset </f7-button>
          </div>
        </f7-page>
      </f7-popup>

      <f7-popup id="filterOptionMenu" class="popup-options popup-filter">
        <f7-page>
          <f7-navbar>
            <f7-nav-right>
              <f7-link popup-close>
                <font-awesome-icon :icon="['far', 'times']" fixed-width />
              </f7-link>
            </f7-nav-right>
            <f7-nav-title> Filter Menu </f7-nav-title>
          </f7-navbar>

          <f7-list no-hairlines>
            <f7-list-item divider>Filter Options</f7-list-item>
            <f7-list-input v-model:value="filterData.FilterField" name="FilterField" label="Field" floating-label outline type="select" placeholder="Select filter field." :disabled="filterData.updateIndex >= 0">
              <template v-if="options?.filter?.list">
                <option v-for="(item, key) in options?.filter?.list" :key="'frf_' + key" :value="key">{{ item.name || $h.formatConfigValue(key) }}</option>
              </template>
            </f7-list-input>

            <template v-if="filterData.FilterField != ''">
              <f7-list-input v-model:value="filterData.FilterType" name="FilterType" label="Type" floating-label outline type="select" placeholder="Select filter type.">
                <template v-if="filterInputConfig?.FilterType">
                  <option v-for="(item, key) in filterInputConfig?.FilterType" :key="'frt_' + key" :value="item.value">{{ item.name }}</option>
                </template>
              </f7-list-input>

              <f7-list-item divider>Filter Values</f7-list-item>

              <template v-for="(filterValueItem, key) in filterData.FilterValue" :key="'fvi_' + key">
                <template v-if="filterInputConfig?.InputType === 'datepicker'">
                  <f7-list-input
                    v-model:value="filterData.FilterValue[key]"
                    label="Value"
                    :calendar-params="{ dateFormat: 'mm/dd/yy', rangePicker: true }"
                    placeholder="Select in filter value."
                    name="FilterValue"
                    floating-label
                    outline
                    type="datepicker"
                  >
                    <template #content>
                      <f7-button fill color="red" class="button-remove" @click="onRemoveFilterValue(key)">
                        <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                      </f7-button>
                    </template>
                  </f7-list-input>
                </template>

                <template v-else-if="filterInputConfig?.InputType === 'range'">
                  <f7-list-input floating-label outline :input="false" class="filterrange">
                    <template #input>
                      <f7-list-input v-model:value="filterData.FilterValue[key][0]" name="FilterValueMinimum" label="Minimum" floating-label outline type="number" placeholder="0"> </f7-list-input>
                      <f7-list-input v-model:value="filterData.FilterValue[key][1]" name="FilterValueMaximum" label="Maximum" floating-label outline type="number" placeholder="0"> </f7-list-input>
                    </template>
                    <template #content>
                      <f7-button fill color="red" class="button-remove" @click="onRemoveFilterValue(key)">
                        <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                      </f7-button>
                    </template>
                  </f7-list-input>
                </template>

                <template v-else>
                  <f7-list-input v-model:value="filterData.FilterValue[key]" name="FilterValue" label="Value" floating-label outline :type="filterInputConfig?.InputType" placeholder="Select/Type in filter value.">
                    <template v-if="filterInputConfig?.InputItems && $h.isArray(filterInputConfig?.InputItems) && filterInputConfig?.InputItems.length > 0">
                      <option v-for="item in filterInputConfig?.InputItems" :key="'frv_' + item.Code || item.Value || item.value" :value="item.Code || item.Value || item.value">{{ item.Name || item.name }}</option>
                    </template>
                    <template #content>
                      <f7-button fill color="red" class="button-remove" @click="onRemoveFilterValue(key)">
                        <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                      </f7-button>
                    </template>
                  </f7-list-input>
                </template>
              </template>

              <f7-list-item>
                <f7-button fill large class="button-full-width" @click="onAddFilterValue"> Add Filter Value </f7-button>
              </f7-list-item>
            </template>
          </f7-list>

          <div class="options-container">
            <f7-button fill large popup-close class="button-full-width" :disabled="filterData.FilterField == '' || filterData.FilterType == ''" @click="onApplyFilter"> Apply Filter </f7-button>
          </div>
        </f7-page>
      </f7-popup>

      <f7-popup id="sortOptionMenu" class="popup-options popup-sort">
        <f7-page>
          <f7-navbar>
            <f7-nav-right>
              <f7-link popup-close>
                <font-awesome-icon :icon="['far', 'times']" fixed-width />
              </f7-link>
            </f7-nav-right>
            <f7-nav-title> Sort Menu </f7-nav-title>
          </f7-navbar>

          <f7-list no-hairlines>
            <f7-list-item divider>Sort Options</f7-list-item>
            <f7-list-input v-model:value="sortData.SortField" name="SortField" label="Sort Field" floating-label outline type="select" placeholder="Select sort field.">
              <option v-for="(item, key) in options?.sort?.list" :key="'srt_' + key" :value="key">{{ item?.name || $h.formatConfigValue(key) }}</option>
            </f7-list-input>
            <f7-list-input v-model:value="sortData.SortType" name="SortType" label="Sort Type" floating-label outline type="select" placeholder="Select sort type.">
              <option v-for="type in options?.sort?.type" :key="'srv_' + type.value" :value="type.value">{{ type.name }}</option>
            </f7-list-input>
          </f7-list>

          <div class="options-container">
            <f7-button fill large popup-close class="button-full-width" :disabled="sortData.SortField == '' || sortData.SortType == ''" @click="onApplySort"> Apply Sort </f7-button>
          </div>
        </f7-page>
      </f7-popup>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, watch } from 'vue'
import { Dom7 } from 'framework7'
import { f7 } from 'framework7-vue'

import Tabulator from 'tabulator-tables'
import xlsx from 'xlsx'

import { get } from '@/utils/axios'
import { configs } from '@/utils/configs'
import { helpers } from '@/utils/helpers'
import { useStore } from '@/store'

const FROZEN_WIDTH = 1000

export default defineComponent({
  name: 'DataTableComponent2',
  props: {
    url: String,
    actions: Object,
    configs: Object,
    columns: [String, Array],
    nodata: String,
    size: Number
  },
  emits: ['performAction'],
  setup(props, { emit }) {
    let windowWidth = 0
    let isAllowFrozen = true

    let fileName = ''

    const store = useStore()

    const tableReference = ref()
    const tabulator = ref()
    const columns = ref([])
    const tableInfo = ref(false)

    const options = ref(false)

    const selectedRow = ref(false)
    const selectedRowData = ref(false)

    const customFilterData = ref({})
    const customSortData = ref({})

    let defaultFormData = {
      size: options.value?.size?.default || props?.size || 15,
      page: 1,
      search: '',
      filter: [],
      filterJoinString: 'AND',
      sort: []
    }

    const formData = reactive({ ...defaultFormData })

    const filterInputConfig = reactive({
      FilterType: [],
      InputType: options.value?.filter?.default?.input || 'text',
      InputItems: options.value?.filter?.default?.items || []
    })

    let defaultFilterData = {
      FilterField: '',
      FilterType: '',
      FilterValue: [''],
      updateIndex: -1
    }

    const filterData = reactive({ ...defaultFilterData })

    let defaultSortData = {
      SortField: '',
      SortType: '',
      updateIndex: -1
    }

    const sortData = reactive({ ...defaultSortData })

    let defaultSizePageData = {
      size: '',
      page: ''
    }

    const sizePageData = reactive({ ...defaultSizePageData })

    const getTableConfig = async () => {
      let getDataTableConfigs = await get(`${props.url}`, { page: 1, size: props.size, config: 1 })
      if (getDataTableConfigs?.options) {
        options.value = getDataTableConfigs?.options

        if (options?.value?.filter?.list) {
          for (let key in options?.value?.filter?.list) {
            let isDate = options?.value?.filter?.list[key].type == 'datepicker' ? true : false
            customFilterData.value[key] = { value: isDate ? [] : '', type: isDate ? 'BETWEEN' : 'EXACT', inputType: options?.value?.filter?.list[key].type }
          }
        }

        if (options?.value?.sort?.list) {
          customSortData.value = {
            SortField: '',
            SortType: ''
          }
        }
      }
    }

    const prepareColumns = () => {
      if (props?.columns && helpers.isArray(props.columns)) {
        columns.value = []

        let defaultValue = {
          minWidth: 200,
          hozAlign: 'left',
          vertAlign: 'middle',
          print: false,
          download: false
        }

        for (let item of props?.columns) {
          let preparedItem = {
            ...defaultValue,
            ...item
          }

          preparedItem.frozen = preparedItem.frozen && isAllowFrozen ? true : false
          columns.value.push(preparedItem)
        }

        // prepare data for printing
        if (options.value?.print?.enabled && options.value?.print?.list) {
          for (let item of options.value?.print?.list) {
            columns.value.push({
              title: helpers.formatConfigValue(item),
              field: item,
              visible: false,
              print: true,
              download: false
            })
          }
        }

        // prepare data for exporting
        if (options.value?.export?.enabled && options.value?.export?.list) {
          for (let item of options.value?.export?.list) {
            columns.value.push({
              title: helpers.formatConfigValue(item),
              field: item,
              visible: false,
              print: false,
              download: true
            })
          }
        }
      }
    }

    //initialize table
    const initTabulator = () => {
      selectedRow.value = false
      selectedRowData.value = false

      let tabulatorConfig = {
        maxHeight: '100%',
        resizableColumns: true,
        ajaxURL: `${configs.baseURL}${props.url}`,
        ajaxFiltering: true,
        ajaxSorting: true,
        paginationSize: formData.size,

        paginationDataReceived: {
          last_page: 'lastPage'
        },
        ajaxURLGenerator: (url, config, params) => {
          let ajaxURL = `${configs.baseURL}${props.url}`

          formData.page = sizePageData.page != '' && parseInt(sizePageData.page) > 0 ? sizePageData.page : params.page
          formData.size = sizePageData.size != '' && parseInt(sizePageData.size) > 0 ? sizePageData.size : params.size

          for (let key in formData) {
            if (formData[key] !== '') {
              let value = formData[key]

              if (key === 'sort' || key === 'filter') {
                value = encodeURIComponent(JSON.stringify(formData[key]))
              }

              ajaxURL += ajaxURL.indexOf('?') > -1 ? '&' : '?'
              ajaxURL += `${key}=${value}`
            }
          }

          return ajaxURL
        },
        ajaxResponse: (url, params, response) => {
          let data = response?.data || {}

          tableInfo.value = {
            totalPage: data.lastPage,
            totalData: data.totalData
          }

          resetSizePageData()
          return data
        },
        ajaxError: async (err) => {
          let errorData = await err.json()
          helpers.catchError(errorData, true)
        },
        layout: 'fitDataFill',
        tooltips: true,
        printAsHtml: true,
        printStyled: true,
        movableColumns: true,
        movableRows: false,
        headerSort: false,
        placeholder: props.nodata || 'No records found',
        rowClick: (e, row) => {
          selectedRow.value = row
          selectedRowData.value = row.getData()
          if (!helpers.isEmpty(props?.actions)) {
            f7.actions.get('#actionsTableRowClick').open()
          }
        },
        rowFormatter: (row) => {
          let data = row.getData()
          if (data?.StatusCode === 'DELETED' || data?.UserStatusCode === 'ONHOLD' || data?.UserStatusCode === 'TERMINATED') {
            row.getElement().classList.add('row-deleted')
          }
        },
        downloadReady: (fileContents, blob) => {
          var a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          a.classList.add('external')

          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = fileName
          a.click()

          window.URL.revokeObjectURL(url)
          return false
        },
        ajaxLoaderLoading: `<span>LOADING DATA</span>`,
        ajaxLoaderError: `<span>ERROR FETCHING DATA</span>`,
        locale: true,
        langs: {
          en: {
            pagination: {
              page_size: 'Page Size',
              page_title: 'Show Page',
              first: 'First',
              last: 'Last',
              prev: 'Prev',
              next: 'Next',
              all: 'All',
              first_title: 'First Page',
              last_title: 'Last Page',
              prev_title: 'Prev Page',
              next_title: 'Next Page'
            }
          }
        }
      }

      const userToken = store.getters['user/getToken']
      if (userToken && userToken !== '') {
        tabulatorConfig.ajaxConfig = {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      }

      tabulatorConfig.pagination = 'remote'
      tabulatorConfig.paginationSizeSelector = [15, 50, 100, 250]

      if (props?.columns && helpers.isString(props.columns) && props.columns == 'autoColumns') {
        tabulatorConfig.autoColumns = true
      } else {
        prepareColumns()
        tabulatorConfig.columns = columns.value
      }

      if (props?.configs) {
        tabulatorConfig = { ...tabulatorConfig, ...props.configs }
      }

      tabulator.value = new Tabulator(tableReference.value, tabulatorConfig)
    }

    // START SEARCH
    const onSearchReset = () => {
      formData.search = ''
      onApply()
    }
    // END SEARCH

    // START FILTER
    const resetFilterData = () => {
      Object.assign(filterData, defaultFilterData)
    }

    const onShowAddFilter = () => {
      resetFilterData()
      f7.popup.get('#filterOptionMenu').open()
    }

    const checkFilterValue = () => {
      let value = ''
      if (filterInputConfig.InputType === 'datepicker') {
        value = []
      } else if (filterInputConfig.InputType === 'range') {
        value = [null, null]
      } else {
        value = ''
      }

      return value
    }

    const getFilterTypeName = (filterType) => {
      let filterTypeName = ''
      options.value?.filter?.type.forEach((item) => {
        if (item.value === filterType) filterTypeName = item.name
      })

      return filterTypeName
    }

    const getFilterValueName = (filterValue, filterField) => {
      let filterValueName = ''

      switch (options.value?.filter?.list?.[filterField]?.type) {
        case 'select':
          options.value?.filter?.list?.[filterField]?.items.forEach((item) => {
            if (item.value == filterValue) filterValueName = item.name
            if (item.Value == filterValue) filterValueName = item.Name
            if (item.Code == filterValue) filterValueName = item.Name
          })

          break
        case 'datepicker':
          filterValueName = `${helpers.formatDate(filterValue[0])} - ${helpers.formatDate(filterValue[1])}`
          break
        case 'range':
          filterValueName = `${parseFloat(filterValue[0])} - ${parseFloat(filterValue[1])}`
          break
        default:
          filterValueName = filterValue
      }

      return filterValueName
    }

    const onAddFilterValue = () => {
      let value = checkFilterValue()
      filterData.FilterValue.push(value)
    }

    const onRemoveFilterValue = (index) => {
      filterData.FilterValue.splice(index, 1)
    }

    const onApplyFilter = () => {
      let FilterValue = filterData.FilterValue.filter((item) => item != '')
      if (filterData.updateIndex >= 0) {
        formData.filter[filterData.updateIndex] = { ...filterData, FilterValue }
      } else {
        formData.filter.push({ ...filterData, FilterValue })
      }

      resetFilterData()
    }

    const onEditFilter = (filter, index) => {
      Object.assign(filterData, filter)
      filterData.updateIndex = index
      f7.popup.get('#filterOptionMenu').open()
    }

    const onDeleteFilter = (filter, index) => {
      formData.filter.splice(index, 1)
    }

    const onUpdateFilterToggle = (joinString) => {
      formData.filterJoinString = joinString
    }
    // END FILTER

    // START CUSTOM FILTER
    const isCustomFilterDisabled = computed(() => {
      let isDisabled = true

      for (let key in customFilterData.value) {
        let item = customFilterData.value[key]

        if (item?.value != '' || (helpers.isArray(item?.value) && item?.value?.length > 0)) {
          isDisabled = false
          break
        }
      }

      return isDisabled
    })

    const onToggleCustomFilterValue = (key) => {
      customFilterData.value[key].type = customFilterData.value[key].type == 'EXACT' ? 'LIKE' : 'EXACT'
    }

    const onClearCustomFilterValue = (key, filter) => {
      let isDate = filter.inputType == 'datepicker' ? true : false
      customFilterData.value[key].value = isDate ? [] : ''
    }

    const onApplyCustomFilter = () => {
      formData.filter = []
      for (let key in customFilterData.value) {
        if (customFilterData.value[key].value != '') {
          let isDate = customFilterData.value[key].inputType == 'datepicker' ? true : false

          formData.filter.push({
            FilterField: key,
            FilterType: isDate ? 'BETWEEN' : customFilterData.value[key].type == 'EXACT' ? '=' : 'LIKE',
            FilterValue: isDate ? [customFilterData.value[key].value] : customFilterData.value[key].value
          })
        }
      }

      onApply()
      f7.popup.get('#popupFilterMenu')?.close()
    }

    const onResetCustomFilter = () => {
      if (options?.value?.filter?.list) {
        for (let key in options?.value?.filter?.list) {
          let isDate = options?.value?.filter?.list[key].type == 'datepicker' ? true : false
          customFilterData.value[key] = { value: isDate ? [] : '', type: isDate ? 'BETWEEN' : 'EXACT', inputType: options?.value?.filter?.list[key].type }
        }
      }

      onReset()
      f7.popup.get('#popupFilterMenu')?.close()
    }
    // END CUSTOM FILTER

    // START SORT
    const resetSortData = () => {
      Object.assign(sortData, defaultSortData)
    }

    const onShowAddSort = () => {
      resetSortData()
      f7.popup.get('#sortOptionMenu').open()
    }

    const onApplySort = () => {
      if (sortData.updateIndex >= 0) {
        formData.sort[sortData.updateIndex] = { ...sortData }
      } else {
        formData.sort.push({ ...sortData })
      }

      resetSortData()
    }

    const onEditSort = (sort, index) => {
      Object.assign(sortData, sort)
      sortData.updateIndex = index
      f7.popup.get('#sortOptionMenu').open()
    }

    const onDeleteSort = (sort, index) => {
      formData.sort.splice(index, 1)
    }
    // END SORT

    // START CUSTOM SORT
    const isCustomSortDisabled = computed(() => {
      return customSortData.value.SortField == '' || customSortData.value.SortType == ''
    })

    const onApplyCustomSort = () => {
      formData.sort = []
      formData.sort.push(customSortData.value)

      onApply()
      f7.popup.get('#popupSortMenu')?.close()
    }

    const onResetCustomSort = () => {
      customSortData.value = {
        SortField: '',
        SortType: ''
      }

      onReset()
      f7.popup.get('#popupSortMenu')?.close()
    }
    // END CUSTOM SORT

    // START SIZE PAGE
    const resetSizePageData = () => {
      Object.assign(sizePageData, defaultSizePageData)
    }
    // END SIZE PAGE

    const onApply = () => {
      tabulator.value.setData()
    }

    const onReset = () => {
      if (options?.value?.filter?.list) {
        for (let key in options?.value?.filter?.list) {
          let isDate = options?.value?.filter?.list[key].type == 'datepicker' ? true : false
          customFilterData.value[key] = { value: isDate ? [] : '', type: isDate ? 'BETWEEN' : 'EXACT', inputType: options?.value?.filter?.list[key].type }
        }
      }

      if (options?.value?.sort?.list) {
        customSortData.value = {
          SortField: '',
          SortType: ''
        }
      }

      Object.assign(formData, defaultFormData)
      formData.filter = []
      formData.sort = []

      tabulator.value.setData()
    }

    const onPrint = () => {
      tabulator.value.print()
    }

    const onExportCSV = () => {
      fileName = `${options.value?.export?.fileName || 'data'}.csv`
      tabulator.value.download('csv', fileName)
    }

    const onExportJSON = () => {
      fileName = `${options.value?.export?.fileName || 'data'}.json`
      tabulator.value.download('json', fileName)
    }

    const onExportXLSX = () => {
      const win = window
      win.XLSX = xlsx
      fileName = `${options.value?.export?.fileName || 'data'}.xlsx`

      tabulator.value.download('xlsx', fileName)
    }

    const showTableOptionMenu = () => {
      f7.popup.get('#popupOptionMenu').open()
    }

    const showFilterOptionMenu = () => {
      f7.popup.get('#popupFilterMenu').open()
    }

    const showSortOptionMenu = () => {
      f7.popup.get('#popupSortMenu').open()
    }

    const onPerformAction = (type) => {
      emit('performAction', type, selectedRowData)
    }

    onMounted(async () => {
      windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      isAllowFrozen = windowWidth >= FROZEN_WIDTH

      await getTableConfig()
      initTabulator()
      tabulator?.value?.setLocale('en')

      Dom7('.page').on('click', '.image', (e) => {
        helpers.openImage(e.target.src)
        f7.actions.get('#actionsTableRowClick')?.close()
      })

      Dom7('.page').on('click', 'a', (e) => {
        f7.actions.get('#actionsTableRowClick')?.close()
      })

      Dom7('.page').on('click', '.checkbox', (e) => {
        f7.actions.get('#actionsTableRowClick')?.close()
      })

      Dom7('.page').on('click', '.toggle-collapse', (e) => {
        f7.actions.get('#actionsTableRowClick')?.close()
      })
    })

    watch(
      () => filterData.FilterField,
      (newValue) => {
        filterInputConfig.InputType = options.value?.filter?.list?.[newValue]?.type || 'text'
        filterInputConfig.InputItems = options.value?.filter?.list?.[newValue]?.items || []
        filterInputConfig.FilterType = []

        for (let item of options.value?.filter?.type) {
          if (item?.type.indexOf(filterInputConfig.InputType) > -1) {
            filterInputConfig.FilterType.push(item)
          }
        }

        if (filterData?.FilterValue == '' || filterData?.FilterValue?.length <= 0) {
          filterData.FilterValue = [checkFilterValue()]
        }
      }
    )

    return {
      // TABLE
      tableInfo,
      tableReference,
      initTabulator,
      // SEARCH
      onSearchReset,
      // FILTER
      filterData,
      customFilterData,
      isCustomFilterDisabled,
      filterInputConfig,
      getFilterTypeName,
      getFilterValueName,
      onShowAddFilter,
      onAddFilterValue,
      onRemoveFilterValue,
      onEditFilter,
      onDeleteFilter,
      onUpdateFilterToggle,
      onApplyFilter,
      onToggleCustomFilterValue,
      onClearCustomFilterValue,
      onApplyCustomFilter,
      onResetCustomFilter,
      // SORT
      sortData,
      customSortData,
      isCustomSortDisabled,
      onShowAddSort,
      onEditSort,
      onDeleteSort,
      onApplySort,
      onApplyCustomSort,
      onResetCustomSort,
      // SIZE PAGE
      sizePageData,
      // GENERATE
      onPrint,
      onExportCSV,
      onExportJSON,
      onExportXLSX,
      // OPTION MENU
      formData,
      options,
      showTableOptionMenu,
      showFilterOptionMenu,
      showSortOptionMenu,
      onApply,
      onReset,
      // ACTIONS
      selectedRowData,
      onPerformAction
    }
  }
})
</script>

<style>
.popup.popup-options {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 420px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;
}
.popup.popup-options.modal-in {
  transform: translate3d(0, 0, 0);
}
.popup.popup-options.modal-out {
  transform: translate3d(100vh, 0, 0);
}
.popup.popup-options .navbar a.link {
  padding: 0 20px;
}
.popup-options .page-content {
  padding: 90px 30px 20px;
}
.popup.popup-options .list {
  width: 100%;
}
.md .search-field .item-input-info {
  overflow: visible;
}
.popup.popup-options.popup-filter .list,
.popup.popup-options.popup-sort .list {
  --f7-list-item-padding-horizontal: 0px;
  margin: 0px;
}
.popup.popup-options .button {
  margin-top: 10px;
  width: 100%;
}
.popup.popup-options .button.button-remove {
  width: 60px;
  height: 55px;
  margin-top: 5px;
  margin-left: 15px;
}
</style>
