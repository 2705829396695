<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation("LBL_INFLUENCER") + " - " + (isUpdate ? $t.getTranslation("LBL_UPDATE") : $t.getTranslation("LBL_CREATE")) }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <div v-if="influencerInfo && influencerInfo.UserId > 0" class="profile-info">
          <img :src="$h.getImage(influencerInfo.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />

          <div class="info">
            <h1>{{ $h.getFullName(influencerInfo) }}</h1>
            <h2>{{ influencerInfo.UserName || influencerInfo.Email || influencerInfo.OpenId }}</h2>
          </div>
        </div>

        <div v-if="!isUpdate">
          <f7-button class="select-user" large fill raised @click="selectUser()">
            {{ $t.getTranslation("LBL_SELECT_USER") }}
          </f7-button>
        </div>

        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.IsPjfOfficial"
            name="IsPjfOfficial"
            :required="validationRules?.IsPjfOfficial?.required"
            :minlength="validationRules?.IsPjfOfficial?.minimumLength"
            :maxlength="validationRules?.IsPjfOfficial?.maximumLength"
            :label="$t.getTranslation('LBL_IS_PJF_OFFICIAL')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option :value="1">{{ $t.getTranslation("LBL_YES") }}</option>
            <option :value="0">{{ $t.getTranslation("LBL_NO") }}</option>
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.UserStatusCode"
            name="UserStatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="userstatus in userStatusList" :key="'st_' + userstatus.Code" :value="userstatus.Code">{{ userstatus.Name }}</option>
          </f7-list-input>

          <f7-list-item>
            <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
              {{ $t.getTranslation("LBL_SAVE") }}
            </f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </section>

    <UserSelectionPopUpComponent :open="openUserPopup" @closed="onCloseUserPopup" @selected="onUserSelected" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";

import { post, get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

import UserSelectionPopUpComponent from "@/components/UserSelectionPopUpComponent.vue";

export default defineComponent({
  name: "InfluencerSavePage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    UserSelectionPopUpComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const influencerInfo = ref(false);

    const userStatusList = computed(() => store.getters["config/getData"]?.userStatusList);

    const formData = reactive({
      UserId: 0,
      UserStatusCode: "PENDING",
      IsPjfOfficial: 0,
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      if (props?.f7route?.params?.id && parseInt(props?.f7route?.params?.id) > 0) {
        isUpdate.value = true;
        getData();
      }
    });

    const getData = async () => {
      let getDataReturn = await get(`/admin/influencer/view`, { InfluencerId: props?.f7route?.params?.id });
      influencerInfo.value = getDataReturn;

      for (let keys in formData) {
        if (getDataReturn && getDataReturn[keys]) {
          formData[keys] = getDataReturn[keys];
        }
      }

      console.log(influencerInfo.value);
    };

    const openUserPopup = ref(false);

    const onCloseUserPopup = () => {
      openUserPopup.value = false;
    };

    const selectUser = () => {
      openUserPopup.value = true;
    };

    const onUserSelected = (data) => {
      console.log(data);
      formData.UserId = data?.UserId;
      influencerInfo.value = data;
    };

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const save = async () => {
      let isValid = validate(true);
      if (isValid) {
        isButtonProcessing.value = true;

        if (isUpdate.value) {
          formData.InfluencerId = props?.f7route?.params?.id;
        }

        let formSaveReturn = await post("/admin/influencer/save", formData);
        isButtonProcessing.value = false;

        if (formSaveReturn) {
          props.f7router.back({ force: true });
        }
      }
    };

    return {
      isUpdate,
      formData,
      influencerInfo,
      validationRules,
      selectUser,
      openUserPopup,
      onCloseUserPopup,
      onUserSelected,
      isButtonProcessing,
      isDisableSaveButton,
      save,
      userStatusList,
    };
  },
});
</script>

<style scoped>
.select-user {
  width: 300px;
  margin-left: 15px;
  margin-bottom: 20px;
}
</style>
