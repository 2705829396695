import dayjs from 'dayjs'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'

const data = {
  languageCode: 'EN',
  translations: {},
  expirationDate: ''
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getLanguage: (state) => {
    return state.data.languageCode
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  setLanguage: async (context, payload) => {
    context.commit('UPDATE_DATA', payload)
    await $HTTP.post(`/user/update/language`, { LanguageCode: payload.languageCode })
  },
  resetData: (context) => {
    context.commit('UPDATE_DATA', data)
  },
  fetchData: async (context, payload) => {
    const expirationDate = context.state.data.expirationDate
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (payload?.force) {
      isExpired = true
    }

    if (expirationDate == '' || isExpired) {
      try {
        const res = await $HTTP.get(`/translation/public?LanguageCode=${context.state.data.languageCode}`)

        if (res && res.status === 200 && res.data && res.data.data) {
          const translationList = res.data.data
          let expirationDate = dayjs()
            .add(1, 'hour')
            .valueOf()

          let translations = context.state.data.translations
          translations[context.state.data.languageCode] = translationList

          context.commit('UPDATE_DATA', { translations, expirationDate })
          return
        }

        throw new Error('Server not responding, Please try again later.')
      } catch (err) {
        helpers.catchError(err, true)
      }
    } else {
      return
    }
  }
}

export const translation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
