import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'coupon',
    path: '/coupon/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'ticket',
        title: translation.getTranslation('LBL_COUPON'),
        activeLink: 'coupon'
      },
      isSecured: true,
      hasAnyRoles: ['MANAGE_COUPON']
    },
    redirect: '/coupon/voucher/list/',
    routes: [
      {
        name: 'voucherExcludeList',
        path: 'voucher/exclude/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('BTN_EXCLUDE_SKU'),
            activeLink: 'voucherExcludeList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/VoucherExcludeListPage.vue')
      },
      {
        name: 'voucherCodeList',
        path: 'voucher/code/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_COUPON_CODE'),
            activeLink: 'voucherCodeList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/RedeemVoucherListPage.vue')
      },
      {
        name: 'voucherCodeSave',
        path: 'voucher/code/save/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'voucherCodeSave'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/RedeemVoucherSavePage.vue')
      },
      {
        name: 'voucherList',
        path: 'voucher/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_SYSTEM_COUPON'),
            activeLink: 'voucherList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/VoucherListPage.vue')
      },
      {
        name: 'voucherSave',
        path: 'voucher/save/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'voucherSave'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/VoucherSavePage.vue')
      },

      {
        name: 'voucherOrders',
        path: 'voucher/order/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'voucherOrders'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/VoucherOrder.vue')
      },

      {
        name: 'voucherPicked',
        path: 'voucher/picked/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'voucherPicked'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/VoucherPicked.vue')
      },

      {
        name: 'userVoucherList',
        path: 'user/voucher/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_USER_DESIGNATED_COUPON'),
            activeLink: 'userVoucherList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/UserVoucherListPage.vue')
      },
      {
        name: 'userVoucherSave',
        path: 'user/voucher/save/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'userVoucherSave'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/UserVoucherSavePage.vue')
      },
      {
        name: 'voucherGroupList',
        path: 'voucher/group/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_GROUP_COUPON'),
            activeLink: 'voucherGroupList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUPON']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/voucher/VoucherGroupListPage.vue')
      }
    ]
  }
]

export default routes
