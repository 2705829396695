<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_PAGE_VIEW_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>
    </div>

    <DataTableComponent v-if="url" ref="datatable" :columns="columns" :size="10" :url="url" :static="true"></DataTableComponent>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, reactive } from "vue";

import dayjs from 'dayjs'
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "ProductListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route : Object },
  setup(props) {
    const $t = inject("$translation");

    const columns = [
      {
        title: $t.getTranslation("LBL_IMAGE"),
        maxWidth: 90,
        minWidth: 90,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${helpers.getImage(cell.getData().Image, "USER") || require("@/assets/images/placeholder.jpg")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_NAME"),
        maxWidth: 320,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <strong>${(cell.getData().UserId) ? (cell.getData().FullName || cell.getData().Username || cell.getData().OpenId || cell.getData().UserId || "---") : $t.getTranslation('LBL_GUEST_LOGIN')}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IP_ADDRESS"),
        formatter(cell) {
          return `
            <div>
              <strong>${(cell.getData().IpAddress) ? cell.getData().IpAddress.toString().replace(/[^\d.]/g, '') : "---"}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IS_MOBILE_BROWSER"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.IsMobile == 1 ? "color-blue" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.IsMobile == 1 ?   $t.getTranslation("LBL_YES") : $t.getTranslation("LBL_NO") || '---' }</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IS_WECHAT_BROWSER"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.IsWechat == 1 ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.IsWechat == 1 ?   $t.getTranslation("LBL_YES") : $t.getTranslation("LBL_NO") || '---' }</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_VISITED_URL"),
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Url.split('?')[0] || "---"}</strong>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_LAST_CREATED"),
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        },
      }
    ];

    const url = ref("");
    onMounted(()=>{
      if (props?.f7route?.query && props?.f7route?.query?.Start && props?.f7route?.query?.End && props?.f7route?.query?.SearchKey) {
        url.value = `/admin/pageview/list?from=${props.f7route.query.Start}&to=${props.f7route.query.End}&SearchKey=${props.f7route.query.SearchKey}`;   
      }
    })

    return {
      	columns,
      	url
    };
  },
});
</script>

<style scoped>
.card-count{
  padding:10px;
  font-size:18px;
}
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}
</style>
