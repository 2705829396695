let configs = {
  baseURL: '',
  environment: '',
  title: 'PJF Wine Admin',
  appId: 'app.pjfwine.admin',
  chatServer: 'https://pjfwines.com/signal',
  baseURLInfo: {
    local: 'http://localhost:3000/api',
    development: 'http://uat.pjfwines.com/api',
    production: 'https://pjfwines.com/api'
  },
  customerSupportThread: {
    local: 'LOCAL_CUSTOMER_SUPPORT',
    development: 'DEV_CUSTOMER_SUPPORT',
    production: 'PROD_CUSTOMER_SUPPORT'
  },
  appHost: 'http://localhost:3003/',
  axiosTimeout: 300000,
  tokenSession: 'pjf-wine-admin',
  tokenExpiration: 86400000,
  tokenExpirationRememberMe: 2592000000,
  encryptionSecret: 'zHs4s2kadhs1$23@Sasd1a9kjaj2xj1aki11asdjd',
  externalPageLinks: {
    termsAndConditions: '/terms',
    privacyPolicy: '/privacy'
  },
  socialLinks: {
    facebook: '',
    instagram: '',
    youtube: ''
  },
  contactInfo: {
    telephone: [],
    mobile: [],
    email: '',
    address: '',
    map: ''
  }
}

configs.environment = 'production'
configs.baseURL = configs.baseURLInfo[configs.environment]
configs.customerSupport = configs.customerSupportThread[configs.environment]

for (let key in configs.externalPageLinks) {
  configs.externalPageLinks[key] = configs.baseURL.replace('/api', '/page') + configs.externalPageLinks[key]
}

export default configs
