<template>
  <f7-popup id="saveUserReferralCodeSupport" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="false">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <FormComponent ref="formComponent" @successSave="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { f7 } from "framework7-vue";

import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";

const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "UserReferralCodeSavePartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const title = ref("");
    const $t = inject("$translation");

    const formComponentData = {
      buttonText: "",
      getLink: "/admin/userreferralcode/view",
      getParams: {},
      validation: "userreferralcode",
      saveLink: "/admin/userreferralcode/save",
      saveParams: {},
      successCreateMessage: $t.getTranslation("LBL_SUCCESS_CREATE_USER_REFERRAL_CODE"),
      successUpdateMessage: $t.getTranslation("LBL_SUCCESS_UPDATE_USER_REFERRAL_CODE"),
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("LBL_USER_REFERRAL_CODE_INFORMATION"),
        type: "input",
        list: [{ name: $t.getTranslation("LBL_USER_REFERRAL_CODE"), type: "text", value: "ReferralCode", required: true }],
      },
    ];

    return {
      title,
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup(data) {
      const self = this;
      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);

      if (data?.UserId) {
        self.title = this.$t.getTranslation("LBL_UPDATE_USER_REFERRAL_CODE");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_UPDATE_USER_REFERRAL_CODE");
        self.formComponentData.getParams.UserId = data?.UserId;
        self.formComponentData.saveParams.UserId = data?.UserId;

        self.$refs.formComponent.setFormComponentData(self.formComponentData, true);
      } else {
        self.title = this.$t.getTranslation("LBL_CREATE_USER_REFERRAL_CODE");
        self.formComponentData.buttonText = this.$t.getTranslation("LBL_CREATE_USER_REFERRAL_CODE");
        self.formComponentData.getParams = {};
        self.formComponentData.saveParams = {
          UserId: data.UserId,
        };

        self.$refs.formComponent.setFormComponentData(self.formComponentData);
      }

      f7.popup.open("#saveUserReferralCodeSupport");
      self.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#saveUserReferralCodeSupport");
      this.$emit("closed", data);
    },
  },
});
</script>
