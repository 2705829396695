<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'users']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_USER_VOUCHER_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <UserViewPartial ref="userViewPartial" :f7router="f7router" />
    <UserCustomerSupportSavePartial ref="userCustomerSupportSavePartial" :f7router="f7router" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

// PARTIALS
import UserViewPartial from "@/partials/user/UserViewPartial.vue";
import UserCustomerSupportSavePartial from "@/partials/user/UserCustomerSupportSavePartial.vue";

import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "UserVoucherListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    // PARTIALS
    UserViewPartial,
    UserCustomerSupportSavePartial,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const actions = [
      {
        groupText: "Perform Actions",
        list: [{ text: "Delete", type: "DELETE", color: "red" }],
      },
    ];

    const columns = [
      {
        title: "Id",
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().UserVoucherId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: "Image",
        minWidth: 80,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${helpers.getImage(cell.getData().Image, "USER")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_INFO"),
        minWidth: 320,
        frozen: true,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.getFullName(cell.getData(), true)}</strong></div>
              <div><strong>${cell.getData().UserId}</strong> - <strong>${cell.getData().UserName || ""}</strong></div>
              <div>${cell.getData().UserKey}</div>
              <div>
                <strong>${cell.getData().MobileCode || ""} ${cell.getData().MobileNumber || "0000000000"}</strong>
                <div>${cell.getData().Email || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_VOUCHER_TYPE_INFO"),
        minWidth: 300,
        formatter(cell) {
          return `
            <div>
              <div>${cell.getData().VoucherTypeName || "--"}</div>
              <strong>${cell.getData().VoucherTypeCode || ""} - ${cell.getData().DispType || ""}</strong>
              <div><strong>${$t.getTranslation("LBL_START_DATE")}:</strong> ${helpers.formatDateTime(cell.getData().StartDate)}</div>
              <div><strong>${$t.getTranslation("LBL_END_DATE")}:</strong> ${helpers.formatDateTime(cell.getData().EndDate)}</div>
              <div><strong>${$t.getTranslation("LBL_SPECIFIC_SKU")}:</strong> ${cell.getData().SpecificSku || "--"}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_VOUCHER_INFO"),
        minWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_AMOUNT")}:</strong> ${cell.getData().Amount}</div>
              <div><strong>${$t.getTranslation("LBL_AMOUNT_TYPE")}:</strong> ${cell.getData().AmountType}</div>
              <div><strong>${$t.getTranslation("LBL_MIN_ORDER_AMOUNT")}:</strong> ${cell.getData().MinOrderAmount}</div>
            </div>
          `;
        },
      },

      {
        title: $t.getTranslation("LBL_REDEEM_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.UserVoucherStatus)} ${cell.getData()?.UserVoucherStatus}">
                <div class="chip-label">${cell.getData()?.UserVoucherStatus}</div>
              </div>
              <br /><br />
              <div><strong>${$t.getTranslation("LBL_ORDER_ID")}:</strong> ${cell.getData().OrderId || "--"}</div>
              <div><strong>${$t.getTranslation("LBL_DATE_REDEEMED")}:</strong> ${helpers.formatDateTime(cell.getData().RedeemedDate)}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="Date Created"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="Date Updated"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const url = ref("/admin/uservoucher/datalist");

    if (props?.f7route?.query?.UserId && props?.f7route?.query?.UserId != "") {
      url.value += `?UserId=${props?.f7route?.query?.UserId}`;
    }

    return {
      url,
      columns,
      actions,
    };
  },
  methods: {
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "DELETE":
          if (data?.value?.UserVoucherId) {
            if (data?.value?.UserVoucherStatus === "AVAILABLE") {
              helpers.createConfirmation({
                message: "Are you sure you want to delete selected user voucher?",
                confirm: async () => {
                  let returnData = await post("/admin/uservoucher/delete", { UserVoucherId: data?.value?.UserVoucherId });
                  if (returnData)
                    helpers.createNotification({
                      type: "info",
                      title: "INFO",
                      message: "Successfully deleted selected user voucher.",
                    });

                  self.$refs.dataTableComponent.initTabulator();
                },
              });
            } else {
              helpers.createNotification({
                type: "error",
                title: "ERROR",
                message: "Cannot delete selected user voucher.",
              });
            }
          }
          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
