import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'manage',
    path: '/manage/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'mobile',
        title: translation.getTranslation('LBL_MANAGE'),
        activeLink: 'manage'
      },
      isSecured: true,
      hasAnyRoles: ['MANAGE_POST', 'MANAGE_CATEGORY', 'MANAGE_EVENT', 'MANAGE_CONTACT', 'MANAGE_REVIEW', 'MANAGE_PLATFORM_REVIEW', 'MANAGE_BANNER', 'MANAGE_COUNTRY', 'MANAGE_GRAPES', 'MANAGE_WINERY', 'MANAGE_WAREHOUSE', 'MANAGE_SURVEY']
    },
    redirect: '/manage/post/list',
    routes: [
      {
        name: 'postList',
        path: 'post/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_POST_LIST'),
            activeLink: 'postList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_POST']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/post/PostListPage.vue')
      },
      {
        name: 'categoryList',
        path: 'category/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_CATEGORY_LIST'),
            activeLink: 'categoryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CATEGORY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/CategoryListPage.vue')
      },
      {
        name: 'categoryPositionList',
        path: 'category/position/list',
        meta: {
          navigation: {
            isShown: false,
            title: translation.getTranslation('LBL_CATEGORY_POSITION_LIST'),
            activeLink: 'categoryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CATEGORY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/categories/CategoryPositionListPage.vue')
      },
      {
        name: 'categorySave',
        path: 'category/save/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'categoryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CATEGORY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/categories/CategorySavePage.vue')
      },
      {
        name: 'categoryUpload',
        path: 'category/category/:id',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'categoryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CATEGORY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/categories/CategoryUploadImage.vue')
      },
      {
        name: 'eventList',
        path: 'event/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_EVENT_LIST'),
            activeLink: 'eventList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_EVENT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/event/ListPage.vue')
      },
      {
        name: 'eventSave',
        path: 'event/save/:id',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'eventList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_EVENT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/event/SavePage.vue')
      },
      {
        name: 'contactList',
        path: 'contact/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_INQUIRY_LIST'),
            activeLink: 'contactList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CONTACT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/contact/ContactListPage.vue')
      },
      {
        name: 'contactView',
        path: 'contact/view/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'contactList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CONTACT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/contact/ContactViewPage.vue')
      },
      {
        name: 'bannerList',
        path: 'banner/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_BANNER_LIST'),
            activeLink: 'bannerList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_BANNER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/banner/BannerListPage.vue')
      },
      // {
      //   name: 'bannerSave',
      //   path: 'banner/save/',
      //   meta: {
      //     navigation: {
      //       isShown: false,
      //       activeLink: 'bannerList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_BANNER']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/banner/BannerSavePage.vue')
      // },
      // {
      //   name: 'bannerUploadPage',
      //   path: 'banner/upload/:id',
      //   meta: {
      //     navigation: {
      //       isShown: false,
      //       activeLink: 'bannerList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_BANNER']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/banner/BannerUploadPage.vue')
      // },
      {
        name: 'inquiryList',
        path: 'inquiry/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_MERCHANT_INQUIRY_LIST'),
            activeLink: 'inquiryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CONTACT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/inquiry/MerchantInquiryListPage.vue')
      },
      {
        name: 'inquiryView',
        path: 'inquiry/view/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'inquiryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_CONTACT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/inquiry/MerchantInquiryViewPage.vue')
      },
      {
        name: 'reviewList',
        path: 'review/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_REVIEW_LIST'),
            activeLink: 'reviewList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_REVIEW']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/review/ReviewListPage.vue')
      },
      {
        name: 'platformReviewList',
        path: 'platform/review/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PLATFORM_REVIEW_LIST'),
            activeLink: 'platformReviewList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PLATFORM_REVIEW']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/PlatformReviewListPage.vue')
      },
      {
        name: 'countryList',
        path: 'country/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_COUNTRY_LIST'),
            activeLink: 'countryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUNTRY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/country/CountryListPage.vue')
      },
      {
        name: 'countryPositionList',
        path: 'country/position',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'countryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUNTRY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/country/CountryPositionListPage.vue')
      },
      {
        name: 'countrySave',
        path: 'country/save',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'countryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_COUNTRY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/country/CountrySavePage.vue')
      },
      {
        name: 'grapesList',
        path: 'grapes/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_GRAPES_LIST'),
            activeLink: 'grapesList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_GRAPES']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/grapes/GrapesListPage.vue')
      },
      {
        name: 'grapesSave',
        path: 'grapes/save',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'grapesList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_GRAPES']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/grapes/GrapesSavePage.vue')
      },
      {
        name: 'grapesUpload',
        path: 'grapes/upload/:id',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'grapesList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_GRAPES']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/grapes/GrapesUploadPage.vue')
      },
      {
        name: 'wineryList',
        path: 'winery/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_WINERY_LIST'),
            activeLink: 'wineryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_WINERY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/winery/WineryListPage.vue')
      },
      {
        name: 'winerySave',
        path: 'winery/save',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'wineryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_WINERY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/winery/WinerySavePage.vue')
      },
      {
        name: 'wineryUpload',
        path: 'winery/upload/:id',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'wineryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_GRAPES']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/winery/WineryUploadPage.vue')
      },
      {
        name: 'warehouseList',
        path: 'warehouse/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_WAREHOUSE_LIST'),
            activeLink: 'warehouseList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_WAREHOUSE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/warehouse/WareHouseListPage.vue')
      },
      {
        name: 'warehouseSave',
        path: 'warehouse/save',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'warehouseList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_WAREHOUSE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/warehouse/WareHouseSavePage.vue')
      },
      {
        name: 'warehouseRegionList',
        path: 'warehouse/region/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_WAREHOUSE_REGION_LIST'),
            activeLink: 'warehouseRegionList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_WAREHOUSE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/warehouse/WareHouseRegionListPage.vue')
      },
      {
        name: 'warehouseRegionSave',
        path: 'warehouse/region/save',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'warehouseList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_WAREHOUSE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/warehouse/WareHouseRegionSavePage.vue')
      }
      // {
      //   name: 'surveyuserList',
      //   path: 'survey/user/list',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('LBL_SURVEY_USER_LIST'),
      //       activeLink: 'surveyuserList'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_SURVEY']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/survey/SurveyUserListPage.vue')
      // },
      // {
      //   name: 'surveyuserView',
      //   path: 'survey/user/view',
      //   meta: {
      //     navigation: {
      //       isShown: false,
      //       title: translation.getTranslation('LBL_SURVEY_USER_LIST'),
      //       activeLink: 'surveyuserView'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_SURVEY']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/survey/SurveyUserViewPage.vue')
      // }
    ]
  }
]

export default routes
