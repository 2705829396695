import axios from 'axios'
import { configs } from '@/utils/configs'
import { helpers } from '@/utils/helpers'
import { translation as $t } from '@/utils/translation'

import { useStore } from '@/store'
const store = useStore()

const HTTP = axios.create({
  baseURL: configs.baseURL,
  timeout: configs.axiosTimeout
})

HTTP.interceptors.request.use((config) => {
  const userToken = store.getters['user/getToken']

  if (userToken && userToken !== '') {
    config.headers.Authorization = `Bearer ${userToken}`
  }

  return config
})

const get = async (apiLink, query, symbol = '?') => {
  try {
    if (apiLink.indexOf('?') > -1) symbol = '&'
    let res = await HTTP.get(`${apiLink}${query && !helpers.isBlank(query) ? helpers.objToQueryString(query || {}, symbol) : ''}`)

    if (res && res.status === 200 && res?.data?.data) {
      helpers.hideLoader()
      return res.data.data
    }

    throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
  } catch (err) {
    helpers.hideLoader()
    helpers.catchError(err, true)
  }
}

const post = async (apiLink, post) => {
  try {
    helpers.showLoader()
    let res = await HTTP.post(apiLink, post)

    if (res && res.status === 200 && res?.data?.data) {
      helpers.hideLoader()
      return res.data.data
    }

    throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
  } catch (err) {
    helpers.hideLoader()
    helpers.catchError(err, true)
  }
}

const install = (app) => {
  app.config.globalProperties.$HTTP = HTTP
}

export { install as default, HTTP as $HTTP, get, post }
