<template>
  <div class="view-info">
    <h2>{{ title }}</h2>

    <div v-for="field in fields" :key="'vk_' + field.field" class="field">
      <h3>{{ field.name === "auto" ? $h.formatPascalCase(field.field) : field.name }}</h3>
      <div v-if="field.format === 'image'" class="image-container">
        <img :src="formatField(field, data)" :alt="field.field + 'Image'" @click="$h.openImage(formatField(field, data))" />
      </div>
      <p v-else>{{ formatField(field, data) }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "ViewItemComponent",
  props: {
    title: String,
    fields: Array,
    data: Object,
  },
  setup(props) {
    const formatField = (field, data) => {
      let returnField = "";

      switch (field.format) {
        case "date":
          returnField = helpers.formatDate(data?.[field?.field]);
          break;
        case "datetime":
          returnField = helpers.formatDateTime(data?.[field?.field]);
          break;
        case "uppercase":
          returnField = data?.[field?.field]?.toUpperCase();
          break;
        case "lowercase":
          returnField = data?.[field?.field]?.toLowerCase();
          break;
        case "number":
          returnField = parseInt(data?.[field?.field]);
          break;
        case "decimal":
          returnField = parseFloat(data?.[field?.field]);
          break;
        case "image":
          returnField = helpers.getImage(data?.[field?.field], field?.bucket);
          break;
        default:
          returnField = data?.[field?.field];
      }

      if (helpers.isBlank(returnField)) {
        returnField = field?.placeholder ? field.placeholder : "--";
      }

      return returnField;
    };

    return { formatField };
  },
});
</script>
<style></style>
