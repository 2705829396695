import { getNavigationLists, checkMeta } from '@/routes/@common.js'

// Import Routes
import auth from '@/routes/auth.js'
import dashboard from '@/routes/dashboard.js'
import order from '@/routes/order.js'
import payment from '@/routes/payment.js'
import product from '@/routes/product.js'
import merchant from '@/routes/merchant.js'
import manage from '@/routes/manage.js'
import user from '@/routes/user.js'
import setting from '@/routes/setting.js'
import message from '@/routes/message.js'
import marketing from '@/routes/marketing.js'
import coupon from '@/routes/coupon.js'

const routes = [
  {
    name: '',
    path: '/',
    redirect: ({ to, resolve, reject }) => {
      return resolve('/dashboard/')
    }
  },
  ...auth,
  ...dashboard,
  ...message,
  ...order,
  ...payment,
  ...product,
  ...merchant,
  ...manage,
  ...marketing,
  ...coupon,
  ...user,
  ...setting,
  {
    path: '(.*)',
    meta: {
      activeLink: '',
      isSecured: false,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/NotFoundPage.vue')
  }
]

export { routes as default, getNavigationLists }
