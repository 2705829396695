<template>
  <div class="list" :class="{ active: active }">
    <img :src="$h.getImage(data.User?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
    <div class="info">
      <span class="user" style="white-space: nowrap; overflow: hidden; width: 90%; display: inline-block; text-overflow: ellipsis">{{
        $h.formatName(data.User) || data?.User?.UserName || data?.User?.Email || $t.getTranslation("LBL_CUSTOMER")
      }}</span>
      <span class="text" style="white-space: nowrap; overflow: hidden; width: 80%; display: inline-block; text-overflow: ellipsis" v-if="data.LastMsg">{{ $t.getTranslation(data.LastMsg) }}</span>
    </div>
    <div class="count-time">
      <span class="time">{{ $h.formatDateTimeCustom(data.LastModified) }}</span>
      <span class="count" v-if="data.Unread && !active">{{ data.Unread }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "ThreadComponent",
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    data: Object,
  },
  setup(props) {
    return {};
  },
});
</script>
