<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_KOL_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div>


      <div class="button-container">
          <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>

    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @viewItem="editItem"></DataTableComponent>
     
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, computed } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "BlankPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object },
  setup(props) {
     const $t = inject("$translation");
     const store = useStore();
     const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
     const columns = [
      {
        title: $t.getTranslation("LBL_SKU"),
        field: "Sku"
      },
      {
        title: $t.getTranslation("LBL_MERCHANT_NAME"),
        field: "MerchantName"
      },
      {
        title: $t.getTranslation("LBL_KOL_USERNAME"),
        field: "UserName"
      },
      {
        title: $t.getTranslation("LBL_PROMOTION_CHANNEL"),
        field: "PromotionChannel"
      },
      {
        title: $t.getTranslation("LBL_DEEPLINK"),
        field: "DeepLink"
      },
      {
        title: $t.getTranslation("LBL_COMMISSION_RATIO"),
        field: "CommissionRatio"
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_COMMISSION_PERIOD"),
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_START_DATE")}:</strong> ${helpers.formatDateTime(cell.getData().StartDate)}</div>
              <div><strong>${$t.getTranslation("LBL_END_DATE")}:</strong> ${helpers.formatDateTime(cell.getData().EndDate)}</div>
            </div>
          `;
        },
      }
    ];


    const editItem = (data) => {
      if (data?.value?.KolUserProductId) {
        props.f7router.navigate({ name: "kolView", params: { id: data.value.KolUserProductId } });
      }
    };

    const create = () => {
      props.f7router.navigate({ name: "kolView", params: { id: 0 } });
    };

    let url = `/admin/kol/list?LanguageCode=${currentLanguageCode.value}`;
    return {
      columns,
      url,
      editItem,
      create
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
</style>
