<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation("LBL_EVENT") + " - " + (isUpdate ? $t.getTranslation("LBL_UPDATE") : $t.getTranslation("LBL_CREATE")) }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableSaveButton" @click="save">
          {{ $t.getTranslation("LBL_SAVE") }}
        </f7-button>
      </div>
    </div>

    <section class="form-container">
      <link :href="require('@/assets/css/materialicon.css')" rel="stylesheet" />

      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{ $t.getTranslation("LBL_PRIMARY") }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.EventCode"
            name="EventCode"
            :required="validationRules?.EventCode?.required"
            :minlength="validationRules?.EventCode?.minimumLength"
            :maxlength="validationRules?.EventCode?.maximumLength"
            :label="$t.getTranslation('LBL_EVENT_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="text"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.EventDate"
            name="EventDate"
            :required="validationRules?.EventDate?.required"
            :minlength="validationRules?.EventDate?.minimumLength"
            :maxlength="validationRules?.EventDate?.maximumLength"
            :label="$t.getTranslation('LBL_EVENT_DATE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="date"
            validate
          >
          </f7-list-input>
          <f7-list-input
            v-if="statusList && statusList.length > 0"
            v-model:value="formData.StatusCode"
            name="StatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-list-item no-hairlines class="content-item-container">
            <div class="title">
              <h1>{{ $t.getTranslation("LBL_CONTENT") }}</h1>
              <f7-button fill @click="addContent">{{ $t.getTranslation("LBL_ADD_CONTENT") }}</f7-button>
            </div>

            <div class="translation-container">
              <template v-if="formData?.TranslationList && formData?.TranslationList.size > 0">
                <f7-list media-list>
                  <f7-list-item
                    v-for="translation of formData.TranslationList"
                    :key="'trn_' + translation.LanguageCode"
                    link="#"
                    :title="$h.formatTrimString(translation?.Title) || '--'"
                    :after="translation?.LanguageCode"
                    :text="$h.formatTrimString(translation?.Description) || '--'"
                    @click="contentTranslationActions(translation)"
                  ></f7-list-item>
                </f7-list>

                <f7-actions class="contentTranslationActions">
                  <f7-actions-group>
                    <f7-actions-button @click="editPostContent">{{ $t.getTranslation("LBL_EDIT") }}</f7-actions-button>
                    <f7-actions-button color="red" @click="deletePostContent">{{ $t.getTranslation("LBL_DELETE") }}</f7-actions-button>
                  </f7-actions-group>
                </f7-actions>
              </template>

              <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_CONTENT_EMPTY')" />
            </div>
          </f7-list-item>
        </f7-list>
      </div>

      <div class="container">
        <div class="title">
          <h1>{{ $t.getTranslation("LBL_IMAGE") }}</h1>
        </div>

        <div class="create-content" @click="selectImage">
          <img v-if="formData?.Image" :src="$h.renderImageBlob(formData?.Image) || $h.getImage(formData?.Image, 'EVENT')" />

          <div v-else class="select-image">
            <font-awesome-icon :icon="['fal', 'layer-plus']" fixed-width />
          </div>
        </div>

        <div class="title">
          <h1>{{ $t.getTranslation("LBL_VIDEO") }}</h1>
        </div>

        <div class="create-content" @click="selectVideo">
          <video v-if="formData?.Video" controls muted>
            <source :src="selectedVideo || $h.getVideo(formData?.Video, 'EVENT')" type="video/mp4" />
            {{ $t.getTranslation("LBL_BROWSER_NO_SUPPORT_VIDEO") }}
          </video>

          <div v-else class="select-image">
            <font-awesome-icon :icon="['fal', 'video-plus']" fixed-width />
          </div>
        </div>
      </div>
    </section>

    <MainFooterComponent />

    <ImageUploadPopUpComponent :description="$t.getTranslation('LBL_SELECT_IMAGE_TO_USE')" :open="openPopup" :image-size-limit="5" @closed="onClosedPopup" @cropped="onImageSelected" />
    <VideoUploadPopUpComponent :open="openVideoPopup" :video-size-limit="15" @closed="onCloseVideoPopup" @selected="onVideoSelected" />

    <ContentTranslationPopUpComponent :open="openContentTranslationPopup" :data="selectedContentTranslation" @closed="onCloseContentTranslationPopup" @saved="onContentTranslationSaved" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from "vue";
import { f7 } from "framework7-vue";

import { post, get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";

import ImageUploadPopUpComponent from "@/components/ImageUploadPopUpComponent.vue";
import VideoUploadPopUpComponent from "@/components/VideoUploadPopUpComponent.vue";
import ContentTranslationPopUpComponent from "@/components/ContentTranslationPopUpComponent.vue";
import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

export default defineComponent({
  name: "EventSavePage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    ImageUploadPopUpComponent,
    VideoUploadPopUpComponent,
    ContentTranslationPopUpComponent,
    NoDataFoundComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const selectedVideo = ref(false);

    const statusList = computed(() => store.getters["config/getData"]?.statusList);

    const formData = reactive({
      EventCode: "",
      EventDate: "",
      StatusCode: "",
      Image: "",
      Video: "",
      TranslationList: new Set(),
      DeleteTranslationList: new Set(),
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      if (props?.f7route?.params?.id && parseInt(props?.f7route?.params?.id) > 0) {
        isUpdate.value = true;
        getData();
      }
    });

    const getData = async () => {
      let getDataReturn = await get(`/admin/event/view`, { EventId: props?.f7route?.params?.id });

      for (let keys of ["EventCode", "Image", "Video", "StatusCode"]) {
        if (getDataReturn && getDataReturn[keys]) {
          formData[keys] = getDataReturn[keys];
        }
      }

      formData["EventDate"] = helpers.formatJSDate(getDataReturn["EventDate"]);

      formData.TranslationList = new Set();
      formData.DeleteTranslationList = new Set();

      if (getDataReturn?.TranslationList) {
        for (let translation of getDataReturn?.TranslationList) {
          formData.TranslationList.add(translation);
        }
      }
    };

    // FOR ADDING CONTENT POPUPS
    const openContentTranslationPopup = ref(false);
    const selectedContentTranslation = ref(false);

    const addContent = () => {
      openContentTranslationPopup.value = true;
    };

    const onCloseContentTranslationPopup = () => {
      openContentTranslationPopup.value = false;
    };

    const onContentTranslationSaved = (data) => {
      for (let item of formData.TranslationList) {
        if (item.LanguageCode === data.LanguageCode) {
          formData.TranslationList.delete(item);
          break;
        }
      }

      formData.TranslationList.add(data);
    };

    const contentTranslationActions = (data) => {
      selectedContentTranslation.value = data;
      f7.sheet.get(".contentTranslationActions").open();
    };

    const editPostContent = () => {
      openContentTranslationPopup.value = true;
    };

    const deletePostContent = () => {
      if (selectedContentTranslation.value?.PostTranslationId && selectedContentTranslation.value?.PostTranslationId > 0) {
        helpers.createConfirmation({
          message: $t.getTranslation("LBL_CONFIRM_DELETE_POST_CONTENT"),
          confirm: async () => {
            formData.DeleteTranslationList.add(selectedContentTranslation.value);
            formData.TranslationList.delete(selectedContentTranslation.value);
          },
        });
      } else {
        formData.TranslationList.delete(selectedContentTranslation.value);
      }
    };

    const openPopup = ref(false);

    const onClosedPopup = () => {
      openPopup.value = false;
    };

    const selectImage = (type) => {
      openPopup.value = true;
    };

    const onImageSelected = (data) => {
      formData.Image = data?.blob;
    };

    const openVideoPopup = ref(false);

    const onCloseVideoPopup = () => {
      openVideoPopup.value = false;
    };

    const selectVideo = (type) => {
      openVideoPopup.value = true;
    };

    const onVideoSelected = (data) => {
      formData.Video = data?.file;
      selectedVideo.value = data?.data;
    };

    // FOR SAVING
    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const save = async () => {
      let isValid = validate(true);
      let formSaveReturn = false;

      if (isValid) {
        isButtonProcessing.value = true;
        const saveFormData = new FormData();

        if (isUpdate.value) {
          saveFormData.append("EventId", parseInt(props?.f7route?.params?.id));
        }

        for (let formItem in formData) {
          if (formItem === "TranslationList") {
            let translationList = [];
            formData[formItem].forEach((item) => {
              translationList.push({
                LanguageCode: item.LanguageCode,
                Title: item.Title,
                Description: item.Description,
              });
            });

            saveFormData.append(formItem, JSON.stringify(translationList));
          } else if (formItem === "DeleteTranslationList") {
          } else if (formItem === "EventDate") {
            saveFormData.append(formItem, helpers.formatSQLDate(formData[formItem]));
          } else {
            saveFormData.append(formItem, formData[formItem]);
          }
        }

        formSaveReturn = await post(`/admin/event/save`, saveFormData);
        isButtonProcessing.value = false;

        if (formSaveReturn) {
          helpers.createNotification({
            type: "info",
            title: $t.getTranslation("LBL_INFO"),
            message: isUpdate.value === true ? $t.getTranslation("LBL_INFO_SUCCESS_UPDATE") : $t.getTranslation("LBL_INFO_SUCCESS_CREATE"),
          });

          props.f7router.back({ force: true });
        }
      }
    };

    return {
      isUpdate,
      statusList,
      addContent,
      openContentTranslationPopup,
      selectedContentTranslation,
      onCloseContentTranslationPopup,
      onContentTranslationSaved,
      contentTranslationActions,
      editPostContent,
      deletePostContent,
      openPopup,
      onClosedPopup,
      openVideoPopup,
      onCloseVideoPopup,
      selectImage,
      onImageSelected,
      selectVideo,
      onVideoSelected,
      selectedVideo,
      formData,
      validationRules,
      isButtonProcessing,
      isDisableSaveButton,
      save,
    };
  },
});
</script>
