<template>
  <f7-navbar>
    <f7-nav-left>
      <f7-link v-if="type === 'back'" back force>
        <font-awesome-icon :icon="['far', 'long-arrow-left']" fixed-width />
      </f7-link>

      <f7-link v-else panel-toggle="#leftPanel">
        <font-awesome-icon :icon="['far', 'bars']" fixed-width />
      </f7-link>
    </f7-nav-left>

    <f7-nav-title>{{ title }}</f7-nav-title>

    <f7-nav-right>

      <f7-link href="/message/">
        <f7-badge v-if="currentCount" class="badge-chat" color="blue">{{ currentCount }}</f7-badge>
        <font-awesome-icon :icon="['far', 'comment']" fixed-width />
      </f7-link>


      <f7-link panel-toggle="#rightPanel" class="profile-picture">
        <img src="@/assets/images/svg/avatar_male.svg" alt="Profile Picture" />
        <span style="font-size: 14px;margin-left: 10px;">{{ userInfo.UserName }}</span>
      </f7-link>

    </f7-nav-right>
  </f7-navbar>
</template>


<style scoped>
  .badge-chat{
    position: absolute;
    top: 4px;
    right: 4px;
  }
</style>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "MainHeaderComponent",
  components: {},
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
    },
    navRight: {
      type: String,
    },
    search: {
      type: String,
      default: "search",
    },
  },
  setup() {
    const store = useStore();
    const activeLink = ref(store.getters["page/getActiveLink"]);
    const currentCount = computed(() => store.getters["chat/currentCount"]);
    const userInfo = computed(() => store.getters["user/getData"]);

    return {
      userInfo,
      activeLink,
      currentCount
    };
  },
});
</script>
