<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation('LBL_PRODUCT_WEEKLY_RECOMMENDED') }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button preloader large fill raised @click="upload($refs)">{{ $t.getTranslation('LBL_IMPORT') }}</f7-button>
        <f7-button @click="download()">{{ $t.getTranslation('LBL_DOWNLOAD_IMPORT_TEMPLATE') }}</f7-button>
        <!-- <f7-button large icon="far fa-fw fa-cog"></f7-button> -->
      </div>
    </div>

    <DataTableComponent ref="dataTableComponent" :columns="columns" :size="10" :url="url" @viewItem="editItem"></DataTableComponent>
    <input ref="fileInput" capture="user" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadExcel($event, $refs)" />
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject } from 'vue'
import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import DataTableComponent from '@/components/DataTableComponent.vue'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { useStore } from '@/store'

export default defineComponent({
  name: 'ProductRecommendListPage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const isUploading = ref(false)

    const columns = [
      {
        title: $t.getTranslation('LBL_IMAGE'),
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${cell.getData().DefaultProductImage || require('@/assets/images/placeholder.jpg')}" />
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_PRODUCT_NAME'),
        formatter(cell) {
          return `
            <div>
              <strong>${cell.getData().Name}</strong>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_INVENTORY'),
        field: 'Inventory'
      },
      {
        title: $t.getTranslation('LBL_WAREHOUSE'),
        field: 'WareHouses'
      },
      {
        title: $t.getTranslation('LBL_WAREHOUSE_REGION'),
        field: 'WareHouseRegions'
      },
      {
        title: $t.getTranslation('LBL_CATEGORY'),
        field: 'Category'
      },
      {
        title: $t.getTranslation('LBL_RATING'),
        formatter(cell) {
          return `
            <div>
              ${cell.getData().Rating}
            </div>
          `
        }
      }
    ]

    let url = `/admin/product/list?LanguageCode=${currentLanguageCode.value}&mode=WEEKLY`

    const status = async (key) => {
      if (key) {
        props.f7router.navigate({ name: 'productRecommendedSave', params: { key: key } })
      }
    }

    const add = async () => {
      props.f7router.navigate({ name: 'productList', query: { setWeekly: 1 } })
    }

    const upload = (refs) => {
      refs.fileInput.value = ''
      refs.fileInput.click()
    }

    const uploadExcel = async (event, refs) => {
      let input = event.target

      if (input?.files?.[0]) {
        let file = input?.files?.[0]
        let isValidFile = file.name.toLowerCase().match(/\.(xlsx)$/)
        if (!isValidFile) throw new Error($t.getTranslation('LBL_ERR_INVALID_FILE'))

        //upload now
        isUploading.value = true

        const formData = new FormData()
        formData.append('file', file)

        let ret = await $HTTP.post('/admin/product/weekly/import', formData)
        isUploading.value = false

        if (ret) {
          helpers.createNotification({ type: 'success', title: $t.getTranslation('LBL_SUCCESS'), message: $t.getTranslation('MSG_SUCCESS_UPLOAD') })
          refs.dataTableComponent.initTabulator()
        }
      }
    }

    const download = () => {
      window.location.href = '/excel/product/618.xlsx'
      return
    }

    onMounted(async () => {})

    return {
      status,
      url,
      columns,
      upload,
      uploadExcel,
      isUploading,
      download
    }
  }
})
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}

.form-container .container {
  display: flex;
  margin: 0px 0px;
  /* padding: 40px 0px 40px 40px; */
  width: 90%;
  justify-content: center;
  align-items: center;
}
input[type='file'] {
  display: none;
}
</style>
