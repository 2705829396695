const data = {
  activeLink: '',
  activeLinkParent: '',
  activeRoute: {},
  navigations: [],
  f7router: {}
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  },
  getActiveLink: (state) => {
    return state.data.activeLink
  },
  getActiveLinkParent: (state) => {
    return state.data.activeLinkParent
  },
  getActiveRoute: (state) => {
    return state.data.activeRoute
  },
  getNavigations: (state) => {
    return state.data.navigations
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  }
}

export const page = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
