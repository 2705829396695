import { configs } from '@/utils/configs'
import { Dom7 } from 'framework7'
import { f7 } from 'framework7-vue'

import { cloneDeep } from 'lodash'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const helpers = {
  checkIsMobile() {
    if (navigator?.userAgent && /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent)) {
      return true
    }

    return false
  },
  replaceData(msg, data) {
    let obj = JSON.parse(data)
    let renderMsg = msg
    for (let field in obj) {
      renderMsg = renderMsg.replace('{' + field.toUpperCase() + '}', obj[field])
    }
    return renderMsg
  },
  cutText(text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  padStart(value, numberOfPad, padChar) {
    let pattern = ''
    for (let i = 0; i < numberOfPad; i++) {
      pattern += padChar
    }

    return (pattern + value).slice(-numberOfPad)
  },
  formatPHMobile({ MobileCode = '+63', MobileNumber }) {
    return `${MobileCode} ${MobileNumber.substring(0, 3)} ${MobileNumber.substring(3, 7)} ${MobileNumber.substring(7, 10)}`
  },
  formatName(User) {
    if (!User) return null

    var name = []
    if (User.FirstName) name.push(User.FirstName)
    if (User.LastName) name.push(User.LastName)
    if (name.length == 0) return null
    return name.join(' ')
  },
  formatDateFromNow(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).fromNow()
    } else {
      return ''
    }
  },
  formatDate(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('MMM D, YYYY')
    } else {
      return ''
    }
  },
  formatSQLDate(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('YYYY-MM-DD')
    } else {
      return ''
    }
  },
  formatDatimeTimePicker(date) {
    //format
    //2021-07-21T12:16
    let EndDateYear = new Date(date).getFullYear()
    let EndDateMonth = new Date(date).getMonth() + 1
    let EndDateDay = new Date(date).getDate()
    let EnDateHour = new Date(date).getHours()
    let EnDateMinutes = new Date(date).getMinutes()

    if (EndDateDay.toString().length == 1) {
      EndDateDay = '0' + EndDateDay
    }

    if (EndDateMonth.toString().length == 1) {
      EndDateMonth = '0' + EndDateMonth
    }

    if (EnDateHour.toString().length == 1) {
      EnDateHour = '0' + EnDateHour
    }

    if (EnDateMinutes.toString().length == 1) {
      EnDateMinutes = '0' + EnDateMinutes
    }

    let str = `${EndDateYear}-${EndDateMonth}-${EndDateDay}T${EnDateHour}:${EnDateMinutes}`
    return str
  },
  formatSQLDateTime(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    } else {
      return ''
    }
  },
  formatJSDate(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('YYYY-MM-DD')
    } else {
      return ''
    }
  },
  formatJSDateTime(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('YYYY-MM-DD h:mm A')
    } else {
      return ''
    }
  },
  formatDateTime(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('MMM D, YYYY h:mm A')
    } else {
      return ''
    }
  },
  formatDateTimeCustom(date) {
    let isValidDate = dayjs(date).isValid()
    if (isValidDate) {
      return dayjs(date).format('MMM DD')
    } else {
      return ''
    }
  },
  formatPascalCase(str) {
    return (
      str
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, '$1 $2')
        .replace(/^./, function(str) {
          return str.toUpperCase()
        })
        // Remove any white space left around the word
        .trim()
    )
  },
  formatConfigValue(str) {
    return helpers.formatPascalCase(str.replace(/^.+\./, ''))
  },
  formatTrimString(str, count = 255, append = '...') {
    if (str && str != '') {
      str = str.replace(/(<([^>]+)>)/gi, '')
      str = str.replace(/&nbsp;/g, ' ')
    }

    if (str && str.length > count) {
      return str.substring(0, count) + append
    } else {
      return str
    }
  },
  formatNumber(value, isPlaces) {
    let val = (value / 1).toFixed(2) //original toFixed();
    val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    // if(isPlaces){
    //   val = (value/1).toFixed(2);
    // };
    if (val.split('.')[1] == '00') {
      val = val.replace('.00', '')
    }
    return val
  },
  formatFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }

    const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
  },
  formatField(field, data) {
    let returnField = ''

    switch (field.format) {
      case 'date':
        returnField = helpers.formatDate(data?.[field?.field])
        break
      case 'datetime':
        returnField = helpers.formatDateTime(data?.[field?.field])
        break
      case 'uppercase':
        returnField = data?.[field?.field]?.toUpperCase()
        break
      case 'lowercase':
        returnField = data?.[field?.field]?.toLowerCase()
        break
      case 'number':
        returnField = parseInt(data?.[field?.field]) || field.fallback
        returnField = returnField >= 0 ? returnField : 0
        break
      case 'decimal':
        returnField = parseFloat(data?.[field?.field])
        returnField = returnField >= 0 ? returnField : 0
        break
      case 'currency':
        returnField = parseFloat(data?.[field?.field])
        returnField = returnField >= 0 ? returnField : 0
        returnField = '$' + returnField
        break
      case 'image':
        returnField = helpers.getImage(data?.[field?.field], field?.bucket)
        break
      case 'yesno':
        returnField = parseInt(data?.[field?.field]) >= 1 ? 'YES' : 'NO'
        break
      case 'namejoin':
        let tmpData = []
        data?.[field?.field].forEach((item) => tmpData.push(item.name))
        returnField = tmpData.join(', ')
        break
      default:
        returnField = data?.[field?.field]
    }

    if (helpers.isBlank(returnField)) {
      returnField = field?.placeholder ? field.placeholder : '--'
    }

    return returnField
  },
  checkTableButtonEnabled(button, data) {
    let isEnabled = true

    if (typeof button?.isEnabled === 'function') {
      isEnabled = button?.isEnabled(data)
    }

    if (button.type === 'DELETE' && (data?.StatusCode === 'DELETED' || data?.UserStatusCode === 'DELETED')) {
      isEnabled = false
    }

    return isEnabled
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  isBlank: (value) => {
    if (value === null || value === undefined || value === '' || value.length === 0) {
      return true
    } else {
      return false
    }
  },
  isValidTableId: (val) => {
    return val && parseInt(val) > 0
  },
  isUndefined: (val) => {
    return typeof val === 'undefined'
  },
  isDecimal: (num) => {
    return !!(num % 1)
  },
  isInteger: (val) => {
    return helpers.isUndefined(val) ? false : Number.isInteger(!isNaN(val) ? Number(val) : null)
  },
  isString: (val) => {
    return typeof val === 'string' || val instanceof String
  },
  isObject: (val) => {
    return !helpers.isUndefined(val) && val.toString() === '[object Object]'
  },
  isArray: (val) => {
    return val instanceof Array
  },
  isEmpty: (val) => {
    return helpers.isBlank(val) || val.length <= 0 || Object.keys(val).length <= 0
  },
  isFunction: (val) => {
    return typeof val === 'function'
  },
  isJSON: function(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }

    return true
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomNumbers(from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  isChinese(str) {
    const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u

    if (!REGEX_CHINESE.test(str)) {
      return false
    }
    return true
  },
  renderUserName(user, isReverse) {
    if (!user) return '---'

    let FullName = []
    if (user.FirstName) FullName.push(user.FirstName)
    if (user.LastName) FullName.push(user.LastName)

    if (isReverse) {
      if (helpers.isChinese(FullName.join(''))) {
        FullName = FullName.reverse().join('')
      } else {
        FullName = FullName.join(' ')
      }
    } else {
      FullName = FullName.join(' ')
    }

    return FullName ? FullName : user.Username || user.ReferralCode || 'User'
  },
  getFullName(user, showMiddleName = false) {
    let fullName = ''

    if (user && user.FirstName) {
      fullName += user.FirstName
    }

    if (showMiddleName && user && user.MiddleName) {
      fullName += ` ${user.MiddleName}`
    }

    if (user && user.LastName) {
      fullName += ` ${user.LastName}`
    }

    return fullName
  },
  getFullAddress(item) {
    let Address = ''
    if (item && item.Street) {
      Address += item.Street + ', '
    }

    if (item && item.Barangay) {
      Address += (item.Barangay.toLowerCase().indexOf('barangay') > -1 ? '' : 'Barangay ') + item.Barangay + ', '
    }

    if (item && item.City) {
      Address += item.City + (item.Barangay.toLowerCase().indexOf('city') > -1 ? '' : ' City') + ', '
    }

    if (item && item.Province) {
      Address += item.Province + ', '
    }

    if (item && item.Country) {
      Address += item.Country + ', '
    }

    if (item && item.ZipCode) {
      Address += item.ZipCode
    }

    return Address
  },

  renderImageBlob(blob) {
    if (blob instanceof Blob) {
      const objectURL = URL.createObjectURL(blob)
      return objectURL
    } else {
      return false
    }
  },
  encodeFileName(file) {
    return encodeURIComponent(file)
      .replace(/'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
  },
  getImage(item, type, placeholder, isBase64) {
    let imageLink = ''

    if (isBase64) {
      imageLink = item
      return imageLink
    }

    if (helpers.isString(item) && !helpers.isEmpty(item)) {
      imageLink = configs.baseURL + `/image?name=${helpers.encodeFileName(item)}&type=${type}`
    } else if (item && helpers.isObject(item) && item.Image) {
      imageLink = configs.baseURL + `/image?name=${helpers.encodeFileName(item.Image)}&type=${type}`
    } else if (item && helpers.isObject(item) && item.Name) {
      imageLink = configs.baseURL + `/image?name=${helpers.encodeFileName(item.Name)}&type=${type}`
    } else {
      return placeholder && placeholder !== '' ? placeholder : require('@/assets/images/placeholder.jpg')
    }

    return imageLink
  },
  getStyleImage(item, type, placeholder) {
    let imageLink = ''
    if (helpers.isString(item) && !helpers.isEmpty(item)) {
      imageLink = configs.baseURL + `/image?name=${helpers.encodeFileName(item)}&type=${type}`
    } else if (item && helpers.isObject(item) && item.Image) {
      imageLink = configs.baseURL + `/image?name=${helpers.encodeFileName(item.Image)}&type=${type}`
    } else if (item && helpers.isObject(item) && item.Name) {
      imageLink = configs.baseURL + `/image?name=${helpers.encodeFileName(item.Name)}&type=${type}`
    } else {
      imageLink = placeholder && placeholder !== '' ? placeholder : require('@/assets/images/placeholder.jpg')
    }

    return 'url(' + imageLink + ')'
  },
  getVideo(item, type, placeholder, isThumb) {
    let videoLink = ''
    let link = isThumb ? '/video/thumb' : '/video'

    if (helpers.isString(item)) {
      videoLink = configs.baseURL + `${link}?name=${item}&type=${type}`
    } else if (item && helpers.isObject(item) && item.Video) {
      videoLink = configs.baseURL + `${link}?name=${item.Video}&type=${type}`
    } else if (item && helpers.isObject(item) && item.Name) {
      videoLink = configs.baseURL + `${link}?name=${item.Name}&type=${type}`
    } else {
      return placeholder && placeholder !== '' ? placeholder : require('@/assets/images/placeholder.jpg')
    }

    return videoLink
  },
  getAttribute(data, LanguageCode, field) {
    LanguageCode = LanguageCode || 'EN'

    if (!data || !data.Attributes) return null

    let { Attributes } = data

    let attribute = _.find(Attributes, (r) => {
      return r.LanguageCode == LanguageCode
    })

    let attributeEN = _.find(Attributes, (r) => {
      return r.LanguageCode == 'EN'
    })

    let val = attribute[field] || attributeEN[field] || null

    if (attribute[field] && attribute[field].indexOf('N/A') > -1 && !(LanguageCode == 'EN')) {
      val = attributeEN[field]
    }

    if (val && field == 'AlcoholLevel') {
      if (!isNaN(val) && parseFloat(val) < 0) {
        val = val * 100
      }

      val = val.toString()
      if (!(val.indexOf('%') > -1)) {
        val += '%'
      }
    }

    return val
  },
  formatPrice(price, currencyCode, currencySign) {
    if (price && parseFloat(price) > 0) {
      return (
        (currencyCode ? currencyCode : '') +
        //(currencySign || '$') +
        ' ' +
        (price
          ? parseFloat(price)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
          : '0')
      )
    } else {
      return '0'
    }
  },
  openImage(imageLink) {
    const imageList = [imageLink]
    this.openImageList(imageList)
  },
  openImageList(imageList) {
    const photoBrowser = f7.photoBrowser.create({
      photos: imageList,
      routableModals: false,
      popupCloseLinkText: '<i class="far fa-fw fa-times"></i>',
      type: 'popup',
      toolbar: imageList.length > 1
    })

    photoBrowser.open()

    photoBrowser.on('close', function() {
      photoBrowser.destroy()
    })
  },
  objToQueryString(obj, symbol = '?') {
    const keyValuePairs = []
    for (let i = 0; i < Object.keys(obj).length; i += 1) {
      if (typeof Object.values(obj)[i] === 'undefined') continue
      keyValuePairs.push(`${encodeURIComponent(Object.keys(obj)[i])}=${encodeURIComponent(Object.values(obj)[i])}`)
    }

    if (keyValuePairs.length == 0) return ''
    return symbol + keyValuePairs.join('&')
  },
  showLoader() {
    f7.preloader.show()
  },
  hideLoader() {
    f7.preloader.hide()
  },
  showLoaderText(text = 'Loading...') {
    f7.dialog.preloader(text)
  },
  hideLoaderText() {
    f7.dialog.close()
  },
  createTimePicker(timePickerElement, onChange) {
    Dom7(timePickerElement).forEach((element) => {
      f7.picker.create({
        inputEl: element,
        rotateEffect: true,
        cols: [
          {
            textAlign: 'center',
            values: (() => {
              var arr = []
              for (var i = 0; i <= 24; i++) {
                arr.push(i < 10 ? '0' + i : i)
              }
              return arr
            })()
          },
          {
            divider: true,
            content: ':'
          },
          {
            textAlign: 'center',
            values: (() => {
              var arr = []
              for (var i = 0; i <= 60; i++) {
                arr.push(i < 10 ? '0' + i : i)
              }
              return arr
            })()
          },
          {
            divider: true,
            content: ':'
          },
          {
            textAlign: 'center',
            values: (() => {
              var arr = []
              for (var i = 0; i <= 60; i++) {
                arr.push(i < 10 ? '0' + i : i)
              }
              return arr
            })()
          }
        ],
        on: {
          change: onChange
        }
      })
    })
  },
  createDatePicker(datePickerElement, onChange) {
    let today = new Date()
    let monthMap = 'January February March April May June July August September October November December'.split(' ')

    Dom7(datePickerElement).forEach((element) => {
      let startYear =
        Dom7(element)
          .parents('li')
          .attr('data-start-year') || today.getFullYear()

      let defaultDate =
        Dom7(element)
          .parents('li')
          .attr('data-default-date') || null

      if (defaultDate === 'today') {
        defaultDate = [monthMap[today.getMonth()], today.getDate(), today.getFullYear()]
      }

      f7.picker.create({
        inputEl: element,
        rotateEffect: true,
        value: defaultDate,
        cols: [
          {
            values: monthMap,
            textAlign: 'left'
          },
          {
            values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
            textAlign: 'center'
          },
          {
            values: (function() {
              var arr = []
              for (var i = startYear; i <= today.getFullYear() + 10; i++) {
                arr.push(i)
              }
              return arr
            })()
          }
        ],
        on: {
          change: onChange
        }
      })
    })
  },
  createDateTimePicker(dateTimePickerElement, onChange) {
    Dom7(dateTimePickerElement).forEach((element) => {
      let parentElement = Dom7(element).parents('.datetimepicker')
      let elementParams = parentElement.attr('params')
      let parseParams = elementParams != '' ? JSON.parse(elementParams) : {}

      let minDate = null,
        maxDate = null
      if (parseParams?.minDate) {
        minDate = parseParams?.minDate == 'today' ? dayjs().subtract(1, 'day') : dayjs()
      }

      if (parseParams?.maxDate) {
        maxDate = parseParams?.maxDate == 'today' ? dayjs().subtract(1, 'day') : dayjs()
      }

      f7.calendar.create({
        inputEl: element,
        timePicker: true,
        minDate: minDate,
        maxDate: maxDate,
        dateFormat: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
        on: {
          change: onChange
        }
      })
    })
  },
  createNotification({ type, title = 'Notification', message = 'Click me to close', time = 'now' }) {
    let notificationCreated = false
    if (!notificationCreated) {
      const notification = f7.notification.create({
        title: configs.title,
        titleRightText: time,
        subtitle: `<strong class="${type}">${title}</strong>`,
        text: message,
        closeTimeout: 3000,
        closeOnClick: true
      })

      notification.open()

      notification.on('open', function() {
        notificationCreated = true
      })

      notification.on('close', function() {
        notification.destroy()
        notificationCreated = false
      })
    }
  },
  createConfirmation({ message, title = 'Confirm', confirm, cancel }) {
    const dialog = f7.dialog.confirm(message, title, confirm, cancel)
  },
  createRemarks({ message, title = 'Confirm', confirm, cancel }) {
    const dialog = f7.dialog.prompt(message, title, confirm, cancel)
  },
  catchError(err, showNotifications = false) {
    if (err.Message || err.Code) {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: 'Error!',
          message: err.Message || err.Code
        })
      }
    } else if (err && err.response && err.response.data && (err.response.data.Message || err.response.data.Code)) {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: 'Error!',
          message: err.response.data.Message || err.response.data.Code
        })
      }
    } else if (err && err.message) {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: 'Error!',
          message: err.message
        })
      }
    } else {
      if (showNotifications) {
        helpers.createNotification({
          type: 'error',
          title: 'Error!',
          message: 'Server not reachable, Please try again later.'
        })
      }
    }
  },
  copyToClipBoard(text) {
    var element = document.createElement('textarea')
    element.value = text
    element.setAttribute('readonly', '')
    element.style = { position: 'absolute', left: '-9999px' }

    document.body.appendChild(element)
    element.select()

    document.execCommand('copy')
    document.body.removeChild(element)
  },
  clearFormData(formData) {
    for (let keys in formData) {
      formData[keys] = ''
    }

    return true
  },
  deleteFormData(formData) {
    for (let keys in formData) {
      delete formData[keys]
    }

    return true
  },
  resetForm(formElement) {
    if (formElement && Dom7(formElement).length > 0) Dom7(formElement)[0].reset()

    setTimeout(() => {
      const inputs = Dom7(formElement).find('.item-input-invalid')

      inputs.forEach((input) => {
        Dom7(input).removeClass('item-input-invalid')
      })
    }, 100)
  },
  getDeviceWidth() {
    return Dom7('body')[0].offsetWidth
  },
  getFieldInfo(input) {
    let info = input.info || (input?.required ? 'This field is required to proceed.' : '')
    if (info == '') {
      switch (input.value) {
        case 'Description':
          info = 'Maximum character limit is 60000 characters.'
          break
        case 'Position':
          info = 'Position are used in sorting, sorted by ascending order.'
          break
        case 'IsListed':
          info = 'This value will determine if item will be returned in front end.'
          break
        case 'IsFeatured':
          info = 'Will determine if item will be returned in featured list.'
          break
        case 'IsRecommended':
          info = 'Will determine if item will be returned in recommended list.'
          break
        case 'IsSale':
          info = 'Will determine if item is on sale or not.'
          break
        case 'IsSearchable':
          info = 'Will determine if item will be returned in search in front end.'
          break
        case 'StatusCode':
          info = 'Visibility status of item through out the system.'
          break
        default:
          info = ''
      }
    }

    return info
  },
  getStatusColor(status) {
    let chipColor = ''
    switch (status) {
      case '':
      case 'PENDING':
      case 'REDEEMED':
      case 'EXPIRED':
      case 'INACTIVE':
      case 'INITIATED':
      case 'CONFIRMED':
      case 'CLOSED':
      case 'NOT_DECLARED':
        chipColor = 'color-gray'
        break
      case 'ACTIVE':
      case 'AVAILABLE':
      case 'PAID':
      case 'SHIPPED':
      case 'RECEIVED':
      case 'DECLARED':
        chipColor = 'color-primary'
        break
      case 'DISABLED':
      case 'PARTIAL_SHIPPED':
      case 'PARTIALLY_PAID':
        chipColor = 'color-orange'
        break
      case 'DELETED':
      case 'ONHOLD':
      case 'TERMINATED':
      case 'CANCELLED':
      case 'REFUNDED':
      case 'UNPAID':
        chipColor = 'color-red'
        break
      default:
        chipColor = 'color-primary'
    }

    return chipColor
  },
  returnBaseURL() {
    return configs.baseURL
  }
}

helpers.cloneDeep = cloneDeep

const install = (app) => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers }
