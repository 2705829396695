<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'mobile']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_CATEGORY_LIST") }}</h1>
        </div>
      </div>

      <div class="button-container">
        <f7-button preloader large fill raised @click="onFeaturedCategories()">{{ $t.getTranslation("LBL_FEATURED_CATEGORIES") }}</f7-button>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <CategorySavePartial ref="categorySavePartial" @closed="initDataTableComponent" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

// PARTIALS
import CategorySavePartial from "@/partials/manage/CategorySavePartial.vue";

import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";

export default defineComponent({
  name: "CategoryListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    CategorySavePartial,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();

    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);

    const url = ref(`/admin/category/datalist?&LanguageCode=${currentLanguageCode.value || "EN"}`);

    if (props?.f7route?.query?.isFeatured) {
      url.value += `&mode=NONFEATURED`;
    }

    const actions = [
      {
        groupText: "Perform Actions",
        list: [
          { text: "Edit", type: "EDIT", bold: true },
          { text: "View Products", type: "VIEWPRODUCTS" },
        ],
      },
    ];

    const columns = [
      {
        title: $t.getTranslation("LBL_ID"),
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().CategoryId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IMAGE"),
        maxWidth: 90,
        minWidth: 90,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${helpers.getImage(cell.getData().Image, "CATEGORY")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_CATEGORY_INFO"),
        frozen: true,
        minWidth: 280,
        maxWidth: 360,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().CategoryCode || "--"}</strong></div>
              <div>${cell.getData().CategoryName || ""}</div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.StatusCode)} ${cell.getData()?.StatusCode}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_TOTAL_PRODUCT"),
        minWidth: 160,
        maxWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().TotalProduct || 0}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_TAX_RATE"),
        minWidth: 160,
        maxWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.formatNumber(cell.getData().TaxRate) || 0}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PARENT_CATEGORY_INFO"),
        minWidth: 220,
        maxWidth: 360,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().ParentCategoryCode || "--"}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_STATUS_INFO"),
        minWidth: 120,
        maxWidth: 220,
        formatter(cell) {
          return `
            <div>
              <div>${$t.getTranslation("LBL_POSITION")}: <strong>${cell.getData().Position || 0}</strong></div>
              ${cell.getData()?.IsFeatured > 0 ? "<div class='chip color-primary'><div class='chip-label uppercase'>" + $t.getTranslation("LBL_FEATURED") + "</div></div>" : ""}

            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="${$t.getTranslation("LBL_DATE_CREATED")}"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="${$t.getTranslation("LBL_DATE_UPDATED")}"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    // const editItem = (data) => {
    //   if (data?.value?.CategoryId && props?.f7route?.query?.isFeatured) {
    //     props.f7router.navigate({ name: "categorySave", query: { CategoryId: data.value.CategoryId, isFeatured: 1 } });
    //     return;
    //   }

    //   if (data?.value?.CategoryId) {
    //     props.f7router.navigate({ name: "categorySave", query: { CategoryId: data.value.CategoryId } });
    //   }
    // };

    return {
      url,
      columns,
      actions,
    };
  },
  methods: {
    onFeaturedCategories() {
      const self = this;
      self.f7router.navigate({ name: "categoryPositionList" });
    },
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "EDIT":
          if (data?.value?.CategoryId) {
            self.$refs.categorySavePartial.openPopup(data.value);
          }
          break;
        // case "EDIT":
        //   if (data?.value?.CategoryId && self?.f7route?.query?.isFeatured) {
        //     self.f7router.navigate({ name: "categorySave", query: { CategoryId: data.value.CategoryId, isFeatured: 1 } });
        //     return;
        //   }

        //   if (data?.value?.CategoryId) {
        //     self.f7router.navigate({ name: "categorySave", query: { CategoryId: data.value.CategoryId } });
        //   }

        //   break;
        case "VIEWPRODUCTS":
          if (data?.value?.CategoryId) {
            self.f7router.navigate({ name: "productList", query: { CategoryId: data.value.CategoryId } });
          }

          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
