<template>
  <f7-popup class="contenttranslation-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation("LBL_CONTENT") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <div class="form-container">
        <link :href="require('@/assets/css/materialicon.css')" rel="stylesheet" />

        <f7-list id="formContent" form no-hairlines>
          <f7-list-input
            v-model:value="formData.LanguageCode"
            name="LanguageCode"
            :required="validationRules?.LanguageCode?.required"
            :minlength="validationRules?.LanguageCode?.minimumLength"
            :maxlength="validationRules?.LanguageCode?.maximumLength"
            :label="$t.getTranslation('LBL_LANGUAGE_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="languageList && languageList.length > 0">
              <option v-for="language in languageList" :key="'lc_' + language.Code" :value="language.Code">{{ language.Name }}</option>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.Title"
            name="Title"
            :label="$t.getTranslation('LBL_TITLE')"
            :required="validationRules?.Title?.required"
            :minlength="validationRules?.Title?.minimumLength"
            :maxlength="validationRules?.Title?.maximumLength"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            outline
            floating-label
            type="text"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-text-editor
            v-model:value="formData.Description"
            name="Description"
            :dividers="false"
            :label="$t.getTranslation('LBL_DESCRIPTION')"
            :placeholder="$t.getTranslation('LBL_DESCRIPTION')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            outline
            floating-label
            validate
            clear-button
            @texteditor:change="(v) => (formData.Description = v)"
          >
          </f7-text-editor>

          <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="addContentTranslation">
            {{ isUpdate ? $t.getTranslation("LBL_UPDATE") : $t.getTranslation("LBL_SAVE") }}
          </f7-button>
        </f7-list>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { reactive, ref, computed, inject } from "vue";
import { f7 } from "framework7-vue";

import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";

import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

export default {
  name: "ContentTranslationPopUpComponent",
  components: {},
  props: {
    open: Boolean,
    data: Object,
  },
  emits: ["opened", "closed", "saved"],
  setup(props, { emit }) {
    const $t = inject("$translation");
    const store = useStore();

    const languageList = computed(() => store.getters["config/getData"]?.languageList);

    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);

    const formData = reactive({
      LanguageCode: "",
      Title: "",
      Description: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const openPopup = () => {
      if (props?.data && props?.data?.LanguageCode != "") {
        for (let keys in formData) {
          if (props?.data && props?.data[keys]) {
            formData[keys] = props?.data[keys];
          }
        }
      }

      emit("opened", true);
    };

    const closePopup = () => {
      emit("closed", true);
    };

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      validationRules["Description"].maximumLength = 65000;
      validationRules["LanguageCode"] = {
        required: true,
      };

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);
      return !isValid;
    });

    const addContentTranslation = () => {
      let isValid = validate(true);

      if (isValid) {
        f7.popup.close();
        emit("saved", helpers.toRaw(formData));

        helpers.clearFormData(formData);
        helpers.resetForm("form#formContent");
      }
    };

    return {
      openPopup,
      closePopup,
      isUpdate,
      languageList,
      formData,
      validationRules,
      isButtonProcessing,
      isDisableNextButton,
      addContentTranslation,
    };
  },
};
</script>

<style scoped>
.contenttranslation-popup {
  --f7-text-editor-height: 450px;
  --f7-text-editor-margin: 10px;
  --f7-text-editor-padding: 20px;
  --f7-text-editor-border-color: #bbb;
  --f7-text-editor-toolbar-bg-color: #transparent;
  --f7-text-editor-font-size: 14px;
}
.contenttranslation-popup.popup .navbar .title {
  margin-left: 15px;
}
.contenttranslation-popup .form-container {
  width: calc(100% - 40px);
  margin: 20px auto;
}
.contenttranslation-popup .form-container .list {
  width: 100%;
  --f7-list-item-padding-vertical: 5px;
  --f7-list-item-padding-horizontal: 10px;
  --f7-list-item-title-font-size: 15px;
  --f7-list-item-title-font-weight: 400;
  --f7-list-border-color: #f7f7f7;
  --f7-list-item-border-color: #f7f7f7;
}
.contenttranslation-popup .form-container .text-editor {
  border-radius: 5px;
}
.contenttranslation-popup .form-container .button {
  width: calc(100% - 20px);
  margin: 20px auto;
}
</style>
