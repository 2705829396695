<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent type="back" />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'cog']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_INVENTORY_LOCATION') }}</h1>
        </div>
      </div>

      <div class="button-container"></div>
    </div>

    <section class="form-container">
      <div class="container">
        <div style="margin-top: 10px;width: 50%;">
          <h2>{{ $t.getTranslation('LBL_PRODUCT') + ' - ' + viewData.Sku }}</h2>
          <template v-if="viewData.Product">
            <ProductListCardComponent :data="viewData.Product" :category="true" :f7router="f7router" />
          </template>
        </div>

        <f7-list no-hairlines>
          <ViewItemComponent :title="$t.getTranslation('LBL_INVENTORY_DETAILS')" :data="viewData" :fields="inventoryFields" />

          <f7-list-input
            v-model:value="formData.IncreaseQty"
            name="Increase"
            :required="validationRules?.Increase?.required"
            :minlength="validationRules?.Increase?.minimumLength"
            :maxlength="validationRules?.Increase?.maximumLength"
            :label="$t.getTranslation('LBL_INCREASE_QTY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.DecreaseQty"
            name="Decrease"
            :required="validationRules?.Increase?.required"
            :minlength="validationRules?.Increase?.minimumLength"
            :maxlength="validationRules?.Increase?.maximumLength"
            :label="$t.getTranslation('LBL_DECREASE_QTY')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_REQUIRED')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="number"
            validate
            clear-button
          >
          </f7-list-input>

          <f7-list-input
            v-model:value="formData.WareHouseRegionCode"
            name="WareHouseRegionCode"
            :required="validationRules?.WareHouseRegionCode?.required"
            :minlength="validationRules?.WareHouseRegionCode?.minimumLength"
            :maxlength="validationRules?.WareHouseRegionCode?.maximumLength"
            :label="$t.getTranslation('LBL_WAREHOUSE_REGION_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="region in warehouseregionList" :key="'st_' + region.Code" :value="region.Code">{{ region.Name }}</option>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.StatusCode"
            name="StatusCode"
            :required="validationRules?.StatusCode?.required"
            :minlength="validationRules?.StatusCode?.minimumLength"
            :maxlength="validationRules?.StatusCode?.maximumLength"
            :label="$t.getTranslation('LBL_STATUS')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :info="$t.getTranslation('LBL_INFO_FIELD_OPTIONAL')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="status in statusList" :key="'st_' + status.Code" :value="status.Code">{{ status.Name }}</option>
          </f7-list-input>

          <f7-button large fill raised preloader :loading="isButtonProcessing" @click="confirm($refs)">
            {{ $t.getTranslation('LBL_SAVE') }}
          </f7-button>
        </f7-list>

        <DataTableComponent ref="datatable" :columns="columns" :size="10" :url="url" @viewItem="editItem"></DataTableComponent>
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted, inject } from 'vue'
import _ from 'lodash'
import { useStore } from '@/store'
import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers.js'

import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'
import ViewItemComponent from '@/components/ViewItemComponent.vue'
import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import ProductListCardComponent from '@/components/cards/ProductListCardComponent.vue'
import DataTableComponent from '@/components/DataTableComponent.vue'

export default defineComponent({
  name: 'ProductInventoryLocationViewPage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    ViewItemComponent,
    ProductListCardComponent,
    DataTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const isUpdate = ref(false)
    const isButtonProcessing = ref(false)
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const warehouseregionList = computed(() => store.getters['config/getData']?.wareHouseRegionList)
    const statusList = computed(() => store.getters['config/getData']?.statusList)
    const formData = reactive({
      DecreaseQty: 0,
      IncreaseQty: 0,
      LanguageCode: currentLanguageCode.value,
      WareHouseRegionCode: null,
      StatusCode: ''
    })

    const url = `/admin/product/inventory/location/history?InventoryLocationId=${props?.f7route?.query?.id}`

    const columns = [
      {
        title: $t.getTranslation('LBL_PREVIOUS_SELLABLE_QTY'),
        field: 'PreviousSellableQty'
      },
      {
        title: $t.getTranslation('LBL_REMARKS'),
        field: 'Remarks'
      },
      {
        title: $t.getTranslation('LBL_LAST_CREATED'),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `
        }
      }
    ]

    let viewData = ref({})
    let validationRules = {}
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys]
      }
    }

    const inventoryFields = ref([
      { field: 'SellableQty', name: $t.getTranslation('LBL_SELLABLE_QTY'), format: 'string' },
      { field: 'WareHouseCode', name: $t.getTranslation('LBL_WAREHOUSE'), format: 'string' },
      { field: 'WareHouseRegionCode', name: $t.getTranslation('LBL_WAREHOUSE_REGION'), format: 'string' }
    ])

    onMounted(async () => {
      if (props?.f7route?.query && props?.f7route?.query?.id) {
        await getData()
      }
    })

    const getData = async () => {
      try {
        helpers.showLoader()
        let res = await $HTTP.get(`/admin/product/inventory/location/view?InventoryLocationId=${props?.f7route?.query?.id}`)
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()
          viewData.value = res.data.data
          let productkey = { ProductKey: viewData.value.ProductKey }
          viewData.value.Product = { ...viewData.value.Product, ...productkey }
          formData.WareHouseRegionCode = viewData.value.WareHouseRegionCode
          formData.StatusCode = res.data.data.StatusCode
          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    const isDisableSaveButton = computed(() => {
      let isValid = validate(false)

      return !isValid
    })

    const confirm = async ($refs) => {
      let message = $t.getTranslation('MSG_CONFIRM_INVENTORY_UPDATE_MESSAGE')
      let TotalQty = parseInt(viewData.value.SellableQty) + parseInt(formData.IncreaseQty)
      TotalQty = TotalQty - formData.DecreaseQty
      message = message.replace('{TotalQty}', TotalQty)

      if (formData.DecreaseQty == 0 && formData.IncreaseQty == 0) {
        await save()
        return
      }

      helpers.createConfirmation({
        message: message,
        title: $t.getTranslation('LBL_CONFIRM_INVENTORY_UPDATE'),
        confirm: () => {
          addRemarks($refs)
        }
      })
    }

    const addRemarks = ($refs) => {
      helpers.createRemarks({
        message: $t.getTranslation('LBL_INVENTORY_ADD_REMARKS'),
        confirm: (remarks) => {
          if (remarks == '') {
            helpers.createNotification({
              type: 'error',
              title: 'Error!',
              message: $t.getTranslation('ERR_INVENTORY_ADD_REMARKS')
            })
          } else {
            save(remarks, $refs)
          }
        }
      })
    }

    const save = async (remarks, $refs) => {
      try {
        let isValid = validate(true)
        if (isValid) {
          isButtonProcessing.value = true
          helpers.showLoader()
          formData.InventoryLocationId = props?.f7route?.query?.id
          formData.WareHouseId = viewData.value.WareHouseId
          if (remarks) {
            formData.Remarks = remarks
          }

          if ($refs) {
            setTimeout(() => {
              $refs.datatable.initTabulator()
            }, 300)
          }

          //send update to server
          let res = await $HTTP.post('/admin/product/inventory/location', formData)
          if (res?.status === 200 && res?.data?.data) {
            isButtonProcessing.value = false
            helpers.hideLoader()
            await getData()
            helpers.createNotification({ type: 'success', title: $t.getTranslation('LBL_SUCCESS'), message: $t.getTranslation('MSG_SUCCESS_PRODUCT_INVENTORY') })
            return
          }

          throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
        }
      } catch (err) {
        isButtonProcessing.value = false
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    return { isUpdate, formData, validationRules, isButtonProcessing, isDisableSaveButton, save, inventoryFields, viewData, confirm, url, columns, warehouseregionList, statusList }
  }
})
</script>
