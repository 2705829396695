<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_POST_VIDEO_STATS") }}</h1>
        </div>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent2" :columns="columns" :actions="actions" :url="url" :size="15" @performAction="performAction"></DataTableComponent2>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import { post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { configs } from "@/utils/configs";

export default defineComponent({
  name: "PostVideoStatsPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    let url = "/admin/postvideostats/list";

    const actions = [
      {
        groupText: "Perform Actions",
        list: [{ text: "View", type: "VIEW", bold: true }],
      },
    ];

    const columns = [
      {
        title: $t.getTranslation("LBL_POST_ID"),
        frozen: true,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().PostId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_MEDIA_ID"),
        frozen: true,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().PostMediaId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_VIDEO"),
        minWidth: 120,
        formatter(cell) {
          return `
            <div>
              <a class="external" href="${helpers.getVideo(cell.getData().Video, "POST", null)}" target="_blank">${cell.getData().Video}</a>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_THUMBNAIL"),
        minWidth: 120,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image wide">
                <img src="${helpers.getVideo(cell.getData().Video, "POST", null, true)}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_POST_CONTENT"),
        field: "PostContent",
      },
      {
        title: $t.getTranslation("LBL_TOTAL_PLAYED"),
        field: "TotalPlayed",
      },
      {
        title: $t.getTranslation("LBL_TOTAL_FINISHED"),
        field: "TotalFinished",
      },
      {
        title: $t.getTranslation("LBL_AVERAGE_WATCH_TIME"),
        field: "AverageWatchTime",
      },
      {
        title: $t.getTranslation("LBL_AVERAGE_SKIP_TIME"),
        field: "AverageSkipTime",
      },
    ];

    return {
      columns,
      url,
      actions,
    };
  },
  methods: {
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.value?.UserGroupId) {
            self.$refs.userGroupViewPartial.openPopup(data?.value?.UserGroupId);
          }
          break;
      }
    },
    initDataTableComponent2(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent2.initTabulator();
      }
    },
  },
});
</script>
