<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'wine-bottle']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_PRODUCT_REVIEW_LIST") }}</h1>
          <h3>List of available product reviews.</h3>
        </div>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent2" :columns="columns" :actions="actions" :url="url" :size="15" @performAction="performAction"></DataTableComponent2>

    <ProductReviewSavePartial ref="productReviewSavePartial" @closed="initDataTableComponent2" />

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import ProductReviewSavePartial from "@/partials/product/ProductReviewSavePartial.vue";

import { helpers } from "@/utils/helpers";
import { post } from "@/utils/axios";

export default defineComponent({
  name: "ProductReviewListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
    // PARTIALS
    ProductReviewSavePartial,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    let url = "/admin/productreview/list";

    const actions = [
      {
        groupText: "Perform Actions",
        list: [{ text: "Edit", type: "EDIT", bold: false }],
      },
      {
        groupText: "",
        list: [{ text: "Delete", type: "DELETE", color: "red" }],
      },
    ];

    const columns = [
      {
        title: $t.getTranslation("LBL_ID"),
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().ProductReviewId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_PRODUCT_INFO"),
        minWidth: 150,
        maxWidth: 350,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().Sku} - ${cell.getData().SupplierCode}</strong> </div>
              <div>${cell.getData().ProductName}</div>
              <div><strong>ID:</strong> ${cell.getData().ProductId}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_INFO"),
        minWidth: 150,
        maxWidth: 350,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.getFullName(cell.getData(), true)}</strong></div>
              <div><strong>${cell.getData().UserName || ""}</strong></div>
              <div><strong>ID:</strong> ${cell.getData().UserId}</div>
              <div>${cell.getData().UserKey}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_ORDER_INFO"),
        minWidth: 150,
        maxWidth: 350,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().OrderNumber || ""}</strong></div>
              <div>${cell.getData().OrderKey || ""}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_CONTENT"),
        minWidth: 150,
        maxWidth: 250,
        field: "Content",
      },
      {
        title: $t.getTranslation("LBL_RATING"),
        minWidth: 100,
        maxWidth: 100,
        field: "Rating",
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 120,
        maxWidth: 120,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.StatusCode)} ${cell.getData()?.StatusCode}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
              ${
                cell.getData()?.IsPrivate == 1
                  ? `<div class="chip chip-red">
                    <div class="chip-label">${$t.getTranslation("LBL_PRIVATE")}</div>
                  </div>`
                  : ``
              }
              ${
                cell.getData()?.IsHidden == 1
                  ? `<div class="chip chip-red">
                    <div class="chip-label">${$t.getTranslation("LBL_HIDDEN")}</div>
                  </div>`
                  : ``
              }
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <strong>${$t.getTranslation("LBL_DATE_CREATED")}:</strong> ${helpers.formatDateTime(cell.getData().LastCreated)} <br />
              <strong>${$t.getTranslation("LBL_DATE_UPDATED")}:</strong> ${helpers.formatDateTime(cell.getData().LastModified)}
            </div>
          `;
        },
      },
    ];

    return {
      columns,
      url,
      actions,
    };
  },
  methods: {
    performAction(type, data) {
      const self = this;

      switch (type) {
        case "EDIT":
          if (data?.value?.ProductReviewId) {
            self.$refs.productReviewSavePartial.openPopup(data.value);
          }
          break;
        case "DELETE":
          if (data?.value?.ProductReviewId) {
            helpers.createConfirmation({
              message: "Are you sure you want to delete selected product review?",
              confirm: async () => {
                let returnData = await post("/admin/productreview/delete", { ProductReviewId: data?.value?.ProductReviewId });
                if (returnData)
                  helpers.createNotification({
                    type: "info",
                    title: "INFO",
                    message: "Successfully deleted selected product review.",
                  });

                self.$refs.dataTableComponent2.initTabulator();
              },
            });
          }
          break;
      }
    },
    initDataTableComponent2(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent2.initTabulator();
      }
    },
  },
});
</script>
