<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_PRODUCT_SALE_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button large fill raised @click="create">{{ $t.getTranslation("LBL_CREATE") }}</f7-button>
      </div>
    </div>

    <DataTableComponent :columns="columns" :size="10" :url="url" @viewItem="editItem"></DataTableComponent>
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject } from "vue";
import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

import { $HTTP } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
export default defineComponent({
  name: "ProductListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    const isUploading = ref(false);
    const hideImport = ref(false);

    const columns = [
      {
        title: $t.getTranslation("LBL_SKU"),
        field: "Sku",
        hozAlign: "center",
        maxWidth: 130,
        minWidth: 130,
      },
      {
        title: $t.getTranslation("LBL_SALE_FROM"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().SaleFrom)}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_SALE_TO"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().SaleTo)}
            </div>
          `;
        }
      },
      {
        title: $t.getTranslation("LBL_QTY_PER_USER"),
        field: "QtyPerUser",
      },
      {
        title: $t.getTranslation("LBL_QTY_PER_ORDER"),
        field: "QtyPerOrder",
      },
      {
        title: $t.getTranslation("LBL_AMOUNT"),
        field: "Amount",
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_LAST_CREATED"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              ${helpers.formatDateTime(cell.getData().LastCreated)}
            </div>
          `;
        }
      }
    ];

    const editItem = (data) => {
      if (data?.value?.ProductSaleId) {
        props.f7router.navigate({ name: "productSaleSave", query: { ProductSaleId : data.value.ProductSaleId } });
      }
    };

    const create = () => {
      props.f7router.navigate({ name: "productSaleSave", query: {} });
      return;
    };

    let url = `/admin/product/list/sale?LanguageCode=${currentLanguageCode.value}`;
    return {
      columns,
      url,
      editItem,
      create
    };
  },
});
</script>

<style scoped>
.custom-container {
  min-height: 600px;
}
.default-img {
  width: 100px;
  height: 100px;
}

input[type="file"] {
    display: none;
}
</style>
