<template>
  <div class="view-info">
    <h2>{{ title }}</h2>

    <div class="data-table">
      <table>
        <thead>
          <tr>
            <th v-for="field in fields" :key="'tbh_' + field.field">
              {{ field.name === "auto" ? $h.formatPascalCase(field.field) : field.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="data && data.length > 0">
            <tr v-for="(datum, index) in data" :key="'tbr_' + index">
              <td v-for="field in fields" :key="'tbd_' + field.field">
                <div v-if="field.format === 'image'" class="image-container">
                  <img :src="formatField(field, datum)" :alt="field.field + 'Image'" @click="$h.openImage(formatField(field, datum))" />
                </div>
                <span v-else>{{ formatField(field, datum) }}</span>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="fields.length" class="no-data">{{ $t.getTranslation("LBL_NO_DATA_TO_BE_DISPLAYED") }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "ViewTableComponent",
  props: {
    title: String,
    fields: Array,
    data: Array,
  },
  setup(props) {
    const formatField = (field, data) => {
      let returnField = "";

      switch (field.format) {
        case "date":
          returnField = helpers.formatDate(data?.[field?.field]);
          break;
        case "datetime":
          returnField = helpers.formatDateTime(data?.[field?.field]);
          break;
        case "uppercase":
          returnField = data?.[field?.field]?.toUpperCase();
          break;
        case "lowercase":
          returnField = data?.[field?.field]?.toLowerCase();
          break;
        case "number":
          returnField = parseInt(data?.[field?.field]);
          break;
        case "decimal":
          returnField = parseFloat(data?.[field?.field]);
          break;
        case "image":
          returnField = helpers.getImage(data?.[field?.field], field?.bucket);
          break;
        case "yesno":
          returnField = parseInt(data?.[field?.field]) >= 1 ? "YES" : "NO";
          break;
        case "customProcessFullName":
          returnField = helpers.getFullName({ FirstName: data?.ProcessFirstName, MiddleName: data?.ProcessMiddleName, LastName: data?.ProcessLastName });
          break;
        default:
          returnField = data?.[field?.field];
      }

      if (helpers.isBlank(returnField)) {
        returnField = field?.placeholder ? field.placeholder : "--";
      }

      return returnField;
    };

    return { formatField };
  },
});
</script>
