<template>
  <f7-panel id="rightPanel" right reveal :visible-breakpoint="2600">
    <f7-page>
      <f7-navbar>
        <f7-nav-right>
          <f7-link panel-open="#rightPanel">
            <font-awesome-icon :icon="['far', 'bell']" fixed-width />
          </f7-link>
          <f7-link class="close" panel-toggle="#rightPanel">
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="profile-container">
        <div class="profile-image">
          <img src="@/assets/images/svg/avatar_male.svg" alt="Profile Picture" />
        </div>

        <h1>{{ $h.getFullName(userInfo) }}</h1>
        <h2>{{ userInfo.UserName }}</h2>

        <div class="button-container">
          <f7-button fill raised>{{ $t.getTranslation("LBL_PROFILE") }}</f7-button>
          <f7-button @click="logout">
            <font-awesome-icon :icon="['far', 'power-off']" fixed-width />
          </f7-button>
        </div>
      </div>

      <div class="list-container">
        <h2>{{ $t.getTranslation("LBL_PENDINGS") }}</h2>

        <NoDataFoundComponent size="sm" />

        <!-- <f7-list class="no-hairlines no-hairlines-between">
          <f7-list-item link="#" header="Riders Applications" title="0 Applications" after=""></f7-list-item>
          <f7-list-item link="#" header="Shipments" title="0 Items" after=""></f7-list-item>
          <f7-list-item link="#" header="Orders" title="0 Items" after=""></f7-list-item>
        </f7-list> -->
      </div>

      <div class="list-container">
        <h2>{{ $t.getTranslation("LBL_RECENT_ACTIVITY") }}</h2>

        <NoDataFoundComponent size="sm" />

        <!-- <f7-list class="no-hairlines no-hairlines-between">
          <f7-list-item link="#" header="January 01, 2021 12:05 AM" title="Update Item 1" after=""></f7-list-item>
          <f7-list-item link="#" header="January 01, 2021 12:05 AM" title="Update Item 1" after=""></f7-list-item>
          <f7-list-item link="#" header="January 01, 2021 12:05 AM" title="Update Item 1" after=""></f7-list-item>
          <f7-list-item link="#" header="January 01, 2021 12:05 AM" title="Update Item 1" after=""></f7-list-item>
        </f7-list> -->
      </div>
    </f7-page>
  </f7-panel>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

import { logout } from "@/logics/auth.js";

import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

export default defineComponent({
  name: "RightPanelComponent",
  components: { NoDataFoundComponent },
  setup() {
    const store = useStore();
    const userInfo = computed(() => store.getters["user/getData"]);

    return { userInfo, logout };
  },
});
</script>
