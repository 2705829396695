<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'chart-area']" fixed-width />

        <div class="text">
          <h1>{{ $t.getTranslation('LBL_ORDER_DETAILS') }} {{ order.Ref }}</h1>
          <h3></h3>
        </div>
      </div>

      <div class="button-container">
        <f7-button @click="downloadInvoice()" v-if="order.Payment">
          {{ $t.getTranslation('BTN_DOWNLOAD_INVOICE_ADMIN') }}
        </f7-button>

        <f7-button @click="confirm('closed')" v-if="['SHIPPED', 'CONFIRMED'].indexOf(order.OrderStatusCode) > -1 && !(['CLOSED'].indexOf(order.OrderStatusCode) > -1) && order.ParentOrderId == 0">
          <font-awesome-icon :icon="['far', 'check']" fixed-width />
          {{ $t.getTranslation('LBL_ORDER_CLOSED') }}
        </f7-button>
        <f7-button @click="confirm('cancel')" v-if="['CONFIRMED'].indexOf(order.OrderStatusCode) > -1">
          <font-awesome-icon :icon="['far', 'check']" fixed-width />
          {{ $t.getTranslation('LBL_ORDER_CANCEL') }}
        </f7-button>
        <f7-button @click="viewSubOrder()" v-if="order?.SubOrderCounter > 0">
          <font-awesome-icon :icon="['far', 'eye']" fixed-width />
          {{ $t.getTranslation('LBL_VIEW_SUB_ORDER') }}
        </f7-button>
        <f7-button v-if="order?.GroupCommissionStatus === 'AVAILABLE' && order?.GroupCommission > 0" fill large @click="releaseGroupCommission()">
          <font-awesome-icon :icon="['far', 'check']" fixed-width />
          {{ $t.getTranslation('LBL_RELEASE_GROUP_COMMISSION') }}
        </f7-button>
      </div>

      <!-- <div v-show="false" class="button-container">
        <f7-button large fill raised>Actions</f7-button>
        <f7-button large icon="far fa-fw fa-cog"></f7-button>
      </div> -->
    </div>

    <section class="timeline-section" v-if="order.SubOrderCounter == 0">
      <h2 class="timeline-title">{{ $t.getTranslation('LBL_ORDER_TIMELINE') }}</h2>
      <div class="container">
        <ul id="timeline" class="timeline">
          <li class="li complete" v-for="status in order.AdminTimeline" :key="status.OrderStatusHistoryId">
            <div class="timestamp">
              <span class="author"></span>
              <span class="date">{{ $h.formatDateTime(status.LastModified) }}</span>
            </div>
            <div class="status">
              <h4>{{ status.OrderStatusName }}</h4>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="timeline-section" v-else>
      <f7-swiper navigation pagination>
        <f7-swiper-slide v-for="sub in order.SubOrders" :key="sub.OrderId">
          <h2 class="timeline-title">{{ $t.getTranslation('LBL_ORDER_TIMELINE') }} - {{ sub.Ref }}</h2>
          <div class="container">
            <ul id="timeline" class="timeline">
              <li class="li complete" v-for="timeline in sub.AdminTimeline" :key="'timeline_' + timeline.OrderStatusCode">
                <div class="timestamp">
                  <span class="author"></span>
                  <span class="date">{{ $h.formatDateTime(timeline.LastModified) }}</span>
                </div>
                <div class="status">
                  <h4>{{ timeline.OrderStatusName }}</h4>
                </div>
              </li>
            </ul>
          </div>
        </f7-swiper-slide>
      </f7-swiper>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_ORDER_DETAILS')" :data="orderDetails" :fields="orderDetailsFields" />
      </div>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_COMMISSION_DETAILS')" :data="orderDetails" :fields="orderCommissionFields" />
      </div>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_ORDER_ITEMS')" :data="order.Items" :fields="orderItemFields" />
      </div>
    </section>

    <section class="" v-if="order?.ItemBundle?.length">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_BUNDLE_SKUS')" :data="order.ItemBundle" :fields="orderItemBundleFields" />
      </div>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_PAYMENT_DETAILS')" :data="payment" :fields="paymentFields" />
      </div>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_REFUND_DETAILS')" :data="orderRefundData" :fields="ordeRefundFields" />
      </div>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_SHIPPING_DETAILS')" :data="shipping" :fields="shippingAddressFields" />
      </div>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_CUSTOMS_HISTORY')" :data="customHistoryRawData" :fields="customHistoryFields" />
      </div>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_ORDER_INVENTORY')" :data="returnData.OrderInventoryList" :fields="orderInventoryFields" />
      </div>
    </section>

    <section class="">
      <div class="container">
        <ViewTableComponent :title="$t.getTranslation('LBL_ORDER_REVIEWS')" :data="returnData.ReviewList" :fields="orderReviewsFields" />
      </div>
    </section>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, onMounted, inject, reactive } from 'vue'
import _ from 'lodash'
import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import ViewTableComponent from '@/components/ViewTableComponent.vue'
import { validations } from '@/utils/validations'
import validationRulesConfig from '@/validationRules'
import { $HTTP, post } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { useStore } from '@/store'
import { configs } from '@/utils/configs'

export default defineComponent({
  name: 'ProductListPage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    ViewTableComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')
    const store = useStore()
    const currentLanguageCode = computed(() => store.getters['translation/getLanguage'])
    const order = ref({})
    const payment = ref([])
    const orderDetails = ref([])
    const shipping = ref([])
    const paymentRawData = ref({})
    const orderRefundData = ref([])
    const customRawData = ref([])
    const customHistoryRawData = ref([])
    const isButtonProcessing = ref(false)
    const formData = reactive({})

    const returnData = ref(false)

    const orderItemFields = ref([
      {
        field: 'PrimaryImage',
        name: $t.getTranslation('LBL_PRODUCT_PRIMARY_IMAGE'),
        format: 'image',
        bucket: 'PRODUCT'
      },
      {
        field: 'CategoryCode',
        name: $t.getTranslation('LBL_CATEGORY'),
        format: 'string'
      },
      { field: 'CountryCode', name: $t.getTranslation('LBL_COUNTRY'), format: 'string' },
      { field: 'Sku', name: $t.getTranslation('LBL_SKU'), format: 'string' },
      {
        field: 'CurrentAttributeName',
        name: $t.getTranslation('LBL_NAME'),
        format: 'string'
      },
      {
        field: 'MerchantName',
        name: $t.getTranslation('LBL_MERCHANT_NAME'),
        format: 'string'
      },
      { field: 'ItemTotal', name: $t.getTranslation('LBL_ITEM_TOTAL'), format: 'string' },
      { field: 'UnitPrice', name: $t.getTranslation('LBL_UNIT_PRICE'), format: 'string' },
      {
        field: 'QtyOrdered',
        name: $t.getTranslation('LBL_QTY_ORDERED'),
        format: 'string'
      },
      {
        field: 'QtyConfirmed',
        name: $t.getTranslation('LBL_QTY_CONFIRMED'),
        format: 'string'
      },
      {
        field: 'QtyShipped',
        name: $t.getTranslation('LBL_QTY_SHIPPED'),
        format: 'string'
      },
      {
        field: 'QtyCancelled',
        name: $t.getTranslation('LBL_QTY_CANCELLED'),
        format: 'string'
      },
      {
        field: 'QtyReturned',
        name: $t.getTranslation('LBL_QTY_RETURNED'),
        format: 'string'
      },
      {
        field: 'IsGroupBuy',
        name: $t.getTranslation('LBL_IS_GROUP_BUY'),
        format: 'yesno'
      },
      {
        field: 'GroupBuyStatusCode',
        name: $t.getTranslation('LBL_GROUP_BUY_STATUS'),
        format: 'string'
      },
      {
        field: 'GroupBuyIdentifier',
        name: $t.getTranslation('LBL_GROUP_BUY_IDENTIFIER'),
        format: 'string'
      },
      {
        field: 'GroupBuyOrderNumber',
        name: $t.getTranslation('LBL_GROUP_BUY_ORDER_NUMBER'),
        format: 'string'
      }
    ])

    const orderItemBundleFields = ref([
      {
        field: 'PrimaryImage',
        name: $t.getTranslation('LBL_PRODUCT_PRIMARY_IMAGE'),
        format: 'image',
        bucket: 'PRODUCT'
      },
      { field: 'Sku', name: $t.getTranslation('LBL_SKU'), format: 'string' },
      {
        field: 'Qty',
        name: $t.getTranslation('LBL_QTY'),
        format: 'string'
      }
    ])

    const paymentFields = ref([
      {
        field: 'payment_id',
        name: $t.getTranslation('LBL_PAYMENT_ID'),
        format: 'string'
      },
      {
        field: 'payment_Method',
        name: $t.getTranslation('LBL_PAYMENT_METHOD'),
        format: 'string'
      },
      {
        field: 'order_number',
        name: $t.getTranslation('LBL_ORDER_NUMBER'),
        format: 'string'
      },
      {
        field: 'order_currency',
        name: $t.getTranslation('LBL_ORDER_CURRENCY'),
        format: 'string'
      },
      {
        field: 'order_amount',
        name: $t.getTranslation('LBL_ORDER_AMOUNT'),
        format: 'string'
      },
      {
        field: 'payment_amount',
        name: $t.getTranslation('LBL_PAYMENT_AMOUNT'),
        format: 'string'
      }
    ])

    const ordeRefundFields = ref([
      { field: 'account', name: $t.getTranslation('LBL_ACCOUNT'), format: 'string' },
      { field: 'terminal', name: $t.getTranslation('LBL_TERMINAL'), format: 'string' },
      { field: 'push_id', name: $t.getTranslation('LBL_PUSH_ID'), format: 'string' },
      {
        field: 'push_details',
        name: $t.getTranslation('LBL_PUSH_DETAILS'),
        format: 'string'
      },
      {
        field: 'push_datetime',
        name: $t.getTranslation('LBL_PUSH_DATE'),
        format: 'string'
      },
      {
        field: 'order_currency',
        name: $t.getTranslation('LBL_ORDER_CURRENCY'),
        format: 'string'
      },
      {
        field: 'payment_id',
        name: $t.getTranslation('LBL_PAYMENT_ID'),
        format: 'string'
      },
      {
        field: 'refund_number',
        name: $t.getTranslation('LBL_REFUND_NUMBER'),
        format: 'string'
      },
      {
        field: 'refund_reference',
        name: $t.getTranslation('LBL_REFUND_REFERENCE'),
        format: 'string'
      }
    ])

    const orderDetailsFields = ref([
      { field: 'UserId', name: $t.getTranslation('LBL_USER_ID'), format: 'string' },
      {
        field: 'OrderStatusName',
        name: $t.getTranslation('LBL_ORDER_STATUS'),
        format: 'string'
      },
      {
        field: 'CourierCode',
        name: $t.getTranslation('LBL_COURIER_CODE'),
        format: 'string'
      },
      {
        field: 'CourierTrackingNo',
        name: $t.getTranslation('LBL_COURIER_TRACKING_NO'),
        format: 'string'
      },
      {
        field: 'Comments',
        name: $t.getTranslation('LBL_ORDER_COMMENTS'),
        format: 'string'
      },
      {
        field: 'OrderDiscount',
        name: $t.getTranslation('LBL_ORDER_DISCOUNT'),
        format: 'string'
      },
      {
        field: 'UserVoucherAmount',
        name: $t.getTranslation('LBL_COUPON_AMOUNT'),
        format: 'string'
      },
      {
        field: 'TotalOrderTax',
        name: $t.getTranslation('LBL_ORDER_TOTAL_TAX'),
        format: 'string'
      },
      {
        field: 'ShippingTotal',
        name: $t.getTranslation('LBL_SHIPPING_TOTAL'),
        format: 'string'
      },
      {
        field: 'GrandTotal',
        name: $t.getTranslation('LBL_GRAND_TOTAL'),
        format: 'string'
      },
      {
        field: 'IsGroupBuy',
        name: $t.getTranslation('LBL_IS_GROUP_BUY'),
        format: 'yesno'
      },
      {
        field: 'GroupBuyStatusCode',
        name: $t.getTranslation('LBL_GROUP_BUY_STATUS'),
        format: 'string'
      }
    ])

    const orderCommissionFields = ref([
      {
        field: 'OrderReferrerId',
        name: $t.getTranslation('LBL_ORDER_REFERRER_INFO'),
        format: 'string'
      },
      {
        field: 'ReferrerGroupId',
        name: $t.getTranslation('LBL_REFERRER_GROUP_INFO'),
        format: 'string'
      },
      {
        field: 'GroupCommissionTypeCode',
        name: $t.getTranslation('LBL_GROUP_COMMISSION_TYPE'),
        format: 'string'
      },
      {
        field: 'CommissionEligibleAmount',
        name: $t.getTranslation('LBL_COMMISSION_ELIGIBLE_AMOUNT'),
        format: 'currency'
      },
      {
        field: 'GroupCommissionRatio',
        name: $t.getTranslation('LBL_GROUP_COMMISSION_RATIO'),
        format: 'currency'
      },
      {
        field: 'GroupCommission',
        name: $t.getTranslation('LBL_GROUP_COMMISSION'),
        format: 'currency'
      },
      {
        field: 'GroupCommissionStatus',
        name: $t.getTranslation('LBL_GROUP_COMMISSION_STATUS'),
        format: 'string'
      }
    ])

    const shippingAddressFields = ref([
      // { field: "LastName", name: $t.getTranslation("LBL_LAST_NAME"), format: "string" },
      // { field: "FirstName", name: $t.getTranslation("LBL_FIRST_NAME"), format: "string" },
      {
        field: 'CustomerName',
        name: $t.getTranslation('LBL_CONSIGNEE_NAME'),
        format: 'string'
      },
      {
        field: 'MobileCode',
        name: $t.getTranslation('LBL_MOBILE_CODE'),
        format: 'string'
      },
      {
        field: 'MobileNumber',
        name: $t.getTranslation('LBL_MOBILE_NUMBER'),
        format: 'string'
      },
      { field: 'Email', name: $t.getTranslation('LBL_EMAIL'), format: 'string' },
      { field: 'IdNumber', name: $t.getTranslation('LBL_ID_NUMBER'), format: 'string' },
      { field: 'FullName', name: $t.getTranslation('LBL_FULL_NAME'), format: 'string' },
      {
        field: 'CountryCode',
        name: $t.getTranslation('LBL_COUNTRY_CODE'),
        format: 'string'
      },
      { field: 'Province', name: $t.getTranslation('LBL_PROVINCE'), format: 'string' },
      { field: 'City', name: $t.getTranslation('LBL_CITY'), format: 'string' },
      { field: 'District', name: $t.getTranslation('LBL_DISTRICT'), format: 'string' },
      // { field: "PostalCode", name: $t.getTranslation("LBL_POSTAL_CODE"), format: "string" },
      {
        field: 'PrimaryAddress',
        name: $t.getTranslation('LBL_PRIMARY_ADDRESS'),
        format: 'string'
      },
      {
        field: 'SecondaryAddress',
        name: $t.getTranslation('LBL_SECONDARY_ADDRESS'),
        format: 'string'
      }
    ])

    const customFields = ref([
      { field: 'verifyId', name: $t.getTranslation('LBL_VERIFY_ID'), format: 'string' },
      { field: 'account', name: $t.getTranslation('LBL_ACCOUNT'), format: 'string' },
      { field: 'terminal', name: $t.getTranslation('LBL_TERMINAL'), format: 'string' },
      { field: 'signvalue', name: $t.getTranslation('LBL_SIGN_VALUE'), format: 'string' },
      {
        field: 'payment_datetime',
        name: $t.getTranslation('LBL_PAYMENT_DATE'),
        format: 'datetime'
      },
      {
        field: 'order_number',
        name: $t.getTranslation('LBL_ORDER_NUMBER'),
        format: 'string'
      },
      {
        field: 'order_currency',
        name: $t.getTranslation('LBL_ORDER_CURRENCY'),
        format: 'string'
      },
      {
        field: 'order_amount',
        name: $t.getTranslation('LBL_ORDER_AMOUNT'),
        format: 'string'
      },
      {
        field: 'push_datetime',
        name: $t.getTranslation('LBL_PUSH_DATE'),
        format: 'datetime'
      }
    ])

    const customHistoryFields = ref([
      { field: 'verifyId', name: $t.getTranslation('LBL_VERIFY_ID'), format: 'string' },
      { field: 'account', name: $t.getTranslation('LBL_ACCOUNT'), format: 'string' },
      { field: 'terminal', name: $t.getTranslation('LBL_TERMINAL'), format: 'string' },
      { field: 'signvalue', name: $t.getTranslation('LBL_SIGN_VALUE'), format: 'string' },
      {
        field: 'payment_datetime',
        name: $t.getTranslation('LBL_PAYMENT_DATE'),
        format: 'datetime'
      },
      {
        field: 'order_number',
        name: $t.getTranslation('LBL_ORDER_NUMBER'),
        format: 'string'
      },
      {
        field: 'order_currency',
        name: $t.getTranslation('LBL_ORDER_CURRENCY'),
        format: 'string'
      },
      {
        field: 'order_amount',
        name: $t.getTranslation('LBL_ORDER_AMOUNT'),
        format: 'string'
      },
      {
        field: 'push_datetime',
        name: $t.getTranslation('LBL_PUSH_DATE'),
        format: 'datetime'
      },
      {
        field: 'push_details',
        name: $t.getTranslation('LBL_PUSH_DETAILS'),
        format: 'string'
      },
      {
        field: 'verify_department',
        name: $t.getTranslation('LBL_VERIFY_DEPARTMENT'),
        format: 'string'
      },
      {
        field: 'secureCode',
        name: $t.getTranslation('LBL_SECURE_CODE'),
        format: 'string'
      }
    ])

    const orderInventoryFields = ref([
      {
        field: 'OrderInventoryId',
        name: $t.getTranslation('LBL_ORDER_INVENTORY_ID'),
        format: 'number'
      },
      {
        field: 'InventoryLocationId',
        name: $t.getTranslation('LBL_INVENTORY_LOCATION_ID'),
        format: 'number'
      },
      { field: 'ProductId', name: $t.getTranslation('LBL_PRODUCT_ID'), format: 'string' },
      { field: 'Qty', name: $t.getTranslation('LBL_QUANTITY'), format: 'string' },
      { field: 'Sku', name: $t.getTranslation('LBL_SKU'), format: 'string' },
      {
        field: 'ProductName',
        name: $t.getTranslation('LBL_PRODUCT_NAME'),
        format: 'string'
      },
      {
        field: 'WareHouseName',
        name: $t.getTranslation('LBL_WAREHOUSE_NAME'),
        format: 'string'
      },
      {
        field: 'WareHouseCode',
        name: $t.getTranslation('LBL_WAREHOUSE_CODE'),
        format: 'string'
      },
      {
        field: 'WareHouseRegionName',
        name: $t.getTranslation('LBL_WAREHOUSE_REGION_NAME'),
        format: 'string'
      },
      {
        field: 'WareHouseRegionCode',
        name: $t.getTranslation('LBL_WAREHOUSE_REGION_CODE'),
        format: 'string'
      },
      {
        field: 'LastModified',
        name: $t.getTranslation('LBL_LAST_MODIFIED'),
        format: 'datetime'
      },
      {
        field: 'LastCreated',
        name: $t.getTranslation('LBL_LAST_CREATED'),
        format: 'datetime'
      }
    ])

    const orderReviewsFields = ref([
      {
        field: 'ProductReviewId',
        name: $t.getTranslation('LBL_PRODUCT_REVIEW_ID'),
        format: 'string'
      },
      {
        field: 'ProductName',
        name: $t.getTranslation('LBL_PRODUCT_NAME'),
        format: 'string'
      },
      { field: 'UserName', name: $t.getTranslation('LBL_USER_NAME'), format: 'string' },
      { field: 'FullName', name: $t.getTranslation('LBL_FULL_NAME'), format: 'string' },
      { field: 'Rating', name: $t.getTranslation('LBL_RATING'), format: 'string' },
      { field: 'Content', name: $t.getTranslation('LBL_CONTENT'), format: 'string' },
      {
        field: 'LastModified',
        name: $t.getTranslation('LBL_LAST_MODIFIED'),
        format: 'datetime'
      },
      {
        field: 'LastCreated',
        name: $t.getTranslation('LBL_LAST_CREATED'),
        format: 'datetime'
      }
    ])

    const getOrder = async () => {
      try {
        helpers.showLoader()
        let res = await $HTTP.get(`/admin/order/view?&LanguageCode=${currentLanguageCode.value || 'EN'}&OrderKey=${props?.f7route?.params?.id}`)
        if (res?.status === 200 && res?.data?.data) {
          helpers.hideLoader()
          returnData.value = res.data?.data

          orderDetails.value = []
          shipping.value = []
          payment.value = []
          paymentRawData.value = []

          let pickRemarks = _.filter(res.data.data.StatusHistory, function(status) {
            if (status.OrderStatusCode == res.data.data.OrderStatusCode) {
              return status
            }
          })

          if (pickRemarks.length > 0 && pickRemarks[0]?.Remark) {
            res.data.data.Comments = pickRemarks[0].Remark
          }

          let paymentRaw = res.data.data.Payment || {}
          if (paymentRaw?.RawData) {
            paymentRaw = paymentRaw.RawData
            paymentRaw = paymentRaw.replace(/\//g, '')
            paymentRaw = JSON.parse(paymentRaw)
            let pickPayment = _.pick(paymentRaw, ['payment_id', 'payment_Method', 'order_number', 'order_currency', 'order_amount', 'payment_amount', 'methods'])
            if (!pickPayment.payment_Method || pickPayment.payment_Method == '') {
              pickPayment.payment_Method = pickPayment.methods
            }
            payment.value.push(pickPayment)
            paymentRawData.value = pickPayment
          }

          // let customRaw = res.data.data.Customs || {};
          // let verifyId = customRaw.VerifyId || null;
          // if (customRaw?.RawData) {
          //   customRaw = customRaw.RawData;
          //   customRaw = customRaw.replace(/\//g, "");
          //   customRaw = JSON.parse(customRaw);
          //   let pickCustom = _.pick(customRaw, ['account', 'terminal', 'signvalue', 'payment_datetime', 'order_number', 'order_currency', 'order_amount', 'push_datetime']);
          //   pickCustom['verifyId'] = verifyId;
          //   customRawData.value.push(pickCustom);
          // }

          let customHistoryRaw = res.data.data.CustomsHistory || {}
          let verifyId = customHistoryRaw.VerifyId || null
          if (customHistoryRaw?.RawData) {
            customHistoryRaw = customHistoryRaw.RawData
            customHistoryRaw = customHistoryRaw.replace(/\//g, '')
            customHistoryRaw = JSON.parse(customHistoryRaw)
            let pickCustom = _.pick(customHistoryRaw, ['account', 'terminal', 'signvalue', 'payment_datetime', 'order_number', 'order_currency', 'order_amount', 'push_datetime', 'push_details', 'verify_department', 'secureCode'])
            pickCustom['verifyId'] = verifyId
            customHistoryRawData.value.push(pickCustom)
          }

          let pickorder = _.pick(res.data.data, [
            'UserId',
            'OrderStatusName',
            'Comments',
            'ShippingTotal',
            'GrandTotal',
            'TotalOrderTax',
            'OrderDiscount',
            'UserVoucherAmount',
            'CommissionEligibleAmount',
            'GroupCommissionRatio',
            'GroupCommission',
            'GroupCommissionTypeCode',
            'GroupCommissionStatus',
            'OrderReferrerId',
            'ReferrerGroupId',
            'IsGroupBuy',
            'GroupBuyStatusCode'
          ])
          let pickaddress = _.pick(res.data.data.Address, ['FirstName', 'LastName', 'MobileCode', 'MobileNumber', 'Email', 'IdNumber', 'CountryCode', 'City', 'Province', 'District', 'PostalCode', 'PrimaryAddress', 'SecondaryAddress'])

          let pickUserIdentification = _.pick(res.data.data.UserIdentification, ['IdNumber', 'FullName'])

          let name = `${pickaddress.LastName}${pickaddress.FirstName}`
          name = name.replace(' ', '')
          if (pickaddress) {
            pickaddress.CustomerName = name
          }

          pickaddress = { ...pickaddress, ...pickUserIdentification }

          if (res?.data?.data.Shipment && res?.data?.data.Shipment.length > 0) {
            let pickTracking = _.pick(res?.data?.data.Shipment[0], ['CourierCode', 'CourierTrackingNo'])
            pickorder['CourierCode'] = pickTracking.CourierCode
            pickorder['CourierTrackingNo'] = pickTracking.CourierTrackingNo
          }

          orderDetails.value.push(pickorder)
          shipping.value.push(pickaddress)

          for (var i = 0; i < res.data.data.Items.length; i++) {
            let item = res.data.data.Items[i]
            item.PrimaryImage = item.Product.PrimaryImage
            for (const attribute of item.Product.Attributes) {
              if (attribute.LanguageCode == currentLanguageCode.value) {
                item.CurrentAttributeName = attribute.Name
              }
            }
            for (const date of res.data.data.StatusHistory) {
              date.LastModified = helpers.formatDateTime(item.LastModified)
            }
          }

          if (res.data.data.SubOrderCounter > 0 && res.data.data.ParentOrderId == 0) {
            for (var i = 0; i < res.data.data.SubOrders.length; i++) {
              let item = res.data.data.SubOrders[i]
              let customHistoryRaw = item.CustomsHistory || {}
              let verifyId = customHistoryRaw.VerifyId || null
              if (customHistoryRaw?.RawData) {
                customHistoryRaw = customHistoryRaw.RawData
                customHistoryRaw = customHistoryRaw.replace(/\//g, '')
                customHistoryRaw = JSON.parse(customHistoryRaw)
                let pickCustom = _.pick(customHistoryRaw, ['account', 'terminal', 'signvalue', 'payment_datetime', 'order_number', 'order_currency', 'order_amount', 'push_datetime', 'push_details', 'verify_department', 'secureCode'])
                pickCustom['verifyId'] = verifyId
                customHistoryRawData.value.push(pickCustom)
              }
            }
          }

          let OrderRefund = res.data.data.Refund || {}
          if (OrderRefund?.RawData) {
            let rawData = OrderRefund?.RawData
            rawData = rawData.replace(/\//g, '')
            rawData = JSON.parse(rawData)
            let pickRefund = _.pick(rawData, ['account', 'terminal', 'payment_datetime', 'push_datetime', 'order_currency', 'order_amount', 'payment_id', 'refund_number', 'refund_reference', 'push_id', 'push_details'])
            let orderdetails = {
              OrderNumber: OrderRefund.PaymentOrderNumber
            }
            pickRefund = { ...pickRefund, ...orderdetails }
            //insert the refund details in the timeline..

            res.data.data.AdminTimeline.push({
              OrderStatusCode: 'ORDER_REFUND',
              OrderStatusName: 'Order Refunded',
              OrderStatusHistoryId: pickRefund.payment_id,
              LastModified: pickRefund.push_datetime
            })
            orderRefundData.value.push(pickRefund)
          }

          order.value = res.data.data
          return
        }
        throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const confirm = (msg, event) => {
      if (msg == 'cancel' && order.value.SubOrderCounter > 0) {
        helpers.createNotification({
          type: 'success',
          title: $t.getTranslation('LBL_ERROR'),
          message: $t.getTranslation('ERR_CANCEL_PARENT_ORDER')
        })
        return
      }

      if (msg == 'closed' && order.value.SubOrderCounter > 0) {
        helpers.createNotification({
          type: 'success',
          title: $t.getTranslation('LBL_ERROR'),
          message: $t.getTranslation('ERR_CLOSED_PARENT_ORDER')
        })
        return
      }

      let message = $t.getTranslation('MSG_CONFIRM_CLOSED_ORDER')
      let title = $t.getTranslation('LBL_CONFIRM_ORDER_CLOSED')
      if (msg == 'cancel') {
        message = $t.getTranslation('MSG_CONFIRM_CANCEL_ORDER')
        title = $t.getTranslation('LBL_CONFIRM_ORDER_CANCEL')
      }
      message = message.replace('{OrderRef}', order.value.Ref)
      helpers.createConfirmation({
        message: message,
        title: title,
        confirm: () => {
          addRemarks(msg)
        }
      })
    }

    const addRemarks = (msg) => {
      let message = $t.getTranslation('LBL_ORDER_CLOSED_ADD_REMARKS')
      let errorMessage = $t.getTranslation('ERR_ORDER_CLOSE_REMARKS')
      let isCancel = false
      if (msg == 'cancel') {
        message = $t.getTranslation('LBL_ORDER_CANCEL_ADD_REMARKS')
        errorMessage = $t.getTranslation('ERR_ORDER_CANCEL_REMARKS')
        isCancel = true
      }
      helpers.createRemarks({
        message: message,
        confirm: (remarks) => {
          if (remarks == '') {
            helpers.createNotification({
              type: 'error',
              title: 'Error!',
              message: errorMessage
            })
          } else {
            save(remarks, isCancel)
          }
        }
      })
    }

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      return isValid
    }

    let validationRules = {}
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys]
      }
    }

    const save = async (remarks, isCancel) => {
      try {
        let isValid = validate(true)
        if (isValid) {
          isButtonProcessing.value = true
          helpers.showLoader()
          if (remarks) {
            formData.Remarks = remarks
          }

          formData.OrderKey = order.value.OrderKey
          let url = `/admin/order/close`
          let message = $t.getTranslation('MSG_SUCCESS_ORDER_CLOSED')
          if (isCancel) {
            url = `/admin/order/cancel`
            message = $t.getTranslation('MSG_SUCCESS_ORDER_CANCEL')
          }

          //send update to server
          let res = await $HTTP.post(url, formData)
          if (res?.status === 200 && res?.data?.data) {
            isButtonProcessing.value = false
            helpers.hideLoader()
            helpers.createNotification({
              type: 'success',
              title: $t.getTranslation('LBL_SUCCESS'),
              message: message
            })
            getOrder()
            return
          } else {
            throw new Error($t.getTranslation('ERR_SERVER_NOT_RESPONDING'))
          }
        }
      } catch (err) {
        isButtonProcessing.value = false
        helpers.hideLoader()
        helpers.catchError(err, true)
      }
    }

    const viewSubOrder = () => {
      props.f7router.navigate({
        name: 'orderList',
        query: { ParentOrderId: order.value.OrderId }
      })
    }

    onMounted(async () => {
      if (props?.f7route?.params?.id) {
        await getOrder()
      }
    })

    const downloadInvoice = () => {
      window.location.href = `${configs.baseURL}/order/invoice?key=${order.value.OrderKey}`
    }

    const releaseGroupCommission = async () => {
      helpers.createRemarks({
        message: $t.getTranslation('LBL_CONFIRM_RELEASE_GROUP_COMMISSION'),
        confirm: async (Remarks) => {
          if (Remarks) {
            let returnData = await post('/admin/user/groupcommission/release', {
              OrderId: order?.value?.OrderId,
              Remarks
            })
            if (returnData) {
              helpers.createNotification({
                type: 'info',
                title: 'INFO',
                message: 'Successfully release user group commission.'
              })

              await getOrder()
            }
          }
        }
      })
    }

    return {
      orderItemBundleFields,
      downloadInvoice,
      order,
      orderItemFields,
      orderCommissionFields,
      paymentFields,
      payment,
      orderDetails,
      orderDetailsFields,
      shipping,
      shippingAddressFields,
      paymentRawData,
      confirm,
      addRemarks,
      isButtonProcessing,
      customRawData,
      customFields,
      customHistoryRawData,
      customHistoryFields,
      orderInventoryFields,
      orderReviewsFields,
      returnData,
      viewSubOrder,
      releaseGroupCommission,
      orderRefundData,
      ordeRefundFields
    }
  }
})
</script>

<style scoped>
.timeline-section {
  margin-top: 30px;
}
.timeline-title {
  text-align: center;
}
.timeline-section > .container {
  padding-top: 10px;
}
table {
  width: 100%;
}
td {
  text-align: center;
}
.timeline {
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.li {
  transition: all 200ms ease-in;
}

.timestamp {
  margin-bottom: 20px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
}

.status {
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #0c4d8f;
  position: relative;
  transition: all 200ms ease-in;
}
.status h4 {
  font-weight: 600;
  text-align: center;
}
.status:before {
  content: '';
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #0c4d8f;
  position: absolute;
  top: -15px;
  left: 45%;
  transition: all 200ms ease-in;
}

.li.complete .status {
  border-top: 2px solid #0c4d8f;
}
.li.complete .status:before {
  background-color: #0c4d8f;
  border: none;
  transition: all 200ms ease-in;
}
.li.complete .status h4 {
  color: #0c4d8f;
}

@media (min-device-width: 320px) and (max-device-width: 700px) {
  .timeline {
    list-style-type: none;
    display: block;
  }

  .li {
    transition: all 200ms ease-in;
    display: flex;
    width: inherit;
  }

  .timestamp {
    width: 100px;
  }

  .status:before {
    left: -8%;
    top: 30%;
    transition: all 200ms ease-in;
  }
}
</style>
