<template>
  <f7-popup class="userselect-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation('LBL_SELECT_USER') }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar v-model:value="searchValue" :disable-button="false" :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')" :clear-button="true"></f7-searchbar>
      <div class="user-container">
        <f7-list no-hairlines>
         <f7-list-input
            v-model:value="formData.SearchField"
            name="StatusCode"
            :required="validationRules?.Fields?.required"
            :minlength="validationRules?.Fields?.minimumLength"
            :maxlength="validationRules?.Fields?.maximumLength"
            :label="$t.getTranslation('LBL_SEARCH_FIELD')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            type="select"
            validate
          >
            <option v-for="s in SearchFields" :key="'st_' + s.Code" :value="s.Code">{{ s.Name }}</option>
          </f7-list-input>
        </f7-list>
      
        <template v-for="(user, ind) in userList" :key="'us_' + ind">
          <div v-if="all || (!all && !user.InfluencerId)" class="profile-info" @click="selectUser(user)">
            <img :src="$h.getImage(user.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />

            <div class="info">
              <h1>{{ $h.getFullName(user) }}</h1>
              <h2>{{ user.UserName || user.Email || user.OpenId }}</h2>
              <span> UserId : {{ user.UserId }}</span>
              <!-- <p>UserId : {{ user.UserId }}</p> -->
            </div>
          </div>
        </template>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { ref, onMounted, inject, watch, computed, reactive } from 'vue'
import { f7 } from 'framework7-vue'

import { get } from '@/utils/axios'
import { useStore } from '@/store'

export default {
  name: 'UserSelectionPopUpComponent',
  components: {},
  props: {
    exclude: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    usertype: {
      type: String,
      default: 'USER'
    },
    all: {
      type: Boolean,
      default: false
    },
    open: Boolean,
    attachUser: Function
  },
  emits: ['opened', 'closed', 'selected'],
  setup(props, { emit }) {
    const $t = inject('$translation')

    let size = 15
    let page = 1
    let lastPage = 1

    const userList = ref([])
    const showPreloader = ref(false)
    const searchValue = ref('')
    const store = useStore()
    //const SearchFields = ref(['U.UserKey', 'U.UserId', 'U.Email', 'U.Username', 'U.FirstName', 'U.LastName', 'U.MobileCode', 'U.MobileNumber', 'WL.OpenId'])
    const statusList = computed(() => store.getters['config/getData']?.statusList);
    const formData = reactive({
      SearchField : "U.UserId",
    });

    const SearchFields = ref([
      {Code: "U.UserId", Name: "UserId"},
      {Code: "U.UserName", Name: "Username"},
      {Code: "WL.OpenId", Name: "Wechat Id"},
      {Code: "UserFullName", Name: "Full Name"}
    ])


    const getUserList = async () => {
      //let isInt = searchValue.value && !isNaN(searchValue.value) ? true : false
      let payload = {
        LanguageCode: $t.getLanguage(),
        page,
        size,
        UserTypeCode: props.usertype,
        UserRole: props.role,
        UserExclude: props.exclude,
        SearchField: formData.SearchField,
        SearchValue: searchValue.value,
        SearchType: "LIKE"
      }
      if(formData.SearchField == 'U.UserId'){
        payload = {
          ...payload,
          UserId: searchValue.value,
          SearchValue: "",
          SearchField: ""
        }
      }else{
        payload = {
          ...payload,
          SearchField: formData.SearchField,
          SearchValue: searchValue.value,
          UserId: ""
        }
      }

      let ret = await get('/admin/user/list', payload)

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter]
          userList.value.push(item)
        }

        lastPage = ret?.lastPage
        page++
      }
    }

    const loadMore = () => {
      return lastPage > page ? getUserList() : false
    }

    onMounted(getUserList)

    watch(searchValue, (newValue, oldValue) => {
      userList.value = []
      page = 1
      getUserList()
    })

    const openPopup = () => {
      emit('opened', true)
    }

    const closePopup = () => {
      emit('closed', true)
    }

    const selectUser = (data) => {
      f7.popup.close()

      if (props.attachUser) {
        let obj = JSON.stringify(data)
        props.attachUser(obj)
        return
      }

      emit('selected', data)
    }

    return {
      openPopup,
      closePopup,
      userList,
      selectUser,
      loadMore,
      showPreloader,
      searchValue,
      statusList,
      formData,
      SearchFields
    }
  }
}
</script>

<style scoped>
.popup .navbar {
  height: 80px;
}
.userselect-popup.popup .navbar .title {
  margin-left: 15px;
}
.userselect-popup .user-container {
  width: calc(100% - 40px);
  margin: 20px auto;
}
.profile-info {
  cursor: pointer;
}
</style>
