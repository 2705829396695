<template>
  <section v-if="inputList?.length > 0" class="form-container">
    <div class="container">
      <f7-list class="formContainer" form no-hairlines no-hairlines-between>
        <div v-for="inputGroup in inputList" :key="'ing_' + inputGroup.name" class="form-group-container">
          <template v-if="!inputGroup?.visibleSelector || (inputGroup?.visibleSelector && inputGroup?.visibleValue && formData[inputGroup?.visibleSelector] == inputGroup?.visibleValue)">
            <f7-list-item v-if="inputGroup?.name && !inputGroup?.hidden" divider>
              {{ inputGroup.name }}
              <template v-if="inputGroup?.required === true">
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>

              <!-- <f7-toggle :key="'ftog_' + groupIndex" v-model:checked="inputGroup.isVisible" class="toggle-visibility"></f7-toggle> -->
            </f7-list-item>

            <transition name="slide-fade">
              <div v-if="inputGroup.isVisible && !inputGroup?.hidden">
                <template v-if="inputGroup?.type === 'selectmultiple' && !inputGroup?.hidden">
                  <f7-list-item class="selectmultiple-container">
                    <template v-if="inputGroup?.listingDesign === 'blocks'">
                      <ul v-if="formData[inputGroup?.value + 'List']?.length > 0" class="blocks">
                        <li v-for="(item, index) in formData[inputGroup.value + 'List']" :key="inputGroup.value + '_sml_' + item[inputGroup?.params?.valueSelector] || index">
                          {{ item[inputGroup?.params?.nameSelector || "name"] }}
                        </li>
                      </ul>
                    </template>

                    <template v-else>
                      <f7-list v-if="formData[inputGroup?.value + 'List']?.length > 0" media-list sortable sortable-enabled class="multipleitem-container" @sortable:sort="onSelectMultipleSort($event, inputGroup.value + 'List')">
                        <f7-list-item
                          v-for="(item, index) in formData[inputGroup.value + 'List']"
                          :key="inputGroup.value + '_sml_' + item[inputGroup?.params?.valueSelector] || index"
                          :title="item[inputGroup?.params?.nameSelector || 'name']"
                          :text="item[inputGroup?.params?.descriptionSelector || 'description'] || 'No description available.'"
                        >
                          <template #media>
                            <img v-if="inputGroup?.params?.imageSelector && inputGroup?.params?.imageBucket" :src="$h.getImage(item[inputGroup?.params?.imageSelector || 'Image'], inputGroup?.params?.imageBucket)" alt="Item Image" />
                            <img v-else :src="require('@/assets/images/placeholder.jpg')" alt="Image Placeholder" />
                          </template>
                        </f7-list-item>
                      </f7-list>
                    </template>

                    <f7-button class="button-select" fill large @click="onSelectMultiple(inputGroup)">{{ inputGroup?.buttonText || "Select Item" }}</f7-button>
                  </f7-list-item>
                </template>

                <template v-if="inputGroup?.type === 'price' && !inputGroup?.hidden">
                  <f7-list-item class="price-container">
                    <f7-list v-if="formData[inputGroup?.value + 'List']?.length > 0" media-list class="multipleitem-container">
                      <f7-list-item v-for="(item, index) in formData[inputGroup.value + 'List']" :key="inputGroup.value + '_sml_' + item['CurrencyId'] || index">
                        <template #title>
                          <template v-if="item?.PriceMerchant > 0">{{ item.CurrencySymbol }}{{ item.PriceMerchant }}</template>
                          <template v-if="item?.PriceRegular > 0"> / {{ item.CurrencySymbol }}{{ item.PriceRegular }}</template>
                          <template v-if="item?.PriceSale > 0"> / {{ item.CurrencySymbol }}{{ item.PriceSale }}</template>
                        </template>
                        <template #text>
                          <strong>{{ item.CurrencyCode }}</strong> - {{ item.CurrencyName }}
                        </template>
                        <template #media>
                          <img :src="require('@/assets/images/currency.jpg')" alt="Price Placeholder" />
                        </template>
                        <template #content>
                          <div class="button-container" @click.stop="onEditPrice(inputGroup, item, index)">
                            <f7-button fill color="blue">
                              <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                            </f7-button>
                            <f7-button fill color="red" @click.stop="onDeletePrice(inputGroup, item, index)">
                              <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                            </f7-button>
                          </div>
                        </template>
                      </f7-list-item>
                    </f7-list>

                    <f7-button class="button-select" fill large @click="onSetPrice(inputGroup)">{{ inputGroup?.buttonText || "Set Price" }}</f7-button>
                  </f7-list-item>
                </template>

                <template v-if="inputGroup?.type === 'translation' && !inputGroup?.hidden">
                  <f7-list-item class="translation-container">
                    <f7-list v-if="formData[inputGroup?.value + 'List']?.length > 0" media-list class="multipleitem-container">
                      <f7-list-item v-for="(item, index) in formData[inputGroup.value + 'List']" :key="inputGroup.value + '_tlc_' + item['TranslationId'] || index">
                        <template #title>
                          <strong>{{ item.LanguageName }}</strong>
                        </template>
                        <template #text>
                          <template v-for="(content, key) in item" :key="inputGroup.value + '_tlc_c' + key">
                            <span v-if="['LanguageId', 'LanguageName', 'LanguageCode'].indexOf(key) <= -1" class="translation-content"> {{ content }} </span>
                          </template>
                        </template>
                        <template #media>
                          <div class="language-media">
                            {{ item.LanguageCode }}
                          </div>
                        </template>
                        <template #content>
                          <div class="button-container" @click.stop="onEditTranslation(inputGroup, item, index)">
                            <f7-button fill color="blue">
                              <font-awesome-icon :icon="['far', 'edit']" fixed-width />
                            </f7-button>
                            <f7-button fill color="red" @click.stop="onDeleteTranslation(inputGroup, item, index)">
                              <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                            </f7-button>
                          </div>
                        </template>
                      </f7-list-item>
                    </f7-list>

                    <f7-button class="button-select" fill large @click="onSetTranslation(inputGroup)">{{ inputGroup?.buttonText || "Set Translation" }}</f7-button>
                  </f7-list-item>
                </template>

                <template v-if="inputGroup?.type === 'image' && !inputGroup?.hidden">
                  <f7-list-item class="image-container">
                    <div class="image-item image-upload" @click="onSelectImage(inputGroup?.value)">
                      <font-awesome-icon :icon="['far', 'plus']" fixed-width />

                      <ImageUploadPopUpComponent2
                        ref="imageUploadPopUpComponent"
                        :params="{
                          type: inputGroup?.value,
                        }"
                        :image-size-limit="inputGroup?.sizeLimit"
                        :canvas="inputGroup?.canvas"
                        :stencil-size="inputGroup?.stencilSize"
                        :stencil-props="inputGroup?.stencilProps"
                        :backdrop="true"
                        @cropped="onImageSelected"
                      />
                    </div>

                    <div v-for="(image, index) in formData[inputGroup.value + 'List']" :key="'i_' + index" :class="{ default: image?.IsDefault === 1 }" class="image-item" @click.stop="toggleDefaultImage(inputGroup?.value, index)">
                      <div class="select">
                        <font-awesome-icon :icon="['far', 'star']" fixed-width />
                      </div>

                      <template v-if="image?.blob">
                        <img :src="$h.renderImageBlob(image?.blob)" :alt="inputGroup?.value" />
                      </template>

                      <template v-if="image?.image">
                        <img :src="image?.image" :alt="inputGroup?.value" />
                      </template>

                      <template v-if="image?.Type && image?.FileName">
                        <img :src="$h.getImage(image.FileName, image?.Type)" :alt="inputGroup?.value" />
                      </template>

                      <f7-button class="delete" @click.stop="deleteImage(inputGroup?.value, index, image?.ImageId, image?.IsDefault)">
                        <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                      </f7-button>
                    </div>
                  </f7-list-item>
                  <div class="image-description">
                    <p v-if="inputGroup?.value === 'Image'">Image can be a logo or profile image. Image are required to have a 1x1 aspect ratio, with a max width and height of 600px.</p>
                    <p v-if="inputGroup?.value === 'BannerImage'">Banner Image are used/shown in details page. A Banner Image are required to have a 2x1 aspect ratio, with a max width of 1200px and height of 600px.</p>
                  </div>
                </template>

                <template v-if="inputGroup?.type === 'video' && !inputGroup?.hidden">
                  <f7-list-item class="upload-container">
                    <ul class="file-upload-container">
                      <li v-for="(fileItem, index) in formData[inputGroup.value + 'List']" :key="'fu_' + index">
                        <template v-if="fileItem?.ImageId">
                          <div class="image">
                            <font-awesome-icon v-if="['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml'].indexOf(fileItem?.MimeType) > -1" :icon="['fas', 'image']" fixed-width />
                            <font-awesome-icon v-if="['video/x-msvideo', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime', 'video/x-m4v'].indexOf(fileItem?.MimeType) > -1" :icon="['fas', 'video']" fixed-width />
                            <font-awesome-icon v-else :icon="['fas', 'file']" fixed-width />
                          </div>

                          <div class="content">
                            <h3>{{ fileItem?.FileName }}</h3>
                            <small>{{ fileItem?.MimeType }} - {{ $h.formatFileSize(fileItem?.Size) }}</small>
                          </div>
                        </template>
                        <template v-else>
                          <div class="image">
                            <font-awesome-icon v-if="['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml'].indexOf(fileItem?.file?.type) > -1" :icon="['fas', 'image']" fixed-width />
                            <font-awesome-icon v-if="['video/x-msvideo', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime', 'video/x-m4v'].indexOf(fileItem?.file?.type) > -1" :icon="['fas', 'video']" fixed-width />
                            <font-awesome-icon v-else :icon="['fas', 'file']" fixed-width />
                          </div>

                          <div class="content">
                            <h3>{{ fileItem?.file?.name }}</h3>
                            <small>{{ fileItem?.file?.type }} - {{ $h.formatFileSize(fileItem?.file?.size) }}</small>
                          </div>
                        </template>

                        <div class="button-container">
                          <f7-button class="delete" @click.stop="deleteVideo(inputGroup?.value, index, fileItem?.ImageId)">
                            <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                          </f7-button>
                        </div>
                      </li>
                    </ul>

                    <f7-button class="button-upload" fill large @click="onSelectVideo(inputGroup?.value)">{{ inputGroup?.buttonText || $t.getTranslation("LBL_SELECT_VIDEO") }}</f7-button>

                    <VideoUploadPopUpComponent2
                      ref="videoUploadPopUpComponent"
                      :params="{
                        type: inputGroup?.value,
                      }"
                      :backdrop="true"
                      @selected="onVideoSelected"
                    />
                  </f7-list-item>

                  <div class="upload-description">
                    <p></p>
                  </div>
                </template>

                <template v-if="inputGroup?.type === 'input' && !inputGroup?.hidden">
                  <template v-for="input in inputGroup?.list" :key="'inp_' + input.value">
                    <template v-if="input.type === 'tags'">
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        class="tags"
                        type="text"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || 'Type Here'"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        floating-label
                        outline
                        validate
                        :clear-button="!input.hideClearButton"
                        @change="input?.onChange ? input['onChange']($event, formData) : ''"
                        @blur="onInputBlur($event, input)"
                        @keyup.enter="onAddTag(input.value, input?.unique)"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                        <template #content-end>
                          <f7-button fill large @click="onAddTag(input.value, input?.unique)">Add</f7-button>
                        </template>
                        <template v-if="formData?.[input.value + 'List']?.length > 0" #root>
                          <f7-chip v-for="(item, index) in formData?.[input.value + 'List']" :key="'chp_' + index" :text="item" deleteable @click="onDeleteTag(input.value, item, index)"></f7-chip>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'texteditor'">
                      <link :href="require('@/assets/css/materialicon.css')" rel="stylesheet" />
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        type="texteditor"
                        :text-editor-params="{ mode: 'popover', divider: true, clearFormattingOnPaste: false }"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || 'Type Here'"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        outline
                        floating-label
                        validate
                        @texteditor:change="(value) => (formData[input.value] = value)"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'timepicker'">
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        type="text"
                        class="timepicker"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || 'Type Here'"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        outline
                        floating-label
                        validate
                        clear-button
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'datepicker'">
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        type="text"
                        class="datepicker"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || 'Type Here'"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        :data-default-date="input?.defaultDate"
                        :data-start-year="input?.startYear"
                        outline
                        floating-label
                        validate
                        clear-button
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else-if="input.type === 'datetimepicker'">
                      <f7-list-input
                        v-model:value="formData[input.value]"
                        type="text"
                        class="datetimepicker"
                        :params="input?.params ? JSON.stringify(input.params) : ''"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || 'Type Here'"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        outline
                        floating-label
                        validate
                        clear-button
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>
                      </f7-list-input>
                    </template>

                    <template v-else>
                      <f7-list-input
                        v-if="!input.hidden"
                        v-model:value="formData[input.value]"
                        :class="input.type"
                        :type="input.type"
                        :name="input.value"
                        :required="validationRules?.[input.value]?.required"
                        :minlength="validationRules?.[input.value]?.minimumLength"
                        :maxlength="validationRules?.[input.value]?.maximumLength"
                        :label="input.name"
                        :pattern="input.pattern"
                        :placeholder="input.placeholder || 'Type Here'"
                        :info="$h.getFieldInfo(input)"
                        :error-message="input.error ? input.error : ''"
                        :disabled="input?.disabled"
                        floating-label
                        outline
                        validate
                        :clear-button="!input.hideClearButton"
                        @change="input?.onChange ? input['onChange']($event) : ''"
                        @blur="onInputBlur($event, input)"
                      >
                        <template v-if="input?.required === true" #content-start>
                          <f7-link tabindex="-1" class="form-required">
                            <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                          </f7-link>
                        </template>

                        <template v-if="input.type === 'password'" #content-end>
                          <f7-link tabindex="-1" class="show-password" @click="togglePassword">
                            <font-awesome-icon :icon="['far', 'eye']" fixed-width />
                          </f7-link>
                        </template>

                        <template v-if="input.type === 'select'">
                          <template v-for="item in input?.items" :key="'sl_' + item[input?.valueSelector || 'value']">
                            <option
                              v-if="item[input?.valueSelector || 'value'] == '' || !input?.filter || (input?.filter && formData[input?.filter] == '') || (input?.filter && formData[input?.filter] == item[input?.filter])"
                              :value="item[input?.valueSelector || 'value']"
                            >
                              {{ item[input?.nameSelector || "name"] }}
                            </option>
                          </template>
                        </template>

                        <template v-if="input.type === 'select' && input?.showHelper === true" #root-end>
                          <template v-for="item in input?.items" :key="'sl_' + item[input?.valueSelector || 'value']">
                            <transition name="slide-fade">
                              <div v-if="item[input?.valueSelector || 'value'] === formData[input?.value]" class="select-sub-info-container">
                                <div v-html="item?.helper?.description"></div>

                                <template v-if="item?.helper?.location">
                                  <h3>{{ $t.getTranslation("LBL_LOCATION") }}</h3>
                                  <img :src="require('@/assets/images/banner/' + item?.helper?.location)" alt="Location Image" />
                                </template>

                                <template v-if="item?.helper?.sample">
                                  <h3>{{ $t.getTranslation("LBL_SAMPLE") }}</h3>
                                  <img :src="require('@/assets/images/banner/' + item?.helper?.sample)" alt="Sample Image" />
                                </template>
                              </div>
                            </transition>
                          </template>
                        </template>
                      </f7-list-input>
                    </template>
                  </template>
                </template>
              </div>
            </transition>
          </template>
        </div>
      </f7-list>

      <div class="button-fixed-container">
        <f7-button fill large raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing" @click="processSave">
          {{ buttonText }}
        </f7-button>
      </div>

      <SelectMultiplePopupComponent v-if="hasSelectMultiple" ref="selectMultiplePopupComponent" :backdrop="true" @selected="onMultipleSelected" />
      <SetPricePopupComponent v-if="hasSetPrice" ref="setPricePopupComponent" :backdrop="true" @set="onPriceSet" />
      <SetTranslationPopupComponent v-if="hasSetTranslation" ref="setTranslationPopupComponent" :backdrop="true" @set="onTranslationSet" />
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";

import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";

import { useStore } from "@/store";
const store = useStore();

import { validations } from "@/utils/validations";

import ImageUploadPopUpComponent2 from "@/components/ImageUploadPopUpComponent2.vue";
import VideoUploadPopUpComponent2 from "@/components/VideoUploadPopUpComponent2.vue";
import SelectMultiplePopupComponent from "@/components/SelectMultiplePopupComponent.vue";
import SetPricePopupComponent from "@/components/SetPricePopupComponent.vue";
import SetTranslationPopupComponent from "@/components/SetTranslationPopupComponent.vue";

export default defineComponent({
  name: "FormComponent",
  components: {
    ImageUploadPopUpComponent2,
    VideoUploadPopUpComponent2,
    SelectMultiplePopupComponent,
    SetPricePopupComponent,
    SetTranslationPopupComponent,
  },
  props: {},
  emits: ["successSave"],
  setup(props, { emit }) {
    const isUpdate = ref(false);
    const isButtonProcessing = ref(false);
    const isFormUpload = ref(false);

    const hasSelectMultiple = ref(false);
    const hasSetPrice = ref(false);
    const hasSetTranslation = ref(false);

    const formData = reactive({});
    const inputList = computed(() => store.getters["form/getInputList"]);

    const buttonText = ref("Save");

    let formComponentData = {};
    let validationRuleList = {};
    let validationRules = {};
    let inputListValueMap = {};

    const validate = (showNotifications) => {
      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const togglePassword = (event) => {
      let passwordAttribute = Dom7(event.path[3]).find("input").attr("type");
      Dom7(event.path[3])
        .find("input")
        .attr("type", passwordAttribute === "text" ? "password" : "text");
    };

    const onInputBlur = (event, input) => {
      if (input?.transformValue) {
        switch (input?.transformValue) {
          case "CODE":
            if (formData?.[input?.value] != "") {
              formData[input.value] = formData?.[input?.value].replace(/ /g, "_").toUpperCase();
            }
            break;
        }
      }
    };

    const onMultipleSelected = (data) => {
      if (data?.type && data?.list) {
        formData[data?.type + "List"] = data?.list;
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (inputGroup?.type === "selectmultiple" && inputGroup?.value === data?.type) {
          if (inputGroup?.onChange) inputGroup?.onChange(data?.list, formData);
        }
      });
    };

    const onPriceSet = (data) => {
      if (data?.type && data?.data) {
        let isFound = false;
        formData[data?.type + "List"].map((item, index) => {
          if (parseInt(item?.CurrencyId) === parseInt(data?.data?.CurrencyId)) {
            formData[data?.type + "List"][index] = { ...item, ...data.data };
            isFound = true;
          }
        });

        if (!isFound) formData[data?.type + "List"].push(data?.data);
      }
    };

    const onTranslationSet = (data) => {
      if (data?.type && data?.data) {
        let isFound = false;
        formData[data?.type + "List"].map((item, index) => {
          if (parseInt(item?.LanguageId) === parseInt(data?.data?.LanguageId)) {
            formData[data?.type + "List"][index] = { ...item, ...data.data };
            isFound = true;
          }
        });

        if (!isFound) formData[data?.type + "List"].push(data?.data);
      }
    };

    const onImageSelected = (data) => {
      if (data?.type && data?.blob) {
        formData[data?.type + "List"].push({ blob: data?.blob, IsDefault: formData[data?.type + "List"].length === 0 ? 1 : 0 });
      }

      if (data?.type && data?.file) {
        formData[data?.type + "List"].push({ file: data?.file, image: data?.image, IsDefault: formData[data?.type + "List"].length === 0 ? 1 : 0 });
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (inputGroup?.type === "image" && inputGroup?.value === data?.type) {
          if (inputGroup?.limit && formData[data?.type + "List"].length > inputGroup?.limit) {
            formData[data?.type + "List"].shift();
          }
        }
      });
    };

    const onVideoSelected = (data) => {
      if (data?.type && data?.file) {
        formData[data?.type + "List"].push({ file: data?.file, IsDefault: 1 });
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (inputGroup?.type === "video" && inputGroup?.value === data?.type) {
          if (inputGroup?.limit && formData[data?.type + "List"].length > inputGroup?.limit) {
            formData[data?.type + "List"].shift();
          }
        }
      });
    };

    const toggleDefaultImage = (type, index) => {
      formData[type + "List"].forEach((item, itemIndex) => {
        if (index !== itemIndex) item.IsDefault = 0;
      });

      if (formData[type + "List"][index]?.IsDefault) {
        return (formData[type + "List"][index].IsDefault = 0);
      } else {
        return (formData[type + "List"][index].IsDefault = 1);
      }
    };

    const deleteImage = (type, index, ImageId, isDefault) => {
      if (ImageId) {
        formData["Delete" + type + "List"].push({ ImageId });
      }

      formData[type + "List"].splice(index, 1);

      if (isDefault === 1 && formData[type + "List"].length > 0) {
        formData[type + "List"][0].IsDefault = 1;
      }
    };

    const deleteVideo = (type, index, ImageId) => {
      if (ImageId) {
        formData["Delete" + type + "List"].push({ ImageId });
      }

      formData[type + "List"].splice(index, 1);
    };

    const setFormComponentData = async (data, processUpdate = false) => {
      formComponentData = data;
      helpers.deleteFormData(formData);

      let initialFormData = {};
      let requiredHolder = {};

      // DYNAMIC IMPORT OF VALIDATION RULES BASED ON VALIDATION
      if (formComponentData?.validation) {
        validationRuleList = await get("/public/validation/list", { Type: formComponentData?.validation?.toLowerCase() });
      }

      formComponentData.inputList.forEach((inputGroup) => {
        if (["image", "video"].indexOf(inputGroup?.type) > -1 && inputGroup?.value !== "") {
          let temporaryData = {};
          temporaryData[inputGroup?.value + "List"] = [];
          temporaryData["Delete" + inputGroup?.value + "List"] = [];

          initialFormData = { ...initialFormData, ...temporaryData };
          requiredHolder[inputGroup?.value + "List"] = {
            required: inputGroup?.required,
          };

          isFormUpload.value = true;
        }

        if (["price", "translation", "selectmultiple"].indexOf(inputGroup?.type) > -1 && inputGroup?.value !== "") {
          let temporaryData = {};
          let inputName = inputGroup?.value + "List";

          inputListValueMap[inputName] = inputGroup?.params?.valueSelector;
          temporaryData[inputName] = [];

          initialFormData = { ...initialFormData, ...temporaryData };
          requiredHolder[inputName] = {
            required: inputGroup?.required,
          };

          if (inputGroup?.type === "selectmultiple") hasSelectMultiple.value = true;
          if (inputGroup?.type === "price") hasSetPrice.value = true;
          if (inputGroup?.type === "translation") hasSetTranslation.value = true;
        }

        if (inputGroup?.type === "input" && inputGroup?.list.length > 0) {
          inputGroup?.list.forEach((inputItem) => {
            let temporaryData = {};
            if (inputItem?.type === "tags") {
              let inputName = inputItem?.value + "List";
              temporaryData[inputItem?.value] = "";
              temporaryData[inputName] = inputItem?.defaultValue ? inputItem?.defaultValue : [];
            } else {
              temporaryData[inputItem?.value] = inputItem?.defaultValue || inputItem?.defaultValue === 0 ? inputItem?.defaultValue : "";
            }

            initialFormData = { ...initialFormData, ...temporaryData };
            requiredHolder[inputItem?.value] = {
              required: inputItem?.required,
            };
          });
        }

        inputGroup.isVisible = true;
      });

      Object.assign(formData, { ...initialFormData });

      for (let keys in formData) {
        let requiredDeclaration = requiredHolder[keys];
        let temporaryValidationRule = { ...requiredDeclaration };

        if (validationRuleList && validationRuleList[keys]) {
          let validationRuleDeclaration = validationRuleList[keys];
          temporaryValidationRule = { ...temporaryValidationRule, ...validationRuleDeclaration };
        }

        validationRules[keys] = temporaryValidationRule;
      }

      helpers.resetForm(".formContainer");

      if (processUpdate) {
        isUpdate.value = true;
        if (formComponentData?.getLink) getData();
      } else {
        isUpdate.value = false;
      }

      if (data?.buttonText) buttonText.value = data?.buttonText;
      store.dispatch("form/setInputList", formComponentData.inputList);

      // INITIATE DELAYED PROCEDURES
      window.setTimeout(() => {
        helpers.createTimePicker(".timepicker input", (pickerElement, values) => {
          if (values?.length) formData[pickerElement?.inputEl?.attributes?.name?.value] = values.join(":");
        });

        helpers.createDatePicker(".datepicker input", (pickerElement, values) => {
          if (values?.length >= 3) formData[pickerElement?.inputEl?.attributes?.name?.value] = `${values?.[0]} ${values?.[1]}, ${values?.[2]}`;
        });

        helpers.createDateTimePicker(".datetimepicker input", (pickerElement, values) => {
          if (values?.length) formData[pickerElement?.inputEl?.attributes?.name?.value] = helpers.formatJSDateTime(values[0]);
        });
      }, 800);
    };

    const updateFormComponentData = async (value, data) => {
      for (let inputGroupCounter = 0; inputGroupCounter < inputList.value.length; inputGroupCounter++) {
        let inputGroup = inputList.value[inputGroupCounter];
        if (inputGroup.value === value) {
          inputList.value[inputGroupCounter] = { ...inputGroup, ...data };
        } else {
          if (inputGroup.type === "input") {
            for (let inputItemCounter = 0; inputItemCounter < inputGroup.list.length; inputItemCounter++) {
              let inputItem = inputGroup.list[inputItemCounter];
              if (inputItem.value === value) {
                inputGroup.list[inputItemCounter] = { ...inputItem, ...data };
              }
            }
          }
        }
      }
    };

    const getData = async () => {
      let getDataReturn = await get(formComponentData?.getLink, formComponentData?.getParams);

      if (getDataReturn) {
        for (let formKey in formData) {
          if (getDataReturn && getDataReturn[formKey]) {
            if (formKey.indexOf("Date") > -1 && !helpers.isBlank(getDataReturn[formKey])) {
              formData[formKey] = helpers.formatJSDateTime(getDataReturn[formKey]);
            } else {
              formData[formKey] = getDataReturn[formKey];
            }
          }
        }
      }
    };

    const onSelectMultipleSort = async ({ from, to }, selector) => {
      formData[selector].splice(to, 0, formData[selector].splice(from, 1)[0]);
    };

    // TAGS ACTIONS
    const onAddTag = (type, unique = false) => {
      if (type && formData[type + "List"] && formData[type] != "") {
        if (unique && formData[type + "List"].indexOf(formData[type]) >= 0) {
          return false;
        }

        formData[type + "List"].push(formData[type]);
        formData[type] = "";
      }
    };

    const onDeleteTag = (type, item, index) => {
      formData[type + "List"].splice(index, 1);
    };

    const processSave = async () => {
      let isValid = validate(true);
      let isCustomValidation = true;

      if (formComponentData?.saveValidation && typeof formComponentData?.saveValidation === "function") {
        isCustomValidation = false;
        isCustomValidation = formComponentData?.saveValidation(formData);
      }

      if (isValid && isCustomValidation) {
        isButtonProcessing.value = true;
        const saveFormData = new FormData();
        const processFormData = {};

        if (!helpers.isEmpty(formComponentData?.saveParams)) {
          for (let itemKey in formComponentData?.saveParams) {
            formData[itemKey] = formComponentData?.saveParams[itemKey];
          }
        }

        // PROCESS FORM DATA
        for (let formItem in formData) {
          if (formItem.indexOf("Date") > -1) {
            processFormData[formItem] = helpers.formatSQLDateTime(formData[formItem]);
          } else if (formItem.indexOf("Price") > -1) {
            let tmpData = [];

            formData[formItem].forEach((item) => {
              let tmpObject = {
                CurrencyId: item.CurrencyId,
                StatusCode: item.StatusCode,
              };

              for (let key in item) {
                if (key.indexOf("Price") > -1) tmpObject[key] = item[key];
              }

              tmpData.push(tmpObject);
            });

            processFormData[formItem] = tmpData;
          } else if (formItem.indexOf("Translation") > -1) {
            processFormData[formItem] = formData[formItem];
          } else if (formItem.indexOf("Image") > -1 && formItem.indexOf("Delete") < 0) {
            processFormData[formItem] = formData[formItem];
          } else if (formItem.indexOf("Video") > -1 && formItem.indexOf("Delete") < 0) {
            processFormData[formItem] = formData[formItem];
          } else if (formItem.indexOf("Delete") > -1) {
            processFormData[formItem] = formData[formItem];
          } else if (formItem.indexOf("List") > -1) {
            if (inputListValueMap[formItem]) {
              let tmpData = [];

              formData[formItem].forEach((item, index) => {
                let tmpObject = {};

                tmpObject[inputListValueMap[formItem]] = item[inputListValueMap[formItem]];
                tmpObject["Position"] = index + 1;

                tmpData.push(tmpObject);
              });

              processFormData[formItem] = tmpData;
            }
          } else {
            processFormData[formItem] = formData[formItem];
          }
        }

        if (isFormUpload.value === true) {
          for (let formItem in processFormData) {
            if ((formItem.indexOf("Image") > -1 || formItem.indexOf("Video") > -1) && formItem.indexOf("Delete") < 0 && formItem.indexOf("Id") < 0) {
              let tmpData = [];

              processFormData[formItem].forEach((item, index) => {
                if (item?.blob) {
                  saveFormData.append(`${formItem.replace("List", "")}-${index}${item.IsDefault === 1 ? "-DEFAULT" : ""}`, item?.blob);
                } else if (item?.file) {
                  saveFormData.append(`${formItem.replace("List", "")}-${index}${item.IsDefault === 1 ? "-DEFAULT" : ""}`, item?.file);
                } else {
                  tmpData.push(item);
                }
              });

              saveFormData.append(formItem, JSON.stringify(tmpData));
            } else if (formItem.indexOf("List") > -1) {
              saveFormData.append(formItem, JSON.stringify(processFormData[formItem]));
            } else {
              saveFormData.append(formItem, processFormData[formItem]);
            }
          }
        }

        let formSaveReturn = await post(formComponentData?.saveLink, isFormUpload.value === true ? saveFormData : processFormData);
        isButtonProcessing.value = false;

        if (formSaveReturn) {
          helpers.createNotification({
            type: "info",
            title: "INFO",
            message: isUpdate.value === true ? formComponentData?.successUpdateMessage : formComponentData?.successCreateMessage,
          });

          emit("successSave", { isSave: true });
        }
      }
    };

    return {
      inputList,
      hasSelectMultiple,
      hasSetPrice,
      hasSetTranslation,
      isButtonProcessing,
      buttonText,
      togglePassword,
      onInputBlur,
      onMultipleSelected,
      onImageSelected,
      onVideoSelected,
      onPriceSet,
      onTranslationSet,
      onAddTag,
      onDeleteTag,
      toggleDefaultImage,
      deleteImage,
      deleteVideo,
      formData,
      validationRules,
      setFormComponentData,
      updateFormComponentData,
      getData,
      onSelectMultipleSort,
      processSave,
    };
  },
  methods: {
    onSelectImage(type) {
      const self = this;

      if (type) {
        self.inputList.forEach((inputGroup) => {
          if (inputGroup?.type === "image" && inputGroup?.value === type) {
            if (inputGroup?.limit && self.formData[type + "List"].length >= inputGroup?.limit) {
              throw new Error("Error! Exceed maximum limit of upload. Delete an image first before selecting a new image.");
            }
          }
        });
      }

      self.$refs.imageUploadPopUpComponent.openPopup(type);
    },
    onSelectVideo(type) {
      const self = this;

      if (type) {
        self.inputList.forEach((inputGroup) => {
          if (inputGroup?.type === "video" && inputGroup?.value === type) {
            if (inputGroup?.limit && self.formData[type + "List"].length >= inputGroup?.limit) {
              throw new Error("Error! Exceed maximum limit of upload. Delete a vide first before selecting a new video.");
            }
          }
        });
      }

      self.$refs.videoUploadPopUpComponent.openPopup(type);
    },
    onSelectItem(type) {
      this.$refs.selectItemPopupComponent.openPopup(type);
    },
    onSelectMultiple(inputGroup) {
      if (this.hasSelectMultiple) this.$refs.selectMultiplePopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + "List"]);
    },
    onSetPrice(inputGroup) {
      if (this.hasSetPrice) this.$refs.setPricePopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + "List"]);
    },
    onEditPrice(inputGroup, item, index) {
      if (this.hasSetPrice) this.$refs.setPricePopupComponent.openPopup(inputGroup, item);
    },
    onDeletePrice(inputGroup, item, index) {
      this.formData[inputGroup?.value + "List"].splice(index, 1);
    },
    onSetTranslation(inputGroup) {
      if (this.hasSetTranslation) this.$refs.setTranslationPopupComponent.openPopup(inputGroup, this.formData[inputGroup?.value + "List"]);
    },
    onEditTranslation(inputGroup, item, index) {
      if (this.hasSetTranslation) this.$refs.setTranslationPopupComponent.openPopup(inputGroup, item);
    },
    onDeleteTranslation(inputGroup, item, index) {
      this.formData[inputGroup?.value + "List"].splice(index, 1);
    },
  },
});
</script>
