<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'shopping-cart']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation('LBL_ORDER_CUSTOMS_LIST') }}</h1>
        </div>
      </div>
    </div>

    <DataTableComponent2
      ref="dataTableComponent"
      :class="{ 'has-board': orderCommissionInfo }"
      :columns="columns"
      :configs="tableConfig"
      :actions="actions"
      :url="url"
      :size="15"
      :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')"
      @performAction="performAction"
    ></DataTableComponent2>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref, onMounted } from 'vue'
import { Dom7 } from 'framework7'

import MainHeaderComponent from '@/components/MainHeaderComponent.vue'
import MainFooterComponent from '@/components/MainFooterComponent.vue'
import DataTableComponent2 from '@/components/DataTableComponent2.vue'

import { helpers } from '@/utils/helpers'

export default defineComponent({
  name: 'OrderListPage',
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject('$translation')

    const orderCommissionInfo = ref(null)
    const showBatchReleased = ref(false)
    const popupOpened = ref(false)

    const actions = [
      {
        groupText: 'Perform Actions',
        list: [{ text: 'View', type: 'VIEW', bold: true }]
      }
    ]

    const tableConfig = {
      groupBy: 'ParentOrderNumber',
      groupToggleElement: 'header'
    }

    const columns = [
      {
        title: $t.getTranslation('LBL_ORDER_INFO'),
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().OrderNumber || '--'}</strong></div>
              <div><span>${$t.getTranslation('LBL_COURIER')}:</span> ${cell.getData().CourierTrackingNo || ''}</div>
              <div class="chip ${helpers.getStatusColor(cell.getData()?.OrderStatusCode)} ${cell.getData()?.OrderStatusCode}">
                <div class="chip-label">${cell.getData()?.OrderStatusCode || '--'}</div>
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_CUSTOMS_STATUS'),
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.IsCustomsSubmitted == 1 ? 'color-green' : 'color-gray'}">
                <div class="chip-label">${$t.getTranslation('LBL_CUSTOMS_PUSH')}:${cell.getData()?.IsCustomsSubmitted == 1 ? 'Yes' : 'No'}</div>
              </div>
            </div>
          `
        }
      },
      {
        title: $t.getTranslation('LBL_DATES'),
        formatter(cell) {
          return `
            <div>
              <div title="${$t.getTranslation('LBL_DATE_CREATED')}"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="${$t.getTranslation('LBL_DATE_CUSTOMS_PUSH')}"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
            </div>
          `
        }
      }
    ]

    const url = ref('/admin/order/datalist?CustomsPush=1')

    const getCheckList = () => {
      let checkList = []

      Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
        let isChecked = Dom7(item).prop('checked')
        if (isChecked) {
          checkList.push(parseInt(Dom7(item).value()))
        }
      })

      return checkList
    }

    onMounted(() => {
      Dom7('body').on('change', '.main-checkbox', (e) => {
        let isChecked = Dom7('.main-checkbox').prop('checked')
        if (isChecked) {
          Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
            Dom7(item).prop('checked', true)
          })
        } else {
          Dom7(".checkbox [type='checkbox'].check-item").each((item) => {
            Dom7(item).prop('checked', false)
          })
        }
      })

      Dom7('body').on('click', '.tabulator-page', (e) => {
        Dom7('.main-checkbox').prop('checked', false)
      })
    })

    return {
      url,
      columns,
      actions,
      tableConfig
    }
  },
  methods: {
    async performAction(type, data) {
      const self = this

      switch (type) {
        case 'VIEW':
          if (data?.value?.OrderKey) {
            self.f7router.navigate({ name: 'orderCustomPush', query: { key: data.value.OrderKey } })
          }
          break
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator()
      }
    }
  }
})
</script>
