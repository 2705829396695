import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'product',
    path: '/product/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'wine-bottle',
        title: translation.getTranslation('LBL_PRODUCT'),
        activeLink: 'product'
      },
      isSecured: true,
      hasRoles: ['MANAGE_PRODUCT']
    },
    redirect: '/product/list',
    routes: [
      {
        name: 'productList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PRODUCT_LIST'),
            activeLink: 'productList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductListPage.vue')
      },
      {
        name: 'productSpecialPriceList',
        path: 'list/special/price/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_SPECIAL_PRICE'),
            activeLink: 'productSpecialPriceList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductSpecialPriceList.vue')
      },
      {
        name: 'productReviewList',
        path: 'review/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PRODUCT_REVIEW_LIST'),
            activeLink: 'productReviewList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT_REVIEW']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductReviewListPage.vue')
      },
      {
        name: 'productInventory',
        path: 'list/inventory/location',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PRODUCT_INVENTORY_LOCATION'),
            activeLink: 'productInventory'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductInventoryLocationListPage.vue')
      },
      {
        name: 'productInventoryView',
        path: 'view/inventory/location/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productInventory'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductInventoryLocationViewPage.vue')
      },
      {
        name: 'productListFeatured',
        path: 'list/featured',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PRODUCT_FEATURED_LIST'),
            activeLink: 'productListFeatured'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductFeaturedListPage.vue')
      },
      {
        name: 'productRecommended',
        path: 'recommended/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_PRODUCT_WEEKLY_RECOMMENDED'),
            activeLink: 'productRecommended'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductListRecommendPage.vue')
      },
      {
        name: 'productRecommendedSave',
        path: 'recommend/save/:key',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productRecommended'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductSaveRecommendPage.vue')
      },
      {
        name: 'productView',
        path: 'view/:key',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductDetailsPage.vue')
      },
      {
        name: 'prodInventoryView',
        path: 'inventory/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/InventorySavePage.vue')
      },
      {
        name: 'productInventory',
        path: 'inventory/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/InventorySavePage.vue')
      },
      {
        name: 'productTaxView',
        path: 'tax/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductTaxSavePage.vue')
      },
      {
        name: 'productCriticScore',
        path: 'critic/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductCriticSavePage.vue')
      },
      {
        name: 'productMarketing',
        path: 'marketing/',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductMarketingPage.vue')
      },
      {
        name: 'futureWine',
        path: 'future/wine/list/',
        meta: {
          navigation: {
            isShown: true,
            activeLink: 'futureWine',
            title: translation.getTranslation('LBL_FUTURE_WINES')
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductFutureWineListPage.vue')
      },
      {
        name: 'productSaleList',
        path: 'sale/list/',
        meta: {
          navigation: {
            isShown: true,
            activeLink: 'productSaleList',
            title: translation.getTranslation('LBL_FLASH_SALE')
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductSaleListPage.vue')
      },
      {
        name: 'productSaleSave',
        path: 'sale/save',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'productSaleList',
            title: translation.getTranslation('LBL_FLASH_SALE_SAVE')
          },
          isSecured: true,
          hasRoles: ['MANAGE_PRODUCT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/product/ProductSaleSavePage.vue')
      }
    ]
  }
]

export default routes
