<template>
  <f7-popup id="userViewPopup" class="popup-side popup-view" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="true">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="$t.getTranslation('LBL_USER_INFO')" @close-popup="closePopup" />
      </template>

      <div class="container">
        <ViewItemComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_USER_INFORMATION')" :data="viewInfo" :fields="userFields" fullscreen-width="third" />
        <ViewItemComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_USER_COUNT_INFORMATION')" :data="viewInfo?.TotalCountInfo" :fields="userCountFields" fullscreen-width="third">
          <template #footer>
            <f7-button fill large popup-close @click="showGroupCommission">{{ $t.getTranslation("LBL_RELEASE_GROUP_COMMISSION") }}</f7-button>
          </template>
        </ViewItemComponent2>
        <ViewItemComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_REFERRER_USER_INFORMATION')" :data="viewInfo?.ReferrerInfo" :fields="referrerFields" fullscreen-width="third" />

        <ViewTableComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_USER_REFERRAL_LIST')" :data="viewInfo?.ReferralList" :fields="userTableFields" fullscreen-width="full" @performAction="performUserAction">
          <template #footer>
            <f7-button :href="'/user/list/?referrer=' + viewInfo?.UserKey" fill large popup-close>{{ $t.getTranslation("LBL_VIEW_ALL") }}</f7-button>
          </template>
        </ViewTableComponent2>
        <ViewTableComponent2 v-if="viewInfo" :title="$t.getTranslation('LBL_REFERRED_ORDER_LIST')" :data="viewInfo?.ReferredOrderList" :fields="orderTableFields" fullscreen-width="full" @performAction="performOrderAction">
          <template #footer>
            <f7-button :href="'/order/list/?referrer=' + viewInfo?.UserKey" fill large @click="closeAllPopup">{{ $t.getTranslation("LBL_VIEW_ALL") }}</f7-button>
          </template>
        </ViewTableComponent2>
      </div>

      <ReleaseGroupCommissionPartial ref="releaseGroupCommissionPartial" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { ref, defineComponent, inject } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";

import { get } from "@/utils/axios";

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import ViewItemComponent2 from "@/components/ViewItemComponent2.vue";
import ViewTableComponent2 from "@/components/ViewTableComponent2.vue";

import ReleaseGroupCommissionPartial from "@/partials/user/ReleaseGroupCommissionPartial.vue";

export default defineComponent({
  name: "UserViewPartial",
  components: {
    NavigationPopupSideComponent,
    ViewItemComponent2,
    ViewTableComponent2,
    ReleaseGroupCommissionPartial,
  },
  props: { f7router: Object },
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    let isFetching = false;

    const UserId = ref(false);
    const viewInfo = ref(false);

    const userFields = ref([
      { field: "UserId", name: "auto", format: "number" },
      { field: "UserKey", name: "auto", format: "string" },
      { field: "OpenId", name: "auto", format: "string" },
      { field: "Email", name: "auto", format: "string" },
      { field: "UserName", name: "auto", format: "string" },
      { field: "MobileCode", name: "auto", format: "string" },
      { field: "MobileNumber", name: "auto", format: "string" },
      { field: "FirstName", name: "auto", format: "string" },
      { field: "LastName", name: "auto", format: "string" },
      { field: "Gender", name: "auto", format: "string" },
      { field: "BirthDate", name: "auto", format: "datetime" },
      { field: "LanguageCode", name: "auto", format: "string" },
      { field: "RegistrationType", name: "auto", format: "string" },
      { field: "ReferralCode", name: "auto", format: "string" },
      { field: "UserGroupName", name: "auto", format: "string" },
      { field: "LinkCode", name: "auto", format: "string" },
      { field: "UserStatusCode", name: "auto", format: "status" },
      { field: "LastOnline", name: "auto", format: "datetime" },
      { field: "LastCreated", name: "auto", format: "datetime" },
      { field: "LastModified", name: "auto", format: "datetime" },
    ]);

    const userCountFields = ref([
      { field: "TotalReferredUsers", name: "auto", format: "decimal" },
      { field: "TotalReferredOrders", name: "auto", format: "decimal" },
      {
        field: "PendingGroupCommission",
        name: "auto",
        format: "currency",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrer=${viewInfo.value.UserKey}&commission_status=pending`);
        },
      },
      {
        field: "AvailableGroupCommission",
        name: "auto",
        format: "currency",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrer=${viewInfo.value.UserKey}&commission_status=available`);
        },
      },
      {
        field: "RedeemedGroupCommission",
        name: "auto",
        format: "currency",
        onClick: () => {
          closeAllPopup();
          props.f7router.navigate(`/order/list/?referrer=${viewInfo.value.UserKey}&commission_status=redeemed`);
        },
      },
      { field: "PendingKOLCommission", name: "auto", format: "currency" },
      { field: "AvailableKOLCommission", name: "auto", format: "currency" },
    ]);

    const referrerFields = ref([
      { field: "UserId", name: "auto", format: "number" },
      { field: "UserKey", name: "auto", format: "string" },
      { field: "OpenId", name: "auto", format: "string" },
      { field: "Email", name: "auto", format: "string" },
      { field: "UserName", name: "auto", format: "string" },
      { field: "MobileCode", name: "auto", format: "string" },
      { field: "MobileNumber", name: "auto", format: "string" },
      { field: "FirstName", name: "auto", format: "string" },
      { field: "LastName", name: "auto", format: "string" },
      { field: "ReferralCode", name: "auto", format: "string" },
      { field: "UserGroupName", name: "auto", format: "string" },
    ]);

    const userTableFields = ref([
      {
        field: "Options",
        name: "",
        format: "buttons",
        list: [{ type: "VIEW", text: $t.getTranslation("LBL_VIEW") }],
      },
      { field: "UserId", name: "auto", format: "string" },
      { field: "Title", name: "Name", format: "string" },
      { field: "Referrer", name: "Referrer", format: "string" },
      { field: "Description", name: "Info", format: "string" },
    ]);

    const orderTableFields = ref([
      {
        field: "Options",
        name: "",
        format: "buttons",
        list: [{ type: "VIEW", text: $t.getTranslation("LBL_VIEW") }],
      },
      { field: "OrderId", name: "auto", format: "string" },
      { field: "OrderNumber", name: "auto", format: "string" },
      { field: "GrandTotal", name: "auto", format: "currency" },
      { field: "CommissionEligibleAmount", name: "auto", format: "currency" },
      { field: "GroupCommission", name: "auto", format: "currency" },
      { field: "LastCreated", name: "Date Created", format: "datetime" },
    ]);

    const getInfo = async (UserId) => {
      if (isFetching) return;
      isFetching = true;

      let getResponse = await get("/admin/user/view", { UserId });
      viewInfo.value = getResponse;
      isFetching = false;
    };

    const closeAllPopup = () => {
      Dom7(".popup").forEach((element) => {
        f7.popup.close(element);
      });
    };

    return {
      getInfo,
      UserId,
      viewInfo,
      userFields,
      userCountFields,
      referrerFields,
      userTableFields,
      orderTableFields,
      closeAllPopup,
    };
  },
  methods: {
    performUserAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.UserId) {
            self.getInfo(data?.UserId);
          }
          break;
      }
    },
    performOrderAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.OrderKey) {
            self.closePopup();
            Dom7(".popup").forEach((element) => {
              f7.popup.close(element);
            });

            self.f7router.navigate({ name: "orderView", params: { id: data.OrderKey } });
          }
          break;
      }
    },
    showGroupCommission() {
      const self = this;

      self.$refs.releaseGroupCommissionPartial.openPopup(self.UserId);
    },
    openPopup(UserId) {
      this.UserId = UserId;
      this.getInfo(this.UserId);

      f7.popup.open("#userViewPopup");
      this.$emit("opened", true);
    },
    closePopup(data) {
      f7.popup.close("#userViewPopup");
      this.$emit("closed", data);
    },
  },
});
</script>

<style>
#userViewPopup .container {
  padding-top: 0px;
}
</style>
