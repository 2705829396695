<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'users']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_USER_CHAT_LOG_LIST") }}</h1>
          <h3>List of available chat logs by selected user.</h3>
        </div>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>

    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, inject, ref } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import { helpers } from "@/utils/helpers";

export default defineComponent({
  name: "UserChatLogListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const actions = [
      {
        groupText: "Perform Actions",
        list: [{ text: "View", type: "VIEW", bold: true }],
      },
    ];

    const columns = [
      {
        title: "Id",
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().ThreadId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_INFO"),
        minWidth: 220,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${helpers.getFullName(cell.getData(), true)}</strong></div>
              <div><strong>${cell.getData().UserName || ""}</strong></div>
              <div>${cell.getData().UserKey}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_THREAD_INFO"),
        minWidth: 220,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().ThreadKey || ""}</strong></div>
              <div>${cell.getData().IsDeleted ? "DELETED" : "ACTIVE"}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_TOTAL_MESSAGE"),
        minWidth: 200,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().TotalMessage}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_TOTAL_USER"),
        minWidth: 200,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().TotalUser}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_LAST_MESSAGE"),
        minWidth: 350,
        frozen: false,
        formatter(cell) {
          return `
            <div>
              <div>${cell.getData().LastMessage}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        minWidth: 200,
        formatter(cell) {
          return `
            <div>
              <div title="Date Created"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="Date Updated"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    const url = ref("/admin/userchatlog/datalist");

    if (props?.f7route?.query?.UserId && props?.f7route?.query?.UserId != "") {
      url.value += `?UserId=${props?.f7route?.query?.UserId}`;
    }

    return {
      url,
      columns,
      actions,
    };
  },
  mounted() {
    this.UserId = this.f7route?.query?.UserId;
  },
  methods: {
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "VIEW":
          if (data?.value?.ThreadKey) {
            self.f7router.navigate({ name: "messages", query: { ThreadKey: data?.value?.ThreadKey } });
          }
          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
