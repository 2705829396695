import { createApp } from 'vue'
import { registerComponents } from '@/f7components.js'

// FONT AWESOME ICONS
// import '@fortawesome/fontawesome-pro/css/all.min.css'
// import '@fortawesome/fontawesome-pro/js/all.min.js'

// CUSTOM STYLING
import '@/assets/css/index.css'

//CUSTOM FONT
import '@/assets/fonts/AvenirNextLTPro/stylesheet.css'

import utils from '@/utils'
import App from '@/App.vue'

const mainApp = createApp(App)
registerComponents(mainApp)
utils(mainApp)

// VUEX Store
import store from '@/store/index.js'
mainApp.use(store)

// REGISTER FONT AWESOME COMPONENTS
import { FontAwesomeIcon } from '@/fontawesome.js'
mainApp.component('FontAwesomeIcon', FontAwesomeIcon)

// MOUNT mainApp
mainApp.mount('#app')
