<template>
  <f7-navbar>
    <f7-nav-title> {{ title }} </f7-nav-title>
    <f7-nav-right>
      <f7-link @click="toggleFullScreen">
        <font-awesome-icon v-if="!isFullScreen" :icon="['far', 'expand']" fixed-width />
        <font-awesome-icon v-if="isFullScreen" :icon="['far', 'compress']" fixed-width />
      </f7-link>

      <f7-link @click="closePopup">
        <font-awesome-icon :icon="['far', 'times']" fixed-width />
      </f7-link>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Dom7 } from "framework7";

import Masonry from "masonry-layout";

export default defineComponent({
  name: "NavigationPopupSideComponent",
  components: {},
  props: {
    title: { type: String },
    type: { type: String },
  },
  emits: ["close-popup", "toggle-fullscreen"],
  setup(props, { emit }) {
    const isFullScreen = ref(false);

    let formMasonry = false;

    const closePopup = () => {
      emit("close-popup");
    };

    const toggleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value;

      if (Dom7(".popup.popup-side.modal-in").length > 0) {
        if (isFullScreen.value === true) {
          Dom7(".popup.popup-side.modal-in").addClass("popup-fullscreen");
        } else {
          Dom7(".popup.popup-side.modal-in").removeClass("popup-fullscreen");
        }
      }

      window.setTimeout(initFormMasonry, 300);
      emit("toggle-fullscreen");
    };

    const initFormMasonry = () => {
      let masonryElement = "",
        itemSelector = "",
        columnWidth = "";

      if (Dom7(".popup-save.modal-in .formContainer").length > 0) {
        masonryElement = document.querySelector(".popup-save.modal-in .formContainer");
        itemSelector = ".form-group-container";
        columnWidth = ".form-group-container";
      }

      if (Dom7(".popup-view.modal-in").length > 0) {
        masonryElement = document.querySelector(".popup-view.modal-in .container");
        itemSelector = ".view-info-container";
        columnWidth = ".view-info-container";
      }

      if (masonryElement != "") {
        formMasonry = new Masonry(masonryElement, {
          itemSelector: itemSelector,
          columnWidth: columnWidth,
          percentPosition: true,
          gutter: 30,
        });
      }
    };

    return {
      isFullScreen,
      closePopup,
      toggleFullScreen,
      initFormMasonry,
    };
  },
});
</script>
