<template>
  <div class="view-info-container" :class="fullscreenWidth">
    <div class="view-info">
      <div class="title">
        <h2>{{ title }}</h2>
        <f7-toggle v-model:checked="isVisible" class="toggle-visibility"></f7-toggle>
      </div>

      <transition name="slide-fade">
        <div v-if="isVisible" class="view-item">
          <template v-if="content && content != ''">
            <div class="content" v-html="content"></div>
          </template>
          <template v-if="link && link != ''">
            <div class="view-code-link">
              <div class="codelink">{{ link }}</div>
              <f7-button fill large @click="$h.copyToClipBoard(link)">{{ $t.getTranslation("LBL_COPY_LINK") }}</f7-button>
            </div>
          </template>
          <template v-else>
            <div v-for="field in fields" :key="'vk_' + field.field" class="field" @click="field && field.onClick ? field?.onClick() : ''">
              <h3>{{ field.name === "auto" ? $h.formatPascalCase(field.field) : field.name }}</h3>
              <div v-if="field.format === 'image'" class="image-container">
                <img :src="$h.formatField(field, data)" :alt="field.field + '_Image'" @click="$h.openImage($h.formatField(field, data))" />
              </div>

              <p v-else>{{ $h.formatField(field, data) }}</p>
            </div>
          </template>
          <slot name="footer"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ViewItemComponent2",
  props: {
    title: String,
    fields: Array,
    data: Object,
    fullscreenWidth: String,
    content: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    let isVisible = ref(true);

    return {
      isVisible,
    };
  },
});
</script>
