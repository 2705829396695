<template>
  <!-- Main Framework7 App component where we pass Framework7 params -->
  <f7-app v-bind="f7params">
    <!-- Main view-->
    <LeftPanelComponent v-if="!hideLeftPanel" />
    <f7-view id="main-view" main url="/" :init="false"></f7-view>
    <RightPanelComponent v-if="!hideRightPanel" />
  </f7-app>
</template>

<script>
import routes from "@/routes";
import { useStore } from "@/store";

import { defineComponent, onMounted, onErrorCaptured, computed, provide } from "vue";

import { Dom7 } from "framework7";
import { f7ready } from "framework7-vue";

import { helpers } from "@/utils/helpers.js";
import { configs } from "@/utils/configs.js";
import { translation } from "@/utils/translation.js";
import { socket } from "@/utils/socket.js"; //import only to connect automatically

import LeftPanelComponent from "@/components/LeftPanelComponent.vue";
import RightPanelComponent from "@/components/RightPanelComponent.vue";

export default defineComponent({
  name: "MainApp",
  components: {
    LeftPanelComponent,
    RightPanelComponent,
  },
  setup() {
    provide("$translation", translation);

    const store = useStore();

    const f7params = {
      routes,
      view: {
        browserHistory: true,
        browserHistorySeparator: "",
        transition: "f7-cover",
        preloadPreviousPage: false,
      },
      root: "#main-view",
      name: configs.title,
      id: configs.appId || "app.unique.id",
      language: "EN",
      theme: "md",
      dialog: {
        title: configs.title,
      },
      notification: {
        title: configs.title,
        closeTimeout: 3000,
      },
    };

    const hideLeftPanel = computed(() => store.getters["page/getActiveRoute"]?.meta?.hideLeftPanel);
    const hideRightPanel = computed(() => store.getters["page/getActiveRoute"]?.meta?.hideRightPanel);

    // FETCH CONFIG DATA
    store.dispatch("config/fetchData", { force: true });
    store.dispatch("translation/fetchData", { force: true });
    store.dispatch("user/connect");

    onMounted(() => {
      f7ready((f7) => {
        const mainView = f7.views.create("#main-view", {
          url: "/home/",
        });

        const leftPanel = f7.panel.get(".panel-left");
        const rightPanel = f7.panel.get(".panel-right");

        // EVENTS ON POPUP-CLOSE
        f7.on("popupClosed", () => {
          window.setTimeout(() => {
            if (Dom7(".popup.modal-in").length > 0) Dom7(".popup-backdrop").addClass("backdrop-in");
          }, 10);
        });
      });
    });

    onErrorCaptured((error) => {
      helpers.catchError(error, true);
    });

    return { f7params, hideLeftPanel, hideRightPanel };
  },
});
</script>

<style></style>
