<template>
  <f7-page class="home-page">
    <template #fixed>
      <MainHeaderComponent />
    </template>

    <div class="bg-gray title-container">
      <div class="title">
        <font-awesome-icon :icon="['fad', 'mobile']" fixed-width />
        <div class="text">
          <h1>{{ $t.getTranslation("LBL_POST_LIST") }}</h1>
          <h3></h3>
        </div>
      </div>
    </div>

    <DataTableComponent2 ref="dataTableComponent" :columns="columns" :actions="actions" :url="url" :size="15" :nodata="$t.getTranslation('LBL_NO_DATA_TO_BE_DISPLAYED')" @performAction="performAction"></DataTableComponent2>
    <MainFooterComponent />
  </f7-page>
</template>

<script>
import { defineComponent, ref, computed, inject } from "vue";

import MainHeaderComponent from "@/components/MainHeaderComponent.vue";
import MainFooterComponent from "@/components/MainFooterComponent.vue";
import DataTableComponent2 from "@/components/DataTableComponent2.vue";

import { helpers } from "@/utils/helpers";
import { post } from "@/utils/axios";
import { useStore } from "@/store";

export default defineComponent({
  name: "PostListPage",
  components: {
    MainHeaderComponent,
    MainFooterComponent,
    DataTableComponent2,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);

    const url = ref(`/admin/post/datalist?LanguageCode=${currentLanguageCode.value || "EN"}`);

    const actions = [
      // {
      //   groupText: "Perform Actions",
      //   list: [{ text: $t.getTranslation("LBL_CHANGE_STATUS"), type: "CHANGESTATUS", bold: true }],
      // },
    ];

    const columns = [
      {
        title: $t.getTranslation("LBL_ID"),
        frozen: true,
        maxWidth: 80,
        minWidth: 80,
        formatter(cell) {
          return `
            <div>
              <div><strong>${cell.getData().PostId}</strong></div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_IMAGE"),
        maxWidth: 90,
        minWidth: 90,
        frozen: true,
        formatter(cell) {
          return `
            <div class="image-lists">
              <div class="image">
                <img src="${helpers.getImage(cell.getData().PostImage, "POST") || require("@/assets/images/placeholder.jpg")}" />
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_POST_INFO"),
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_POST_TYPE")}:</strong> ${cell.getData().PostType}</div>
              <div>${cell.getData().PostKey}</div>
              <div><strong>${$t.getTranslation("LBL_MEDIA_TYPE")}:</strong> ${cell.getData().PostMediaType || "--"}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_POST_CONTENT"),
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_LANGUAGE_CODE")}:</strong> ${cell.getData()?.LanguageCode || "--"}</div>
              <div>
                <strong>${cell.getData()?.PostTitle || ""}</strong><br />
                <span class="text-wrap">${cell.getData()?.PostText || ""}</span>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_USER_INFO"),
        formatter(cell) {
          return `
            <div>
              <div>
                <strong>${cell.getData()?.UserName || ""}</strong><br />
                ${helpers.getFullName(cell.getData()) || ""}
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_POST_COUNT"),
        formatter(cell) {
          return `
            <div>
              <div><strong>${$t.getTranslation("LBL_TOTAL_VIEW")}:</strong> ${cell.getData()?.TotalView || 0}</div>
              <div><strong>${$t.getTranslation("LBL_TOTAL_LIKE")}:</strong> ${cell.getData()?.TotalLike || 0}</div>
              <div><strong>${$t.getTranslation("LBL_TOTAL_COMMENT")}:</strong> ${cell.getData()?.TotalComment || 0}</div>
              <div><strong>${$t.getTranslation("LBL_TOTAL_PRODUCT")}:</strong> ${cell.getData()?.ProductList.length || 0}</div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_STATUS"),
        minWidth: 160,
        formatter(cell) {
          return `
            <div>
              <div class="chip ${cell.getData()?.StatusCode === "ACTIVE" ? "color-green" : "color-gray"}">
                <div class="chip-label">${cell.getData()?.StatusCode || "--"}</div>
              </div>
            </div>
          `;
        },
      },
      {
        title: $t.getTranslation("LBL_DATES"),
        formatter(cell) {
          return `
            <div>
              <div title="${$t.getTranslation("LBL_DATE_CREATED")}"><i class="far fa-fw fa-clock"></i> ${helpers.formatDateTime(cell.getData().LastCreated)}</div>
              <div title="${$t.getTranslation("LBL_DATE_UPDATED")}"><i class="far fa-fw fa-edit"></i> ${helpers.formatDateTime(cell.getData().LastModified)}</div>
            </div>
          `;
        },
      },
    ];

    return {
      columns,
      url,
      actions,
    };
  },
  methods: {
    async performAction(type, data) {
      const self = this;

      switch (type) {
        case "CHANGESTATUS":
          if (data?.value?.PostId) {
            self.$refs.changeStatusPartial.openPopup({
              saveLink: "/admin/post/status",
              saveParams: { PostId: data?.value?.PostId },
              data: {
                Title: data?.value?.PostId,
                StatusCode: data?.value?.StatusCode,
              },
            });
          }
          break;
      }
    },
    initDataTableComponent(data) {
      if (data?.isSave) {
        this.$refs.dataTableComponent.initTabulator();
      }
    },
  },
});
</script>
