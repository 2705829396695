import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'setting',
    path: '/setting/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'cog',
        title: translation.getTranslation('LBL_SETTING'),
        activeLink: 'setting'
      },
      isSecured: true,
      hasRoles: ['MANAGE_SETTING']
    },
    redirect: '/setting/language/',
    routes: [
      {
        name: 'settingAdminUser',
        path: 'adminuser/list',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_ADMIN_USER'),
            activeLink: 'settingAdminUser'
          },
          isSecured: true,
          hasRoles: ['MANAGE_ADMIN']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/admin/AdminListPage.vue')
      },
      {
        name: 'settingAdminUserView',
        path: 'adminuser/view',
        meta: {
          navigation: {
            isShown: false,
            title: translation.getTranslation('LBL_ADMIN_USER_SAVE'),
            activeLink: 'settingAdminUser'
          },
          isSecured: true,
          hasRoles: ['MANAGE_ADMIN']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/admin/AdminSavePage.vue')
      },
      {
        name: 'settingAdminUserUpload',
        path: 'adminuser/upload/:id',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'categoryList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_ADMIN']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/admin/AdminUploadPage.vue')
      },
      {
        name: 'settingLanguage',
        path: 'language/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_LANGUAGE'),
            activeLink: 'settingLanguage'
          },
          isSecured: true,
          hasRoles: ['MANAGE_LANGUAGE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/language/ListPage.vue')
      },
      {
        name: 'settingLanguageSave',
        path: 'language/save/:id',
        meta: {
          navigation: {
            isShown: false,
            title: 'Language',
            activeLink: 'settingLanguage'
          },
          isSecured: true,
          hasRoles: ['MANAGE_LANGUAGE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/language/SavePage.vue')
      },
      // {
      //   name: 'settingsShippingFee',
      //   path: 'fee/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: 'Shipping Fee',
      //       activeLink: 'settingsShippingFee'
      //     },
      //     isSecured: true,
      //     hasRoles: ['MANAGE_TRANSLATION']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/setting/shipping/ShippingFeePage.vue')
      // },
      {
        name: 'settingTranslation',
        path: 'translation/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_TRANSLATION'),
            activeLink: 'settingTranslation'
          },
          isSecured: true,
          hasRoles: ['MANAGE_TRANSLATION']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/translation/ListPage.vue')
      },
      {
        name: 'settingTranslationSave',
        path: 'translation/save/:code',
        meta: {
          navigation: {
            isShown: false,
            title: 'Translation',
            activeLink: 'settingTranslation'
          },
          isSecured: true,
          hasRoles: ['MANAGE_TRANSLATION']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/translation/SavePage.vue')
      }
    ]
  }
]

export default routes
