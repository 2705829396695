import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'order',
    path: '/order/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'shopping-cart',
        title: translation.getTranslation('LBL_ORDER'),
        activeLink: 'order'
      },
      isSecured: true,
      hasRoles: ['MANAGE_ORDER']
    },
    redirect: '/order/list/',
    routes: [
      {
        name: 'orderList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_ORDER_LIST'),
            activeLink: 'orderList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_ORDER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/order/OrderListPage.vue')
      },
      {
        name: 'orderCustomList',
        path: 'list/customs',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('LBL_ORDER_CUSTOMS_LIST'),
            activeLink: 'orderCustomList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_ORDER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/order/OrderCustomsListPage.vue')
      },
      {
        name: 'orderCustomPush',
        path: 'view/customs',
        meta: {
          navigation: {
            isShown: false,
            title: translation.getTranslation('LBL_ORDER_CUSTOMS_VIEW'),
            activeLink: 'orderCustomList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_ORDER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/order/OrderCustomsPushPage.vue')
      },
      {
        name: 'orderView',
        path: 'view/:id',
        meta: {
          navigation: {
            isShown: false,
            activeLink: 'orderList'
          },
          isSecured: true,
          hasRoles: ['MANAGE_ORDER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/order/OrderViewPage.vue')
      }
    ]
  }
]

export default routes
